import dayjs from "dayjs";
import { useTheme } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { CustomDatePicker, Loader } from "../common/components";
import { StyledLabelSm, StyledValueSm } from "../common/styledComponents";
import { convertDateFormat } from "../utils";
import { payloadGenerator, updateData } from "../services";
import { useQuoteContext, useUserContext } from "../context";

export const QuoteExpirationDate = ({ quote, dateFormat, isLegacyPage }) => {
	const [value, setValue] = useState(quote?.ExpirationDate);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const { IsLocked } = useQuoteContext();
	const { IsReadOnly } = useUserContext();
    const isEditAllowedState = !IsLocked && !IsReadOnly;
	
	useEffect(() => {
		setValue(quote?.ExpirationDate);
	}, [quote?.ExpirationDate]);

	const updateExpirationDate = useCallback(
		(expDate) => {
			setLoading(true);
			updateData(
				payloadGenerator({ name: "IdQuoteMain", value: quote?.IdQuoteMain }, "QuoteMain", {
					ExpirationDate: expDate
				})
			).then(async (res) => {
				const { response0: { error } = [] } = res;
				const { response0 } = res;
				setOpen(false);
				if (error || !response0?.partialResponse?.partialQuote?.ExpirationDate) {
					setValue("");
					setLoading(false);
					return;
				}
				quosal.sell.quote.updateFromApiResponse(response0);
				setLoading(false);
				return quote;
			});
		},
		[value, quote?.IdQuoteMain]
	);

	const onDateChange = useCallback(
		(newValue) => {
			const dt = convertDateFormat(newValue, dateFormat);
			setValue(dt);
			updateExpirationDate(dt);
		},
		[updateExpirationDate]
	);

	return (
		<>
			<StyledLabelSm sx={{marginRight:"0px"}} >Expiration:</StyledLabelSm>

			<StyledValueSm
				onClick={() => isEditAllowedState && setOpen(true)}
				data-testid={isEditAllowedState ? "test-expiration-date" : "test-expiration-date-disabled"}
				data-test-value={value || ""}
			>
				{isLegacyPage && dateFormat ? dayjs(value).format(dateFormat) :  value || ""}
			</StyledValueSm>
			<CustomDatePicker
				inputField={ButtonField}
				open={open}
				setOpen={setOpen}
				value={dayjs(value, dateFormat)}
				onChange={onDateChange}
				dateFormat={dateFormat}
				disabled={!isEditAllowedState}
			/>
			<Loader loading={loading} loadingText={"Saving"} />
		</>
	);
};

function ButtonField(props) {
	const {
		slotProps,
		setOpen,
		id,
		disabled,
		InputProps: { ref } = {},
		inputProps: { "aria-label": ariaLabel } = {}
	} = props;
	{
		const theme = useTheme();

		return (
			<ModeEditOutlinedIcon
				fontSize="small"
				sx={{
					color:disabled
						? theme.palette.text.disabled
						: slotProps?.color,
					width: "12px",
					height: "12px",
					border: "1px",
					margin: "auto",
					cursor: disabled? "default" : "pointer"
				}}
				variant="outlined"
				id={id}
				disabled={disabled}
				ref={ref}
				aria-label={ariaLabel}
				onClick={() => !disabled && setOpen?.((prev) => !prev)}
				data-testid={disabled ? "test-edit-expiration-date-disabled" : "test-edit-expiration-date"}
			/>
		);
	}
}
