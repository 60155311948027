import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTypographyBold = styled(Typography)(({ theme,sx }) => ({
	color: theme.palette.primary.main,
    fontWeight:600,
	lineHeight: "18px",
    letterSpacing: ".16px",
    fontSize:"14px",
    ...sx
}));

export { StyledTypographyBold };
