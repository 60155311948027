export const formatOtherUsersList = (otherUsers = []) => {
	let userList = "";
	if (otherUsers && otherUsers.length > 0) {
		for (var i = 0; i < otherUsers.length; i++) {
			var user = otherUsers[i];
			userList +=
				(userList == "" ? "" : ", ") +
				user.FirstName +
				" " +
				user.LastName +
				" (" +
				user.UserName +
				")";
		}
	}
	return userList;
};
