import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledValueSm = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
	lineHeight: "18px",
	letterSpacing: ".16px",
	minWidth: "auto",
	margin: "auto 10px",
	textAlign: "right"
}));

export { StyledValueSm };
