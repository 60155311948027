import { Stack, Box, useTheme } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { QuoteName } from "./QuoteName";
import { BannerComponent } from "./BannerComponent";
import { useQuoteContext } from "../context";
import { Loader } from "../common/components";
import { payloadGenerator, updateData } from "../services";

export const QuoteNameContainer = () => {
	const [focus, setFocus] = useState(false);
	const [disableUnderline, setDisableUnderline] = useState(true);
	const theme = useTheme();
	const nameRef = useRef(null);
	const { QuoteName: name, IdQuoteMain } = useQuoteContext();
	const [quoteName, setQuoteName] = useState(name);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setDisableUnderline(!focus);
	}, [focus]);

	useEffect(() => {
		setQuoteName(app.currentQuote.QuoteName);
	}, [app.currentQuote.QuoteName]);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [quoteName]);

	const handleBlur = () => {
		if (quoteName !== app.currentQuote.QuoteName) {
			setLoading(true);

			updateData(
				payloadGenerator({ name: "IdQuoteMain", value: IdQuoteMain }, "QuoteMain", {
					QuoteName: quoteName
				})
			).then(async (res) => {
				const { response0: { error } = [] } = res;
				const { response0 } = res;
				if (error) {
					console.error("Error:", error);
					setQuoteName(app.currentQuote.QuoteName);
					setLoading(false);
					return;
				}
				quosal.sell.quote.updateFromApiResponse(response0);
				setQuoteName(quoteName);
				setLoading(false);
			});
		}
		setFocus(false);
	};

	const handleClickOutside = (e) => {
		if (nameRef.current && !nameRef.current.contains(e.target)) {
			setFocus(false);
			handleBlur();
			setDisableUnderline(true);
		}
	};

	return (
		<div>
			<Stack direction="row">
				<Box
					sx={{
						display: "flex",
						height: "41px",
						alignItems: "center",
						backgroundColor: theme.palette.background.paper
					}}
				>
					<Stack direction="row">
						<Box
							sx={{
								color: theme.palette.text.input,
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center"
							}}
							ref={nameRef}
						>
							<QuoteName
								setFocus={setFocus}
								setQuoteName={setQuoteName}
								focus={focus}
								quoteName={quoteName}
								setDisableUnderline={setDisableUnderline}
								disableUnderline={disableUnderline}
								handleBlur={handleBlur}
							/>
						</Box>
					</Stack>
					<div>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								marginLeft: "8px"
							}}
						>
							<BannerComponent />
						</Box>
					</div>
				</Box>
			</Stack>
			<Loader loading={loading} loadingText={"Saving"} />
		</div>
	);
};
