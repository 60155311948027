quosal.api.admin = {};

//==========================================================//
//* FindUsers                                              *//
//==========================================================//
//==========================================================//
quosal.api.admin.findUsers = function () {
    return quosal.api.call('Admin.FindUsers', {});
};

quosal.api.admin.initApprovalGroups = function () {
    return quosal.api.call('Admin.InitApprovalGroups', {});
};

quosal.api.admin.saveApprovalGroup = function (id, name, userIds) {
    return quosal.api.call('Admin.SaveApprovalGroup', {
        id: id,
        name: name,
        userids: userIds
    });
};

quosal.api.admin.InitApprovalRules = function () {
    return quosal.api.call('Admin.InitApprovalRules', {});
};

quosal.api.admin.saveApprovalRule = function (id, name, notification, isActive, approvalorder, templates, locations, teams, criteria, approvers) {
    return quosal.api.call('Admin.SaveApprovalRule', {
        id: id,
        name: name,
        notification: notification,
        active: isActive,
        approvalorder: approvalorder,
        templates: templates,
        locations: locations,
        teams: teams,
        criteria: criteria,
        approvers: approvers
    });
};

quosal.api.admin.enableDisableRule = function (id, isActive) {
    return quosal.api.call('Admin.EnableDisableRule', {
        id: id,
        active: isActive
    });
};

quosal.api.admin.validateLicenseCount = function () {
    return quosal.api.call('Admin.ValidateLicenseCount');
};

quosal.api.admin.validateReadOnlyLicenseCount = function () {
    return quosal.api.call('Admin.ValidateReadOnlyLicenseCount');
};

quosal.api.admin.createApiKey = function (description) {
    return quosal.api.call('Admin.CreateApiKey', {
        description: description
    });
}

quosal.api.admin.deleteApiKey = function (id) {
    return quosal.api.call('Admin.DeleteApiKey', {
        id: id
    });
}

quosal.api.admin.InitGuidedSelling = function (filter) {
    return quosal.api.call('Admin.GuidedSelling.InitGuidedSelling', {filter});
}

quosal.api.admin.GetSalesTrack = function () {
    return quosal.api.call('Admin.GuidedSelling.GetSalesTrack', {});
}

quosal.api.admin.DeleteSalesTrack = function (id) {
    return quosal.api.call('Admin.GuidedSelling.DeleteSalesTrack', {
        id: id
    });
}

quosal.api.admin.PostSalesTracks = function (newSalesTrack) {
    const { id, name, description, state, notes, picture, quoteTemplateId } = newSalesTrack;

    return quosal.api.call('Admin.GuidedSelling.PostSalesTracks', {
        id,
        name,
        description,
        state,
        notes,
        picture,
        quoteTemplateId
    });
}

quosal.api.admin.GetSalesTrackForm = function (id) {
    return quosal.api.call('Admin.GuidedSelling.GetSalesTrackForm', {
        id: id
    });
}

quosal.api.admin.PostSalesTrackForm = function (newSalesTrackForm) {
    return quosal.api.call('Admin.GuidedSelling.PostSalesTrackForm', {
        idSalesTracks: newSalesTrackForm.idSalesTracks,
        sections: newSalesTrackForm.sections
    });
}

quosal.api.admin.ValidateManufacturerPartNumber = function (manufacturerPartNumber) {
    return quosal.api.call('Admin.GuidedSelling.ValidateManufacturerPartNumber', {
        manufacturerPartNumber: manufacturerPartNumber
    });
}

quosal.api.admin.PostSalesTrackCompletedForm = function (salesTrackCompletedForm) {
    const { questions, idSalesTracks, idQuote, idQuoteTabs } = salesTrackCompletedForm;
    return quosal.api.call('Admin.GuidedSelling.PostSalesTrackCompletedForm', {
        questions,
        idSalesTracks,
        idQuote,
        idQuoteTabs,
    });
}

quosal.api.admin.GetSalesTrackCompletedForms = function (bodyGetSubmittedSalesTracks) {
    const { idQuote, sortBy, sortDirection, pageNumber, pageSize, filter } = bodyGetSubmittedSalesTracks;
    return quosal.api.call('Admin.GuidedSelling.GetSalesTrackCompletedForms', {
        idQuote,
        sortBy,
        sortDirection,
        pageNumber,
        pageSize,
        filter,
    });
}

quosal.api.admin.GetSalesTrackCompletedForm = function (bodyGetSubmittedForm) {
    const { idQuote, idCompletedForm } = bodyGetSubmittedForm;
    return quosal.api.call('Admin.GuidedSelling.GetSalesTrackCompletedForm', {
        idQuote,
        idCompletedForm,
    });
}

quosal.api.admin.SyncDataToBlob = function () {
    return quosal.api.call('Admin.DataSync.SyncDataToBlob');
};

quosal.api.admin.syncDataToFranchisees = function (syncItem) {
    return quosal.api.call('Admin.DataSync.SyncData', {
        syncItem: syncItem
    });
};