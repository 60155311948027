import { forwardRef } from "react";
import { Alert, AlertTitle, Snackbar, Typography, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { errorAlertRed, successAlertGreen } from "../../styles/customColors";

const getAlertTitleAndIcon = (severity) => {
	switch (severity) {
		case "success":
			return {
				title: "Success",
				icon: <CheckCircleIcon fontSize="small" sx={{ color: successAlertGreen }} />,
				color: successAlertGreen
			};
		case "error":
			return {
				title: "Error",
				icon: <ErrorIcon fontSize="small" sx={{ color: errorAlertRed }} />,
				color: errorAlertRed
			};

		default:
			throw new Error("Not implemented");
	}
};

const CustomAlert = forwardRef(({ severity, message, width = "487px" }, ref) => {
	const theme = useTheme();
	const { color, icon, title } = getAlertTitleAndIcon(severity);
	return (
		<Alert
			ref={ref}
			severity={severity}
			sx={{
				width,
				backgroundColor: theme.palette.background.paper,
				color: theme.palette.text.primary,
				borderLeft: `3px solid ${color}`
			}}
			icon={icon}
		>
			<AlertTitle
				sx={{ fontWeight: 600, marginTop: 0, lineHeight: "18px", letterSpacing: "0.16px" }}
			>
				{title}
			</AlertTitle>
			<Typography sx={{ lineHeight: "18px", letterSpacing: "0.16px" }}>{message}</Typography>
		</Alert>
	);
});

export const ToastNotification = ({ toast, setToast, autoHideDuration = 3000 }) => {
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setToast(null);
	};

	if (toast === null) return null;
	const { message, type } = toast;

	return (
		<Snackbar
			sx={{ height: "68px", zIndex: 9998 }}
			open={!!toast}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
		>
			<CustomAlert message={message} severity={type} />
		</Snackbar>
	);
};
