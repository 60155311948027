import { useState, useMemo, createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const ColorModeContext = createContext();

function QuoteHeaderTheme({ children }) {
	const [darkMode, setDarkMode] = useState($("#dark-toggle").prop("checked"));

	const colorMode = useMemo(() => {
		const $checkbox = $("#dark-toggle[type=checkbox]");
		$checkbox.on("change", function () {
			setDarkMode(this.checked);
		});

		return () => {
			$checkbox.off("change");
		};
	}, []);

	const mode = useMemo(() => (darkMode ? "dark" : "light"), [darkMode]);

	const theme = createTheme(themes[mode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
}
const themes = {
	light: {
		typography: {
			fontFamily: `"Inter", sans-serif`
		},
		palette: {
			mode: "light",
			primary: {
				main: "#2E3F80",
				light: "#576599",
				dark: "#202c59",
				contrastText: "#fff"
			},
			secondary: {
				main: "#308CAB",
				light: "#59a3bb",
				dark: "#216277",
				contrastText: "#fff"
			},
			text: {
				primary: "#101120",
				secondary: "#767786",
				disabled: "#C6C6C6",
				input: "#2E3F80",
				error: "#ff0000",
				chipHT: "#151515",
				chipDT: "#FFFFFF",
				chipLD: "#101120",
				chipET: "#161616",
			},
			background: {
				primary: "#F6F6F9",
				paper: "#fff",
				default: "#fff",
				chipHT: "#FBAA45",
				chipDT: "#EE5A56",
				chipET: "#A1D79C",
				chipLD: '#F6F6F9',
				icon: "#eeeeee"
			},
			icon: {
				main: "#27366D",
				secondary: "#4C5780",
				inverse: "#FAFAFA",
				primary: "#2D7CB2",
				turnary: "#25B6BE",
				blankChart: "rgba(153, 153, 156, 0.16)"
			},
			quote_step : { 
				fontColor: "#0093d0",
				bgColor: "#ffffff",
				activeStepColor: "#f78f1e",
				lockedColor: "#8b8b8b",
				isEoNav: '#80c000',
				lockedFontColor: "#cccccc"
			}
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: `
					@font-face {
					  font-family: 'Inter';
					}
				  `
			},
			MuiTypography: {
				styleOverrides: {
					root: { fontSize: 14 }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { textTransform: "none" }
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: { boxShadow: "0px 4px 8px 0px #0000001A", boxSizing: "border-box" }
				}
			},
			MuiInputLabel: {
				styleOverrides: {
					asterisk: {
						color: "#D32F2F"
					}
				}
			}
		}
	},
	dark: {
		typography: {
			fontFamily: `"Inter", sans-serif`
		},
		palette: {
			mode: "dark",
			primary: {
				main: "#0093d0",
				light: "#33a8d9",
				dark: "#006691",
				contrastText: "#e0e0e0"
			},
			secondary: {
				main: "#308CAB",
				light: "#59a3bb",
				dark: "#216277",
				contrastText: "#e0e0e0"
			},
			text: {
				primary: "#e0e0e0",
				secondary: "#e0e0e0",
				disabled: "#65666f",
				input: "#CCCCCC",
				error: "#ff0000",
				chipHT: "#151515",
				chipDT: "#FFFFFF",
				chipLD: "#101120",
				chipET: "#161616",
			},
			background: {
				primary: "#384448",
				paper: "#333333",
				default: "#434343",
				chipHT: "#FBAA45",
				chipDT: "#EE5A56",
				chipET: "#A1D79C",
				chipLD: '#F6F6F9',
				icon: "#333333"
			},
			icon: {
				main: "#27366D",
				secondary: "#FFF",
				inverse: "#FAFAFA",
				primary: "#2D7CB2",
				turnary: "#25B6BE",
				blankChart: "rgba(153, 153, 156, 0.16)"
			},
			quote_step : { 
				fontColor: "#3AAACF",
				bgColor: "#333333",
				activeStepColor: "#FCB663",
				lockedColor: "#434343",
				isEoNav: '#74ad01',
				lockedFontColor: "#9C9C9C"
			}
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: `
					@font-face {
					  font-family: 'Inter';
					}
				  `
			},
			MuiTypography: {
				styleOverrides: {
					root: { color: "#e0e0e0", fontSize: 14 }
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { backgroundColor: "#434343" }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { textTransform: "none" }
				}
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: "0 0 6px #b9b9b9",
						boxSizing: "border-box",
						backgroundImage: "none"
					}
				}
			},
			MuiInputLabel: {
				styleOverrides: {
					asterisk: {
						color: "#D32F2F"
					}
				}
			}
		}
	}
};

export { QuoteHeaderTheme, themes };
