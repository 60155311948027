import { ATTACHED_DYNAMIC_QUESTION_INPUT_VALUE_MESSAGE, COMMON_GRID_PADDING, DIVIDER_STYLE, DUPLICATED_FIELD_ERROR, EMPTY_FIELD_ERROR, ENUM_DATA_NAME, ENUM_KEY_CHECKBOX, INPUT_FIELD_PRODUCTS_MESSAGE, INPUT_VALUE_CHARACTER_LIMIT, MANDATORY_FIELD_HELPER_TEXT, MAX_INPUT_VALUES, MAX_MIN_OPTIONS, MIN_NUMERIC_VALUE, MIN_OPTIONS_HIGH_EQUAL_ERROR_MESSAGE } from "../../../constants/createFormConstants";
import { Button, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { CommonInputTextStyled, CustomWidthTooltip } from "../../../../../common/guidedSelling/styledComponents/commonStyled";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DeleteInputButtonStyled } from "../../../styledComponents/createFormStyled";
import { FullFeaturedCrudGrid } from "../mapTable";
import FormContext from "../../../contexts/configureFormContext";
export class OptionTypeForm extends React.Component {

    static contextType = FormContext;
    constructor(props) {
        super(props);

        this.state = {
                mapValue: '',
                indexValueToMap: -1,
        }
    }

    componentDidMount() {
    }

    render() {

        const { handleValidateMandatoryInput, updateAnswersReference, questionIndex, sectionIndex, theme, question: { answers, mappedAnswers, isActive, answerDataType, isRequired,dynamicQuesConditions }, question } = this.props;
        const { mapValue, indexValueToMap } = this.state;
        const { formActions, isSendable } = this.context;
        const handleValidateDuplicatedInputValue = (answerIndex) => {
            formActions['validate-duplicated-answer'](sectionIndex, questionIndex, answerIndex);
        };
        const handleSaveMapping = () => {
            if (mapValue !== '') {
                if (answers[indexValueToMap].answer !== '') {
                    formActions['add-manufacturerPartNumber'](mapValue, sectionIndex, questionIndex, indexValueToMap);
                    this.setState({ mapValue: '', indexValueToMap: -1 });
                    updateAnswersReference();
                } else {
                    formActions['validate-empty-answer'](sectionIndex, questionIndex, indexValueToMap)
                }
            }
        };

        const handleFormatterAnswers = (arrayToFormat) => {
            return arrayToFormat.map(({ answer, sortOrder }) => { return { label: answer, value: sortOrder } })
        };

        const handleChangeInputValueState = (value, answerIndex) => {
            this.setState({ mapValue: value, indexValueToMap: answerIndex });
        }

        const handleChangeValueToMapIndex = (answerIndex) => {
            this.setState({ ...this.state, indexValueToMap: answerIndex })
        }

        const handleChangeHasMinOptions = (event) => {
            const { target: { checked, name } } = event;
            formActions['common'](checked, name, sectionIndex, questionIndex);
        };

        const handleMinOptionsChange = (event) => {
            const { target: { value, name } } = event;
            const intValue = parseInt(value);
            if ((intValue >= MIN_NUMERIC_VALUE && intValue <= (answers.length - 1)) || value === '') {
                const newValue = !!intValue ? intValue : value;
                formActions['common'](newValue, name, sectionIndex, questionIndex);
            };
        };

        const isMappedWithDynamicQuestion = (inputValue) => {
            return dynamicQuesConditions?.find((dQuest) => dQuest?.answer  === inputValue?.answer);
        }

        return <>
            {
                answers.map((inputValue, answerIndex) =>{
                    const isMapped = isMappedWithDynamicQuestion(inputValue);
                    return (<Grid key={'form-section-' + sectionIndex + '-question-' + questionIndex + '-input-value-' + answerIndex} container item xs={12} p={COMMON_GRID_PADDING} justifyContent='space-between' alignItems='flex-start'>
                            <Grid item xs={8} pr='16px'>
                                <CommonInputTextStyled
                                    error={inputValue?.isRepeated ? inputValue?.isRepeated : inputValue?.isEmpty ? inputValue?.isEmpty : answerIndex < 2 && handleValidateMandatoryInput(inputValue.answer)}
                                    helperText={inputValue?.isRepeated ? DUPLICATED_FIELD_ERROR : inputValue?.isEmpty ? EMPTY_FIELD_ERROR : (inputValue.answer && inputValue.products.length > 0) ? INPUT_FIELD_PRODUCTS_MESSAGE : answerIndex < 2 && handleValidateMandatoryInput(inputValue.answer) ? MANDATORY_FIELD_HELPER_TEXT : null}
                                    required={answerIndex < 2}
                                    disabled={inputValue.answer !== '' && !inputValue.isEmpty && !inputValue.isRepeated && (!isActive || inputValue.products.length > 0 || isMapped)}
                                    inputProps={{ maxLength: INPUT_VALUE_CHARACTER_LIMIT }}
                                    label='Input value to be mapped'
                                    value={inputValue.answer}
                                    onChange={(event) => formActions['answers'](event.target.value, sectionIndex, questionIndex, answerIndex)}
                                    onBlur={() => handleValidateDuplicatedInputValue(answerIndex)}
                                />
                            </Grid>
                            <Grid item xs={3.3} pr={ window.devicePixelRatio === 1.5 && '16px'}>
                                <CustomWidthTooltip title={mapValue}>
                                    <CommonInputTextStyled
                                        disabled={!isActive}
                                        id={`input-value-section-${sectionIndex}-question-${questionIndex}-manufacturer-${answerIndex}`}
                                        label='Add manufacturer part number of product to map'
                                        value={indexValueToMap === answerIndex ? mapValue : ''}
                                        onClick={() => handleChangeValueToMapIndex(answerIndex)}
                                        onChange={(event) => handleChangeInputValueState(event.target.value, answerIndex)}
                                        onBlur={() => handleSaveMapping()}
                                    />
                                </CustomWidthTooltip>
                            </Grid>
                            <CustomWidthTooltip title={isMapped ? ATTACHED_DYNAMIC_QUESTION_INPUT_VALUE_MESSAGE : ""}>
                                <Grid item container xs={0.7} justifyContent='center' alignItems='center'>
                                    <DeleteInputButtonStyled id={`delete-question-button-section-${sectionIndex}-question-${questionIndex}`} disabled={answerIndex < 2 || !isActive || inputValue.products?.length > 0 || isMapped} onClick={() => formActions['remove-answer'](sectionIndex, questionIndex, answerIndex)}><DeleteOutlineOutlinedIcon fontSize="small"/></DeleteInputButtonStyled>
                                </Grid>
                            </CustomWidthTooltip>
                        </Grid>
                        )
                }
                )
            }
            <Grid item xs={12} p={COMMON_GRID_PADDING} sx={{ borderBottom: DIVIDER_STYLE }}>
                <Button disabled={!isActive ? !isActive : answers.length >= MAX_INPUT_VALUES} id={`add-input-value-button-section-${sectionIndex}-question-${questionIndex}`} color="button_primary" sx={{ padding: '5px 16px', textTransform: 'none' }} onClick={() => formActions['add-answer'](sectionIndex, questionIndex)} endIcon={<AddIcon />} >Add Input Value</Button>
            </Grid>
            {
                answerDataType === ENUM_DATA_NAME[ENUM_KEY_CHECKBOX] &&
                <>
                    <Grid key={'form-section-' + sectionIndex + '-set-minimum-options-' + questionIndex} item xs={12} p={COMMON_GRID_PADDING}>
                        <FormControlLabel disabled={!isRequired || !isActive} control={<Checkbox name='hasMinOptions' checked={question?.hasMinOptions} onChange={handleChangeHasMinOptions} />} label="Set minimum options to be selected" />
                    </Grid>
                    {
                        question?.hasMinOptions &&
                            <Grid item xs={2} pb='20px'>
                                <CommonInputTextStyled
                                    value={question?.minOptions}
                                    type='number'
                                    disabled={!isActive}
                                    id={`min-options-section-${sectionIndex}-question-${questionIndex}`}
                                    label='Minimum options'
                                    haslimit={1}
                                    minlimit={MIN_NUMERIC_VALUE}
                                    maxlimit={(answers.length - 1)}
                                    name='minOptions'
                                    onChange={handleMinOptionsChange}
                                    required
                                    error={!isSendable && question?.minOptions >= question.answers.length ? question?.minOptions >= question.answers.length : handleValidateMandatoryInput(question?.minOptions)}
                                    helperText={!isSendable ? question?.minOptions >= question.answers.length ? MIN_OPTIONS_HIGH_EQUAL_ERROR_MESSAGE : handleValidateMandatoryInput(question?.minOptions) ? MANDATORY_FIELD_HELPER_TEXT : null : null}
                                />
                            </Grid>
                    }
                </>
            }
            {!!mappedAnswers && mappedAnswers.length > 0 && < FullFeaturedCrudGrid theme={theme} rows={mappedAnswers} options={handleFormatterAnswers(answers)} isActive={isActive} formActions={formActions} updateReference={updateAnswersReference} dynamicQuestionLists={dynamicQuesConditions} />}
        </>
    }
}