import { useState, useEffect } from "react";

const useDarkMode = () => {
	const [darkMode, setDarkMode] = useState(() => $("#dark-toggle").prop("checked"));

	useEffect(() => {
		const $checkbox = $("#dark-toggle[type=checkbox]");
		const handleChange = function () {
			setDarkMode(this.checked);
		};

		$checkbox.on("change", handleChange);

		return () => {
			$checkbox.off("change", handleChange);
		};
	}, []);

	return darkMode;
};

export default useDarkMode;
