import {CustomizerFieldListItem} from "js/jsx/src/classes/forms/customization.jsx";
import {DateTimeInput, DateTimeRangeInput, NumericRangeInput} from "js/jsx/src/classes/forms/specialInputs.jsx";
import {ModificationTag} from "js/jsx/src/classes/quote/modificationTag.jsx";
import {PriceModifierHelper} from "js/jsx/src/classes/quote/priceModifierHelper.jsx";
import {ColorPicker} from "js/jsx/src/classes/colorPicker.jsx";
import {GridColumn} from "js/jsx/src/classes/grids.jsx";
import {EditableImage} from "js/jsx/src/classes/imageEditor.jsx";
import {RtfLoader} from "js/jsx/src/classes/rtfEditor.jsx";

export class FormPlaceholder extends React.Component {
    componentDidMount() {
        $(this.refs.spinner).append(quosal.ui.spinners.icon());
    }
    render() {
        // puts a spinner and label in a panel as a placeholder for asynchronous server data
        var message = this.props.message ? this.props.message : 'Loading Data...';
        return (
            <div className="form-placeholder" style={this.props.style}>
                <div style={{display:'inline-block', padding:'5px'}}>
                    <div ref="spinner" style={{marginLeft: '-5px'}} />
                    <div className="loading-message">{message}</div>
                </div>
            </div>
        );
    }
}
global.FormPlaceholder = FormPlaceholder;

export class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false, 
            editConfig: null, 
            sortRoots: [],
            isDirty: false,
        };
        // This binding is necessary to make `this` work in the callback
        this.fieldChanged = this.fieldChanged.bind(this);
        this.promptForAction = this.promptForAction.bind(this);
        this.submit = this.submit.bind(this);
        this.validate = this.validate.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.addPanel = this.addPanel.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.clearSelectedFields = this.clearSelectedFields.bind(this);
        this.removePanel = this.removePanel.bind(this);
        this.changePanelTitle = this.changePanelTitle.bind(this);
        this.repairFieldOrder = this.repairFieldOrder.bind(this);
        this.changeConfigurationName = this.changeConfigurationName.bind(this);
        this.deleteBadConfigurations = this.deleteBadConfigurations.bind(this);
        this.saveConfiguration = this.saveConfiguration.bind(this);
        this.loadConfiguration = this.loadConfiguration.bind(this);
        this.deleteConfiguration = this.deleteConfiguration.bind(this);
        this.getFields = this.getFields.bind(this);
        this.prepareThenSave = this.prepareThenSave.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.clean = this.clean.bind(this);
        this.reset = this.reset.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.repairPanelOrder = this.repairPanelOrder.bind(this);
        this.updateSorting = this.updateSorting.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.onWindowUnloading = this.onWindowUnloading.bind(this);
    }
    static onNavigation(navState) {
        if (!this) {
            return;
        }
        var runOnNavigation = function () {
            if (quosal.htmlEditorCallback) {
                quosal.htmlEditorCallback();
                delete quosal.htmlEditorCallback;
            }
            if (this.state.isDirty && !this.props.noSave) {
                var resumeNavigation = function (navState) {
                    if (typeof this.reset === 'function') {
                        this.reset();
                    }

                    Dialog.closeAll();

                    navState.resumeNavigation();
                }.bind(this, navState);

                var saveChanges = function (navState) {
                    if (this.props.onSubmit) this.props.onSubmit();

                    resumeNavigation();
                }.bind(this, navState);

                var title = 'Save Changes?'
                var message = 'Do you want to save changes before leaving this page?';
                var links = [];

                if(!this.props.productEditForm || (this.props.productEditForm && (!this.props.productEditForm.state.invalidFields || !this.props.productEditForm.state.invalidFields.length > 0))){
                    links.push({
                        title: 'Save',
                        callback: saveChanges
                    })                   
                } else {
                    var invalidDisaplyFields = this.props.productEditForm.state.invalidFields.map(fieldName => quosal.customization.fields.getFieldDisplayName('BusinessObject', 'QuoteItems', fieldName));
                    invalidDisaplyFields = invalidDisaplyFields.join(', ').replace(/,(?=[^,]*$)/, ' and');
                    title = 'Unable to Save Changes'
                    message = 'Invalid modifier entered into the ' + invalidDisaplyFields + ' field(s). \
                    Press Cancel to return to the Item Edit screen and enter a valid modifier or \
                    Discard to discard all changes made to the page.'
                }

                links = links.concat([{
                    title: 'Discard',
                    callback: resumeNavigation
                }, {
                    title: 'Cancel',
                    callback: Dialog.closeAll
                }]);
                

                Dialog.open({
                    title: title,
                    message: message,
                    closeRequiresButton: true,
                    links: links
                });

                if (navState.cancelNavigation)
                    navState.cancelNavigation();
            }
        }.bind(this);

        if (this.prepareThenSave) {
            this.prepareThenSave(runOnNavigation);
        } else {
            runOnNavigation();
        }
    }

    static onWindowUnloading(e) {
        if (!this.props) return;
        if(!this.props.noSave && this.state.isDirty) {
            if(!e) e = {};
            e.preventDefault();
            e.cancelBubble = true;
            e.returnValue = 'There are unsaved changes that will be lost, are you sure you want to navigate away?';
            return e.returnValue;
        }
    }

    static hookUpNavigationActions() {
        quosal.events.beforeWindowUnload.bind(this.onWindowUnloading, 0);
        quosal.navigation.onBeforeNavigate.bind(this.onNavigation);
        quosal.sell.modules.onBeforeLoad.bind(this.onNavigation);
    }

    static unhookNavigationActions() {
        quosal.events.beforeWindowUnload.unbind(this.onWindowUnloading);
        quosal.navigation.onBeforeNavigate.unbind(this.onNavigation);
        quosal.sell.modules.onBeforeLoad.unbind(this.onNavigation);
    }
   
    promptForAction(args) { //Use this function before using an action that will lead to a page navigation, such as delete
        if(typeof args === 'function')
            args = { confirm: args };

        if (this.state.isDirty && !this.props.noSave) {
            if (quosal.htmlEditorCallback) {
                quosal.htmlEditorCallback();
                delete quosal.htmlEditorCallback;
            }

            Dialog.open({
                title: args.title || 'Save Changes?',
                message: args.message || 'This action may cause unsaved changes to be lost. Do you want to save changes before proceeding?',
                links: [{
                    title: args.saveLinkTitle || 'Save',
                    callback: function (args) {
                        var finishedEvent = null;

                        if (this.props.onSubmit)
                            finishedEvent = this.props.onSubmit();
                        else
                            this.reset();

                        if (finishedEvent && finishedEvent.bind) {
                            finishedEvent.bind(function(args) {
                                Dialog.close({
                                    callback: function(args) {
                                        args.confirm();
                                    }.bind(this, args)
                                });
                            }.bind(this, args));
                        } else {
                            Dialog.close({
                                callback: function(args) {
                                    args.confirm();
                                }.bind(this, args)
                            });
                        }
                    }.bind(this, args)
                }, {
                    title: args.discardLinkTitle || 'Discard',
                    callback: function (args) {
                        this.reset();
                        Dialog.close({
                            callback: function(args) {
                                args.confirm();
                            }.bind(this, args)
                        });
                    }.bind(this, args)
                }, {
                    title: args.cancelLinkTitle || 'Cancel',
                    callback: args.cancel || Dialog.closeAll
                }]
            });
        } else {
            args.confirm();
        }
    }
    submit(sourceField) {
        if (quosal.htmlEditorCallback) {
            quosal.htmlEditorCallback();
            delete quosal.htmlEditorCallback;
        }

        var validation = this.validate();

        for(var i = 0; i < validation.length; i++) {
            if(validation[i].level == 'error' && !validation[i].allowSave) {
                return;
            }
        }

        if(this.props.onSubmit) {
            this.props.onSubmit(sourceField);
        }
    }
    validate(action) {
        var result = [];

        if(this.props.validateField) {
            var fields = this.getFields({fullField:true});

            for(var i = 0; i < fields.length; i++) {
                var validation = this.props.validateField(fields[i].refs.input, action);

                if(validation) {
                    fields[i].setValidation(validation);

                    result.push(validation);
                } else {
                    fields[i].clearValidation();
                }
            }
        }

        return result;
    }   
    toggleEditMode() {
        if(!this.state.editMode) {
            //entering edit mode
            var configClone = quosal.util.clone(this.props.configuration, 2);
            if (configClone.Fields && configClone.Fields.length){
                for (var i = 0; i < configClone.Fields.length; i++) {
                    delete configClone["Fields"][i]["Value"];
                }
            }

            this.setState({ editMode: true, editConfig: configClone })
        } else {
            //leaving edit mode
            this.setState({ editMode: false, editConfig: null })
        }
    }
    addPanel() {
        var panel = {
            IsNew: true,
            IdFormPanel: quosal.util.generateGuid(),
            PanelTitle: 'New Panel',
            RowIndex: this.state.editConfig.Panels.length,
            ColumnIndex: 0,
            GridIndex: 0,
            CanRemove: true
        };
        if (!quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard) && app.currentUser.IsQuosalAdmin) {
            panel.QuosalAdminOnly = true;
        }

        this.state.editConfig.Panels.push(panel);

        this.forceUpdate();
    }
    addField(fieldName, panel, callback) {
        var fields = quosal.customization.fields[this.state.editConfig.ObjectType][this.state.editConfig.ObjectName];
        var allFields = fields.standardFields.concat(fields.additionalFields);
        var field = allFields.where((f)=>f.FieldName == fieldName).firstOrNull();
        var fieldConfig = fields.fieldConfigurations[field.FieldName];
        var displayName = fieldConfig && fieldConfig.FieldRename || field.DisplayName;

        var columnIndex = 0;
        var rowIndex = this.state.editConfig.Fields.where((s)=>s.IdFormPanel == panel.props.panel.IdFormPanel && s.ColumnIndex == columnIndex).length;
        var fieldCustomization = quosal.customization.forms[this.props.configuration.FormName].Configurations.Default.Fields.firstOrNull((s)=>s.FieldName == field.FieldName);
        //TODO: FormWidget implementation (See FormConfigurationCustom.cs)

        if (field != null) {
            var formField = {
                IsNew: true,
                IdFormPanel: panel.props.panel.IdFormPanel,
                FieldName: fieldName,
                DataType: field.DataType,
                ColumnIndex: columnIndex,
                RowIndex: rowIndex,
                CanRemove: true,
                LabelName: displayName
            };
            if(fieldCustomization && fieldCustomization.EnumType){
                formField.DataType = fieldCustomization.DataType;
                formField.EnumType = fieldCustomization.EnumType;
            }
            if(fieldCustomization && fieldCustomization.WidgetType)
                formField.WidgetType = fieldCustomization.WidgetType;
            if(fieldCustomization && fieldCustomization.WidgetParameters)
                formField.WidgetParameters = fieldCustomization.WidgetParameters;

            this.state.editConfig.Fields.push(formField);

            this.forceUpdate(callback);
        }
    }
    removeField(fieldName, panel, callback) {
        var formField = this.state.editConfig.Fields.where((c)=>c.FieldName == fieldName).firstOrNull();
        var index = this.state.editConfig.Fields.findIndex((c)=>c.FieldName == fieldName);

        if(formField != null && index >= 0) {
            this.state.editConfig.Fields.splice(index, 1);

            for(var i = 0; i < this.state.editConfig.Fields.length; i++) {
                if(this.state.editConfig.Fields[i].ColumnIndex > index)
                    this.state.editConfig.Fields[i].ColumnIndex--;
            }

            this.forceUpdate(callback);
        }
    }
    clearSelectedFields(panel, callback) {
        this.state.editConfig.Fields.removeAll(s=>s.IdFormPanel == panel.props.panel.IdFormPanel);
        this.forceUpdate(callback);
    }
    removePanel(panel) {
        if(this.state.editConfig.Fields)
            this.state.editConfig.Fields.removeAll((s) => s.IdFormPanel == panel.props.panel.IdFormPanel);
        if(this.state.editConfig.Panels)
            this.state.editConfig.Panels.removeAll((s) => s.IdFormPanel == panel.props.panel.IdFormPanel);

        this.forceUpdate();
    }
    changePanelTitle(panel, newTitle) {
        panel.props.panel.PanelTitle = newTitle;

        this.forceUpdate();
    }
    repairFieldOrder() {
        for(var iPanel in this.refs) {
            if(iPanel.indexOf('panel') == 0) {
                this.refs[iPanel].repairFieldOrder();
            }
        }
    }
    changeConfigurationName(newName) {
        this.state.editConfig.ConfigurationName = newName;
    }
    deleteBadConfigurations() {
        //This will only run if the user has pre-existing bad configs that were saved before the ProductSearch config field validation was added.
        var allConfigs = quosal.customization.forms[this.state.editConfig.FormName].Configurations;
        var badConfigArray = [];
        for (var configName in allConfigs) {
            if (!allConfigs[configName].Fields || allConfigs[configName].Fields.Length < 1) {
                badConfigArray.push(configName);
            }
        }
        if (badConfigArray.length > 0) {
            for (var badConfig of badConfigArray) {
                quosal.customization.forms.deleteBadConfig(this.state.editConfig.FormName, badConfig);
            }
        }
    }
    saveConfiguration() {
        // These two if blocks can be conslidated
        if (!this.state.editConfig.Panels || this.state.editConfig.Panels.length < 1) {
            Dialog.open({
                title: 'Invalid Configuration',
                message: 'You must add at least one panel to save this configuration.',
                links: [{ title: 'OK', callback: Dialog.closeAll }]
            });
            return;
        }
        if (this.state.editConfig.FormName.toLowerCase() === "productsearch") {
            if (!this.state.editConfig.Fields || this.state.editConfig.Fields.length < 1) {
                Dialog.open({
                    title: 'Invalid Configuration',
                    message: 'You must add at least one field to save this configuration.',
                    links: [{ title: 'OK', callback: Dialog.closeAll }]
                });
                return;
            }
        }

        var componentDidMount = function () {
            this.props.form.setState({ formSaveDialog: this }); // `this` will refer to the instance of FormSaveDialog that did mount
        };
        var componentWillUnmount = function () {
            this.props.form.setState({ formSaveDialog: null }); // `this` will refer to the instance of FormSaveDialog that will unmount
        };

        var currentGlobalConfigName = quosal.settings.getValue(this.props.configuration.FormName + '_FormConfiguration') || 'Default';
        var globalChangeConfigFunction = function (isChecked, formName, newConfigurationName, callback) {
            if (isChecked) {
                quosal.customization.forms.changeConfig.call(this, formName, newConfigurationName, callback);
            } else {
                quosal.customization.forms.changeConfig.call(this, formName, 'Default', callback);
            }
        }.bind(this);
        var globalConfigSaveSpecification = {
            key: 'global',
            changeConfigFunction: globalChangeConfigFunction,
            checkboxLabel: 'Use This as the Global Form Configuration',
            isCheckedInitialValue: function (value) { return value == currentGlobalConfigName; },
            addActionPreviewText: 'Set this as the global form configuration',
            removeActionPreviewText: 'Reset the global form configuration back to "Default"'
        };
        var saveSpecifications = [globalConfigSaveSpecification];
        if (this.props.saveSpecifications && this.props.saveSpecifications.length) {
            saveSpecifications = saveSpecifications.concat(this.props.saveSpecifications);
        }

        var saveForm = <FormSaveDialog form={this} saveSpecifications={saveSpecifications} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount} />;

        var doSave = function () {
            if (!this.state.formSaveDialog) {
                return true;
            }
            var updatedConfigName = this.state.formSaveDialog.getUpdatedConfigName();
            if (!updatedConfigName) {
                return true;
            }

            var oldConfigName = this.state.editConfig.ConfigurationName;
            this.state.editConfig.ConfigurationName = updatedConfigName;

            var callbacks = []
            for (var i = 0; i < this.state.formSaveDialog.props.saveSpecifications.length; i++) {
                var specification = this.state.formSaveDialog.props.saveSpecifications[i];
                if (specification.isChanged) {
                    callbacks.push(specification.changeConfigFunction.bind(this, specification.isChecked))
                }
            }

            var runAllCallbacksAndFinishUp = function recursiveRunAllCallbacks(formToUpdate) {
                if (callbacks.length > 0) {
                    var callback = callbacks.shift();
                    callback(this.state.editConfig.FormName, this.state.editConfig.ConfigurationName, recursiveRunAllCallbacks.bind(this, formToUpdate));
                } else {
                    if (formToUpdate) {
                        quosal.customization.forms.update(formToUpdate);
                    } else {
                        quosal.customization.forms.configurationChanged.call();
                    }
                    this.setState({ editMode: false, isSaving: false, editConfig: null });
                    Dialog.close();
                }
            }.bind(this);

            if (!this.state.formSaveDialog.validate()) {
                if (callbacks.length > 0) {
                    this.setState({ isSaving: true });
                    runAllCallbacksAndFinishUp();
                } else {
                    this.state.editConfig.ConfigurationName = oldConfigName;
                    return true;
                }
            } else {
                this.setState({ isSaving: true });
                Dialog.setIsWorking(true);
                var saveApi = quosal.api.customization.saveFormConfiguration(this.state.editConfig);
                saveApi.finished = function (msg) {
                    runAllCallbacksAndFinishUp(msg.form);
                }.bind(this);
                saveApi.call();
                if (this.state.editConfig.FormName.toLowerCase() === "productsearch") {
                    this.deleteBadConfigurations();
                }
            }
        }.bind(this);

        var dialogOffset = $(this.refs.root).offset() || $('#grid0').offset();
        Dialog.open({
            title: 'Save Form Configuration', height: 'auto', width: '240px',
            draggable: true,
            top: dialogOffset.top, left: dialogOffset.left,
            links: [{ title: 'Save', callback: doSave }, Dialog.links.cancel],
            message: saveForm
        });
    }
    loadConfiguration() {
        var doLoad = function() {
            if (this.state.formLoadDialog && this.state.formLoadDialog.refs.input) {
                // FSP 9346296 11/20/17: Stay in edit mode if there are multiple ways to save (e.g. global vs tab level),
                //                       but otherwise change the global config and leave edit mode like load used to do.
                var globalIsTheOnlyWayToSave = !(this.props.saveSpecifications && this.props.saveSpecifications.length);
                if (globalIsTheOnlyWayToSave) {
                    quosal.customization.forms.changeConfig(this.state.editConfig.FormName, this.state.formLoadDialog.refs.input.value, function() {
                        quosal.customization.forms.configurationChanged.call();
                        this.setState({editMode: false, isSaving: false, editConfig: null});
                    }.bind(this));
                } else {
                    var editConfig = this.props.configuration;
                    editConfig = quosal.customization.forms[editConfig.FormName].Configurations[this.state.formLoadDialog.refs.input.value]
                    editConfig = quosal.util.clone(editConfig, 2);
                    this.setState({editMode: true, editConfig: editConfig});
                }
                Dialog.close();
            } else {
                return true;
            }
        }.bind(this);

        var componentDidMount = function () {
            this.props.form.setState({formLoadDialog: this}); // `this` will refer to the instance of FormLoadDialog that did mount
        };
        var componentWillUnmount = function () {
            this.props.form.setState({formLoadDialog: null}); // `this` will refer to the instance of FormLoadDialog that will unmount
        };
        var loadForm = <FormLoadDialog form={this} doLoad={doLoad} componentDidMount={componentDidMount} componentWillUnmount={componentWillUnmount}/>;

        var dialogOffset = $(this.refs.root).offset() || $('#grid0').offset();
        Dialog.open({
            title:'Load Form Configuration', height:'auto', width:'240px',
            draggable: true,
            top: dialogOffset.top, left: dialogOffset.left,
            links:[Dialog.links.cancel],
            message: loadForm
        });
    }
    deleteConfiguration() {
        var doDelete = function() {
            var deleteApi = quosal.api.customization.deleteFormConfiguration(this.state.editConfig.FormName, this.state.editConfig.ConfigurationName);
            deleteApi.finished = function (msg) {
                delete quosal.customization.forms[this.state.editConfig.FormName].Configurations[this.state.editConfig.ConfigurationName];

                quosal.customization.forms.changeConfig(this.state.editConfig.FormName, 'Default', function () {
                    quosal.customization.forms.configurationChanged.call();

                    this.setState({editMode: false, isSaving: false, editConfig: null});

                    Dialog.close();
                }.bind(this));
            }.bind(this);
            deleteApi.call();
        }.bind(this);

        var confirmDeleteMessage = <span>Are you sure you want to delete the configuration <b>{'"' + this.state.editConfig.ConfigurationName + '"'}</b>?</span>;
        Dialog.confirmDelete({callback: doDelete, message: confirmDeleteMessage});
    }
    getFields(flags) {
        var result = [];
        for(var i in this.refs) {
            if(i.indexOf('panel') == 0) {
                var panel = this.refs[i];
                var fields = panel.refs.fields;

                if (fields) {
                    for (var f in fields.refs) {
                        if (f.indexOf('field') == 0) {
                            var field = fields.refs[f];

                            var input = field.refs.input;

                            if (flags) {
                                // whitelist has priority--if a field is on the whitelist, it doesn't matter what other flags it fails.
                                if (flags.getAllValues || (flags.whitelist && typeof flags.whitelist === 'object' && flags.whitelist[input.props.field.FieldName])) {
                                    result.push(input);
                                    continue;
                                }
                                // Other flags stack negatively--the field must succeed on all flags to be included.
                                if (flags.dirty && !input.isDirty()) {
                                    continue;
                                }
                            }
                            var addToResult = (flags && flags.fullField) ? field : input;
                            result.push(addToResult);
                        }
                    }
                }
            }
        }

        return result;
    }
    prepareThenSave(saveCallback) {
        var beforeSaveFunctions = [];
        var fields = this.getFields();
        for(var i = 0; i < fields.length; i++) {
            var input = fields[i];

            if (typeof input.beforeSave === 'function') {
                beforeSaveFunctions.push(input.beforeSave);
            }
        }
        var countdown = beforeSaveFunctions.length;
        var executeBeforeSaveFunctions = function recursiveExecuteBeforeSaveFunctions (callback) {
            if (countdown > 0) {
                countdown--;
                beforeSaveFunctions[countdown](recursiveExecuteBeforeSaveFunctions.bind(null, callback));
            } else {
                callback();
            }
        }.bind(null, saveCallback);
        executeBeforeSaveFunctions();
    }
    getFormValues(arg0) {
        var flagsForGetFields;
        var getListOfEnumFields = false;
        if (typeof arg0 === 'object') {
            flagsForGetFields = arg0;
            if (flagsForGetFields.getListOfEnumFields) {
                delete flagsForGetFields.getListOfEnumFields;
                getListOfEnumFields = true;
            }
        } else {
            flagsForGetFields = arg0 ? {dirty: arg0} : null;
        }

        var values = {};
        var enumFields = [];
        var fields = this.getFields(flagsForGetFields);

        var fieldInfos = quosal.customization.fields[this.props.configuration.ObjectType][this.props.configuration.ObjectName].allFields;    

        for(var i = 0; i < fields.length; i++) {
            var input = fields[i];

            var fieldValues = input.getFormValues(flagsForGetFields);

            for(var key in fieldValues) {
                var value = fieldValues[key];

                if (input.props.field.DataType == 'Enum' && input.props.field.EnumType == 'Boolean') {
                    //SearchCritera booleans become three-state dropdowns
                    if ('true'.ciEquals(value)) {
                        value = 'true';
                    } else if ('false'.ciEquals(value)) {
                        value = 'false';
                    } else {
                        value = '';
                    }
                }

                if (getListOfEnumFields) {
                    if (input.props.field.DataType == 'Enum' || (input.props.fieldConfig && input.props.fieldConfig.CustomDropdownContents)) {
                        enumFields.push(key);
                    }
                    else {
                        var fieldInfo = fieldInfos.where(function (x) { return x.FieldName == key })[0];

                        if (fieldInfo && fieldInfo.IsEnum) {
                            enumFields.push(key);
                        }
                    }
                }             
                values[key] = value;
            }
        }

        if (this.props.alwaysDirtyInputValues) {
            for (var alwaysDirtyFieldName in this.props.alwaysDirtyInputValues) {
                // FSP 4/1/17: Deliberately checking values.hasOwnProperty instead truthiness of values[alwaysDirtyFieldName] because deliberately blanked inputs should not be overridden by alwaysDirtyInputValues.
                if (!values.hasOwnProperty(alwaysDirtyFieldName)) {
                    values[alwaysDirtyFieldName] = this.props.alwaysDirtyInputValues[alwaysDirtyFieldName];
                }
            }
        }

        if (getListOfEnumFields) {
            return { values: values, enumFields: enumFields };
        } else {
            return values;
        }
    }
    clean() {
        delete quosal.htmlEditorCallback;
        //this.setState({isDirty: false}); //form does not currently hold this state...
        this.state.isDirty = false;

        var fields = this.getFields({dirty: true});

        for(var i = 0; i < fields.length; i++) {
            fields[i].clean();
        }
    }
    reset() {
        delete quosal.htmlEditorCallback;
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.state.isDirty = false;

        var fields = this.getFields({dirty: true});

        for(var i = 0; i < fields.length; i++) {
            fields[i].reset();
        }

        this.validate('reset');
    }
    clearFields() {
        var fields = this.getFields({dirty: true});

        for(var i = 0; i < fields.length; i++) {
            fields[i].clear();
        }
    }
    repairPanelOrder() {
        for(var i in this.refs) {
            if(i.indexOf('sortRoot') == 0) {
                var root = ReactDOM.findDOMNode(this.refs[i]);
                var panels = $(root).find('.panel');

                for(var i = 0; i < panels.length; i++) {
                    var panelId = panels[i].id;
                    var panel = this.state.editConfig.Panels.where((s)=>s.IdFormPanel == panelId).firstOrNull();

                    var parentGrid = $(panels[i]).parents('.grid').first();
                    if(parentGrid.length > 0) {
                        var gridIndex = parseInt(parentGrid[0].id.replace('grid', ''));
                        panel.GridIndex = gridIndex;
                    }

                    panel.RowIndex = i;
                    panel.ColumnIndex = i; //TODO: horizontal forms?

                }
            }
        }
    }
    updateSorting() {
        if (this.state.editMode && !this.state.isSaving) {
            for (var i in this.refs) {
                if (i.indexOf('sortRoot') == 0) {

                    var sortRoot = ReactDOM.findDOMNode(this.refs[i]);

                    if (this.state.sortRoots.indexOf(sortRoot) >= 0)
                        continue;

                    this.state.sortRoots.push(sortRoot);

                    $(sortRoot).sortable({
                        handle: '.form-dragger',
                        connectWith: '.panel-container',
                        stop: function (sortRoot, e, ui) {
                            this.repairPanelOrder();
                            $(sortRoot).sortable('cancel');
                            this.forceUpdate();
                        }.bind(this, sortRoot)
                    });
                }
            }
        } else {
            while (this.state.sortRoots.length > 0) {
                var sortRoot = this.state.sortRoots.pop();

                $(sortRoot).sortable('destroy');
            }
        }
    }
    fieldChanged(panel, input, callback) {
        this.state.isDirty = true;

        if(this.props.onChange)
            this.props.onChange(this, panel, input, callback);

        this.validate('change');
    }
    onNavigation(navState) {
        Form.onNavigation.call(this, navState);
    }
    onWindowUnloading(e) {
        Form.onWindowUnloading.call(this, e);
    }
    componentDidUpdate() {
        this.updateSorting();
    }
    componentDidMount() {
        this.updateSorting();

        if (!this.props.noNavigationActions) {
            Form.hookUpNavigationActions.call(this);
        }

        if(!this.props.configuration.Panels) {
            this.props.configuration.Panels = [{
                IsNew: true,
                IdFormPanel: quosal.util.generateGuid(),
                PanelTitle: 'New Panel',
                RowIndex: 0,
                ColumnIndex: 0,
                GridIndex: 0,
                CanRemove: true
            }];
        }
        if(!this.props.configuration.Fields) {
            this.props.configuration.Fields = [];
        }

        this.validate('load');
    }
    componentWillUnmount() {
        if (!this.props.noNavigationActions) {
            Form.unhookNavigationActions.call(this);
        }
    }
    render() {
        var config = this.state.editMode ? this.state.editConfig : this.props.configuration;

        if (this.props.isSearchForm)
        {
            for(var i = 0; i < config.Fields.length; i++){
                //Searchable booleans become three-state dropdowns
                if(config.Fields[i].DataType === 'Boolean') {
                    config.Fields[i].DataType = 'Enum';
                    config.Fields[i].EnumType = 'Boolean';
                }
            }
        }
        
        //create grid layout
        var grid = [];
        var gridLayout = String.isNullOrEmpty(config.GridSize) ? [] : config.GridSize.split(',');

        var sortedPanels = config.Panels ? config.Panels.sort((a,b)=>a.RowIndex - b.RowIndex) : [];
        var panels = [];

        var firstPanelTitleContents = this.props.firstPanelTitleContents;
        for(var i = 0; i < sortedPanels.length; i++) {
            var panel = sortedPanels[i];
            var fields = config.Fields ? config.Fields.where((s)=>s.IdFormPanel == panel.IdFormPanel) : [];
            var controls = this.props.panelControls ? this.props.panelControls.where((s)=>s.panel == panel.IdFormPanel) : [];

            if(gridLayout.length > 1) {
                var gridIndex = panel.GridIndex;
                if(gridIndex < 0)
                    gridIndex = 0;
                if(gridIndex >= gridLayout.length)
                    gridIndex = gridLayout.length - 1;

                if (panels[gridIndex] == null)
                    panels[gridIndex] = [];

                panels[gridIndex].push(
                    <FormPanel key={'panel' + i} ref={'panel' + i} form={this} panel={panel} fields={fields}
                               controls={controls} onChange={this.fieldChanged} titleChildren={firstPanelTitleContents} />
                );
            } else {
                panels.push(
                    <FormPanel key={'panel' + i} ref={'panel' + i} form={this} panel={panel} fields={fields}
                               controls={controls} onChange={this.fieldChanged} titleChildren={firstPanelTitleContents} />
                );
            }
            firstPanelTitleContents = null;
        }

        var copyTabs = quosal.util.userCanCopyTabs();
        var canEnableIsTemplateField = quosal.settings.getValue("canCopyTabsFromTemplate");
        if(canEnableIsTemplateField && copyTabs){
            panels.push(
                <Panel key={'copyTabPanel'}>  
                    <PanelTitle><span>Additional Search Criteria</span></PanelTitle>
                    <div className="formcolumn">
                        <div className="formfieldwrapper">
                            <div></div>
                            <select id="templateSelection" className="formselectfield">
                                <option value="include">All Quotes and Templates</option>
                                <option value="false">Only Quotes</option>
                                <option value="true">Only Input Templates</option>
                            </select>
                        </div>
                    </div>
                </Panel>);
    
        }
       
        var floatBox = (!this.state.editMode && this.props.floatingControls) ? <FloatBox>{this.props.floatingControls}</FloatBox>: null;

        var containerClass = '';

        if(gridLayout.length > 1) {
            var className = '';
            if (this.state.editMode) {
                className = 'editMode';
            }
            for (var i = 0; i < gridLayout.length; i++) {
                containerClass = ''
                if(this.state.editMode) {
                    containerClass += ' edit';

                    if(panels[i] == null || panels[i].length == 0)
                        containerClass += ' empty';
                }

                grid.push(<GridColumn key={'grid' + i} id={'grid' + i} className={className} size={gridLayout[i]}>
                            <div ref={'sortRoot' + i} className={'panel-container' + containerClass}>
                                {panels[i]}
                            </div>
                        </GridColumn>);
            }
        } else {
            if(this.state.editMode) {
                containerClass += ' edit';

                if(panels.length == 0)
                    containerClass += ' empty';
            }
        }

        var formTopControls = [];
        var formBottomControls = [];

        if(!this.state.editMode && this.props.formControls) {
            for(var i = 0; i < this.props.formControls.length; i++) {
                if(this.props.formControls[i].position == 'top')
                    formTopControls.push(this.props.formControls[i].control);
                else if(this.props.formControls[i].position == 'bottom')
                    formBottomControls.push(this.props.formControls[i].control);
            }
        }
        var infoMessage;
        if(this.props.infoMessage)
        infoMessage = this.props.infoMessage;
        if(gridLayout.length > 1) {
            return (
                <div ref="root">
                    {this.state.editMode ? <FormCustomizationTools form={this} /> : ''}
                    {infoMessage? infoMessage: ''}
                    {formTopControls}
                        {grid}
                    {formBottomControls}
                    {floatBox}
                </div>
            );
        } else if(gridLayout.length == 1) {
            var className = '';
            if (this.state.editMode) {
                className = 'editMode';
            }
            return (
                <GridColumn ref="root" id="grid0" className={className} size={config.GridSize}>
                    {this.state.editMode ? <FormCustomizationTools form={this} /> : ''}
                    {formTopControls}
                    <div ref="sortRoot" className={'panel-container' + containerClass}>
                        {panels}
                    </div>
                    {formBottomControls}
                    {floatBox}
                </GridColumn>
            );
        } else {
            return (
                <div ref="root">
                    {this.state.editMode ? <FormCustomizationTools form={this} /> : ''}
                    {infoMessage? infoMessage: ''}
                    {formTopControls}

                    <div ref="sortRoot" className={'panel-container' + containerClass}>
                        {panels}
                    </div>
                    {formBottomControls}
                    {floatBox}
                </div>
            )
        }
    }
}


class FormSaveDialog extends React.Component {
    constructor(props) {
        super(props);

        var updatedCheckboxValues = {};
        // FSP 7/28/17: If there is only one home for this configuration, always assume that user wants to update it.
        if (this.props.saveSpecifications.length == 1) {
            updatedCheckboxValues[this.props.saveSpecifications[0].key] = true;
        }

        this.state = {
            updatedCheckboxValues: updatedCheckboxValues,
            updatedConfigName: this.props.form.state.editConfig.ConfigurationName,
        };

        this.onConfigNameChanged = this.onConfigNameChanged.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.validate = this.validate.bind(this);
        this.configNameIsDefault = this.configNameIsDefault.bind(this);
        this.getUpdatedConfigName = this.getUpdatedConfigName.bind(this);
        this.getValidationCode = this.getValidationCode.bind(this);
    }
    onConfigNameChanged(e) {
        this.setState({
            updatedConfigName: e.target.value
        });
    }
    onCheckboxChange(key, e) {
        var updatedCheckboxValues = this.state.updatedCheckboxValues;
        updatedCheckboxValues[key] = e.target.checked;
        this.setState({
            updatedCheckboxValues: updatedCheckboxValues
        });
    }
    validate() {
        $.quosal.validation.clear();
        var validationCode = this.getValidationCode(this.state.updatedConfigName);
        if(validationCode === 'default') {
            $.quosal.validation.validateField(this.refs.input, 'error', 'Can not overwrite Default form configuration, please choose a different name.')
            return false;
        } else if(validationCode === 'blank') {
            $.quosal.validation.validateField(this.refs.input, 'error', 'Configuration name can not be blank.')
            return false;
        }
        return true; //valid
    }
    configNameIsDefault() {
        return (this.state.updatedConfigName.toLowerCase().trim() === 'default');
    }
    getUpdatedConfigName() {
        if (this.configNameIsDefault()) {
            return 'Default';
        } else {
            return this.state.updatedConfigName.trim()
        }
    }
    getValidationCode(configName) {
        configName = configName.trim().toLowerCase();
        if (configName === 'default') {
            return 'default';
        } else if(configName === '') {
            return 'blank';
        } else {
            return '';
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.getValidationCode(prevState.updatedConfigName) !== this.getValidationCode(this.state.updatedConfigName)) {
            this.validate();
        }
    }
    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
        this.validate();
    }
    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    render() {
        var checkboxes = [];
        var saveSpecifications = this.props.saveSpecifications;
        var onlyOneSaveSpecification = (saveSpecifications.length === 1);

        var formName = this.props.form.props.configuration.FormName;
        var updatedConfigName = this.getUpdatedConfigName();
        var configNameInputIsDirty = (this.props.form.state.editConfig.ConfigurationName != updatedConfigName);

        var saveMessagePieces = [];

        var isDefault = this.configNameIsDefault();


        var configIsNotAppliedAnywhere = true;
        for (var i = 0; i < saveSpecifications.length; i++) {
            var saveSpecification = saveSpecifications[i];
            var key = saveSpecification.key;

            var isCheckedInitialValue = saveSpecification.isCheckedInitialValue(updatedConfigName)
            var isChecked = this.state.updatedCheckboxValues[key];
            if (isChecked == null) {
                isChecked = isCheckedInitialValue;
            }
            if (isChecked) {
                configIsNotAppliedAnywhere = false;
            }

            var isChanged = (isChecked != isCheckedInitialValue);
            var checkboxWrapperClass = 'formcheckboxwrapper';
            if (isChanged) {
                checkboxWrapperClass += ' isDirtyHighlighted';
            }

            if (isChanged) {
                if (onlyOneSaveSpecification) {
                    // FSP 9346296 11/20/17: If there's only one place to set a config, then we hide the business of changing
                    //                       what the active config is and we change the active config on load, so this dialog
                    //                       is just for saving modifications to the contents of configs. But when 'Default'
                    //                       is the config name, modifying the contents is not valid, so there's
                    //                       nothing both useful and allowed for the saveSpecification to do.
                    if (isDefault) {
                        isChanged = false;
                    }
                } else {
                    if (isChecked) {
                        saveMessagePieces.push(<p key={key}>{saveSpecification.addActionPreviewText}</p>);
                    } else {
                        saveMessagePieces.push(<p key={key}>{saveSpecification.removeActionPreviewText}</p>);
                    }
                }
            }

            saveSpecification.isChanged = isChanged;
            saveSpecification.isChecked = isChecked;

            var id = 'formConfigurationSaveCheckbox_' + key;

            var checkboxContainerStyle = onlyOneSaveSpecification ? {display:'none'} : {};
            checkboxes.push(
                <div key={key} className="formfieldwrapper" style={checkboxContainerStyle}>
                    <div className={checkboxWrapperClass}>
                        <input ref={'checkbox_' + key} id={id} type="checkbox" checked={isChecked} onChange={this.onCheckboxChange.bind(this, key)} />
                        <label htmlFor={id} className="formfieldlabel">{saveSpecification.checkboxLabel}</label>
                    </div>
                </div>
            );
        }

        if (!isDefault) {
            var configIsNotAppliedMessage = configIsNotAppliedAnywhere ? <b> without applying it</b> : '';
            if (!configNameInputIsDirty) {
                saveMessagePieces.unshift(<p key={'name'}><b>Update this configuration</b> "{updatedConfigName}"{configIsNotAppliedMessage}</p>);
            } else if (quosal.customization.forms[formName].Configurations[updatedConfigName]) {
                saveMessagePieces.unshift(<p key={'name'}><b>Overwrite the existing configuration</b> called "{updatedConfigName}"{configIsNotAppliedMessage}</p>);
            } else {
                saveMessagePieces.unshift(<p key={'name'}><b>Create a new configuration</b> called "{updatedConfigName}"{configIsNotAppliedMessage}</p>);
            }
        }

        var saveMessagePiecesToPrepend = [];
        if (isDefault) {
            saveMessagePiecesToPrepend.push(<p key={'defaultWarning'}><b>The configuration "Default" cannot be updated.</b> Change the name in order to save edits to this configuration.</p>);
        }
        if(saveMessagePieces.length > 0) {
            saveMessagePiecesToPrepend.push(<p key={'start'}>Click "Save" to: </p>);
        }
        saveMessagePieces = saveMessagePiecesToPrepend.concat(saveMessagePieces);

        var saveMessage = <div className="prose"><i>{saveMessagePieces}</i></div>


        var configNameInputClass = configNameInputIsDirty ? 'isDirtyHighlighted' : '';
        return (
            <div>
                <div className="formcolumn" >
                    <div className="formfieldwrapper" >
                        <div className="formfieldlabel"><FormFieldLabel label="Configuration Name" /></div>
                        <div className="formfield"><input type="text" ref="input" className={configNameInputClass} onChange={this.onConfigNameChanged} value={this.state.updatedConfigName} /></div>
                    </div>
                    {checkboxes}
                </div>
                {saveMessage}
            </div>
        );
    }
}


class FormLoadDialog extends React.Component {
    componentDidMount() {
        if (this.props.componentDidMount) {
            this.props.componentDidMount.call(this);
        }
    }
    componentWillUnmount() {
        if (this.props.componentWillUnmount) {
            this.props.componentWillUnmount.call(this);
        }
    }
    render() {
        var options = [];
        options.push(<option key="Default" value="Default">(Default)</option>);

        var allConfigs = quosal.customization.forms[this.props.form.state.editConfig.FormName].Configurations;
        Object.keys(allConfigs)
            .map(function (config) {
                return allConfigs[config].ConfigurationName})
            .sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase())})
            .forEach(function(config){
                if(config != 'Default'){
                    options.push(<option key={config} value={config}>{config}</option>)
                }
            })


        var scrollDivStyle = {
            height: 'auto',
            maxHeight: '200px',
            overflowY: 'auto'
        };
        var selectWrapperStyle = {
            height: 'auto',
            background: 'none'
        };
        var selectStyle = {
            height: '100%'
        };
        return (
            <div className="formcolumn" >
                <div className="formfieldwrapper">
                    <div className="formfieldlabel"><FormFieldLabel label="Available Configurations" /></div>
                    <div style={scrollDivStyle} >
                        <div className="formselectfieldwrapper" style={selectWrapperStyle} >
                            <select ref="input" size={options.length} className="formselectfield" style={selectStyle} onChange={this.props.doLoad} defaultValue={this.props.form.state.editConfig.ConfigurationName}>{options}</select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class FormCustomizeButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                       
        };
        this.showCustomizer = this.showCustomizer.bind(this);        
    }
    showCustomizer() {
        if($(".fullscreen iframe").length > 0){
            return
        }
        this.props.form.toggleEditMode();
    }
    render() {
        return (
            <PanelToolbar style={{float:'right', marginTop:'-3px'}}>
                <PanelToolbarButton title="Customize Form" className="notfullscreen" onClick={this.showCustomizer} image="img/svgs/v1.0/Action_Edit.svg" dataCy="customizeForm" />
            </PanelToolbar>
        );
    }
}


class FormCustomizationTools extends React.Component {
    render() {
        var config = this.props.form.state.editConfig;

        return (
            <Panel style={{backgroundColor: '#FFFFCC'}} >
                <PanelToolbar style={{display:'block', fontSize:'12px'}}>
                    {this.props.form.state.isSaving ? '' : <PanelToolbarButton id="form_action_addnew" title="Add Panel" onClick={this.props.form.addPanel} image="img/svgs/v1.0/Action_AddNew.svg" dataCy="addPanel" />}
                    {this.props.form.state.isSaving ? '' : <PanelToolbarButton id="form_menu_system" title="Load Configuration" onClick={this.props.form.loadConfiguration} image="img/svgs/v1.0/Menu_System.svg" />}
                    {this.props.form.state.isSaving ? '' : <PanelToolbarButton id="form_action_delete" title={config.IsSystemDefault ? 'Can not delete default configuration.' : 'Delete Configuration'} onClick={config.IsSystemDefault ? null : this.props.form.deleteConfiguration} image={config.IsSystemDefault ? 'img/svgs/v1.0/Action_Delete.svg' : 'img/svgs/v1.0/Action_Delete.svg'} />}
                    <SaveButton style={{float:'right', marginRight:'5px', minWidth:'60px'}} onClick={this.props.form.saveConfiguration} disabled= {this.props.form.state.isSaving} isSaving={this.props.form.state.isSaving}  />
                    <Button type="cancel" style={{float:'right', marginRight:'5px', minWidth:'60px'}} title="Cancel Changes" onClick={this.props.form.toggleEditMode} disabled={this.props.form.state.isSaving} >Cancel</Button>
               </PanelToolbar>
            </Panel>
        );
    }
}

class PanelCustomizationTools extends React.Component {
    render() {
        var toolbar = <PanelToolbar style={{float:'right', marginTop:'-3px'}}>
                        <PanelToolbarButton id="form_action_edit" title="Add/Remove Fields" onClick={this.props.panel.modifyFields} image="img/svgs/v1.0/Action_Edit.svg" dataCy="AddRemoveField" />
                        {this.props.panel.canRemove() ?
                            <PanelToolbarButton id="form_action_remove_on" title="Remove Panel" onClick={this.props.panel.remove} image="img/svgs/v1.0/Action_Delete.svg" /> :
                            <PanelToolbarButton id="form_action_remove_off" title="This panel cannot be removed." image="img/svgs/v1.0/Action_Delete.svg" />}
                    </PanelToolbar>;

        return (
            this.props.panel.props.form.state.isSaving ? <span /> : toolbar
        );
    }
}


class FormFieldSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {  
            originalFields: quosal.util.clone(this.props.form.state.editConfig.Fields, 2),
        };
        this.onFieldInclusionChanged = this.onFieldInclusionChanged.bind(this);
        this.clearSelectedFields = this.clearSelectedFields.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.crmProvider = quosal.settings.getValue('customerProvider');
    }
    onFieldInclusionChanged(e) {
        var includeField = e.target.checked;
        var fieldName = e.target.value;

        if(includeField) {
            this.props.panel.addField(fieldName, function() {
                this.forceUpdate();
                this.props.panel.repairFieldOrder();
                this.props.panel.forceUpdate();
            }.bind(this));
        } else {
            this.props.panel.removeField(fieldName, function() {
                this.forceUpdate();
                this.props.panel.repairFieldOrder();
                this.props.panel.forceUpdate();
            }.bind(this));
        }

        this.props.changeEvent.call();
    }
    clearSelectedFields() {
        this.props.panel.clearFields(function() {
            this.forceUpdate();
        }.bind(this));
    }
    cancelChanges() {
        this.props.form.state.editConfig.Fields = this.state.originalFields;
        this.props.form.forceUpdate();
    }
    componentDidMount() {
        $.quosal.quickfilter.wrapfieldsInit();
    }
    render() {
        this.props.cancelEvent.unbind().bind(this.cancelChanges);

        var objectName = this.props.form.state.editConfig.ObjectName;
        var fields = quosal.customization.fields[this.props.form.state.editConfig.ObjectType][objectName];

        var gridFields = [];
        var allFields = fields.standardFields.concat(fields.additionalFields);

        if(this.props.form.props.fieldCustomizer) {
            this.props.form.props.fieldCustomizer(allFields);
        }

        allFields.sort(function(a,b) { return a.FieldName.charCodeAt(0) - b.FieldName.charCodeAt(0) });

        if (!this.crmProvider.ciEquals('salesforce'))
        {
            allFields = allFields.filter(function(item) {
                return item.FieldName !== "DefaultOppType";
            })
        }

        //Render field list
        for(var i = 0; i < allFields.length; i++) {
            var field = allFields[i];
            if(field.IsPrivateField){
                continue;
            }
            var fieldConfig = fields.fieldConfigurations[field.FieldName];

            var formField = this.props.form.state.editConfig.Fields.where((f)=> f.FieldName == field.FieldName).firstOrNull();
            var fieldSelected = formField != null;
            var differentPanel = fieldSelected && formField.IdFormPanel != this.props.panel.props.panel.IdFormPanel;
            let disabled = differentPanel;
            var fieldState = disabled ? 'DISABLED' : (fieldSelected ? 'CHECKED' : '');
            var onDropdownUpdate = function () {
                this.props.form.forceUpdate();
                this.forceUpdate();
            }.bind(this);

            gridFields.push(
                <CustomizerFieldListItem key={'FIELD_' + field.FieldName} isDisabled={disabled} className={fieldState}
                                         onChange={this.onFieldInclusionChanged} fieldSelected={fieldSelected} field={field} fieldConfig={fieldConfig} 
                                         objectType={this.props.form.state.editConfig.ObjectType} objectName={objectName} onDropdownUpdate={onDropdownUpdate} />
            );
        }

        return (
            <Panel>
                <PanelTitle>
                    <span>Select Fields</span>
                    <input type="text" className="quicksearch_wrapfields" />
                    <button onClick={this.clearSelectedFields} style={{margin:'-4px 0 0 6px'}}>Clear Selected Fields</button>
                </PanelTitle>

                <PanelContent>
                    <div id="itemfields">
                        {gridFields}
                    </div>
                </PanelContent>
            </Panel>
        );
    }
}

class FormPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        // This binding is necessary to make `this` work in the callback
        this.fieldChanged = this.fieldChanged.bind(this);
        this.addField = this.addField.bind(this);
        this.removeField = this.removeField.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.modifyFields = this.modifyFields.bind(this);
        this.remove = this.remove.bind(this);
        this.titleChanged = this.titleChanged.bind(this);
        this.repairFieldOrder = this.repairFieldOrder.bind(this);
        this.adminOnlyChanged = this.adminOnlyChanged.bind(this);
        this.approverOnlyChanged = this.approverOnlyChanged.bind(this);
        this.canRemove = this.canRemove.bind(this);
        this.quosalAdminOnlyChanged = this.quosalAdminOnlyChanged.bind(this);
    }
    addField(fieldName, callback) {
        this.props.form.addField(fieldName, this, callback);
    }
    removeField(fieldName, callback) {
        this.props.form.removeField(fieldName, this, callback);
    }
    clearFields(callback) {
        this.props.form.clearSelectedFields(this, callback);
    }
    modifyFields() {
        var dialogState = { hasChanged: false };
        var cancelEvent = quosal.events.create();
        var changeEvent = quosal.events.create();
        changeEvent.bind(function(dialogState) {
            dialogState.hasChanged = true;
        }.bind(this, dialogState));

        Dialog.open({
            title:'Add / Remove Fields', height:'60%', width:'60%', resizable: true, draggable: true,
            links:[{
                title: 'OK',
                callback: Dialog.close
            }, {
                title: 'Cancel',
                callback: function() { cancelEvent.call(); Dialog.close(); }
            }],
            onClose: function(dialogState) {
                if(dialogState.hasChanged) {
                    Dialog.open({
                        title: 'Unsaved Changes',
                        message: 'Any changes will be discarded, are you sure you want to close the editor?',
                        links: [{
                            title: 'Keep Changes',
                            callback: Dialog.closeAll
                        }, {
                            title: 'Discard Changes',
                            callback: function () {
                                cancelEvent.call();
                                Dialog.closeAll();
                            }
                        }, {
                            title: 'Cancel',
                            callback: Dialog.close
                        }]
                    });
                } else {
                    Dialog.close();
                }
            }.bind(this, dialogState),
            message: <FormFieldSelector form={this.props.form} panel={this} cancelEvent={cancelEvent} changeEvent={changeEvent} />
        });
    }
    remove() {
        this.props.form.removePanel(this);
    }
    titleChanged(e) {
        this.props.form.changePanelTitle(this, e.target.value);
    }
    repairFieldOrder() {
        this.refs.fields && this.refs.fields.repairFieldOrder();
    }
    fieldChanged(input, callback) {
        if(this.props.onChange)
            this.props.onChange(this, input, callback);
    }
    adminOnlyChanged(e) {
        this.props.panel.AdminOnly = e.target.checked;
        this.forceUpdate();
    }
    approverOnlyChanged(e) {
        this.props.panel.ApproverOnly = e.target.checked;
        this.forceUpdate();
    }
    canRemove() {
        for (let i = 0; i < this.props.fields.length; i++){
            if(!this.props.fields[i].CanRemove) return false;
        }
        return this.props.panel.CanRemove;
    }
    quosalAdminOnlyChanged(e) {
        this.props.panel.QuosalAdminOnly = e.target.checked;
        this.forceUpdate();
    }
    render() {
        var isPackageLevelStarter = !quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard);
        var editMode = this.props.form.state.editMode;
        var isRestrictedEditMode = editMode && isPackageLevelStarter && app.currentUser.IsQuosalAdmin;
        var noEditingThisPanel = isRestrictedEditMode && !this.props.panel.QuosalAdminOnly;
        var isNonStarterAdmin = (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) && quosal.validation.isPackageLevelAuthorized(app.packageLevels.standard);
        var topControls = [];
        var bottomControls = [];

        if(!editMode) {
            for (var c = 0; c < this.props.controls.length; c++) {
                if (this.props.controls[c].position == 'top')
                    topControls.push(this.props.controls[c].control);
                else
                    bottomControls.push(this.props.controls[c].control);
            }
        }

        var style = {};
        if(editMode)
            style.backgroundColor = '#FFFFCC';

        var editable = this.props.form.props.editable !== false;
        var editing = editable && this.props.form.state.editMode && !this.props.form.state.isSaving;
        var titleChildren = this.props.titleChildren;
        if(editMode) {
            titleChildren = (
                <span>
                    { !isRestrictedEditMode &&
                        <span>
                            <input type="checkbox" style={{verticalAlign:'middle', marginLeft:8}}
                                   checked={this.props.panel.AdminOnly}
                                   onChange={this.adminOnlyChanged}/>
                            <label className="formlabel" style={{marginLeft:2}}>Admin-Only</label>
                        </span>
                    }
                    { quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium) &&
                        <span>
                            <input type="checkbox" style={{verticalAlign:'middle', marginLeft:8}}
                                   checked={this.props.panel.ApproverOnly}
                                   onChange={this.approverOnlyChanged}/>
                            < label className="formlabel" style={{marginLeft:2}}>Approver-Only</label>
                        </span>
                    }
                    { app.currentUser.IsQuosalAdmin &&
                        <span>
                            <input type="checkbox" style={{verticalAlign:'middle', marginLeft:8}}
                                   checked={this.props.panel.QuosalAdminOnly}
                                   onChange={this.quosalAdminOnlyChanged}
                                   disabled={isPackageLevelStarter} />
                            <label className="formlabel" style={{marginLeft:2}}>QuosalAdmin-Only</label>
                        </span>
                    }
                </span>
            );
        }

        var beforeTitle = null;
        if (editable) {
            var canEdit = app.currentQuote ? app.currentQuote.QuoteStatus != 'Archived' : true;
            if (editing) {
                beforeTitle = (
                    <div>
                        { !noEditingThisPanel && <PanelCustomizationTools panel={this} /> }
                        <div className="form-dragger"></div>
                    </div>
                );
            } else if (canEdit && (app.currentUser.IsQuosalAdmin || isNonStarterAdmin)) {
                beforeTitle = <FormCustomizeButton form={this.props.form}/>;
            }
        }

        return (
            <Panel style={style} id={this.props.panel.IdFormPanel} ref="panel" allowFullscreen={this.props.panel.AllowFullscreen}
                   editable={editing} onTitleChanged={this.titleChanged} title={this.props.panel.PanelTitle} titleChildren={titleChildren} beforeTitle={beforeTitle}>
                <PanelContent>
                    {topControls}
                    { !noEditingThisPanel &&
                        <FormFields ref="fields" form={this.props.form} onChange={this.fieldChanged} panel={this}
                                    fields={this.props.fields}
                                    noColumns={this.props.form.props.configuration.GridSize == '4x1'}/>
                    }
                    {bottomControls}
                </PanelContent>
            </Panel>
        );
    }
}


class FloatBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {     
            renderFloater: false,
            width: 100,
        };        
        // This binding is necessary to make `this` work in the callback
        this.onScrollOrResize = this.onScrollOrResize.bind(this);
    }    
    onScrollOrResize(e) {
        var renderFloater = this.state.renderFloater;
        var width = this.state.width;

        if (this.refs.root && this.refs.nonFloater) {
            width = $(this.refs.nonFloater).width();
            var root = $(this.refs.root);
            var theWindow = $(window);
            renderFloater = root && (0 < (root.offset().top + root.outerHeight() - (theWindow.height() + theWindow.scrollTop())));
        }

        if (width != this.state.width || renderFloater != this.state.renderFloater) {
            this.setState({
                renderFloater: renderFloater,
                width: width
            });
        }
    }
    componentDidMount() {
        $(window).on('scroll', this.onScrollOrResize);
        $(window).on('resize', this.onScrollOrResize);
        this.onScrollOrResize();
    }
    componentWillUnmount() {
        $(window).off('scroll', this.onScrollOrResize);
        $(window).off('resize', this.onScrollOrResize);
    }
    render() {
        var floatStyle = {
            position: 'fixed',
            bottom: 0,
            marginBottom: 0,
            marginTop: 0,
            zIndex: 1,
            width: this.state.width
        };
        var nonFloatStyle = {};
        if (this.state.renderFloater) {
            nonFloatStyle.boxShadow = 'none';
        }

        return (
            <div ref="root" className="panel-container" >
                <div ref="nonFloater" className="panel" style={nonFloatStyle}>
                    {this.props.children}
                </div>
                {this.state.renderFloater ?
                    <div className="panel" style={floatStyle}>
                        {this.props.children}
                    </div> : null}
            </div>
        );
    }
}


class FormPanelColumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };  
        this.repairFieldOrder = this.repairFieldOrder.bind(this);
    }
    repairFieldOrder() {
        var root = ReactDOM.findDOMNode(this);
        var fields = $(root).find('.formfieldwrapper');

        for(var i = 0; i < fields.length; i++) {
            var fieldName = fields[i].attributes.name.value;
            var field = this.props.form.state.editConfig.Fields.where((s)=>s.FieldName == fieldName).firstOrNull();
            field.RowIndex = i;
            field.ColumnIndex = this.props.column;
            field.IdFormPanel = this.props.panel.props.panel.IdFormPanel;
        }
    }
    render() {
        var style = {};
        if(this.props.noPadding)
            style.padding = 0;

        var editClass = '';
        if(this.props.form.state.editMode) {
            editClass += ' edit';

            if(!this.props.fields || this.props.fields.length == 0) {
                editClass += ' empty';
            }
        }

        return (
            <div className={'formcolumn' + editClass} style={style}>{this.props.fields}</div>
        );
    }
}


class FormFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSortable: false, 
            sortRoots: {},
        };
        // This binding is necessary to make `this` work in the callback
        this.fieldChanged = this.fieldChanged.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.repairFieldOrder = this.repairFieldOrder.bind(this);
        this.updateSorting = this.updateSorting.bind(this);
        this.getFieldByName = this.getFieldByName.bind(this);
    }

    getFormValues() {
        var values = {};

        for(var i in this.refs) {
            var input = this.refs[i].refs.input;

            values[input.props.field.FieldName] = input.state.newValue;
        }

        return values;
    }
    fieldChanged(input, callback) {
        if(this.props.onChange)
            this.props.onChange(input, callback);
    }
    repairFieldOrder() {
        for(var iColumn in this.refs) {
            if(iColumn.indexOf('sortRoot') == 0) {
                this.refs[iColumn].repairFieldOrder();
            }
        }
    }
    updateSorting() {
        var keys = Object.keys(this.refs);
        for(var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if(key.indexOf('sortRoot') == 0) {
                var sortRoot = ReactDOM.findDOMNode(this.refs[key]);

                if (this.props.form.state.editMode && !this.props.form.state.isSaving) {
                    if(this.state.sortRoots[key] && this.state.sortRoots[key] != sortRoot) {
                        $(this.state.sortRoots[key]).sortable('destroy');
                        delete this.state.sortRoots[key];
                    }

                    this.state.sortRoots[key] = sortRoot;

                    $(sortRoot).sortable({
                        connectWith: '.formcolumn',
                        stop: function (sortRoot, e, ui) {
                            this.props.form.repairFieldOrder();
                            $(sortRoot).sortable('cancel');
                            this.props.form.forceUpdate();
                        }.bind(this, sortRoot)
                    });
                } else if(this.state.sortRoots[key]) {
                    $(this.state.sortRoots[key]).sortable('destroy');
                    delete this.state.sortRoots[key];
                }
            }
        }
    }
    getFieldByName(fieldName) {
        return this.refs[this.fieldNameToRef[fieldName]];
    }
    componentDidUpdate() {
        this.updateSorting();
    }
    componentDidMount() {
        this.updateSorting();
    }
    render() {
        var columns = [];
        this.fieldNameToRef = {};

        if(this.props.fields.length == 1 && (this.props.fields[0].WidgetType == 'rtfeditor' || this.props.fields[0].WidgetType == 'greedyTextarea')) {
            //RTF editor requires a full panel to itself.
            //SP 2/27/18 9850252: Also the customNotes fields.
            columns.push(<FormField ref={'field0'} form={this.props.form} onChange={this.props.form.props.onChange ? this.fieldChanged : null} key={this.props.fields[0].FieldName} field={this.props.fields[0]} />);
        } else {
            var columnCount = 0;

            //order the fields by row
            var sortedFields = this.props.fields.sort(function (a, b) {
                return a.RowIndex - b.RowIndex;
            });

            for (var i = 0; i < sortedFields.length; i++) {
                //count the number of columns
                if ((sortedFields[i].ColumnIndex + 1) > columnCount)
                    columnCount = sortedFields[i].ColumnIndex + 1;
            }

            if (this.props.form.state.editMode && !this.props.noColumns)
                columnCount++;

            for (var c = 0; c < columnCount; c++) {
                //group fields by column
                var columnFields = [];

                for (var i = 0; i < sortedFields.length; i++) {
                    if (sortedFields[i].ColumnIndex == c) {
                        var field = sortedFields[i];
                        var params = {};

                        if (field.WidgetParameters) {
                            try {
                                params = JSON.parse(field.WidgetParameters);
                            } catch (ex) {
                            }
                        }

                        var parent = columnFields;

                        if (params.skipLayout) {
                            //Skip column layout for certain widget types
                            parent = columns;
                        }

                        var ref = 'field' + i;
                        parent.push(<FormField ref={ref} form={this.props.form}
                                                     onChange={this.props.onChange ? this.fieldChanged : null}
                                                     key={field.FieldName} field={field}
                                                     metadata={this.props.form.props.configuration.Metadata} />);
                        this.fieldNameToRef[field.FieldName] = ref;
                    }
                }

                //if(columnFields.length > 0) {
                    columns.push(<FormPanelColumn ref={'sortRoot' + c} column={c} form={this.props.form}
                                                  panel={this.props.panel} noPadding={this.props.noColumns}
                                                  key={"col" + c}
                                                  fields={columnFields}/>);
               // }
            }

            if (columns.length == 0)
                columns.push(<FormPanelColumn ref="sortRoot0" column={0} form={this.props.form} panel={this.props.panel}
                                              noPadding={this.props.noColumns} key={"col0"} fields={[]} />);
        }

        return (
            <div className="formcolumns">{columns}</div>
        );
    }
}


class FormFieldDragger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: {width: null, height: null} ,
        };
        this.resize = this.resize.bind(this);
    }
    resize(size) {
        this.setState({size:size});
    }
    render() {
        return this.props.form.state.editMode ? <div style={this.state.size} className="field-dragger" /> : <div />;
    }
}


export class FormField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            isDirty: false,
            validation: null,
        };
        this.defineValidationFunction = this.defineValidationFunction.bind(this);
        this.setValidation = this.setValidation.bind(this);
        this.clearValidation = this.clearValidation.bind(this);
        this.validate = this.validate.bind(this);
        this.resizeFieldDragger = this.resizeFieldDragger.bind(this);
        this.dirtyStateChanged = this.dirtyStateChanged.bind(this);
    }
    defineValidationFunction(getValidationError) {
        this.getValidationError = getValidationError.bind(this);
    }
    setValidation(validation) {
        if ('string' === typeof validation) {
            validation = {
                level: 'error',
                message: validation
            }
        }
        this.setState({validation: validation});
    }
    clearValidation() {
        if(this.state.validation)
            this.setState({validation: null});
    }
    validate () {
        var errorMessage = this.getValidationError && this.getValidationError();
        if (errorMessage) {
            this.setValidation(errorMessage);
            return false;
        } else {
            this.clearValidation();
            return true;
        }
    }
    resizeFieldDragger(size) {
        if(this.refs.fieldDragger)
            this.refs.fieldDragger.resize(size);
    }
    dirtyStateChanged(params) {
        params = params || {};
        var isDirtyValue = !!this.refs.input.state.isDirty;
        if (typeof params.callback === 'function') {
            this.setState({isDirty: isDirtyValue}, params.callback);
        } else {
            this.setState({isDirty: isDirtyValue});
        }
    }
    render() {
        var hidden = false;
        if(this.props.field.FieldName && this.props.field.FieldName == 'IsSpecialOrder' &&
            this.props.form.props.product && this.props.form.props.product.IsPackageHeader){
            hidden = true;
        }
        var sideEffectOnChange = this.validate;
        var autoUpdateField = this.props.form ? !!this.props.form.props.autoUpdate : false;
        var fieldId = this.props.fieldId || (this.props.form && (this.props.form.props.configuration.FormName + '_' + this.props.field.FieldName));
        var fieldConfig = (this.props.form && this.props.form.props.configuration) ?
            quosal.customization.fields.getFieldConfiguration(this.props.form.props.configuration.ObjectType, this.props.form.props.configuration.ObjectName, this.props.field.FieldName) : null;
        var formField = <FormFieldInput ref="input" fieldId={fieldId} fieldWrapper={this} autoUpdate={autoUpdateField} form={this.props.form}
                                        onChange={this.props.field.onChange || this.props.onChange} field={this.props.field}
                                        sideEffectOnChange={sideEffectOnChange}
                                        fieldConfig={fieldConfig} metadata={this.props.metadata} dirtyStateChanged={(e) => this.dirtyStateChanged(e)}
                                        disabled={this.props.disabled} hidden = {hidden} />;
        var fieldValidation = this.state.validation ? <FormFieldValidationIcon validation={this.state.validation} field={this} /> : null;
        var validationTooltip = this.state.validation && this.state.tooltipVisible ? <FormFieldValidationTooltip validation={this.state.validation} field={this} /> : null;
        var labelText = hidden ? '' : this.props.field.LabelName;
        if(fieldConfig && fieldConfig.FieldRename)
            labelText = fieldConfig.FieldRename;

        if(this.props.field.WidgetParameters) {
            try {
                var params = JSON.parse(this.props.field.WidgetParameters);

                if(params.skipLayout)
                    return formField;
            } catch (ex) {}
        }

        if(this.props.field.WidgetType == 'rtfeditor')
            return formField; //skip label and column layout for RTF editors
        
        var fieldDragger = this.props.form ? <FormFieldDragger form={this.props.form} ref="fieldDragger" /> : null;

        var className = this.props.className || this.props.field.className;
        className = (className ? className + ' ' : '') + 'formfieldwrapper';

        var dataType = this.props.field.DataType;
        var fieldName = this.props.field.FieldName;
        var fieldInfos = (this.props.form && quosal.customization.fields[this.props.form.props.configuration.ObjectType][this.props.form.props.configuration.ObjectName].allFields) || [{}];
        var fieldInfo = fieldInfos.where(function(x) { return x.FieldName == fieldName })[0];
        if (fieldInfo && fieldInfo.IsEnum) {
            dataType = 'Enum';
        }
        if (fieldConfig && fieldConfig.CustomDropdownContents) {
            dataType = 'Enum';
        }
        if(dataType == 'Boolean') {
            if (this.state.isDirty) {
                className += ' isDirtyHighlighted';
            }
            return (
                //checkboxes have labels to the side instead of on top
                <div className={className} name={this.props.field.FieldName} >
                    {fieldDragger}
                    <div className="formcheckboxwrapper">
                        {fieldValidation}
                        {validationTooltip}
                        {formField}
                        {this.props.field.NoLabel ? '' : <FormFieldLabel label={labelText} fieldId={fieldId} style={this.state.isDirty ? {backgroundColor: '#FFFFCC'} : null} />}
                    </div>
                </div>
            );
        } else  if(dataType == 'Enum') {
            //enums become select dropdowns with metadata as the options
            return (
                <div className={className} name={this.props.field.FieldName} >
                    {fieldDragger}
                    {this.props.field.NoLabel ? '' : <div className="formfieldlabel"><FormFieldLabel label={labelText} fieldId={fieldId} /></div>}
                    {fieldValidation}
                    {validationTooltip}
                    {formField}
                </div>
            );
        } else if(dataType == 'DateTime') {
            //datepicker widget has an additional icon in it
            return (
                <div className={className} name={this.props.field.FieldName} >
                    {fieldDragger}
                    {this.props.field.NoLabel ? '' : <div className="formfieldlabel"><FormFieldLabel label={labelText} fieldId={fieldId} /></div>}
                    <div className="formfield">
                        {fieldValidation}
                        {validationTooltip}
                        {formField}
                    </div>
                </div>
            );
        } else if(dataType == 'Currency') {
            //TODO: persistent currency symbol in front of input (stays when in edit mode)
        } else {
            //all other field types follow the label above field below layout
            return (
                <div className={className} name={this.props.field.FieldName} >
                    {fieldDragger}
                    {this.props.field.NoLabel ? '' : <div className="formfieldlabel"><FormFieldLabel label={labelText} fieldId={fieldId} /></div>}
                    <div className="formfield">
                        {fieldValidation}
                        {validationTooltip}
                        {formField}
                    </div>
                </div>
            );
        }
    }
}


class FormFieldLabel extends React.Component {
    render() {
        return (
            <label htmlFor={this.props.fieldId} className="formlabel" style={this.props.style}>{this.props.label}</label>
        );
    }
}

// props:
// highlightWhenDirty : set to true in order to have the field highlight when changed, without needing a form.
// disabled : input will be disabled if this is true
export class FormFieldInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDirty: false,
            isSelected: false,
            isDarkMode: $('#dark-toggle[type=checkbox]').prop('checked'),
        };
        // This binding is necessary to make `this` work in the callback
        this.clear = this.clear.bind(this);
        this.gotFocus = this.gotFocus.bind(this);
        this.lostFocus = this.lostFocus.bind(this);
        this.setValue = this.setValue.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onUpdateAux = this.onUpdateAux.bind(this);
        this.getValue = this.getValue.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
        this.fieldIsProtected = this.fieldIsProtected.bind(this);
        this.isReadOnly = this.isReadOnly.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.reselectOnMouseUp = this.reselectOnMouseUp.bind(this);
        this.preventDrag = this.preventDrag.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAndUpdate = this.handleChangeAndUpdate.bind(this);
        this.getValueToSet = this.getValueToSet.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.getFormValues = this.getFormValues.bind(this);
        this.reset = this.reset.bind(this);
        this.getRtfEditor = this.getRtfEditor.bind(this);
        this.getRtfHtml = this.getRtfHtml.bind(this);
        this.rtfChanged = this.rtfChanged.bind(this);
        this.darkModeChanged = this.darkModeChanged.bind(this);
        this.editingTimeoutId = null;
        this.editingTimeoutFunction = null;
        this.editingTimeoutInterval = 100; // milliseconds
    }
    static getOptionListFromEnumConfiguration(enumeration, selectedValue, dataType, otherOptions) {
        otherOptions = otherOptions || {};
        var options = [];
        var aValidOptionIsSelected = false;
        var selectedValueExists = selectedValue !== undefined;
        for (var i = 0; i < enumeration.length; i++) {
            var meta = enumeration[i];
            if (meta.Value === "" && otherOptions.hideBlankOption)
            {
                continue;
            }
            if (options.firstValue == undefined) {
                options.firstValue = meta.Value;
            }
            var valueMatches = (meta.Value == selectedValue) || (meta.Value === "" && selectedValue === null);
            if (selectedValueExists && !aValidOptionIsSelected &&
                (valueMatches || meta.Synonyms && (meta.Synonyms.indexOf(meta.SynonymsAreLowercase ? selectedValue.toLowerCase() : selectedValue) >= 0))) {
                options.selectedValue = meta.Value;
                aValidOptionIsSelected = true;
            }
            if (otherOptions.includeTitleOnOptionTags) {
                options.push(<option key={i} value={meta.Value} title={meta.Label}>{meta.Label}</option>);
            } else {
                options.push(<option key={i} value={meta.Value}>{meta.Label}</option>);
            }
    }
    if (selectedValueExists && !aValidOptionIsSelected) {
        options.selectedValue = selectedValue;
        var label = selectedValue ? '[INVALID] ' + selectedValue : '[PLEASE SELECT]';
        options.splice(0, 0, <option key={-1} value={selectedValue}>{label}</option>);
    }

    return options;
    }
    darkModeChanged(event) {
        this.setState({ isDarkMode: event.target.checked });   
     }
    // FSP 6/2/16: Is this unused?
    clear() {
        this.props.field.Value = '';
        this.setState({newValue: undefined, isDirty:false});

        if(this.props.field.WidgetType && this.refs.input.clear) {
            this.refs.input.clear();
        }
    }
    gotFocus(e) {
        FormFieldInput.selectedField = this;

        if (this.props.field.DataType == 'Int32' ||
            this.props.field.DataType == 'Double') {
            $(e.target).select();
        }
        if(this.props.onFocus)
            this.props.onFocus(this, this.refs.input);
    }
    lostFocus(e) {
        if(!this.state.isUpdating) {
            this.onUpdate();
        }
    }
    handleKeyDown(e) {
        if (e.which ===  27 || e.key === 'Escape') { // Esc key
            this.reset(function (target){
                $(target).blur();
            }.bind(null, e.target));
        }
    }
    onMouseDown(e) {
        FormFieldInput.mouseDownTarget = e.target;
    }
    // FSP 9/8/16 8076423: Microsoft Edge has a bug where it sets the cursor (& therefore undoes the text selection from focus) on mouseup.
    reselectOnMouseUp(e) {
        if (FormFieldInput.mouseDownTarget && FormFieldInput.mouseDownTarget === e.target) {
            $(e.target).select();
        }
    }
    keyPressed(e) {
        if(this.props.autoUpdate) {
            if (e.charCode == 13 && this.state.isDirty && this.props.onChange) { //Enter key
                //if(this.props.field.DataType == 'String' && e.shiftKey)
                //    return; //Allow shift+enter on textareas to add a new line

                this.onUpdate();
            }
        } else if(e.charCode == 13 && this.props.form) {
            try{
                this.props.form.props.productEditForm.state.invalidFields.length == 0 && this.props.form.submit(this);
            } catch {
                this.props.form.submit(this);
            }
        } else {
            this.handleChange(e);
        }
    }
    preventDrag(e) {
        e.stopPropagation();
        e.preventDefault();
        return false;
    }
    onUpdate(params) {
        if (Object.keys(quosal.priceModifier.modifierMatches).includes(this.props.field.FieldName)) {
            //If Modifier pattern is not valid, do not save
            var productEditForm = this.props.form && this.props.form.props.productEditForm;
            var invalidFields = (this.props.form && this.props.form.props.productEditForm && this.props.form.props.productEditForm.state.invalidFields) || [];
            if(!quosal.priceModifier.validateModifierPattern(this.refs.input, this.props.field.FieldName, this.state.newValue || "")){
                if(productEditForm && !invalidFields.includes(this.props.field.FieldName)){
                    invalidFields.push(this.props.field.FieldName)
                    productEditForm.setState({"invalidFields": invalidFields})
                }
                return;
            } else {
                if(productEditForm && invalidFields.includes(this.props.field.FieldName)){
                    var index = invalidFields.indexOf(this.props.field.FieldName);
                    invalidFields.splice(index, 1)
                    productEditForm.setState({"invalidFields": invalidFields})
                }
            }
        } 
        params = params || {};
        if (this.props.autoUpdate || !this.props.form || this.props.form.props.noSave) {
            return this.onUpdateAux(params);
        }

        var beforeSave = function (callback) {
            if (typeof this.editingTimeoutId === 'number') {
                window.clearTimeout(this.editingTimeoutId);
            }
            if (typeof this.editingTimeoutFunction === 'function') {
                this.editingTimeoutFunction({callback: callback});
            } else {
               callback();
            }
        }.bind(this);
        this.beforeSave = beforeSave;

        if (typeof this.editingTimeoutId === 'number') {
            window.clearTimeout(this.editingTimeoutId);
        }
        this.editingTimeoutFunction = function (params) {
            params = params || {};
            this.onUpdateAux({callback: params.callback});
            this.editingTimeoutId = null;
            this.editingTimeoutFunction = null;
            this.beforeSave = null;
        }.bind(this);
        this.editingTimeoutId = window.setTimeout(this.editingTimeoutFunction, this.editingTimeoutInterval);
    }
    onUpdateAux(params) {
        params = params || {};
        if (this.state.isDirty) {
            if (this.props.onChange && this.props.autoUpdate) {
                this.setState({isUpdating: true});

                this.props.onChange(this, this.refs.input, function () {
                    if(this.componentIsMounted) {
                        this.setState({isDirty: false, isUpdating: false, newValue: null}, function () {
                            if (this.props.dirtyStateChanged)
                                this.props.dirtyStateChanged({callback: params.callback});
                        }.bind(this));
                    }
                }.bind(this));
            } else if (this.props.onChange) {
                this.props.onChange(this.refs.input, function () {
                    if( typeof params.callback === 'function') {
                        params.callback();
                    }
                }.bind(this));
            } else {
                if( typeof params.callback === 'function') {
                    params.callback();
                }
            }
            if (this.props.sideEffectOnChange) {
                this.props.sideEffectOnChange();
            }
        }
    }
    handleChange(e) {
        if (Object.keys(quosal.priceModifier.modifierMatches).includes(this.props.field.FieldName)) {
            quosal.priceModifier.validateModifierPattern(this.refs.input, this.props.field.FieldName, this.refs.input.value);
        } 
        var value = this.getValueToSet(e);

        this.setValue(value);
    }
    handleChangeAndUpdate(e, callback) {
        var value = this.getValueToSet(e);
        this.setValue(value, {alwaysUpdate:true, callback: callback});
    }
    getValueToSet(e) {
        //TODO: validate numeric input values?
        var value = null;
        if(this.props.field.WidgetType && this.props.field.WidgetType == 'colorpicker'){
            value = e;
        } else {
            value = (this.props.field.DataType === 'Boolean') ? this.refs.input.checked
                : (this.refs.input.value || (this.refs.input.getValue ? this.refs.input.getValue(e) : ''));//custom widgets can provide a 'getValue()' function
        }
        return value;
    }
    setValue(value, params) {
        if (this.isDisabled()) {
            return;
        }
        if (!params) { params = {}; }
        this.setState({newValue: value, isDirty: true}, function() {
            if(this.props.dirtyStateChanged)
                this.props.dirtyStateChanged(this);

            if(this.props.field.DataType == 'Boolean' || !this.props.autoUpdate || params.alwaysUpdate) {
                this.onUpdate({callback: params.callback});
            } else if (typeof params.callback === 'function') {
                params.callback();
            }
        }.bind(this));
    }
    getValue() {
        if (this.state.newValue != null) {
            return this.state.newValue;
        }
        if (this.defaultValueOverride != null) {
            return this.defaultValueOverride
        }
        return this.props.field.Value;
    }
    isDisabled() {
        return this.state.isUpdating
            || this.props.disabled
            || this.state.disabled
            || (this.props.parentRow && this.props.parentRow.props.row.isUnsaved)
            || (this.props.form && this.props.form.state.editMode)
            || (this.props.form && this.props.form.props.disabled)
            || this.fieldIsProtected();
    }
    fieldIsProtected() {
        var isSearchForm = this.props.form && this.props.form.props.isSearchForm;
        if(isSearchForm) {
            return false;
        }
        if( (this.props.field.FieldName == "PriceModifier" || this.props.field.FieldName == "RecurringPriceModifier")
            && this.props.form && this.props.form.props.product && this.props.form.props.productEditForm
            && this.props.form.props.product.IsPackageHeader 
            && !this.props.form.props.product.OverridePackageDetails){
                return true;
        }

        if (this.props.field.FieldName == "ItemNotesPlain") {
            return true;
        }

        if(this.props.parentRow && this.props.parentRow.props.row.IsPackageItem){
            var item = quosal.util.getItemById(app.currentQuote, this.props.parentRow.props.row.ParentQuoteItem);
            if(this.props.field.FieldName == "IsProtectedItem" && item.IsProtectedItem){
                return true;
            }
            if(this.props.field.FieldName == "IsProtectedPrice" && item.IsProtectedPrice){
                return true;
            }
        }
        if(this.props.field.ReadOnly) {
            return true;
        }

        var objectType;
        var objectName;

        if(this.props.form) {
            objectType = this.props.form.props.configuration.ObjectType;
            objectName = this.props.form.props.configuration.ObjectName;
        } else if(this.props.parentRow) {
            var gridConfig = quosal.customization.grids[this.props.parentRow.props.configuration.gridName];
            objectType = gridConfig.ObjectType;
            objectName = gridConfig.ObjectName;
        }

        if(objectName && objectType && quosal.schema[objectType][objectName]) {
            var boField = quosal.schema[objectType][objectName][this.props.field.FieldName];
            if(boField && boField.ReadOnly) {
                return true;
            }
        }

        return false;
    }
    isReadOnly() {
        return (
            (this.props.form && this.props.form.props.readonlyInputs && this.props.form.props.readonlyInputs[this.props.field.FieldName])
            ||
            this.props?.field?.ReadOnly
        );
    }
    isDirty() {
        if(this.props.field.WidgetType && this.refs.input && this.refs.input.isDirty) {
            return this.refs.input.isDirty();
        } else {
            return this.state.isDirty;
        }
    }
    getFormValues(flags) {
        flags = flags || {};

        if(this.refs.input && this.refs.input.getFormValues) {
            return this.refs.input.getFormValues();
        } else {
            var values = {};

            values[this.props.field.FieldName] = this.state.newValue;
            if (flags.getAllValues && values[this.props.field.FieldName] === undefined) {
                values[this.props.field.FieldName] = this.getValue();
            }

            return values;
        }
    }
    // FSP 6/2/16: Is this unused?
    clean() {
        delete quosal.htmlEditorCallback;
        this.setState({isDirty: false, isUpdating: false}, function() {
            if(this.props.dirtyStateChanged)
                this.props.dirtyStateChanged(this);
        }.bind(this));
    }
    reset(callback) {
        if (this.props.onReset) {
            this.props.onReset();
        }
        this.setState({newValue: null, isDirty: false, isUpdating: false}, function() {
            if(this.props.dirtyStateChanged)
                this.props.dirtyStateChanged(this);

            if(this.props.field.WidgetType == 'rtfeditor') {
                //TODO: can this be done without re-loading the iframe?
                this.getRtfEditor().contentWindow.location.reload();
            } else if(this.props.field.WidgetType && this.refs.input.reset) {
                this.refs.input.reset();
            }
            if (typeof callback === 'function'){
                callback();
            }
               
            Object.keys(quosal.priceModifier.modifierMatches).forEach(key => {
                $.quosal.validation.clearField($("#ProductEdit_" + key));
            });

            if(this.props.form && this.props.form.props.productEditForm){
                this.props.form.props.productEditForm.setState({"invalidFields" : []});
            }
                

        }.bind(this));
    }
    shouldComponentUpdate(nextProps, nextState) {
        //return this.state.newValue !== nextState.newValue ||
        //        this.state.isUdating !== nextState.isUpdating;

        return true;

        //TODO: if we can implement this reliably it could save performance of unnecessary renders (for any component)
        //return this.state.isDirty || nextState.isDirty;
        //
        //var b = this.state.isDirty ?
        //    nextProps.field.value != this.state.newValue
        //    : nextProps.field.value != this.props.field.Value;
        //
        //return b;
    }
    getRtfEditor() {
        return ReactDOM.findDOMNode(this.refs.input.refs.editor.refs.input);
    }
    getRtfHtml() {
        return $(this.getRtfEditor()).contents().find('#rtfNoteHtmlContainer').html();
    }
    rtfChanged(params) {
        params = params || {};

        var notes = this.getRtfHtml();

        this.state.newValue = notes;
        this.state.isDirty = true;
        this.setState({newValue: notes, isDirty: true}, function() {
            var runOnUpdate = !this.props.autoUpdate && function () { this.onUpdate({callback: params.callback}); }.bind(this);
            if(this.props.dirtyStateChanged) {
                var myCallback = runOnUpdate || params.callback;
                this.props.dirtyStateChanged({callback: myCallback});
            } else if (runOnUpdate) {
                runOnUpdate();
            } else if (typeof params.callback === 'function') {
                params.callback();
            }
        }.bind(this));
    }
    onComponentUpdate(state) {
        if (!state) { state = this.state; }
        if (!state.isDirty && this.props.form && this.props.form.props.alwaysDirtyInputValues) {
            var value = this.props.form.props.alwaysDirtyInputValues[this.props.field.FieldName];
            if (value != null) {
                this.setValue(value);
            }
        }
    }
    componentDidMount() {
        this.componentIsMounted = true;
        this.onComponentUpdate();

        //Initialize Custom Widgets
        if(this.props.field.WidgetType) {
            if(this.props.field.WidgetType == 'textarea') {
                var params = {};
                if (!quosal.util.nullOrEmpty(this.props.field.WidgetParameters))
                    params = JSON.parse(this.props.field.WidgetParameters);

                this.props.fieldWrapper.resizeFieldDragger({height:params.height + 16 /*label height*/});
            }
        }

        if (typeof this.props.componentDidMount === 'function') {
            this.props.componentDidMount(this);
        }
        $('#dark-toggle[type=checkbox]').on('change', this.darkModeChanged)

    }
    componentWillUnmount(){
        this.componentIsMounted = false;
    }
    UNSAFE_componentWillUpdate(nextProps, nextState) {
        this.onComponentUpdate(nextState);

        if(this.state.isSelected)
            this.refs.input.focus();
    }
    render() {
        if(this.props.hidden){
            return null;
        }
        if(this.props.field.fieldName == "TabTotals" || this.props.field.fieldName == "HtmlNotes"){
            return null;
        }
        var quote = this.props.quote || app.currentQuote;
        var input = null;

        var style = this.props.style ? quosal.util.clone(this.props.style) : {};

        if(this.props.form && this.props.form.props.customizeInput) {
            var overrideInput = this.props.form.props.customizeInput(this, style);

            if(overrideInput)
                return overrideInput;
        }
        
        //TODO: left align text, right align numbers, center images
        var currentValue = this.getValue();
        var fieldKey = this.props.parentId + '_' + this.props.field.FieldName;
        var disableInput = this.isDisabled() || this.props.field.IsEditable == false;
        var readOnly = this.isReadOnly();

        currentValue = quosal.sell.data.formatValue(currentValue, this.props.field);
        
        if(this.state.isDirty && (this.props.highlightWhenDirty || (this.props.form && !this.props.form.props.noSave)))
            style.backgroundColor = this.state.isDarkMode ? '#333333' : '#FFFFCC';

        var fieldId = this.props.fieldId + (this.props.parentRow && this.props.parentRow.props.row ? '_' + this.props.parentRow.props.row.IdQuoteItems : '');
        
        //Render Custom Widgets
        if(this.props.field.WidgetType) {
            this.isCustomField = true;
            this.widgetType = this.props.field.WidgetType;
            try {
                this.widgetParameters = quosal.util.nullOrEmpty(this.props.field.WidgetParameters) ? {} : JSON.parse(this.props.field.WidgetParameters);
            } catch (ex) {
                this.widgetParameters = this.props.field.WidgetParameters;
            }

            if(this.props.field.WidgetType == 'textarea') {
                var params = {};
                if (!quosal.util.nullOrEmpty(this.props.field.WidgetParameters))
                    params = JSON.parse(this.props.field.WidgetParameters);

                style.height = params.height;
                style.maxHeight = params.height;

                return <textarea ref="input" id={fieldId} style={style} disabled={disableInput} key={fieldKey} value={currentValue} onChange={this.handleChange}></textarea>
            } else if(this.props.field.WidgetType == 'greedyTextarea') {
                var numberOfFieldsInPanel = 0;
                var me = this;
                this.props.fieldWrapper.props.form.props.configuration.Fields.forEach(function(field){
                    if(field.IdFormPanel == me.props.field.IdFormPanel){
                        numberOfFieldsInPanel ++;
                    }
                })
                var params = {};
                if (!quosal.util.nullOrEmpty(this.props.field.WidgetParameters))
                params = JSON.parse(this.props.field.WidgetParameters);
                //SP 2/27/18 9850252: If a customNote is the only field in a panel make it a rtf editor sized text area, else make it normal textarea sized
                    if(numberOfFieldsInPanel > 1){
                        style.height = params.height;
                        style.maxHeight = params.height;
                    } else {
                        style.width = '100%';
                        style.minHeight = "650px";
                    }
                    return <textarea ref="input" id={fieldId} style={style} disabled={disableInput} key={fieldKey} value={currentValue} onChange={this.handleChange}></textarea>
            } else if(this.props.field.WidgetType == 'colorpicker'){
                var colorChoices = ['red', 'orange', 'yellow', 'green', 'blue', 'pink', 'purple', 'lightblue']
                var selectedColor = this.state.newValue ? this.state.newValue : this.props.field.Value ? this.props.field.Value : null;
                var defaultColor = 'gray';
                return (
                    <ColorPicker ref='input' colorCssSelector="tabcolor" colorChoices={colorChoices} defaultColor={defaultColor} selectedColor={selectedColor} colorChangeCallback={this.handleChange}/>
                )
            }
            else if (this.props.field.WidgetType == 'priceModifierHelper' && this.props.form && this.props.form.props && (this.props.form.props.product || this.props.form.props.quote)){
                let inputstyle=quosal.util.clone(style);
                inputstyle.display = 'inline-block';
                inputstyle.width = '100%';
                if (this.props.field.FieldName == 'PriceModifier') {
                    return (
                        <div style={{ display: 'inline-block' }}>
                            {/* Prepare Content -> Product Details */}
                            <input ref="input" id={this.props.fieldId} style={inputstyle} disabled={disableInput} readOnly={readOnly} key={fieldKey}
                                name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus}
                                onBlur={this.lostFocus} onKeyPress={this.keyPressed} />

                            {(disableInput || readOnly) ? null : <PriceModifierHelper itemCost={this.props.form.props.product.Cost}
                                itemSuggestedPrice={this.props.form.props.product.SuggestedPrice} itemPrice={this.props.form.props.product.QuoteItemPrice}
                                itemBasePrice={this.props.form.props.product.BasePrice} priceModifier={this.props.form.props.product.PriceModifier}
                                idQuoteItems={this.props.form.props.product.IdQuoteItems} idQuoteMain={this.props.form.props.product.IdQuoteMain}
                                field={this.props.field} cellStyle={style} advancedRoundingEnabled={quote.EnableAdvancedRounding} />}
                        </div>
                    );
                }
                else if (this.props.field.FieldName == 'RecurringPriceModifier') {
                    return (<div style={{ display: 'inline-block' }}>
                        {/* Prepare Content -> Product Details */}
                        <input ref="input" id={this.props.fieldId} style={inputstyle} disabled={disableInput} readOnly={readOnly} key={fieldKey}
                            name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus}
                            onBlur={this.lostFocus} onKeyPress={this.keyPressed} />
                            
                        {(disableInput || readOnly) ? null : <PriceModifierHelper itemCost={this.props.form.props.product.RecurringCost} itemSuggestedPrice={this.props.form.props.product.RecurringSuggestedPrice} itemPrice={this.props.form.props.product.RecurringAmount}
                            itemBasePrice={this.props.form.props.product.RecurringBasePrice} priceModifier={currentValue}
                            idQuoteItems={this.props.form.props.product.IdQuoteItems} idQuoteMain={this.props.form.props.product.IdQuoteMain}
                            field={this.props.field} cellStyle={style} advancedRoundingEnabled={quote.EnableAdvancedRounding} />}
                    </div>);
                } else if (this.props.field.FieldName == 'QuotePriceModifier' || this.props.field.FieldName == 'QuoteRecurringPriceModifier') {
                    var priceModifierValue = this.props.field.FieldName == 'QuotePriceModifier' ? this.props.form.props.quote.QuotePriceModifier : this.props.form.props.quote.QuoteRecurringPriceModifier;
                    return (
                        <div style={{ display: 'inline-block' }}>
                            {/* Quote Setup Level */}
                            <input ref="input" id={this.props.fieldId} style={inputstyle} disabled={disableInput} readOnly={readOnly} key={fieldKey}
                                name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus}
                                onBlur={this.lostFocus} onKeyPress={this.keyPressed} />

                            {(disableInput || readOnly) ? null : <PriceModifierHelper itemCost={0}
                                itemSuggestedPrice={0} itemPrice={0}
                                itemBasePrice={0} priceModifier={priceModifierValue}
                                idQuoteItems={''} idQuoteMain={this.props.form.props.quote.IdQuoteMain}
                                field={this.props.field} cellStyle={style} advancedRoundingEnabled={quote.EnableAdvancedRounding} priceModifierLevel={'Quote'} />}
                        </div>
                    );
                }
        }   
            else if (this.props.field.WidgetType == 'rtfeditor' && this.props.form && this.props.form.props.product && this.props.form.props.productEditForm.state.modTagMode) {
                // Mod Tag mode                    
                return <ModificationTag itemQuoteId={this.props.form.props.product.IdQuoteMain} itemQuoteItemId={this.props.form.props.product.IdQuoteItems}
                    itemField={this.props.field.FieldName} itemFieldContent={this.props.field.Value} itemNavigationObject={this.props.form.props.productEditForm} />
            }else if(this.props.field.WidgetType == 'rtfeditor') {
                var additionalParams = [];

                if (!quosal.util.nullOrEmpty(this.props.field.WidgetParameters)) {
                    var params = JSON.parse(this.props.field.WidgetParameters);

                    if (params.urlParameters) {
                        for(var i = 0; i < params.urlParameters.length; i++) {
                            var key = params.urlParameters[i];
                            var value = quosal.util.queryString(key);
                            additionalParams.push(key + '=' + value);
                        }
                    }
                    if(params.name) {
                        additionalParams.push('name=' + params.name);
                    }
                }

                var url = quosal.util.url('RtfEditor.aspx', ...additionalParams)
                style.width = '100%';
                style.minHeight = 650;

                return <RtfLoader ref="input" message="Loading Notes Editor..." {...params} url={url} editorLoadedCallback={this.props.rtfCallback}
                                  formField={this} style={style} onChange={this.rtfChanged} disabled={disableInput} />
            } else {
                //custom React component
                var componentType = window[this.props.field.WidgetType];

                if(componentType) {
                    this.isCustomComponent = true;
                    this.componentType = this.props.field.WidgetType;

                    var params = {};
                    if (!quosal.util.nullOrEmpty(this.props.field.WidgetParameters))
                        params = JSON.parse(this.props.field.WidgetParameters);

                    params.field = this.props.field;
                    params.fieldId = this.props.fieldId;
                    params.formField = this;
                    params.form = this.props.form;
                    params.currentValue = currentValue;
                    params.handleChange = this.handleChange;
                    params.keyPressed = this.keyPressed;
                    params.ref = 'input';

                    if(!params.style)
                        params.style = style;
                    else
                        params.style.backgroundColor = style.backgroundColor;

                    return React.createElement(componentType, params);
                }
            }
        }

        var dataType = this.props.field.DataType;
        var fieldName = this.props.field.FieldName;
        // TODO: quosal.customization.fields.BusinessObject.QuoteMain.allFields field metadata should be plumbed down to the field property on the form, so that we don't have to do this lookup
        var fieldInfos = (this.props.form && quosal.customization.fields[this.props.form.props.configuration.ObjectType][this.props.form.props.configuration.ObjectName].allFields) || [{}];
        var fieldInfo = fieldInfos.where(function(x) { return x.FieldName == fieldName })[0];
        if (fieldInfo && fieldInfo.IsEnum) {
            dataType = 'Enum';
        }
        var customDropdownContents = this.props.fieldConfig && this.props.fieldConfig.CustomDropdownContents;
        if (customDropdownContents) {
            dataType = 'Enum';
        }


        //determine the type of input element to create based on the field dataType
        if(dataType == 'String') {
            return <input ref="input" type="text" id={fieldId} style={style} disabled={disableInput} readOnly={readOnly} key={fieldKey} className={readOnly ? 'readonly' : ''}
                          name={this.props.field.FieldName} value={currentValue} onChange={readOnly ? null : this.handleChange} onFocus={this.gotFocus}
                          onBlur={this.lostFocus} onKeyPress={readOnly ? null : this.keyPressed} onKeyDown={readOnly ? null : this.handleKeyDown} onDragStart={this.preventDrag} />;
            //return <textarea ref="input" id={fieldId} style={style} disabled={disableInput} key={fieldKey} name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus} onBlur={this.lostFocus} onKeyPress={this.keyPressed} />;
            //return <div contentEditable={true}  onChange={this.handleChange} onFocus={this.gotFocus} onBlur={this.lostFocus} onKeyPress={this.keyPressed}>{currentValue}</div>
        } else if(dataType == 'Boolean') {
            var type = 'checkbox';
            var checkboxName = this.props.field.FieldName;
            if (this.props.field.RadioGroup !== undefined) {
                type = 'radio'
                checkboxName = this.props.field.RadioGroup + '_' + checkboxName;
            }
            return <input ref="input" type={type} id={fieldId} style={style} disabled={disableInput || readOnly} key={fieldKey} name={checkboxName} value="true" checked={currentValue} onChange={this.handleChange} onFocus={this.gotFocus} onBlur={this.lostFocus} />;
        } else if(dataType == 'Enum') {
            var enumeration =
                (fieldInfo && quosal.metadata.enums[fieldInfo.EnumType]) ||
                quosal.metadata.enums[this.props.field.EnumType] ||
                (this.props.metadata && this.props.metadata[this.props.field.EnumType]) ||
                customDropdownContents;
            
            if (!enumeration) {
                return <div className="formfield"><input ref="input" type="text" id={fieldId} style={style} disabled={disableInput} readOnly={readOnly} key={fieldKey} name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus} onBlur={this.lostFocus} onKeyPress={this.keyPressed} onKeyDown={this.handleKeyDown} onDragStart={this.preventDrag} /></div>;
            }
            if (this.props.form && this.props.form.props.pickDropdownFirstOption && enumeration[0] && !currentValue) {
                currentValue = enumeration[0].Value;
            }
            var otherOptions;
            if (fieldInfo && fieldInfo.EnumType &&  fieldInfo.EnumType == "CultureNames") 
            {             
                if (app.currentQuote && !app.currentQuote.IsTemplate) {
                    otherOptions ={hideBlankOption : true};
                }               
            }
            var options= FormFieldInput.getOptionListFromEnumConfiguration(enumeration,
                        currentValue,
                        this.props.field.DataType, otherOptions);
            if (options.selectedValue) {
                currentValue = options.selectedValue;
                if (this.props.doCorrectSynonyms || this.props.form && this.props.form.props.doCorrectSynonyms) {
                    this.defaultValueOverride = currentValue;
                }
            }
            return <div className="formselectfieldwrapper" style={style}>
                        <select ref="input" id={this.props.fieldId} className={'formselectfield' + (readOnly ? ' readonly' : '' )} key={fieldKey}
                           name={this.props.field.FieldName} value={currentValue} disabled={disableInput || readOnly}
                           onChange={readOnly ? null : this.handleChangeAndUpdate} onFocus={this.gotFocus} onBlur={this.lostFocus} onKeyPress={readOnly ? null : this.keyPressed}>{options}</select>
                    </div>;
                    
        } else if(dataType == 'Double' || dataType == 'Int32') {
            var inputElementProps = {
                readOnly: readOnly,
                disabled: disableInput,
                name: this.props.field.FieldName,
                onInput: this.handleChange,
                onChange: readOnly ? null : this.handleChange,
                onFocus: this.gotFocus,
                onBlur: this.lostFocus,
                onKeyPress: readOnly ? null : this.keyPressed,
                onKeyDown: readOnly ? null : this.handleKeyDown,
                onMouseDown: this.onMouseDown,
                onMouseUp: this.reselectOnMouseUp,
                onDragStart: this.preventDrag,
                className: 'numeric-input',
                style: style,
                value: currentValue
            };

            if (this.props.form && this.props.form.props.numericInputsUseRanges) {
                return <NumericRangeInput ref="input" setValue={this.setValue} id={fieldId} style={style}
                                           disabled={disableInput} key={fieldKey} name={this.props.field.FieldName}
                                           inputElementProps={inputElementProps}/>;
            } else {
                return <input {...inputElementProps} ref="input" type="text" id={fieldId} style={style} disabled={disableInput} key={fieldKey}
                              onDragStart={this.preventDrag}/>;
            }
        } else if(dataType == 'DateTime') {
            var inputElementProps = {
                readOnly: readOnly,
                disabled: disableInput,
                name: this.props.field.FieldName,
                onInput: this.handleChange,
                onChange: readOnly ? null : this.handleChange,
                onFocus: this.gotFocus,
                onBlur: this.lostFocus,
                onKeyPress: readOnly ? null : this.keyPressed,
                onKeyDown: readOnly ? null : this.handleKeyDown,
                onDragStart: this.preventDrag,
                style: style,
                value: currentValue
            };
            if (this.props.form && this.props.form.props.numericInputsUseRanges) {
                return <DateTimeRangeInput ref="input" setValue={this.setValue} id={fieldId} style={style}
                                           disabled={disableInput} key={fieldKey} name={this.props.field.FieldName}
                                           inputElementProps={inputElementProps}/>;
            } else {
                return <DateTimeInput ref="input" setValue={this.setValue} id={fieldId} style={style}
                                      disabled={disableInput} key={fieldKey} name={this.props.field.FieldName}
                                      inputElementProps={inputElementProps}/>;
            }

        } else if(dataType == 'Byte[]') {
            var idQuoteTabs = (this.props.form && this.props.form.props.tab) ? this.props.form.props.tab.IdQuoteTabs : null;
            var idQuoteItems = (this.props.form && this.props.form.props.product) ? this.props.form.props.product.IdQuoteItems :    //for product edit page
                (this.props.parentRow && this.props.parentRow.props.row) ? this.props.parentRow.props.row.IdQuoteItems : null;      //for product grid
            var size = (this.props.parentRow) ? 'small' : 'medium';     // small in a grid, medium elsewhere
            return <EditableImage src={currentValue} disabled={disableInput || readOnly} size={size} imageId={this.props.fieldId} key={fieldKey} norescale={true} IdQuoteMain={quote.IdQuoteMain}
                                  IdQuoteTabs={idQuoteTabs} IdQuoteItems={idQuoteItems} />
        }
        return <input ref="input" type="hidden" id={this.props.fieldId} style={style} disabled={disableInput} readOnly={readOnly} key={fieldKey} name={this.props.field.FieldName} value={currentValue} onChange={this.handleChange} onFocus={this.gotFocus} onBlur={this.lostFocus} onKeyPress={this.keyPressed} />;
    }
}

FormFieldInput.selectedField= null;

class FormFieldValidationIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.toggleTooltip = this.toggleTooltip.bind(this); 
        this.showTooltip = this.showTooltip.bind(this); 
        this.hideTooltip = this.hideTooltip.bind(this); 
    }
    toggleTooltip() {
        this.props.field.setState({ tooltipVisible: !this.props.field.state.tooltipVisible });
    }
    showTooltip() {
        this.props.field.setState({ tooltipVisible: true });
    }
    hideTooltip() {
        this.props.field.setState({ tooltipVisible: false });
    }
    render() {
        return (
            <div className={'validation ' + this.props.validation.level + ' tip'} style={{ cursor: 'pointer' }} onClick={this.toggleTooltip} onMouseOver={this.showTooltip} onMouseOut={this.hideTooltip}></div>
        );
    }
}


export class FormFieldValidationTooltip extends React.Component {
    render() {
        return (
            <div className="tooltip" style={{display: 'block'}}>
                <div className="point up"></div>
                <span>{this.props.validation.message}</span>
            </div>
        );
    }
}


export class Button extends React.Component {
    render() {
        if(this.props.visible === false)
            return <span />;

        return (
            <button id={this.props.id} className={(this.props.className || '') + ' ' + this.props.type} onClick={this.props.onClick} style={this.props.style} disabled={this.props.disabled}>
                {this.props.children}
            </button>
        );
    }
}
global.Button = Button;

export class SaveButton extends React.Component {
    render() {
        if(this.props.visible === false)
            return <span />;

        var spinnerArgs = {
            lines: 10,
            length: 3,
            radius: 1
        };

        return (
            <Button type="save" {...this.props}>
                {this.props.isSaving ? <Spinner data-cy='saveSpinner' args={spinnerArgs} style={{position:'absolute', marginLeft:-18, marginTop:-2}} /> : ''}
                {this.props.children}
                {this.props.isSaving ? 'Saving' : this.props.text || 'Save'}
            </Button>
        );
    }
}
global.SaveButton = SaveButton;

