import {CustomerSetupToolbar} from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";

export class CwAttachSiteMultiple extends React.Component {
    constructor(props) {
        super(props);
        this.selectedSites = {"quoteto": {}, "billto":{}, "shipto":{}, "sendto":{} };
        this.state = {
            isDirty: false, 
            isSaving: false, 
            filterTerm: "",
        };

        // This binding is necessary to make `this` work in the callback
        this.allSiteAttachCheck = this.allSiteAttachCheck.bind(this);
        this.attachSiteToAll = this.attachSiteToAll.bind(this);
        this.saveCallback = this.saveCallback.bind(this);
        this.search = this.search.bind(this);
        this.selectSite = this.selectSite.bind(this);
    }
    isSameSiteArray(a, b) {
        if (!a) {
            return !b;
        }
        var isSame = true;
        a.every(function(aSite){
            var siteMatch = false;
            b.forEach(function(bSite){
                if(aSite.AddressID == bSite.AddressID){
                    siteMatch = true;
                }
            })
            if(!siteMatch){
                isSame = false;
            }
            return siteMatch;
        })
        return isSame;
    }
    allSiteAttachCheck(){
        if(
            this.isSameSiteArray(this.props.sites["Quote To"],this.props.sites["Bill To"]) &&
            this.isSameSiteArray(this.props.sites["Quote To"],this.props.sites["Send To"]) &&
            this.isSameSiteArray(this.props.sites["Quote To"],this.props.sites["Ship To"])
        ){
            return <CwSiteContainer filterTerm={this.state.filterTerm} attachSiteToAll={this.attachSiteToAll} sites={this.props.sites["Quote To"]} title={"All Sites"} usageType={"all"} />
        }
    }
    attachSiteToAll(site){
        this.selectedSites["quoteto"] = site;
        this.selectedSites["billto"] = {};
        this.selectedSites["shipto"] = site;
        this.selectedSites["sendto"] = site;
        this.setState({isDirty: true})
        this.saveCallback();
    }
    selectSite(usageType, site){
        this.selectedSites[usageType] = site;
        this.setState({isDirty: true})
    }
    saveCallback(){
        var me = this;
        var changeAddressesApi = quosal.api.crm.changeAddresses(app.currentQuote.IdQuoteMain, this.selectedSites["quoteto"],
        this.selectedSites["billto"],this.selectedSites["shipto"],this.selectedSites["sendto"] );
        changeAddressesApi.finished = function(msg){
            me.setState({isDirty: false});
            quosal.sell.quote.update(msg.quote);
            if(quosal.util.areCustomersValid()){
                app.currentModule.loadSubModule('quote.home', {
                    container: 'quoteModule',
                    unloadSubModules: true
                });
            } else {
                app.currentModule.loadSubModule('quote.customer', {
                    container: 'quoteModule',
                    unloadSubModules: true
                });
            }
        }
        changeAddressesApi.call();
        this.setState({isSaving: true})
    }
    search(e){
        this.setState({filterTerm : e.target.value});
    }
    render(){
        return(
            <div>
                <CustomerSetupToolbar saveCallback={this.saveCallback} isDirty={this.state.isDirty} />
                {this.state.isSaving 
                ? 
                <FormPlaceholder message="Attaching..." />
                :
                <div>
                    <div className="grid col4x1">
                        <Panel title="Search">
                            <div style={{margin:"10px 0 0 5px"}}>
                                <input type="text" placeholder="" id="searchInput" onChange={this.search} value={this.state.filterTerm} />
                            </div>
                        </Panel>
                    </div>
                    <div className="grid col4x3">
                        {this.allSiteAttachCheck()}
                        <CwSiteContainer filterTerm={this.state.filterTerm} selectSite={this.selectSite} sites={this.props.sites["Quote To"]} title={"Quote To Sites"} usageType={"quoteto"} />
                        <CwSiteContainer filterTerm={this.state.filterTerm} selectSite={this.selectSite} sites={this.props.sites["Send To"]} title={"Send To Sites"} usageType={"sendto"} />
                        <CwSiteContainer filterTerm={this.state.filterTerm} selectSite={this.selectSite} sites={this.props.sites["Ship To"]} title={"Ship To Sites"} usageType={"shipto"} />
                        <CwSiteContainer filterTerm={this.state.filterTerm} selectSite={this.selectSite} sites={this.props.sites["Bill To"]} title={"Bill To Sites"} usageType={"billto"} />
                    </div>
                </div>
                }
            </div>
        )
    }
}

class CwSiteContainer extends React.Component {
    getSiteRows(){
        var me = this;
        return this.props.sites.map(function(site, index){
            var attachedIcon = "img/svgs/sell/" +site.IsAttached + ".svg";
            var linkText =  me.props.attachSiteToAll && "Attach" || "Select";

            var address = (site.Address1 || "") + (site.Address2 ? " " + site.Address2 : "")
            if(site.city || site.State || site.PostalCode){
                address += "," + (site.City ? " " + site.City : "") + (site.State ? " " + site.State : "") + (site.PostalCode ? " " + site.PostalCode : "");
            }
            if(me.props.filterTerm && (!address.toLowerCase().includes(me.props.filterTerm.toLowerCase()) && !site.SiteName.toLowerCase().includes(me.props.filterTerm.toLowerCase()))){
               return 
            }
            return (
                <tr key={'siteRow_'+index}>
                    <td className="content"><center><div onClick={me.selectSite.bind(me, site)}  className="selectaddress link">{linkText}</div></center></td>
                    {me.props.usageType != "all" &&
                    <td className="content"><img style={{height:"16px"}} className="attachedimage" src={attachedIcon} /></td>
                    }
                    <td className="content">{site.SiteName}</td>
                    <td className="content"> {address}</td>
                </tr>
            )
        })
       
    }
    selectSite(site){
        if(this.props.usageType == "all"){
            this.props.attachSiteToAll(site)
            return;
        }
        this.props.sites.forEach(function(site){
            site.IsAttached = false;
        });
        site.IsAttached = true;
        this.props.selectSite(this.props.usageType, site);
        this.forceUpdate();
    }
    render(){
        return(
            <div>
                <div className="panel paging">
                    <div className="title">
                        {this.props.title}
                    </div>   

                    <div className="content">
                        <table border="0" cellPadding="0" cellSpacing="0" className="datagrid">
                            <thead>
                                <tr>
                                    <th width="100" className="title nosort"></th>
                                    {this.props.usageType != "all" &&<th width="50" className="title nosort">Attached</th>}
                                    <th width={this.props.usageType == "all" ? "343" : "280"} className="title nosort">Site Name</th>
                                    <th width="396" className="title nosort">Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.getSiteRows()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}