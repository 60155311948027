import * as React from 'react';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    DataGrid,
    useGridApiContext,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridEditInputCell
} from '@mui/x-data-grid';
import { ATTACHED_DYNAMIC_QUESTION_INPUT_VALUE_MESSAGE, MANUFACTURER_PART_NUMBER_VALIDATION_ERROR } from '../../constants/createFormConstants';
import { EditSelectStyled, StyledTooltip } from '../../styledComponents/mapTableStyled';
import { CustomWidthTooltip } from '../../../../common/guidedSelling/styledComponents/commonStyled';

export class FullFeaturedCrudGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            rowModesModel: {},
            isValid: true,
            invalidRowId: '',
            lastRowModified: {},
            isEditing: false,
            answerIndex: ''
        }
    }

    componentDidMount() {
        this.setState({ ...this.state, rows: this.props.rows });
    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({ ...this.state, rows: this.props.rows });
        }
    }

    render() {
        const { rows, rowModesModel, lastRowModified, invalidRowId, isValid, isEditing } = this.state;
        const { formActions, options, updateReference, isActive, theme, dynamicQuestionLists } = this.props;
        const handleRowEditStop = (params, event) => {
            if (params.reason === GridRowEditStopReasons.rowFocusOut) {
                event.defaultMuiPrevented = true;
            }
        };

        const handleEditClick = (id) => () => {
            isEditing === false && this.setState({ ...this.state, rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.Edit } }, isEditing: true });
        };

        const handleSaveClick = (id) => () => {
            this.setState({ ...this.state, rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.View } }, isEditing: false });
        };

        const handleDeleteClick = (id) => () => {
            this.setState({
                ...this.state, rows: rows.filter((row, index) => {
                    if (id === row.id) return formActions['removeManufacturerPartNumber']({ ...row, mapIndex: index });
                })
            });
        };

        const isDynamicQuestionMapped = (id) => {
            const dQuestions = dynamicQuestionLists?.flatMap(data=>data?.answer);
            const matchedRow = rows?.find(item => item?.id === id);
            return dQuestions?.includes(matchedRow.answer);
        }

        const handleCancelClick = (id) => () => {
            if (Object.keys(lastRowModified).length > 0) {
                const newRowsArray = rows.map(row => row.id === invalidRowId ? { ...lastRowModified } : row);
                this.setState({
                    ...this.state,
                    rows: newRowsArray,
                    rowModesModel: {
                        ...rowModesModel,
                        [id]: { mode: GridRowModes.View, ignoreModifications: true },
                    },
                    invalidRowId: '',
                    isValid: true,
                    lastRowModified: {},
                    isEditing: false
                });
            } else {
                this.setState({
                    ...this.state,
                    rowModesModel: {
                        ...rowModesModel,
                        [id]: { mode: GridRowModes.View, ignoreModifications: true },
                    },
                    invalidRowId: '',
                    isValid: true,
                    lastRowModified: {},
                    isEditing: false
                });
            }

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow.isNew) {
                this.setState({ ...this.state, rows: rows.filter((row) => row.id !== id) });
            }
        };

        const processRowUpdate = (newRow) => {
            const updatedRow = { ...newRow, isNew: false };
            this.setState({
                ...this.state, rows: rows.map((row, index) => {
                    if (row.id === newRow.id) {
                        this.props.formActions['swapManufacturerPartNumber']({
                            ...updatedRow, answerIndex: this.state.answerIndex !== '' ? (this.state.answerIndex - 1) : updatedRow.answerIndex, mapIndex: index, newRow, row, lastRowModified
                            });
                            updateReference()
                            return updatedRow;
                    }
                    return row;
                })
            });

            return updatedRow;
        };

        const handleRowModesModelChange = (newRowModesModel) => {
            this.setState({ ...this.state, rowModesModel: newRowModesModel });
        };

        function ManufacturerPartNumberEditInputCell(props) {

            return (
                <StyledTooltip placement="bottom-start" open={props.id === props.invalidrowid} title={MANUFACTURER_PART_NUMBER_VALIDATION_ERROR}>
                    <GridEditInputCell {...props} />
                </StyledTooltip>
            );
        }

        const renderEditManufacturerPartNumber = (params) => {
            const isMapped = isDynamicQuestionMapped(params.id);
			if (!isMapped) {
				const newParams = {
					...params,
					error: !isValid,
					invalidrowid: invalidRowId
				};
				return <ManufacturerPartNumberEditInputCell {...newParams} />;
			}
        }

        function SelectEditInputCell(props) {
            const { id, value, field, handleChangeAnswerIndex } = props;
            const apiRef = useGridApiContext();

            const handleChange = async (event) => {
                const index = options.findIndex((element) => element.label === event.target.value);
                const selectedAnswer = options[index];
                handleChangeAnswerIndex(selectedAnswer.value)
                await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
                apiRef.current.stopCellEditMode({ id, field });
            };

            return (
                <EditSelectStyled
                    value={value}
                    onChange={handleChange}
                    size="small"
                    sx={{ height: '90%', width: '90%' }}
                    native
                    autoFocus
                >
                    {
                        options.map((option, index) => <option key={`option-${option.label}-${option.value}-`}>{option.label}</option>)
                    }
                </EditSelectStyled>
            );
        }

        const renderSelectEditInputCell = (params) => {
			const isMapped = isDynamicQuestionMapped(params.id);
			if (!isMapped) {
				const handleChangeAnswerIndex = (newIndex) => {
					this.setState({ ...this.state, answerIndex: newIndex });
				};
				return (
					<SelectEditInputCell
						{...params}
						handleChangeAnswerIndex={handleChangeAnswerIndex}
					/>
				);
			}
		};

        const columns = [
            { field: 'answer', headerName: 'Input value', minWidth: 300, maxWidth: 450, renderEditCell: renderSelectEditInputCell, editable: true, },
            {
                field: 'manufacturerPartNumber',
                headerName: 'Mapped Products',
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                editable: true,
                renderEditCell: renderEditManufacturerPartNumber,
            },
            {
                field: 'actions',
                type: 'actions',
                headerName: '',
                width: 100,
                cellClassName: 'actions',
                align: 'right',
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    const isMappedWithDynamicQuestion = isDynamicQuestionMapped(id);
                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveOutlinedIcon />}
                                label="Save"
                                disabled={!isActive}
                                sx={{
                                    color: 'button_primary.main',
                                    minWidth: '32px',
                                    minHeight: '32px',
                                }}
                                onClick={handleSaveClick(id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                disabled={!isActive}
                                sx={{
                                    color: 'button_danger.main',
                                    minWidth: '32px',
                                    minHeight: '32px',
                                }}
                                onClick={handleCancelClick(id)}

                            />,
                        ];
                    }

                    return [
						<CustomWidthTooltip title={isMappedWithDynamicQuestion ? ATTACHED_DYNAMIC_QUESTION_INPUT_VALUE_MESSAGE : ""}>
							<GridActionsCellItem
								disabled={isEditing || !isActive || isMappedWithDynamicQuestion}
								icon={<EditIcon />}
								label="Edit"
								sx={{
									color: "button_primary.main",
									minWidth: "32px",
									minHeight: "32px"
								}}
								onClick={handleEditClick(id)}
							/>
							<GridActionsCellItem
								disabled={isEditing || !isActive || isMappedWithDynamicQuestion}
								icon={<DeleteIcon />}
								label="Delete"
								sx={{
									color: "button_danger.main",
									minWidth: "32px",
									minHeight: "32px"
								}}
								onClick={handleDeleteClick(id)}
							/>
						</CustomWidthTooltip>
					];
                },
            },
        ];

        return (
            <Grid
                item
                xs={12}
                sx={{
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    '& .MuiDataGrid-row--editing ': {
                        '& .MuiDataGrid-cell': {
                            backgroundColor: theme.palette.layer.layer1+ ' !important',
                        }
                    },
                    '& .MuiDataGrid-cell--editable': {
                        padding: '0',
                        '& .MuiInputBase-root': {
                            height: '100%',
                            '& .MuiInputBase-input': {
                                padding: '0 10px',
                                border: '1px solid',
                                borderRadius: '6px',
                                color: theme.palette.border.border_strong1,
                                borderColor: theme.palette.border.border_strong1,
                                background: theme.palette.layer.layer2,
                            }
                        },
                        '& .MuiInputBase-input': {
                            height: '100%',
                        }
                    },
                    '& .Mui-error': {
                        border: `2px solid #ec0001`,
                        color: '#750f0f',
                    },
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    hideFooterPagination
                    hideFooterSelectedRowCount
                    hideFooter
                    sx={{
                        border: 0,
                        background: 'layer.layer1',
                        '& .MuiDataGrid-columnHeaders': {
                            borderTop: 'none',
                        },
                        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing ': {
                            padding: '4px',
                            background: theme.palette.layer.layer1,
                        },
                        '& .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within ': {
                            outline: 'none',
                        }                        
                    }}
                />
            </Grid>
        );
    }
}