import { AddOpportunityItems } from "js/jsx/src/classes/crm/addOpportunityItems.jsx";
import { QuoteDocumentPreview } from "js/jsx/src/classes/quote/documentPreview.jsx";
import { QuoteHeader } from "js/jsx/src/classes/quote/quoteHeader.jsx";
import { DialogManager } from "js/jsx/src/classes/dialog.jsx";
import { LeftHeader, TopHeader, QuosalHeader } from "js/jsx/src/classes/header.jsx";
import { QuoteHeaderContainer } from "../../classes/quote/newUI";

export default function dashboard() {
	quosal.ui.showMask(1);
	if (quosal.util.queryString("opportunityid")) {
		$.quosal.dialog.loading();
	}
	var quoteDashboardModule = quosal.sell.modules.find("quote.dashboard");
	const showNewQuoteHeader = quosal.settings.getValue("EnableHeaderAccess") || false;
	const renderQuoteHeader = () => {
		quosal.ui.react.render(
			showNewQuoteHeader ? (
				<QuoteHeaderContainer quote={app.currentQuote} />
			) : (
				<QuoteHeader quote={app.currentQuote} />
			),
			document.getElementById("quoteHeader")
		);
	};

	const updateQuoteHeader = () => {
		quosal.ui.react.update(
			showNewQuoteHeader ? (
				<QuoteHeaderContainer quote={app.currentQuote} />
			) : (
				<QuoteHeader quote={app.currentQuote} />
			),
			document.getElementById("quoteHeader")
		);
	};

    var updateQuote = function () {
        if (app.currentQuote) {
           updateQuoteHeader();
        }
    };
    var loadDashboard = function () {
        if (!location.href.match('dashboard')) {
            quosal.ui.hideMask(true);
            return;
        }
        var headerTheme = app.settings.user.embeddedTheme;
        if (headerTheme === "ConnectWise Business Suite") {
            quosal.ui.react.render(<LeftHeader />, document.getElementById('leftSidebarContainer'));
            quosal.ui.react.render(<TopHeader />, document.getElementById('topHeader'));
        } else if ($.queryString('skiplayout') != 'yes') {
            quosal.ui.react.render(<QuosalHeader quote={app.currentQuote} user={app.currentUser} />, document.getElementById('topHeader'));
        }
        var openAttachOpportunityDialog = function (opportunity) {
            var oppId = opportunity.Id || opportunity.OpportunityId;
            if (!oppId) {
                oppId = quosal.util.queryString('opportunityid')
            }
            var getOppItemApi = quosal.api.crm.getOpportunityItems(oppId);
            getOppItemApi.finished = function (msg) {
                if (!msg.items || msg.items.length == 0) {
                    quosal.navigation.moduleFallbackRedirect();
                    return;
                }
                AddOpportunityItems.open(oppId, msg.items);
            }
            getOppItemApi.call()
        }

        renderQuoteHeader();
        quosal.ui.react.render(<DialogManager />, document.getElementById('dialogManager'));
        if (!MicroGrowl.singleton) {
            quosal.ui.react.render(<MicroGrowl />, document.getElementById('notifierholder'));
        }

            quosal.ui.react.render(<QuoteDocumentPreview
                quote={app.currentQuote} />, document.getElementById('documentPreview'));

        if (app.loginMessage != null) {
            Dialog.open(app.loginMessage);
            delete app.loginMessage;
        }
        if (app.currentQuote && app.currentQuote.IsTemplate && !(app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer)) {
            return;
        }
        var recordid = quosal.util.queryString('recordid');
        var oppId = quosal.util.queryString('opportunityid');
        if (oppId && !app.currentQuote.IdCRMOpportunity) {
            if (recordid) {
                window.location.href = 'stratavar.quosalweb' + location.search;
                return;
            }
            var idquoteMain = quosal.util.queryString('idquotemain');
            var attachOppApi = quosal.api.crm.attachOpportunity(idquoteMain, oppId, location.search);
            attachOppApi.finished = function (msg) {
                quosal.sell.quote.update(msg.quote);
                quosal.ui.hideMask(true);
                $.quosal.dialog.hide();

                    quosal.ui.react.render(<QuoteDocumentPreview
                        quote={app.currentQuote} />, document.getElementById('documentPreview'));

                if (msg.opportunity.hasItems || quosal.util.getCrmShortName() == "dynamics") {
                    openAttachOpportunityDialog(msg.opportunity)

                } else {
                    quosal.navigation.moduleFallbackRedirect();
                }
            };
            attachOppApi.call();
        }
        else {
            quosal.ui.hideMask(true);
            var subModules = quosal.util.queryString('submodules');
            subModules = subModules ? subModules.split(',') : [];
            if(subModules.length == 0) {
                quosal.navigation.moduleFallbackRedirect();
            }
            else {
                for (var i = 0; i < subModules.length; i++) {
                    var module = quosal.sell.modules.find(subModules[i]);
    
                    if (module) {
                        quosal.ui.react.render(<FormPlaceholder
                            message={'Loading ' + module.Name + '...'} />, document.getElementById('quoteModule'));
                        app.currentModule.loadSubModule(module.Url, {
                            container: 'quoteModule',
                            query: location.search,
                            suppressNavigation: i < subModules.length - 1,
                            suppressHistory: true,
                            callback: function (subModule) {
                                updateQuoteHeader();
                                    quosal.ui.react.update(<QuoteDocumentPreview
                                        quote={app.currentQuote} />, document.getElementById('documentPreview'));
                            }
                        });
                    }
                }
            }
        }
    };

    var unloadQuote = function () {
        quosal.sell.quote.onUpdate.unbind(updateQuote);
        quosal.sell.modules.onAfterLoad.unbind(updateQuote);
        quoteDashboardModule.onBeforeUnload.unbind(unloadQuote);
        quosal.sell.quote.unload();
    };
    quoteDashboardModule.onBeforeUnload.bind(unloadQuote);

    quosal.sell.quote.onUpdate.unbind(updateQuote).bind(updateQuote);
    quosal.sell.modules.onAfterLoad.unbind(updateQuote).bind(updateQuote);
    if (app.currentQuote == null) {
         quosal.ui.react.render(<FormPlaceholder message="Loading Quote..." />, document.getElementById('quoteHeader'));

        var openQuoteApi = quosal.api.quote.openQuote(quosal.util.queryString('idquotemain'));
        openQuoteApi.finished = function (msg) {
            quosal.sell.quote.initQuote(msg.quote);
            app.currentQuote = msg.quote;
            loadDashboard();


        };
        openQuoteApi.call();
    } else {
        loadDashboard();
    }
}
global.dashboardController = dashboard;