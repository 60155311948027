import dayjs from "dayjs";
import { DATE_FORMAT } from "../common/constant";

export const convertDateFormat = (date, format = DATE_FORMAT) => {
    return dayjs(date)?.format?.(format) || '';
}

export const parseDateFormat = (format) => {
    return format.replace('yy', 'YYYY').toUpperCase();
}

export const isEoPage = () => { return !!$("#isEoPage").length;}
