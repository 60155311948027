import React, { useCallback } from "react";

import ArrowRight from "@mui/icons-material/ArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
	DataGridPro,
	GridCellEditStopReasons,
	GridColumnMenu,
	useGridApiRef
} from "@mui/x-data-grid-pro";
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "lib/muiNestedMenu";
import { v4 as uuid } from "uuid";
import "../../../../../../../css/CKeditor.css";
import {
	addToBundle,
	addToInvoiceGroup,
	createBundle,
	createInvoiceGroup,
	defineDisabledActions,
	removeFromBundle,
	removeFromInvoiceGroup,
	saveBundle,
	unBundle,
	ungroupInvoiceGroup
} from "../PackageService";
import PriceModifierDialog from "../PriceModifierDialog";
import { useAppGlobalContext } from "../context/globalContext";
import "../styles/grid.css";
import useDataGridBoilerplateContainer from "./dataGridBoilerplateContainer";
import useDataGridBoilerplateHelpers from "./dataGridBoilerplateHelpers";
import useStyle from "./styles";

function CustomColumnMenu(props) {
	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuColumnsItem: null,
				columnMenuPinningItem: null
			}}
		/>
	);
}

const useDataGridBoilerplateRenderer = (props) => {
	const { tabItem, setChange } = props;
	const { spinnerStyle, gridMenuStyle, dropdownStyle, vertIconStyle } = useStyle();

	const {
		openNewRecordDialog,
		handleToDeleteRows,
		setSelectedRows,
		processRowUpdate,
		handleRowOrderChange,
		handleCellClick,
		handleModalClose,
		setEditableCell,
		setEditFieldPrevValue,
		isDisabledGridActions,
		calculateMargins,
		updateFieldCallback,
		handleInsertSectionGrouping,
		disableNewProductCreationStyle,
		gridStyle,
		addRecordStyle,
		isStandardUserAndProtectedTab,
		gridRows,
		gridColumns,
		selectedRows,
		ids,
		id,
		open,
		anchorEl,
		modalData,
		expanded,
		openCustomizeGrid,
		isStandardUser,
		isStandardUserAndDisableNewProductCreation,
		isStandardUserPlus,
		isStandardUserAndLockItemEdit,
		filteredRowActions
	} = useDataGridBoilerplateContainer(props);

	const { isEditableRowItem } = useDataGridBoilerplateHelpers(props);

	const { isDisabledQuoteActions, contentGrid, ckeditor } = useAppGlobalContext();
	const apiRef = useGridApiRef();

	const handleCellEditStop = useCallback(
		(params) => {
			if (params.reason === GridCellEditStopReasons.escapeKeyDown) {
				apiRef.current.updateRows([{ id: params.id, [params.field]: params.value }]);
				setEditableCell(null);
			} else {
				setEditableCell(params.field);
				setEditFieldPrevValue(params.value);
			}
		},
		[apiRef, setEditFieldPrevValue, setEditableCell]
	);

	const handleInvoiceGroupItem = useCallback(
		(item) => {
			const disableActions = defineDisabledActions(selectedRows, tabItem?.IdQuoteTabs);
			switch (item.title) {
				case "Create Group...":
					return (
						<DropdownMenuItem
							onClick={() =>
								createInvoiceGroup(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids,
									setChange
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasLineItem ||
								disableActions.hasInvoiceGroupItem ||
								disableActions.hasInvoiceGroupHeader ||
								disableActions.hasBundleItem ||
								disableActions.hasBundleHeader
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Add Items to Group...":
					return (
						<DropdownMenuItem
							onClick={() =>
								addToInvoiceGroup(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasInvoiceGroup ||
								disableActions.hasInvoiceGroupItem ||
								disableActions.hasInvoiceGroupHeader ||
								disableActions.hasBundleItem ||
								disableActions.hasBundleHeader
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Remove Items from Group":
					return (
						<DropdownMenuItem
							onClick={() =>
								removeFromInvoiceGroup(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasInvoiceGroupItem ||
								disableActions.hasLineItem ||
								disableActions.hasInvoiceGroupHeader ||
								disableActions.hasBundleItem
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Ungroup":
					return (
						<DropdownMenuItem
							onClick={() =>
								ungroupInvoiceGroup(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids,
									setChange
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasInvoiceGroupHeader ||
								disableActions.hasInvoiceGroupItem ||
								disableActions.hasLineItem ||
								disableActions.hasBundleItem
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				default:
					break;
			}
		},
		[ckeditor, contentGrid, ids, selectedRows, setSelectedRows, tabItem?.IdQuoteTabs, setChange]
	);

	const handleBundleItem = useCallback(
		(item) => {
			const disableActions = defineDisabledActions(selectedRows, tabItem?.IdQuoteTabs);
			switch (item.title) {
				case "Create Bundle...":
					return (
						<DropdownMenuItem
							onClick={() =>
								createBundle(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids,
									setChange
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasLineItem ||
								disableActions.hasBundleItem ||
								disableActions.hasBundleHeader
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Save Bundle...":
					return (
						<DropdownMenuItem
							onClick={() =>
								saveBundle(contentGrid, tabItem?.IdQuoteTabs, ckeditor, ids)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasBundleHeader ||
								disableActions.hasBundleItem ||
								disableActions.hasLineItem ||
								disableActions.hasInvoiceGroupItem
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Add to Bundle...":
					return (
						<DropdownMenuItem
							onClick={() =>
								addToBundle(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasBundle ||
								disableActions.hasBundleItem ||
								disableActions.hasBundleHeader
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Remove from Bundle":
					return (
						<DropdownMenuItem
							onClick={() =>
								removeFromBundle(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasBundleItem ||
								disableActions.hasLineItem ||
								disableActions.hasBundleHeader ||
								disableActions.hasInvoiceGroupItem
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				case "Unbundle":
					return (
						<DropdownMenuItem
							onClick={() =>
								unBundle(
									contentGrid,
									tabItem?.IdQuoteTabs,
									selectedRows,
									setSelectedRows,
									ckeditor,
									ids,
									setChange
								)
							}
							disabled={
								disableActions.nothingSelected ||
								!disableActions.hasBundleHeader ||
								disableActions.hasBundleItem ||
								disableActions.hasLineItem ||
								disableActions.hasInvoiceGroupItem
							}
						>
							{item.title}
						</DropdownMenuItem>
					);
				default:
					break;
			}
		},
		[ckeditor, contentGrid, ids, selectedRows, setSelectedRows, tabItem?.IdQuoteTabs, setChange]
	);

	const handleSubMenuItem = useCallback(
		(item) => {
			if (item.title === "Invoice Grouping") {
				return item.submenu.map((el) => handleInvoiceGroupItem(el));
			}

			return item.submenu.map((el) => handleBundleItem(el));
		},
		[handleBundleItem, handleInvoiceGroupItem]
	);

	const gridMenuItems = filteredRowActions.map((el, idx) => {
		if (
			!idx &&
			!isStandardUser &&
			!isStandardUserAndDisableNewProductCreation &&
			!isStandardUserPlus &&
			!isStandardUserAndLockItemEdit
		) {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => openCustomizeGrid(tabItem?.IdQuoteTabs)}
				>
					Customize Grid
				</DropdownMenuItem>
			);
		}

		if (el.submenu) {
			return (
				<DropdownNestedMenuItem
					key={el + uuid()}
					label={el.title}
					rightIcon={<ArrowRight />}
					rightAnchored={expanded}
					menu={handleSubMenuItem(el)}
				/>
			);
		}

		if (el.title === "Calculate Margins") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => calculateMargins(true)}
					disabled={isDisabledGridActions()}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		if (el.title === "Update Field...") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => updateFieldCallback(el)}
					disabled={isDisabledGridActions()}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		if (el.title === "Delete Item(s)") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => handleToDeleteRows()}
					disabled={isDisabledGridActions()}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		if(el.title === "Insert Section Header") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => handleInsertSectionGrouping("SubHeader")}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		if(el.title === "Insert Spacer(s)") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => handleInsertSectionGrouping("Comment")}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		if(el.title === "Insert Section Footer") {
			return (
				<DropdownMenuItem
					key={el + uuid()}
					onClick={() => handleInsertSectionGrouping("SubFooter")}
				>
					{el.title}
				</DropdownMenuItem>
			);
		}

		return (
			<DropdownMenuItem
				onClick={() => el.callback()}
				disabled={isDisabledGridActions()}
				key={el + uuid()}
			>
				{el.title}
			</DropdownMenuItem>
		);
	});

	const loadingComponent = (
		<Stack spacing={2} justifyContent="center" direction="row" py={3} sx={spinnerStyle}>
			<CircularProgress size={30} />
			<Typography textAlign="center">Loading...</Typography>
		</Stack>
	);

	const addRecordComponent = (
		<Grid item style={disableNewProductCreationStyle}>
			<Typography style={addRecordStyle} onClick={() => !isDisabledQuoteActions && openNewRecordDialog(true)}>
				+ Add record
			</Typography>
		</Grid>
	);

	const gridMenuComponent = (
		<Grid item sx={gridMenuStyle}>
			{!isStandardUserAndProtectedTab && (
				<Dropdown
					trigger={
						<IconButton style={dropdownStyle} disabled={isDisabledQuoteActions}>
							<MoreVertIcon style={vertIconStyle} />
						</IconButton>
					}
					menu={gridMenuItems}
				/>
			)}
		</Grid>
	);

	const dataGridComponent = (
		<DataGridPro
			style={{ position: "relative" }}
			rows={gridRows}
			getRowClassName={(params) => {
				if (params.row.TaxCode === "PACKAGE") {
					return "package";
				}
				if (params.row.IsPackageItem) {
					return "packageItem";
				}

				return "";
			}}
			getCellClassName={() => {
				if (isDisabledQuoteActions) {
					return "disabled";
				}

				return "";
			}}
			columns={gridColumns}
			disableColumnResize
			disableColumnReorder
			isCellEditable={(params) => isEditableRowItem(params)}
			apiRef={apiRef}
			disableToolbarMenu
			pagination={false}
			slots={{ columnMenu: CustomColumnMenu }}
			selectionModel={selectedRows}
			onRowSelectionModelChange={setSelectedRows}
			disableRowSelectionOnClick
			rowReordering={!isDisabledQuoteActions}
			onCellEditStop={handleCellEditStop}
			onCellDoubleClick={() => {
				setTimeout(() => {
					const inputEl = document.getElementsByClassName("MuiInputBase-input");
					inputEl[0]?.focus?.();
					inputEl[0]?.select?.();
				}, 1);
			}}
			processRowUpdate={processRowUpdate}
			onRowOrderChange={handleRowOrderChange}
			onCellClick={handleCellClick}
			hideFooterRowCount
			hideFooterPagination
			hideFooterSelectedRowCount
			sx={{ ...gridStyle }}
		/>
	);

	const priceModifierComponent = (
		<PriceModifierDialog
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleModalClose}
			modalData={modalData}
			setChange={setChange}
		/>
	);

	return {
		loadingComponent,
		addRecordComponent,
		gridMenuComponent,
		dataGridComponent,
		priceModifierComponent,
		handleBundleItem
	};
};

export default useDataGridBoilerplateRenderer;
