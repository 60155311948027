import {ActiveDisableButton} from "js/jsx/src/classes/etilize/etilizeSearchResults.jsx";
import {ProductPriceHistoryPanel} from "js/jsx/src/classes/product/productPriceHistory.jsx";
import {SimpleTab} from "js/jsx/src/classes/tab/simpleTab.jsx";

/* global React:object quosal:object Dialog:object app:object */
class EtilizePriceLookup {
    constructor() {
        this.tables = [];
    }

    lookupPrice(src, mpn) {
        var mpn1 = mpn.toLowerCase();
        var src1 = src.toLowerCase();
        var tables = this.tables;
        for (var s=0; s<tables.length; s++) {
            if (String.ciEquals(tables[s].source, src1)) {
                var info = tables[s].info;
                for (var m=0; m<info.length; m++){
                    if (String.ciEquals(info[m].mpn, mpn1)) {
                        return info[m];
                    }
                }
                break;
            }
        }

        return {
            mpn: mpn1,
            source: src1,
            vpn: mpn1,
            price: 0.00,
            dprice: '$0.00',
            qty: 0,
            backorder: 0,
            cost: 0.00,
            dcost: 0.00,
            details: '',
            status: 'unknown',
            list: 'unknown',
            error: null
        };
    }

    lookupPriceSourceId(source,mpn) {
        var mpn1 = mpn.toLowerCase();
        var src1 = source.toLowerCase();
        var tables = this.tables;
        for (var s=0; s<tables.length; s++) {
            if (String.ciEquals(tables[s].source, src1)) {
                var info = tables[s].info;
                for (var m=0; m<info.length; m++){
                    if (String.ciEquals(info[m].mpn, mpn1)) {
                        return info[m].sourceid;
                    }
                }
                break;
            }
        }

        return source;//fallback
    }

    queuePriceLookup(src, mpn, vpn, list) {
        var table = null;
        for (var s=0; s<this.tables.length; s++) {
            if (String.ciEquals(this.tables[s].source, src)) {
                table = this.tables[s];
                var info = this.tables[s].info;
                for (var m=0; m<info.length; m++){
                    if (String.ciEquals(info[m].mpn, mpn)) {
                        return;
                    }
                }
                break;
            }
        }
        if (!table) {
            table = {source: src, info: [] };
            this.tables.push(table);
        }
        var rec = {
            mpn: mpn,
            source: src,
            vpn: vpn,
            price: 0.00,
            dprice: '$0.00',
            qty: 0,
            backorder: 0,
            cost: 0.00,
            dcost: '...',
            details: '',
            status: 'pending',
            list: list,
            error: null
        };

        table.info.push(rec);
    }

    getPriceEntry(src, mpn) {
        var table = null;
        for (var s=0; s<this.tables.length; s++) {
            if (String.ciEquals(this.tables[s].source, src)) {
                table = this.tables[s];
                var info = this.tables[s].info;
                for (var m=0; m<info.length; m++){
                    if (String.ciEquals(info[m].mpn, mpn)) {
                        return info[m];
                    }
                }
                break;
            }
        }
        if (!table) {
            table = {source: src, info: [] };
            this.tables.push(table);
        }
        // replaceable
        var entry = {
            mpn: mpn,
            source: src,
            vpn: '',
            price: 0.00,
            dprice: '$0.00',
            qty: 0,
            backorder: 0,
            cost: 0.00,
            dcost: 'set',
            details: '',
            status: 'setting',
            list: 'unknown',
            error: null
        };
        table.info.push(entry);
        return entry;
    }

    hasPriceEntry(src, mpn) {
        for (var s=0; s<this.tables.length; s++) {
            if (String.ciEquals(this.tables[s].source, src)) {
                var info = this.tables[s].info;
                for (var m=0; m<info.length; m++){
                    if (String.ciEquals(info[m].mpn, mpn)) {
                        return true;
                    }
                }
                break;
            }
        }
        return false;
    }

    generateLookupBatch(list) {
        const limit = 20;
        var result = {
            source: '',
            numbers: [],
            skus: []
        };
        for (var s=0; s<this.tables.length; s++) {
            if (result.source.length > 0) continue;
            var info = this.tables[s].info;
            for (var m=0; m<info.length; m++){
                if (info[m].status === 'pending' && info[m].list === list) {
                    info[m].status = 'searching';
                    result.source = info[m].source;
                    result.numbers.push(info[m].mpn);
                    result.skus.push(info[m].vpn);
                    if (result.numbers.length >= limit) {
                        return result;
                    }
                }
            }
        }
        return result;
    }

    truncatePrices() {
        this.tables = [];
    }
}

/* ################################################################################################## */
export class EtilizeDetailsMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updates: 0,
            fullProduct: {},
            selectedcost: 0,
            detailTabs: [],
            mainDestination: quosal.util.queryString('idquotetabs'),
            accessDestination: quosal.util.queryString('idquotetabs'),
            similarDestination: quosal.util.queryString('idquotetabs'),
            upsellDestination: quosal.util.queryString('idquotetabs'),
            originalThumbnail: null,
            lastThumbnail: null,
            currentThumbnail: null,
            selectedAlt: null
        };
        this.childPriceSource = null;
        this.childDetailTab = null;

        this.componentDidMount = this.componentDidMount.bind(this);
        this.loadInNewProduct = this.loadInNewProduct.bind(this);
        this.setupDetailUI = this.setupDetailUI.bind(this);
        this.seedAuxPrices = this.seedAuxPrices.bind(this);
        this.getTabComponent = this.getTabComponent.bind(this);
        this.sortAccessories = this.sortAccessories.bind(this);
        this.renderAccessoriesForTab = this.renderAccessoriesForTab.bind(this);
        this.renderSimilarForTab = this.renderSimilarForTab.bind(this);
        this.renderUpsellForTab = this.renderUpsellForTab.bind(this);
        this.renderSpecsForTab = this.renderSpecsForTab.bind(this);
        this.setupTabs = this.setupTabs.bind(this);
        this.checkedAttributes = this.checkedAttributes.bind(this);
        this.addSubItemsToList = this.addSubItemsToList.bind(this);
        this.addMainToQuote = this.addMainToQuote.bind(this);
        this.backToPrevious = this.backToPrevious.bind(this);
        this.lookupPrice = this.lookupPrice.bind(this);
        this.lookupSourceId = this.lookupSourceId.bind(this);
        this.findAuxPrices = this.findAuxPrices.bind(this);
        this.abortPricingLookup = this.abortPricingLookup.bind(this);
        this.renderEtilizeLink = this.renderEtilizeLink.bind(this);
        this.mouseImgEnter = this.mouseImgEnter.bind(this);
        this.mouseImgExit = this.mouseImgExit.bind(this);
        this.mouseImgClick = this.mouseImgClick.bind(this);
        this.renderMainProductRow = this.renderMainProductRow.bind(this);
        this.mainDestinationChanged = this.mainDestinationChanged.bind(this);
        this.renderResultsHeader = this.renderResultsHeader.bind(this);
        this.renderDetailBody = this.renderDetailBody.bind(this);
        this.render = this.render.bind(this);
        this.historypricecallback = this.historypricecallback.bind(this);
        this.getFullProduct = this.getFullProduct.bind(this);
        this.sourceSelected = this.sourceSelected.bind(this);
        this.pricingdone = this.pricingdone.bind(this);
    }
   
    componentDidMount() {
        this.mounted = true;
        this.priceInfo = new EtilizePriceLookup();
        if (!this.props.visibleProducts || this.props.visibleProducts.length === 0) {
            return;
        }
        var part = this.props.visibleProducts[0];
        this.loadInNewProduct(part);
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    loadInNewProduct(part) {
        var me = this;

        var opts = {showSpecs: true};
        var list = this.setupTabs(opts);
        this.setState(
            {detailTabs: list, originalThumbnail:null, lastThumbnail:null, currentThumbnail:null}
        );

        var api = quosal.api.product.etilizedetails(
                    app.currentQuote.IdQuoteMain,
                    part.productid
                    );
        api.finished=function(msg) {
            me.setupDetailUI(msg.product, part.imageUrl);
            this.priceCompleted = false;
            if (me.childPriceSource) {
                me.childPriceSource.startSourcePriceTimer();
            }
        }.bind(this);
        api.call();        
    }

    setupDetailUI(fullproduct, mainimg) {
        this.priceInfo.truncatePrices();
        
        var opts = {};
        if (fullproduct.AttributeGroup && fullproduct.AttributeGroup.length > 0) {
            opts.showSpecs = true;
        }
        var i;
        if (fullproduct.AccessoryItems && fullproduct.AccessoryItems.length > 0) {
            opts.showAccess = true;
            for (i=0; i<fullproduct.AccessoryItems.length; i++) {
                fullproduct.AccessoryItems[i].isSelected = false;
            }
        }
        if (fullproduct.SimilarItems && fullproduct.SimilarItems.length > 0) {
            opts.showSimilar = true;
            for (i=0; i<fullproduct.SimilarItems.length; i++) {
                fullproduct.SimilarItems[i].isSelected = false;
            }
        }
        if (fullproduct.UpSellItems && fullproduct.UpSellItems.length > 0) {
            opts.showUpsell = true;
            for (i=0; i<fullproduct.UpSellItems.length; i++) {
                fullproduct.UpSellItems[i].isSelected = false;
            }
        }
        var list = this.setupTabs(opts);
        var orig = (mainimg) ? mainimg : 'images/empty.png';
        fullproduct = this.sortAccessories(fullproduct);
        this.setState(
            {fullProduct: fullproduct, detailTabs: list, originalThumbnail:orig, lastThumbnail:orig, currentThumbnail:orig},
            () => {
                this.seedAuxPrices(fullproduct);
            }
        );
    }

    seedAuxPrices(fullproduct) {
        if (!fullproduct) {
            return;
        }
        
        var getSku = function getSku(itm, src) {
            var source = quosal.etilizeHelper.skuCode(src);
            if (!itm.Skus) return '';
            for (var s = 0; s < itm.Skus.length; s++) {
                if (String.ciEquals(itm.Skus[s].SkuType, source)) {
                    return itm.Skus[s].Number;
                }
            }
            if (String.ciEquals(source, 'catalog')) {
                return itm.ManufacturerPartNumber;
            }
            return '';
        };
        var srcs = quosal.etilizeHelper.getSourceList(this.props.config);
        if (fullproduct.AccessoryItems) {
            for (let i=0; i<fullproduct.AccessoryItems.length; i++) {
                var acc = fullproduct.AccessoryItems[i];
                for (let j=0; j<srcs.length; j++) {
                    this.priceInfo.queuePriceLookup(srcs[j], acc.ManufacturerPartNumber, getSku(acc, srcs[j]), 'access');
                }
            }
        }
        if (fullproduct.SimilarItems) {
            for (let i=0; i<fullproduct.SimilarItems.length; i++) {
                var item = fullproduct.SimilarItems[i];
                for (let j=0; j<srcs.length; j++) {
                    this.priceInfo.queuePriceLookup(srcs[j], item.ManufacturerPartNumber, getSku(item, srcs[j]), 'similar');
                }
            }
        }
        if (fullproduct.UpSellItems) {
            for (var i=0; i<fullproduct.UpSellItems.length; i++) {
                var upsell = fullproduct.UpSellItems[i];
                for (let j=0; j<srcs.length; j++) {
                    this.priceInfo.queuePriceLookup(srcs[j], upsell.ManufacturerPartNumber, getSku(upsell, srcs[j]), 'upsell');
                }
            }
        }
        setTimeout(() => {
            this.findAuxPrices(false);
        }, 650);
    }
    pricingdone() {
        this.priceCompleted = true;
    }

    getTabComponent(name) {
        var res = null;
        if (this.state.detailTabs) {
            this.state.detailTabs.forEach(function(c){
                if (String.ciEquals(c.name, name)) {
                    res = c;
                }
            });
        }
        return res;
    }

    sortAccessories(product) {
        var list = product.AccessoryItems;
        if (list) {
            list.sort(function(a, b) {
                var x = '';
                if (a.Category && a.Category.Name) {
                    x = a.Category.Name.toLowerCase();
                }
                var y = '';
                if (b.Category && b.Category.Name) {
                    y = b.Category.Name.toLowerCase();
                }
                if (x < y) {
                    return -1;
                }
                if (x > y) {
                    return 1;
                }
                if (a.ManufacturerPartNumber.toLowerCase() < b.ManufacturerPartNumber.toLowerCase()) {
                    return -1;
                }
                if (a.ManufacturerPartNumber.toLowerCase() > b.ManufacturerPartNumber.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
        return product;
    }

    renderAccessoriesForTab() {
        return (<EtilizeDetailsAccessories key='tAccess' main={this} usageType='Accessories' full={this.state.fullProduct}
            config={this.props.config} deeperClick={this.props.deeperProduct} pricelookupfn={this.lookupPrice}
            />);
    }
    renderSimilarForTab() {
        return (<EtilizeDetailsSimilar key='tSimilar' main={this} usageType='Similar Items' full={this.state.fullProduct} config={this.props.config}
                            deeperClick={this.props.deeperProduct} pricelookupfn={this.lookupPrice} />);
    }
    renderUpsellForTab() {
        return (<EtilizeDetailsUpsell key='tUpsell' main={this} usageType='Upsell Items' full={this.state.fullProduct} config={this.props.config}
                            deeperClick={this.props.deeperProduct} pricelookupfn={this.lookupPrice} />);
    }
    renderSpecsForTab() {
        return (<EtilizeDetailsSpecs key='tSpecs' main={this} usageType='Specifications' full={this.state.fullProduct} config={this.props.config}/>);
    }

    setupTabs(options) {
        var list = [];
        if (options.showAccess) {
            list.push({
                    name: 'Accessories',
                    usageType: 'Accessories',
                    renderFn: this.renderAccessoriesForTab,
                    component: null // use fn
                });
        }
        if (options.showSimilar) {
            list.push({
                name: 'Similar Items',
                usageType: 'Similar Items', 
                renderFn: this.renderSimilarForTab,
                component: null // use fn
            });
        }
        if (options.showUpsell) {
            list.push({
                name: 'Upsell Items', 
                usageType: 'Upsell Items', 
                renderFn: this.renderUpsellForTab,
                component: null // use fn
            });
        }
        list.push({
            name: 'Specifications', 
            usageType: 'Specifications', 
            renderFn: this.renderSpecsForTab,
            component: null
        });
        return list;
    }

    checkedAttributes() {
        var attrs = [];
        var product = this.getFullProduct();

        if (product && product.AttributeGroup) {
            for (var i=0; i<product.AttributeGroup.length; i++) {
                var group = product.AttributeGroup[i];
                for (var j=0; j<group.Items.length; j++) {
                    if (group.Items[j].isChecked === true) {
                        attrs.push(group.Items[j].Name);
                    }
                }
            }
        }

        return attrs;
    }

    addSubItemsToList(items, list, destination) {
        if (!items) {
            return;
        }
        for (var i=0; i<items.length; i++) {
            if (items[i].isSelected) {
                var img = (items[i].ProductImages !== null && items[i].ProductImages[0] !== null) ? items[i].ProductImages[0]: '';
                var info = {
                    productid: items[i].Id,
                    source: items[i].selectedSource,
                    thumbnail: img,
                    warehouse: {WhseCode:0, IDCode:'none'},
                    destination: destination
                };
                if (this.priceInfo.hasPriceEntry(info.source, items[i].ManufacturerPartNumber)) {
                    var price = this.lookupPrice(info.source, items[i].ManufacturerPartNumber); // see pricelookupfn
                    info.cost = price.price;
                    info.msrp = '' + 0.00;
                }
                else {
                    info.cost = 0.00;
                    info.msrp = 0.00;
                }
                info.source = this.lookupSourceId(info.source, items[i].ManufacturerPartNumber);//optimize
                list.push(info);
            }
        }
    }

    getItemSource(sellSource) {
        return sellSource != undefined && sellSource.sourceid != undefined
            ? sellSource.sourceid
            : '';
    }
    getItemCost(sellSource) {
        return '' + (sellSource != undefined && sellSource.price != undefined
            ? sellSource.price
            : 0.00);
    }
    getItemMSRP(sellSource) {
        return '' + (sellSource != undefined && sellSource.cost!= undefined
            ? sellSource.cost
            : 0.00);
    }
    getItemWarehouse(sellSource) {
        let warehouse = '';
        if (sellSource) {
            let selWH = this.childPriceSource.state.selectedWH[sellSource.source];
            warehouse = selWH != undefined && selWH.IDCode != undefined
                ? selWH.IDCode
                : '';
            }
        return warehouse;
    }
    getItemWarehouseCode(sellSource) {
        let code = 0;
        if (sellSource) {
            let selWH = this.childPriceSource.state.selectedWH[sellSource.source];
            code = selWH != undefined && selWH.IDCode != undefined
                ? selWH.WhseCode
                : 0;
        }
        return code;
    }
   
        addMainToQuote(addMode) {
        let product = this.getFullProduct();
        let products = [];

        let selSource = this.childPriceSource.state.selectedSource;
        let prodInfo = this.props.visibleProducts[0];
        let  mainItem = {
            productid: prodInfo.productid,
            source: this.getItemSource(selSource),
            thumbnail: this.state.currentThumbnail,
            destination: this.state.mainDestination,
            cost: this.getItemCost(selSource),
            msrp: this.getItemMSRP(selSource),
            warehouse: this.getItemWarehouse(selSource),
            warehousecode: this.getItemWarehouseCode(selSource),
            attributes: this.checkedAttributes()
        };
       
        if (addMode === 'all' || addMode === 'allnew' || addMode === 'main') {
            products.push(mainItem);
        }

        if (addMode === 'all' || addMode === 'allnew' || addMode === 'accessories') {
            this.addSubItemsToList(product.AccessoryItems, products, this.state.accessDestination);
        }
        if (addMode === 'all' || addMode === 'allnew' || addMode === 'similar') {
            this.addSubItemsToList(product.SimilarItems, products, this.state.similarDestination);
        }
        if (addMode === 'all' || addMode === 'allnew' || addMode === 'upsell') {
            this.addSubItemsToList(product.UpSellItems, products, this.state.upsellDestination);
        }
        
        if (products.length === 0) {
            Dialog.open({
                message: 'You must select at least one product to add to the quote.',
                links: [Dialog.links.ok]
            });
            return;
        }

        Dialog.open({
            title: 'Add to Quote',
            message: (<span><Spinner style={{marginTop: 5}}/>Please wait while products are added to the quote</span>),
            closeRequiresButton: true,
            links: [
            ]
        });

        var attachApi = quosal.api.product.attachEtilizeProducts(
            app.currentQuote.IdQuoteMain,
            products,
            (addMode === 'all' || addMode === 'allnew' || addMode === 'main')
        );
        attachApi.finished = function(amain, msg) {
            amain.isAttaching = false;
            quosal.sell.quote.update(msg.quote);

            if (quosal.settings.getValue('UseEditQuoteCKEditor')) {
                sessionStorage.setItem('etilizeSearchTabId', products[0].destination);
            }

            Dialog.closeAll();
            if (this.mounted) {
                this.setState({ pendingAttachments: this.state.pendingAttachments - 1 });
            }
            if (addMode === 'all' || addMode === 'allnew' || addMode === 'main') {
                if (addMode === 'all' || addMode === 'main') {
                    app.currentModule.unloadSubModules();
                    app.currentModule.loadSubModule('quote.content', {
                        container: 'quoteModule'
                    });
                }
                else {
                    this.props.startAgainFn();
                }
            }
        }.bind(this, mainItem);
        attachApi.call();
    }

    backToPrevious() {
        if (this.props.previousClick) {
            this.props.previousClick();
        }
    }

    lookupPrice(source, mpn) {
        return this.priceInfo.lookupPrice(source, mpn);
    }
    lookupSourceId(source,mpn) {
        return this.priceInfo.lookupPriceSourceId(source,mpn);
    }

    findAuxPrices() {
        if (this.priceCompleted == false) {
            setTimeout(() => {
                this.findAuxPrices(false);
            }, 650);
            return;
        }       

        var searchInfo = {
            numbers:['placeholder']
        };
        while (searchInfo.numbers.length > 0) {
            var list = 'access';
            var searchInfo = this.priceInfo.generateLookupBatch(list);
            if (searchInfo.numbers.length === 0) {
                list = 'similar';
                searchInfo = this.priceInfo.generateLookupBatch(list);
            }
            if (searchInfo.numbers.length === 0) {
                list = 'upsell';
                searchInfo = this.priceInfo.generateLookupBatch(list);
            }
            if (searchInfo.numbers.length > 0) {
                this.performOneAuxPrices(searchInfo, list);
            }
        }
    }

    performOneAuxPrices(searchInfo, list) {
        var me = this;
        
        var batch = searchInfo.numbers;
        var skus = searchInfo.skus;
        
        var makeThePricingCall = function() {
            var cleanSrc = quosal.etilizeHelper.toSourceKey(searchInfo.source);
            var pricingApi = quosal.api.pricing.etilizePricesWithSkus(batch, skus, cleanSrc, app.currentQuote.IdQuoteMain);
            pricingApi.finished = function(msg) {
                var bIsCatalog = String.ciEquals(searchInfo.source, 'catalog');
                if (msg.info) {
                    for (let i = 0; i < msg.info.length; i++) {
                        var p = msg.info[i];
                        var mpn = p.AssignedID;// AssignedID, RefID1, RefID2
                        if (!this.priceInfo.hasPriceEntry(searchInfo.source, mpn)) {
                            mpn = p.RefID1;
                            if (mpn.length === 0 || !this.priceInfo.hasPriceEntry(searchInfo.source, mpn)) {
                                mpn = p.RefID2;
                                if (mpn.length === 0 || !this.priceInfo.hasPriceEntry(searchInfo.source, mpn)) {
                                    mpn = '';
                                }
                            }
                        }
                        
                        if (mpn.length === 0) {
                            continue;
                        }
                        let price = this.priceInfo.getPriceEntry(searchInfo.source, mpn);
                        if (bIsCatalog && (price.status === 'ok' && price.price < p.CustomerPrice)) {
                            continue; // not cheaper
                        }
                        price.status = 'ok';
                        price.error = p.ErrorMsg;
                        price.price = p.CustomerPrice;
                        price.dprice = app.currentQuote.formatCurrency(p.CustomerPrice);
                        price.qty = p.AvailableQty;
                        price.backorder = p.OnOrderQty;
                        price.cost = p.MSRP;
                        price.details = searchInfo.source;
                        price.sourceid = p.SourceId && p.SourceId.length > 0 ? p.SourceId: p.Source;
                        price.dcost = app.currentQuote.formatCurrency(p.MSRP);
                        if (!price.list) {
                            price.list = list;
                        }
                    }
                }
                
                // check for missing
                for (var i = 0; i < batch.length; i++) {
                    let price = this.priceInfo.getPriceEntry(searchInfo.source, batch[i]);
                    if (price.status === 'searching') {
                        price.status = 'remainder';
                        price.price = 0.00;
                        price.dprice = '$0.00';
                        price.qty = 0;
                        price.backorder = 0;
                        price.cost = 0.00;
                        price.dcost = '0.00';
                        price.details = 'unsourced';
                        if (!price.list) {
                            price.list = list;
                        }
                        if (price.vpn.length === 0){
                            price.error = 'No Part Number';
                            price.status = 'nopart';
                        }
                        else {
                            price.error = 'Discontinued';
                            price.status = 'discontinued';
                        }
                    }
                }
                me.setState(prevState => ({
                    updates: prevState.updates + 1,
                    luPricing: false,
                    currentPriceCall: null
                }), () => {
                    if (me.childDetailTab) {
                        me.childDetailTab.forceUpdate();
                    }
                    this.findAuxPrices(false); //more?
                }); 
            }.bind(this);

            if (batch.length > 0) {
                me.setState({
                    luPricing: true,
                    currentPriceCall: pricingApi
                });
                pricingApi.call();
            }
        }.bind(this);

        makeThePricingCall();
    }

    abortPricingLookup(callback) {
        if (this.state.currentPriceCall) {
            this.state.currentPriceCall.abort();

            this.setState({ currentPriceCall: null }, callback);
        }
    }

    renderEtilizeLink() {
        return (<div>
                    <div id='etilizelink' width={166}>
                        <a href='http://www.etilize.com?utm_source=client&utm_medium=banner&utm_campaign=logoLink' target='_blank' rel="noopener noreferrer">
                        <img src='https://content.etilize.com/logoprogram/gfk-powered-by-logo-small.png'
                                    width={166} height={22} alt='product information' />
                        </a>
                    </div>
                </div>);
    }

    mouseImgEnter(newsrc) {
        this.setState({currentThumbnail:newsrc});
    }
    mouseImgExit() {
        this.setState({currentThumbnail:this.state.lastThumbnail});
    }
    mouseImgClick(dir) {
        if (this.state.selectedAlt === dir) {
            this.setState({lastThumbnail:this.state.originalThumbnail, selectedAlt: null});
        }
        else {
            this.setState({lastThumbnail:this.state.currentThumbnail, selectedAlt: dir});
        }
    }

    renderMainProductRow(mainProduct, fullprod) {
        function dangerousMarkup(str) {
            return {__html: str};
        }
        var me = this;
        var mktg = '';
        if (fullprod && fullprod.AttributeGroup) {
            for (var i=0; i<fullprod.AttributeGroup.length; i++) {
                var group = fullprod.AttributeGroup[i];
                for (var j=0; j<group.Items.length; j++) {
                    var attr = group.Items[j];
                    if (attr.Name === 'Marketing Information') {
                        mktg = attr.Value;
                        break;
                    }
                }
                if (mktg.length > 0) break;
            }
        }
        var altimgs = [];
        var locations = ['Top', 'Left', 'Right', 'Front', 'Rear'];
        if (app.settings.global.SearchForAdditionalEtilizeImages) {
            locations.forEach(function(e){
                var url = 'https://content.etilize.com/' + e + '/' + mainProduct.productid + '.jpg';
                var addl = '';
                if (me.state.selectedAlt === e) {
                    addl = ' selected';
                }
                altimgs.push(<img key={e} className={'altimage'+addl} src={url} title={e + ' view'} 
                    onMouseEnter={()=>{me.mouseImgEnter(url);}} onMouseLeave={()=>{me.mouseImgExit();}}
                    onClick={()=>{ me.mouseImgClick(e); }} alt="Product thumbnail" />);
                }
            );
        }
        
        if (mktg.length === 0) {
            mktg = 'No Marketing Description';
        }
        return (<tr>
                    <td style={{ width: 225 }}>
                        <div id='detailimg'>
                            <img id='fullimg' src={this.state.currentThumbnail} alt='Selected Thumbnail'/>
                        </div>
                    </td>
                    <td>
                        <div id='detailtext'>
                            <div className='detaildesc1'>{mainProduct ? mainProduct.Description1 : '--'}</div>
                            <div className='detaildesc2'>{mainProduct ? mainProduct.Description2 : '--'}</div>
                            <div className='detailmf'>Manufacturer Name: {fullprod ? fullprod.ManufacturerName : '--'}</div>
                            <div className='detailmf'>Manufacturer Part Number: {mainProduct ? mainProduct.ManufacturerPartNumber : '--'}</div>
                            <div className='detailseperator' />
                            <div className='detailmarketing' dangerouslySetInnerHTML={dangerousMarkup(mktg)} />
                            <div className='detailseperator' />
                            <div style={{marginBottom:'3px'}} id='altimages'>{altimgs}</div>
                            
                        </div>
                    </td>
                </tr>);
    }

    mainDestinationChanged(e) {
        this.setState({
            mainDestination: e.target.value
        });
    }

    renderResultsHeader() {
        var tabdrops = [];
        tabdrops.push(<option value='~tabroute~' disabled='disabled' key='mainactivetab'>Tab Routing Option</option>);
        var qtabs = app.currentQuote.Tabs;
        for (var i = 0; i < qtabs.length; i++) {
            if (!qtabs[i].IsProtectedTab || quosal.util.userCanModifyProtectedTab()) {
                tabdrops.push(<option value={qtabs[i].IdQuoteTabs} key={'m'+qtabs[i].IdQuoteTabs}>{qtabs[i].TabName}</option>);
            }
        }
        return (<div className="resultsheader">
            <div className="detailcolleft">
              <Button key="btn_search" id="btn_search" onClick={this.backToPrevious} disabled={false}>
                Back to Search
              </Button>
            </div>
            <div className="detailcolright">
              <div className="formselectfieldwrapper compact">
                <select className="formselectfield" id="mainrouting" name="mainrouting" defaultValue={this.state.mainDestination} onChange={this.mainDestinationChanged}>
                  {tabdrops}
                </select>
              </div>
              <Button key="btn_attach" id="btn_attach" onClick={() => this.addMainToQuote('all')} disabled={false} style={{marginLeft: '24px'}}>
                Add to Quote
              </Button>
              <Button key="btn_attachandsearch" id="btn_attachandsearch" onClick={() => this.addMainToQuote('allnew')} disabled={false} style={{marginLeft: '16px'}}>
                Add and Search
              </Button>
            </div>
          </div> );
    }

    renderDetailBody(mainProduct, fullprod) {
        var cost = app.currentQuote.formatCurrency(this.state.selectedcost);
        return (<div className='grid col4x3'>
                    <div className='panel'>
                        <div className='title'>
                            <div id='priceheader'>{cost}</div>
                            <span>Product Details</span>
                        </div>
                        <div className='content'>
                            <div id='detailspanel'>
                                {this.renderResultsHeader()}
                                <table cellPadding={0} cellSpacing={0} style={{ width: '100%' }}>
                                    <tbody>
                                        {this.renderMainProductRow(mainProduct, fullprod)}
                                        <tr>
                                            <td className='detailfooter' colSpan={2}>
                                                <div><SimpleTab id='detailtabs' ref={(r) => {this.childDetailTab = r;}} tabList={this.state.detailTabs} updates={this.state.updates}/></div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {this.renderEtilizeLink()}
                            </div>
                        </div>
                    </div>
                </div>);
    }

    render() {
        var prodInfo = null;
        if (this.props.visibleProducts && this.props.visibleProducts.length > 0) {
            prodInfo = this.props.visibleProducts[0];
        }
        var prod = this.getFullProduct();
        prod.IdQuoteMain = app.currentQuote.IdQuoteMain;

        return (
            <div className='fluid'>
                <input type='hidden' id='helpRoot' value='quote-home/prepare-content/etilize/product' />
                <div className='grid col4x1'>
                    <EtilizeDetailsPriceSources product={this.getFullProduct()} main={this} sourceChanged={this.sourceSelected} pricingdone={this.pricingdone}
                            config={this.props.config} lastPrices={this.props.lastPrices} ref={(r) => {this.childPriceSource = r;}} />
                    <ProductPriceHistoryPanel form={this} product={prod} priceClickCallback={this.historypricecallback} hoverTitlePrice='Add to quote'/>
                </div>
                
                {this.renderDetailBody(prodInfo, this.getFullProduct())}
            </div>
        );
    }

    historypricecallback(price) {
        this.addMainToQuote('all');
    }

    getFullProduct() {
        return this.state.fullProduct;
    }

    sourceSelected(source, wsSelections, cost) {
        this.setState({selectedcost: cost});
    }
}

/* ################################################################################################## */
class EtilizeDetailPriceCell extends React.Component {
    constructor(props) {
        super(props);
        // var expProps = {
        //     extraclass: '',
        //     pricelookupfn: null,
        //     source: '',
        //     lowest: '0.00',
        //     selectSrc: 'src',
        //     mpn: '',
        //     checkfn: null
        // };
        this.render = this.render.bind(this);
    }

    render() {
        var price = this.props.pricelookupfn(this.props.source, this.props.mpn);
        var std = 'etilizeresultcol etilizepricecol ';
        if (!price || price.status === 'pending' || price.status === 'searching' || price.status === 'unknown'){
            return (<td className={std+this.props.extraclass}>...</td>);
        }
        var catinfo = null;
        if (price.source === 'Catalog') {
            catinfo = (<div className='cataloglabel'>{price.details}</div>);
        }
        if (price.error) {
            return (<td className={std+this.props.extraclass}><span className='etilizeerror'>{price.error}</span></td>);
        }
        var newStyle = {};
        if (this.props.lowest === price.price) {
            newStyle.backgroundColor = '#B1FF9B';
        }
        return (<td className={std+this.props.extraclass} style={newStyle}>
                    {catinfo}
                    <span className='etilizeprice'>{price.dprice}</span><br />
                    <span className='etilizeqty'>{price.qty + ' / ' + price.backorder}</span><br />
                    <input data-cost={price.price} type='radio' name={price.mpn} value={this.props.source} checked={this.props.source === this.props.selectSrc} 
                                onChange={this.props.checkfn}/>
                </td>);
    }
}

/* ################################################################################################## */
class EtilizeDetailsAccessories extends React.Component {
    constructor(props) {
        super(props);
        // var expProps = {
        //     main: null,
        //     extraclass: '',
        //     pricelookupfn: null,
        //     source: '',
        //     lowest: '0.00',
        //     selectSrc: 'src',
        //     mpn: '',
        //     checkfn: null
        // };
        this.rowClick = this.rowClick.bind(this);
        this.radioClicked = this.radioClicked.bind(this);
        this.rowCheckChange = this.rowCheckChange.bind(this);
        this.detailAddToQuote = this.detailAddToQuote.bind(this);
        this.accessDestinationChanged = this.accessDestinationChanged.bind(this);
        this.render = this.render.bind(this);
    }

    rowClick(product, imgurl) {
        if (this.props.deeperClick) {
            this.props.deeperClick(product, imgurl);
        }
    }

    radioClicked(e) {
        var source = e.target.value;
        var mpn = e.target.name;
        var product = this.props.main.getFullProduct();
        if (product && product.AccessoryItems) {
            for (var i=0; i<product.AccessoryItems.length; i++) {
                var itm = product.AccessoryItems[i];
                if (itm.ManufacturerPartNumber === mpn) {
                    itm.selectedSource = source;
                    this.forceUpdate();
                    return;
                }
            }
        }
    }

    rowCheckChange(subitem) {
        subitem.isSelected = subitem.isSelected ? false: true;
        this.forceUpdate();
    }

    detailAddToQuote() {
        this.props.main.addMainToQuote('accessories');
    }

    accessDestinationChanged(e) {
        this.props.main.setState({
            accessDestination: e.target.value
        });
    }

    render() {
        var tabdrops = [];
        var me = this;
        tabdrops.push(<option value='~tabroute~' disabled='disabled' key='mainactivetab'>Tab Routing Option</option>);
        var qtabs = app.currentQuote.Tabs;
        for (let i = 0; i < qtabs.length; i++) {
            if (!qtabs[i].IsProtectedTab || quosal.util.userCanModifyProtectedTab()) {
                tabdrops.push(<option value={qtabs[i].IdQuoteTabs} key={'a'+qtabs[i].IdQuoteTabs}>{qtabs[i].TabName}</option>);
            }
        }
        var product = this.props.main.getFullProduct();
        var config = this.props.config;
        
        let oneSelected = false;
        var rows = [];
        if (product && product.AccessoryItems) {
            var hasUnknown = false;
            var selectedSource = '';
            var findMin = function(bUse, src, part, low) {
                if (!bUse) return low;
                var p = me.props.pricelookupfn(src, part);
                var v2 = p.price;
                if (p.status === 'pending' || p.status === 'searching' || p.status === 'unknown') {
                    hasUnknown = true;
                }
                if (p.error || p.price <= 0.00 || isNaN(p.price) || p.price === null) v2 = Number.MAX_VALUE;
                if (v2 < low) {
                    low = v2;
                    selectedSource = src;
                }
                return low;
            };
            
            var lastGroup = null;
            for (var i=0; i<product.AccessoryItems.length;i++) {
                var acc = product.AccessoryItems[i];
                if (acc.isSelected) {
                    oneSelected = true;
                }
                var lowest;
                if (acc.lowest || (acc.selectedSource && acc.selectedSource.length > 0)) {
                    lowest = acc.lowest;
                    selectedSource = acc.selectedSource;
                }
                else {
                    lowest = Number.MAX_VALUE;
                    lowest = findMin(config.UseJenne, 'Jenne', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseSynnex, 'TD SYNNEX', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngram, 'Ingram Micro', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDNH, 'D&H', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseAccutech, 'Accu-Tech', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArbitech, 'Arbitech', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseTechDataUK, 'Tech Data UK', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseWestcoast, 'WestCoast', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngramSAP, 'Ingram Micro SAP', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseScansource, 'Scansource', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseBluestar, 'BlueStar', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDigitek, 'Digitek', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArrow, 'Arrow', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseADI, 'ADI', acc.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.ShowCatalogSource, 'Catalog', acc.ManufacturerPartNumber, lowest);

                    if (!hasUnknown) {
                        acc.lowest = lowest;
                        acc.selectedSource = selectedSource;
                    }
                }

                var imgurl = '';
                if (acc.ProductImages && acc.ProductImages.length > 0) {
                    imgurl = acc.ProductImages[0];
                }
                if (lastGroup !== acc.Category.Name) {
                    var header = <tr key={'acchead_'+i}><td className='comparegroup' colSpan={10}>{acc.Category.Name}</td></tr>;
                    lastGroup = acc.Category.Name;
                    rows.push(header);
                }
                var cols = [];
                if (config.UseJenne) cols.push(<EtilizeDetailPriceCell extraclass='jennepricecol' pricelookupfn={this.props.pricelookupfn} source='Jenne' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c5'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseSynnex) cols.push(<EtilizeDetailPriceCell extraclass='synnexpricecol' pricelookupfn={this.props.pricelookupfn} source='TD SYNNEX' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c6'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseIngram) cols.push(<EtilizeDetailPriceCell shown={config.UseIngram} extraclass='ingrampricecol' pricelookupfn={this.props.pricelookupfn} source='Ingram Micro' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c8a'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseDNH) cols.push(<EtilizeDetailPriceCell shown={config.UseDNH} extraclass='dnhpricecol' pricelookupfn={this.props.pricelookupfn} source='D&H' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c9'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseAccutech) cols.push(<EtilizeDetailPriceCell shown={config.UseAccutech} extraclass='accutechpricecol' pricelookupfn={this.props.pricelookupfn} source='Accu-Tech' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c10'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseArbitech) cols.push(<EtilizeDetailPriceCell shown={config.UseArbitech} extraclass='arbitechpricecol' pricelookupfn={this.props.pricelookupfn} source='Arbitech' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c11'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseTechDataUK) cols.push(<EtilizeDetailPriceCell shown={config.UseTechDataUK} extraclass='techdataukpricecol' pricelookupfn={this.props.pricelookupfn} source='Tech Data UK' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c12'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseWestcoast) cols.push(<EtilizeDetailPriceCell shown={config.UseWestcoast} extraclass='westcoastpricecol' pricelookupfn={this.props.pricelookupfn} source='WestCoast' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c13'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseIngramSAP) cols.push(<EtilizeDetailPriceCell shown={config.UseIngramSAP} extraclass='ingramsappricecol' pricelookupfn={this.props.pricelookupfn} source='Ingram Micro SAP' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c14'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseScansource) cols.push(<EtilizeDetailPriceCell shown={config.UseScansource} extraclass='scansourcepricecol' pricelookupfn={this.props.pricelookupfn} source='Scansource' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c15'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseBluestar) cols.push(<EtilizeDetailPriceCell shown={config.UseBluestar} extraclass='bluestarpricecol' pricelookupfn={this.props.pricelookupfn} source='BlueStar' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c17'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseDigitek) cols.push(<EtilizeDetailPriceCell shown={config.UseDigitek} extraclass='digitekpricecol' pricelookupfn={this.props.pricelookupfn} source='Digitek' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c18'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseArrow) cols.push(<EtilizeDetailPriceCell shown={config.UseArrow} extraclass='arrowpricecol' pricelookupfn={this.props.pricelookupfn} source='Arrow' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c19'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseADI) cols.push(<EtilizeDetailPriceCell shown={config.UseADI} extraclass='adipricecol' pricelookupfn={this.props.pricelookupfn} source='ADI' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c20'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.ShowCatalogSource) cols.push(<EtilizeDetailPriceCell extraclass='catalogpricecol' pricelookupfn={this.props.pricelookupfn} source='Catalog' mpn={acc.ManufacturerPartNumber} key={'ar' + i + 'c21'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);

                var row = (<tr key={'accsrow_'+i} className='etilizeresultrow' > 
                        <td key={'ar'+i+'c1'} className='etilizeresultcol etilizecheckcol'><input type='checkbox' checked={acc.isSelected} onChange={this.rowCheckChange.bind(this, acc)}/></td>
                        <td key={'ar'+i+'c2'} className='etilizeresultcol etilizeimgcol' onClick={this.rowClick.bind(this, acc, imgurl)}><img src={imgurl} className='etilizeimgsmall' alt='small product' /></td>
                        <td key={'ar'+i+'c3'} className='etilizeresultcol etilizedesccol' onClick={this.rowClick.bind(this, acc, imgurl)}>
                                        <div>
                                            <span className='etilizepartnum'>{acc.ManufacturerPartNumber}</span>
                                            <span className='etilizedesc1'>{acc.Description2}</span>
                                        </div>
                                        <div className='etilizedesc2'>{acc.Description3}</div>
                                    </td>
                        {cols}
                    </tr>);
                rows.push(row);
            }
        }

        var head = quosal.etilizeHelper.priceHeaders(config);
        return (<table className='extrastable' cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td colSpan={3} className='resultsheader' style={{ paddingTop: 6 }}>
                                <div className='formselectfieldwrapper compact' style={{marginRight:'8px'}}>
                                    <select className='formselectfield' id='accessoriesrouting' name='accessoriesrouting' 
                                                        value={this.props.main.state.accessDestination} onChange={this.accessDestinationChanged}>
                                        {tabdrops}
                                    </select>
                                </div>
                                <ActiveDisableButton key='addaccessories' id='addaccessories' 
                                    onClick={this.detailAddToQuote} style={{ marginLeft: '24px' }} 
                                    disabled={false} unavailable={!oneSelected ? 'true': 'false'}>Add Selected</ActiveDisableButton>
                            </td>
                            {head}
                        </tr>
                        {rows}
                    </tbody>
                </table>);
    }
}

/* ################################################################################################## */
class EtilizeDetailsSimilar extends React.Component {
    constructor(props) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.radioClicked = this.radioClicked.bind(this);
        this.similarAddToQuote = this.similarAddToQuote.bind(this);
        this.rowCheckChange = this.rowCheckChange.bind(this);
        this.simDestinationChanged = this.simDestinationChanged.bind(this);
        this.render = this.render.bind(this);
    }
    
    rowClick(product,imgurl) {
        if (this.props.deeperClick) {
            this.props.deeperClick(product,imgurl);
        }
    }

    radioClicked(e) {
        var source = e.target.value;
        var mpn = e.target.name;
        var product = this.props.main.getFullProduct();
        if (product && product.SimilarItems) {
            for (var i=0; i<product.SimilarItems.length;i++) {
                var itm = product.SimilarItems[i];
                if (itm.ManufacturerPartNumber === mpn) {
                    itm.selectedSource = source;
                    this.forceUpdate();
                    return;
                }
            }
        }
    }

    similarAddToQuote() {
        this.props.main.addMainToQuote('similar');
    }
    
    rowCheckChange(subitem) {
        subitem.isSelected = subitem.isSelected ? false: true;
        this.forceUpdate();
    }

    simDestinationChanged(e) {
        this.props.main.setState({
            similarDestination: e.target.value
        });
    }

    render() {
        var tabdrops = [];
        tabdrops.push(<option value='~tabroute~' disabled='disabled' key='mainactivetab'>Tab Routing Option</option>);
        var qtabs = app.currentQuote.Tabs;
        for (let i = 0; i < qtabs.length; i++) {
            if (!qtabs[i].IsProtectedTab || quosal.util.userCanModifyProtectedTab()) {
                tabdrops.push(<option value={qtabs[i].IdQuoteTabs} key={'s'+qtabs[i].IdQuoteTabs}>{qtabs[i].TabName}</option>);
            }
        }
        var me = this;
        var product = this.props.main.getFullProduct();
        
        var config = this.props.config;
        
        let oneSelected = false;
        var rows = [];
        if (product && product.SimilarItems) {
            var hasUnknown = false;
            var selectedSource = '';
            var findMin = function(bUse, src, part, low) {
                if (!bUse) return low;
                var p = me.props.pricelookupfn(src, part);
                var v2 = p.price;
                if (p.status === 'pending' || p.status === 'searching' || p.status === 'unknown') {
                    hasUnknown = true;
                }
                if (p.error || p.price <= 0.00 || isNaN(p.price) || p.price === null) v2 = Number.MAX_VALUE;
                if (v2 < low) {
                    low = v2;
                    selectedSource = src;
                }
                return low;
            };
            var lastGroup = '';
            var doSimilarHeaders = false;
            for (var i=0; i<product.SimilarItems.length;i++) {
                var sim = product.SimilarItems[i];
                if (sim.isSelected) {
                    oneSelected = true;
                }
                var lowest;
                if (sim.lowest || (sim.selectedSource && sim.selectedSource.length > 0)) {
                    lowest = sim.lowest;
                    selectedSource = sim.selectedSource;
                }
                else {
                    lowest = Number.MAX_VALUE;
                    hasUnknown = false;
                    selectedSource = '';
                    lowest = findMin(config.UseJenne, 'Jenne', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseSynnex, 'TD SYNNEX', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngram, 'Ingram Micro', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDNH, 'D&H', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseAccutech, 'Accu-Tech', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArbitech, 'Arbitech', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseTechDataUK, 'Tech Data UK', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseWestcoast, 'WestCoast', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngramSAP, 'Ingram Micro SAP', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseScansource, 'Scansource', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseBluestar, 'BlueStar', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDigitek, 'Digitek', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArrow, 'Arrow', sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseADI, "ADI", sim.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.ShowCatalogSource, 'Catalog', sim.ManufacturerPartNumber, lowest);

                    if (!hasUnknown) {
                        sim.lowest = lowest;
                        sim.selectedSource = selectedSource;
                    }
                }

                var imgurl = '';
                if (sim.ProductImages && sim.ProductImages.length > 0) {
                    imgurl = sim.ProductImages[0];
                }
                if (lastGroup !== sim.Category.Name) {
                    var header = <tr key={'simhead_'+i}><td className='comparegroup' colSpan={10}>{sim.Category.Name}</td></tr>;
                    lastGroup = sim.Category.Name;
                    if (doSimilarHeaders) {
                        rows.push(header);
                    }
                }
                var cols = [];
                if (config.UseJenne) cols.push(<EtilizeDetailPriceCell extraclass='jennepricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Jenne' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c5'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseSynnex) cols.push(<EtilizeDetailPriceCell extraclass='synnexpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='TD SYNNEX' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c6'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseIngram) cols.push(<EtilizeDetailPriceCell shown={config.UseIngram} extraclass='ingrampricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Ingram Micro' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c8a'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseDNH) cols.push(<EtilizeDetailPriceCell shown={config.UseDNH} extraclass='dnhpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='D&H' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c9'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseAccutech) cols.push(<EtilizeDetailPriceCell shown={config.UseAccutech} extraclass='accutechpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Accu-Tech' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c10'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseArbitech) cols.push(<EtilizeDetailPriceCell shown={config.UseArbitech} extraclass='arbitechpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Arbitech' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c11'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseTechDataUK) cols.push(<EtilizeDetailPriceCell shown={config.UseTechDataUK} extraclass='techdataukpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Tech Data UK' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c12'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseWestcoast) cols.push(<EtilizeDetailPriceCell shown={config.UseWestcoast} extraclass='westcoastpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='WestCoast' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c13'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseIngramSAP) cols.push(<EtilizeDetailPriceCell shown={config.UseIngramSAP} extraclass='ingramsappricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Ingram Micro SAP' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c14'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseScansource) cols.push(<EtilizeDetailPriceCell shown={config.UseScansource} extraclass='scansourcepricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Scansource' mpn={sim.ManufacturerPartNumber} key={'sr' + i + 'c15'} lowest={lowest} selectSrc={selectedSource} />);
                if (config.UseBluestar) cols.push(<EtilizeDetailPriceCell shown={config.UseBluestar} extraclass='bluestarpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='BlueStar' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c17'} lowest={lowest} selectSrc={selectedSource}/>);
                if (config.UseDigitek) cols.push(<EtilizeDetailPriceCell shown={config.UseDigitek} extraclass='digitekpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Digitek' mpn={sim.ManufacturerPartNumber} key={'sr' + i + 'c18'} lowest={lowest} selectSrc={selectedSource} />);
                if (config.UseArrow) cols.push(<EtilizeDetailPriceCell shown={config.UseArrow} extraclass='arrowpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Arrow' mpn={sim.ManufacturerPartNumber} key={'sr' + i + 'c19'} lowest={lowest} selectSrc={selectedSource} />);
                if (config.UseADI) cols.push(<EtilizeDetailPriceCell shown={config.UseADI} extraclass='adipricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='ADI' mpn={sim.ManufacturerPartNumber} key={'sr' + i + 'c20'} lowest={lowest} selectSrc={selectedSource} />);
                if (config.ShowCatalogSource) cols.push(<EtilizeDetailPriceCell extraclass='catalogpricecol' pricelookupfn={this.props.pricelookupfn} checkfn={this.radioClicked} source='Catalog' mpn={sim.ManufacturerPartNumber} key={'sr'+i+'c21'} lowest={lowest} selectSrc={selectedSource}/>);

                var row = (<tr key={'simrow_'+i} className='etilizeresultrow' > 
                        <td className='etilizeresultcol etilizecheckcol'><input type='checkbox' checked={sim.isSelected} onChange={this.rowCheckChange.bind(this,sim)} /></td>
                        <td className='etilizeresultcol etilizeimgcol' onClick={this.rowClick.bind(this,sim,imgurl)}><img src={imgurl} className='etilizeimgsmall' alt='small product' /></td>
                        <td className='etilizeresultcol etilizedesccol' onClick={this.rowClick.bind(this,sim,imgurl)}>
                            <div>
                                <span className='etilizepartnum'>{sim.ManufacturerPartNumber}</span>
                                <span className='etilizedesc1'>{sim.Description2}</span>
                            </div>
                            <div className='etilizedesc2'>{sim.Description3}</div>
                        </td>
                        {cols}
                    </tr>);
                rows.push(row);
            }
        }

        var head = quosal.etilizeHelper.priceHeaders(config);
        return (<table className='extrastable' cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td colSpan={3} className='resultsheader' style={{ paddingTop: 6 }}>
                                <div className='formselectfieldwrapper compact' style={{marginRight:'8px'}}>
                                    <select className='formselectfield' id='similarrouting' name='similarrouting' 
                                                        value={this.props.main.state.similarDestination} onChange={this.simDestinationChanged} >
                                        {tabdrops}
                                    </select>
                                </div>
                                <ActiveDisableButton key='addsimilar' id='addsimilar' onClick={this.similarAddToQuote}  
                                            style={{ marginLeft: '24px' }} disabled={false} 
                                            unavailable={!oneSelected ? 'true': 'false'} >Add Selected</ActiveDisableButton>
                            </td>
                            {head}
                        </tr>
                        {rows}
                    </tbody>
                </table>);
    }
}

/* ################################################################################################## */
class EtilizeDetailsUpsell extends React.Component {
    constructor(props) {
        super(props);
        this.rowClick = this.rowClick.bind(this);
        this.radioClicked = this.radioClicked.bind(this);
        this.upsellAddToQuote = this.upsellAddToQuote.bind(this);
        this.rowCheckChange = this.rowCheckChange.bind(this);
        this.upDestinationChanged = this.upDestinationChanged.bind(this);
        this.render = this.render.bind(this);
    }

    rowClick(product,imgurl) {
        if (this.props.deeperClick) {
            this.props.deeperClick(product,imgurl);
        }
    }

    upsellAddToQuote() {
        this.props.main.addMainToQuote('upsell');
    }

    radioClicked(e) {
        var source = e.target.value;
        var mpn = e.target.name;
        var product = this.props.main.getFullProduct();
        if (product && product.UpSellItems) {
            for (var i=0; i<product.UpSellItems.length; i++) {
                var itm = product.UpSellItems[i];
                if (itm.ManufacturerPartNumber === mpn) {
                    itm.selectedSource = source;
                    this.forceUpdate();
                    return;
                }
            }
        }
    }

    rowCheckChange(subitem) {
        subitem.isSelected = subitem.isSelected ? false: true;
        this.forceUpdate();
    }

    upDestinationChanged(e) {
        this.props.main.setState({
            upsellDestination: e.target.value
        });
    }
    
    render() {
        var tabdrops = [];
        tabdrops.push(<option value='~tabroute~' disabled='disabled' key='mainactivetab'>Tab Routing Option</option>);
        var qtabs = app.currentQuote.Tabs;
        for (let i = 0; i < qtabs.length; i++) {
            if (!qtabs[i].IsProtectedTab || quosal.util.userCanModifyProtectedTab()) {
                tabdrops.push(<option value={qtabs[i].IdQuoteTabs} key={'u'+qtabs[i].IdQuoteTabs}>{qtabs[i].TabName}</option>);
            }
        }
        var me = this;
        var product = this.props.main.getFullProduct();
        
        var config = this.props.config;
        
        var rows = [];
        let oneSelected = false;
        if (product && product.UpSellItems) {
            var hasUnknown = false;
            var selectedSource = '';
            var findMin = function(bUse, src, part, low) {
                if (!bUse) return low;
                var p = me.props.pricelookupfn(src, part);
                var v2 = p.price;
                if (p.status === 'pending' || p.status === 'searching' || p.status === 'unknown') {
                    hasUnknown = true;
                }
                if (p.error || p.price <= 0.00 || isNaN(p.price) || p.price === null) v2 = Number.MAX_VALUE;
                if (v2 < low) {
                    low = v2;
                    selectedSource = src;
                }
                return low;
            };

            var lastGroup = '';
            var doUpsellHeaders = false;
            for (var i=0; i<product.UpSellItems.length; i++) {
                var upsell = product.UpSellItems[i];
                if (upsell.isSelected) {
                    oneSelected = true;
                }
                var lowest;
                if (upsell.lowest || (upsell.selectedSource && upsell.selectedSource.length > 0)) {
                    lowest = upsell.lowest;
                    selectedSource = upsell.selectedSource;
                }
                else {
                    lowest = Number.MAX_VALUE;
                    lowest = findMin(config.UseJenne, 'Jenne', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseSynnex, 'TD SYNNEX', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngram, 'Ingram Micro', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDNH, 'D&H', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseAccutech, 'Accu-Tech', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArbitech, 'Arbitech', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseTechDataUK, 'Tech Data UK', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseWestcoast, 'WestCoast', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseIngramSAP, 'Ingram Micro SAP', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseScansource, 'Scansource', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseBluestar, 'BlueStar', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseDigitek, 'Digitek', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseArrow, 'Arrow', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.UseADI, 'ADI', upsell.ManufacturerPartNumber, lowest);
                    lowest = findMin(config.ShowCatalogSource, 'Catalog', upsell.ManufacturerPartNumber, lowest);

                    if (!hasUnknown) {
                        upsell.lowest = lowest;
                        upsell.selectedSource = selectedSource;
                    }
                }

                var imgurl = '';
                if (upsell.ProductImages && upsell.ProductImages.length > 0) {
                    imgurl = upsell.ProductImages[0];
                }
                if (lastGroup !== upsell.Category.Name) {
                    var header = <tr key={'uphead_'+i}><td className='comparegroup' colSpan={10}>{upsell.Category.Name}</td></tr>;
                    lastGroup = upsell.Category.Name;
                    if (doUpsellHeaders) {
                        rows.push(header);
                    }
                }
                var cols = [];
                if (config.UseJenne) cols.push(<EtilizeDetailPriceCell extraclass='jennepricecol' pricelookupfn={this.props.pricelookupfn} source='Jenne' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c5'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseSynnex) cols.push(<EtilizeDetailPriceCell extraclass='synnexpricecol' pricelookupfn={this.props.pricelookupfn} source='TD SYNNEX' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c6'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseIngram) cols.push(<EtilizeDetailPriceCell shown={config.UseIngram} extraclass='ingrampricecol' pricelookupfn={this.props.pricelookupfn} source='Ingram Micro' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c8a'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseDNH) cols.push(<EtilizeDetailPriceCell shown={config.UseDNH} extraclass='dnhpricecol' pricelookupfn={this.props.pricelookupfn} source='D&H' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c9'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseAccutech) cols.push(<EtilizeDetailPriceCell shown={config.UseAccutech} extraclass='accutechpricecol' pricelookupfn={this.props.pricelookupfn} source='Accu-Tech' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c10'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseArbitech) cols.push(<EtilizeDetailPriceCell shown={config.UseArbitech} extraclass='arbitechpricecol' pricelookupfn={this.props.pricelookupfn} source='Arbitech' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c11'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseTechDataUK) cols.push(<EtilizeDetailPriceCell shown={config.UseTechDataUK} extraclass='techdataukpricecol' pricelookupfn={this.props.pricelookupfn} source='Tech Data UK' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c12'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseWestcoast) cols.push(<EtilizeDetailPriceCell shown={config.UseWestcoast} extraclass='westcoastpricecol' pricelookupfn={this.props.pricelookupfn} source='WestCoast' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c13'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseIngramSAP) cols.push(<EtilizeDetailPriceCell shown={config.UseIngramSAP} extraclass='ingramsappricecol' pricelookupfn={this.props.pricelookupfn} source='Ingram Micro SAP' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c14'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseScansource) cols.push(<EtilizeDetailPriceCell shown={config.UseScansource} extraclass='scansourcepricecol' pricelookupfn={this.props.pricelookupfn} source='Scansource' mpn={upsell.ManufacturerPartNumber} key={'ur' + i + 'c15'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseBluestar) cols.push(<EtilizeDetailPriceCell shown={config.UseBluestar} extraclass='bluestarpricecol' pricelookupfn={this.props.pricelookupfn} source='BlueStar' mpn={upsell.ManufacturerPartNumber} key={'ur'+i+'c17'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked}/>);
                if (config.UseDigitek) cols.push(<EtilizeDetailPriceCell shown={config.UseDigitek} extraclass='digitekpricecol' pricelookupfn={this.props.pricelookupfn} source='Digitek' mpn={upsell.ManufacturerPartNumber} key={'ur' + i + 'c18'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseArrow) cols.push(<EtilizeDetailPriceCell shown={config.UseArrow} extraclass='arrowpricecol' pricelookupfn={this.props.pricelookupfn} source='Arrow' mpn={upsell.ManufacturerPartNumber} key={'ur' + i + 'c19'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.UseADI) cols.push(<EtilizeDetailPriceCell shown={config.UseADI} extraclass='adipricecol' pricelookupfn={this.props.pricelookupfn} source='ADI' mpn={upsell.ManufacturerPartNumber} key={'ur' + i + 'c20'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                if (config.ShowCatalogSource) cols.push(<EtilizeDetailPriceCell extraclass='catalogpricecol' pricelookupfn={this.props.pricelookupfn} source='Catalog' mpn={upsell.ManufacturerPartNumber} key={'ur' + i + 'c21'} lowest={lowest} selectSrc={selectedSource} checkfn={this.radioClicked} />);
                var row = (<tr key={'upsellrow_'+i} className='etilizeresultrow'  > 
                        <td className='etilizeresultcol etilizecheckcol'><input type='checkbox' checked={upsell.isSelected} onChange={this.rowCheckChange.bind(this,upsell)} /></td>
                        <td className='etilizeresultcol etilizeimgcol' onClick={this.rowClick.bind(this,upsell,imgurl)}><img src={imgurl} className='etilizeimgsmall' alt='small product' /></td>
                        <td className='etilizeresultcol etilizedesccol' onClick={this.rowClick.bind(this,upsell,imgurl)}>
                            <div>
                                <span className='etilizepartnum'>{upsell.ManufacturerPartNumber}</span>
                                <span className='etilizedesc1'>{upsell.Description2}</span>
                            </div>
                            <div className='etilizedesc2'>{upsell.Description3}</div>
                        </td>
                        {cols}
                    </tr>);
                rows.push(row);
            }
        }
        var head = quosal.etilizeHelper.priceHeaders(config);
        return (<table className='extrastable' cellPadding={0} cellSpacing={0}>
                    <tbody>
                        <tr>
                            <td colSpan={3} className='resultsheader' style={{ paddingTop: 6 }}>
                                <div className='formselectfieldwrapper compact' style={{marginRight:'8px'}}>
                                    <select className='formselectfield' id='upsellrouting' name='upsellrouting' 
                                                        value={this.props.main.state.upsellDestination} onChange={this.upDestinationChanged}>
                                       {tabdrops}
                                    </select>
                                </div>
                                <ActiveDisableButton key='addupsell' id='addupsell' onClick={this.upsellAddToQuote}
                                    style={{ marginLeft: '24px' }} disabled={false}
                                    unavailable={!oneSelected ? 'true': 'false'} >Add Selected</ActiveDisableButton>
                            </td>
                            {head}
                        </tr>
                        {rows}
                    </tbody>
                </table>);
    }
}

/* ################################################################################################## */
class EtilizeDetailsSpecs extends React.Component {
    constructor(props) {
        super(props);
        this.handleCheck = this.handleCheck.bind(this);
        this.render = this.render.bind(this);
    }

    handleCheck(e) {
        var product = this.props.main.getFullProduct();
        var iGroup = parseInt(e.currentTarget.dataset.groupidx, 10);
        var iItem = parseInt(e.currentTarget.dataset.itemidx, 10);
        product.AttributeGroup[iGroup].Items[iItem].isChecked = !product.AttributeGroup[iGroup].Items[iItem].isChecked;
        this.forceUpdate();
    }

    render() {
        function dangerousMarkup(str) {
            return {__html: str};
        }
        var product = this.props.main.getFullProduct();
        var hiddenCheck = this.props.config.NotesMode.toLowerCase().indexOf("premier style") >= 0 ? '' : 'hidden';
        var rows = [];
        if (product && product.AttributeGroup) {
            for (let i=0; i<product.AttributeGroup.length; i++) {
                var group = product.AttributeGroup[i];
                rows.push(<tr key={'kr_'+group.Name}><td className='specgroup' colSpan={3} key={'k_'+group.Name}>{group.Name}</td></tr>);
                for (var j=0; j<group.Items.length; j++) {
                    var attr = group.Items[j];
                    if (attr.isChecked !== true && attr.isChecked !== false) {
                        attr.isChecked = true;
                    }
                    rows.push(<tr key={'keyr_' + attr.Name} >
                            <td className='speccheck' ><input type='checkbox' hidden={hiddenCheck} name={'attr_' + attr.Name} data-groupidx={i} data-itemidx={j} key={'key_' + attr.Name} checked={attr.isChecked === true} onChange={this.handleCheck} /></td>
                            <td className='specname'>{attr.Name}</td>
                            <td className='specvalue' dangerouslySetInnerHTML={dangerousMarkup(attr.Value)} />
                        </tr>);
                }
            }
        }

        rows.push(<tr key='kr_dist_part_nums'><td className='specgroup' colSpan={3} key='kr_dist_part_nums'>Distributor Part Numbers</td></tr>);
        if (product.Skus) {
            for (let i=0; i<product.Skus.length;i++) {
                var sku = product.Skus[i];
                rows.push(<tr  key={'keyr_' + i}><td /><td className='specname'>{sku.SkuType}</td><td className='specvalue'>{sku.Number}</td></tr>);
            }
        }

        return (<table className='extrastable' cellPadding={0} cellSpacing={0}>
                    <tbody>
                        {rows}
                    </tbody>
                </table>);
    }
}
/* ################################################################################################## */
class EtilizeDetailsPriceSources extends React.Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.priceTimer = null;
        this.kPriceTimeout = 75;
        this.state = {
            PriceSourcesToLookup: [],
            priceinfo: [],
            selectedSource: null,
            selectedWH: [],
            lastMFP: '~',
            searching: false,
            searchingSources: []
        };
        // var expProps = {
        //     config: null,
        //     main: null,
        //     product: null
        // };
        this.componentDidMount = this.componentDidMount.bind(this);
        this.startSourcePriceTimer = this.startSourcePriceTimer.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.setupSourceLookupList = this.setupSourceLookupList.bind(this);
        this.lookupPriceSourcePrices = this.lookupPriceSourcePrices.bind(this);
        this.selectDefaultSource = this.selectDefaultSource.bind(this);
        this.selectPriceSource = this.selectPriceSource.bind(this);
        this.renderAndAddCatalogRow = this.renderAndAddCatalogRow.bind(this);
        this.warehouseChanged = this.warehouseChanged.bind(this);
        this.renderWarehouseSingleRow = this.renderWarehouseSingleRow.bind(this);
        this.renderWarehouseRows = this.renderWarehouseRows.bind(this);
        this.cloudClick = this.cloudClick.bind(this);
        this.renderStandardRow = this.renderStandardRow.bind(this);
        this.renderWarehouseListing = this.renderWarehouseListing.bind(this);
        this.render = this.render.bind(this);
    }
        
    componentDidMount(){
        this.mounted = true;
        this.startSourcePriceTimer();
    }
    startSourcePriceTimer() {
        this.priceUpdated = false;

        if (this.props.product && this.priceTimer==null && this.props.product.ManufacturerPartNumber &&
            (this.state.lastMFP !== this.props.product.ManufacturerPartNumber || this.state.PriceSourcesToLookup.length > 0)) {
                this.setupSourceLookupList(true);
                this.priceTimer = setInterval(()=>{
                    this.lookupPriceSourcePrices();
                }, this.kPriceTimeout);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.priceTimer != null) {
            clearInterval(this.priceTimer);
            this.priceTimer=null;
        }
    }

    setupSourceLookupList(resetPriceInfo) {
        let list = quosal.etilizeHelper.getSourceList(this.props.config);
		if (resetPriceInfo)
		{
			this.setState({PriceSourcesToLookup: list, priceinfo: []});
		}
        return list;
    }

    lookupPriceSourcePrices() {
        var srclist = this.state.PriceSourcesToLookup;
        var selectedSources = quosal.etilizeHelper.getSourceList(this.props.config);
        if (this.state.lastMFP !== this.props.product.ManufacturerPartNumber) {
            srclist = this.setupSourceLookupList(true);
        }
        this.setState({ lastMFP: this.props.product.ManufacturerPartNumber });
        if (srclist.length === 0) {
            clearInterval(this.priceTimer);
            this.priceTimer = null;
            if (this.props.pricingdone) {
                this.props.pricingdone();
            }
            return;
        }
        
        var config = this.props.config;
        var product = this.props.product;
        var numbers = [];
        var productIds = [];
        var oldsources = this.state.PriceSourcesToLookup;
        var onesource = oldsources.shift();
        this.setState({ PriceSourcesToLookup: oldsources });
        var skus = [];
        if (product.Skus) {
            let srcSkus = quosal.etilizeHelper.productSkuBySource(config, product.Skus, onesource);
            skus.push(...srcSkus);
        }
        if (skus.length === 0) {
            skus.push(product.ManufacturerPartNumber);
        }
		for (var i = 0; i < skus.length; i++) {
			numbers.push(product.ManufacturerPartNumber);
			productIds.push(product.Id);			
		}

        var simplifiedPricingApiCall = function() {
            let cleanSrc = quosal.etilizeHelper.toSourceKey(onesource);
            var pricingApi = quosal.api.pricing.etilizePricesWithSkus(numbers, skus, cleanSrc, app.currentQuote.IdQuoteMain, productIds);
            pricingApi.finished = function(msg) {
                if (!this.mounted) {
                    return;
                }
                var infos = this.state.priceinfo;
                if (msg.info) {
                    for (let i = 0; i < msg.info.length; i++) {
                        var p = msg.info[i];
                        var key = (onesource + '~' + p.RefID1).toLowerCase();
                        if (p.RefID1.length > 0 && p.CustomerPrice > 0.00) {
                            var sourceName = p.Source && p.Source == "PROMO" ? onesource + " " + p.Source : onesource;
                            var info = {
                                key: key,
                                source: sourceName,
                                sourceid: p.SourceId ? p.SourceId : onesource,
                                manufacturer: p.RefIDQual2, // cross-map
                                mpn: p.RefID1,
                                price: p.CustomerPrice,
                                dprice: app.currentQuote.formatCurrency(p.CustomerPrice),
                                qty: p.AvailableQty,
                                cost: p.MSRP,
                                dcost: app.currentQuote.formatCurrency(p.MSRP),
                                backorder: p.OnOrderQty,
                                error: p.ErrorMsg,
                                alternate: p.RefID2,
                                warehouses: p.Warehouses,
                                iscatalog: (onesource === 'Catalog')
                            };
                            if (info.warehouses && info.warehouses.length > 0) {
                                info.qty = 0;
                                info.backorder = 0;
                                for (var widx = 0; widx < info.warehouses.length; widx++) {
                                    info.qty += info.warehouses[widx].Qty;
                                    info.backorder += info.warehouses[widx].OnOrderQty;
                                }
                            }
                            infos.push(info);
                            this.priceUpdated = true;
                        }
                    }
                }
                this.setState({ priceinfo: infos, searching: false }, () => {
                    if (srclist.length === 0) {
                        setTimeout(() => { this.selectDefaultSource(); }, 100);
                    }
                    else {
                        this.lookupPriceSourcePrices();
                    }
                });
            }.bind(this);
			
            this.setState({
                searching: true,
                searchingSources: selectedSources
            });

            if (numbers.length > 0) {
                pricingApi.call();
            }
        }.bind(this);
        simplifiedPricingApiCall();
    }

    selectDefaultSource() {
        if (!this.state.priceinfo || this.state.priceinfo.length === 0) {
            this.setState({selectedSource: null, selectedWH: []});
            return;
        }
        var lowest = null;
        var bestwh = [];
        this.state.priceinfo.forEach(function(src){
                if (src.price > 0) {
                    if (lowest === null || lowest.price > src.price){
                        lowest = src;
                    }
                }
                var most = null;
                src.warehouses.forEach(function(wh){
                    if (most === null || wh.Qty > most.Qty){
                        most = wh;
                    }
                });
                bestwh[src.source] = most;
            }, this);

        this.setState({selectedSource: lowest, selectedWH: bestwh},()=>
            {
                if (this.props.sourceChanged) {
                    this.props.sourceChanged(lowest, bestwh, lowest.price);
                }
            });
    }

    selectPriceSource(src) {
        this.setState({selectedSource: src});
        if (this.props.sourceChanged) {
            this.props.sourceChanged(src, this.state.selectedWH, src.price);
        }
    }

    renderAndAddCatalogRow(source, rows) {
        var sel = (this.state.selectedSource && source.source === this.state.selectedSource.source);
        var short = source.source.length > 13 ? source.source.substring(0, 10) + '…' : source.source;
        var row1= (<tr data-source={source.source} className={source.source + 'source sourcerow'} key={'m'+source.key}
                                data-cost={source.cost} data-displaycost={source.dcost} data-msrp={source.price}>
                    <td className='mainpricecol'><input type='radio' name='mainprice' value={source.source} checked={sel} onChange={()=> {this.selectPriceSource(source);}}/></td>
                    <td className='imgcol' title='Catalog Server Source'><img src='img/svgs/v1.0/Tile_Logs.svg' alt='catalog' width='24px' height='24px'/></td>
                    <td className='pricecol'><span className='etilizeprice'>{source.dprice}</span></td>
                    <td className='qtycol'><span className='etilizeqty'>{source.qty} / {source.backorder}</span></td>
                    <td className='namecol' title={'Catalog Server: ' + source.source}>{'QC:'+short}</td>
                </tr>);

        var row2 = (<tr key={'s'+source.key}>
                        <td className='mainpricecol' />
                        <td className='imgcol' />
                        <td className='pricecol' />
                        <td className='qtycol' />
                        <td className='namecol' title={'Catalog Manufacturer: '+source.manufacturer}>{source.manufacturer}</td>
                    </tr>);
        rows.push(row1);
        rows.push(row2);
    }

    warehouseChanged(src, wh) {
        this.setState(function(previousState) {
            var warehouses = previousState.selectedWH;
            warehouses[src.source] = wh;
            if (this.props.sourceChanged && previousState.selectedSource !== src) {
                this.props.sourceChanged(src, this.state.selectedWH, src.price);
            }
            return {selectedSource: src, selectedWH: warehouses};
          });          
    }
   
    renderWarehouseSingleRow(source, iSrc, wh, iWh) {
        var sel = (this.state.selectedWH[source.source] && this.state.selectedWH[source.source].WhseCode === wh.WhseCode);
        return (<tr className='whrow' key={'wh'+source.key+'~'+iSrc+'~'+iWh} >
                <td className='whstdcol'><input id={source.source+'-'+wh.WhseCode} type='radio' checked={sel} name={source.source} value={wh.WhseCode} onChange={(e)=> {this.warehouseChanged(source, wh, e);}} /></td>
                <td className='whstdcol'><label htmlFor={source.source+'-'+wh.WhseCode}>{wh.IDCode}</label></td>
                <td className='whqtycol'>{wh.Qty} / {wh.OnOrderQty}</td>
            </tr>
        );
    }

    renderWarehouseRows(source, index) {
        var wList = [];
        for (var w=0; w<source.warehouses.length;w++) {
            wList.push(this.renderWarehouseSingleRow(source, index, source.warehouses[w], w));
        }
        return wList;
    }

    cloudClick(src){
        if (this.state.expandwh !== src.source) {
            this.setState({expandwh: src.source});
        }
        else {
            this.setState({expandwh: ''});
        }
    }

    renderStandardRow(source, index) {
        var sel = (this.state.selectedSource && source.source === this.state.selectedSource.source);
        var row = (<tr className='sourcerow' data-pricesource={source.source} key={'r'+source.key+'-'+index}>
                            <td className='mainpricecol'><input type='radio' name='mainprice' value={source.source} checked={sel} onChange={()=>this.selectPriceSource(source)} /></td>
                            <td className='imgcol' title='Real Time Source'><img src='img/svgs/v1.0/Cloud.svg' alt='cloudsourcing' width='24px' height='24px'
                                            onClick={()=>{ this.cloudClick(source); }} /></td>
                            <td className='pricecol'><span className='etilizeprice'>{source.dprice}</span></td>
                            <td className='qtycol'><span className='etilizeqty'>{source.qty} / {source.backorder}</span></td>
                            <td className='namecol' title={'Real Time: ' + source.source}>{source.source}</td>
                        </tr>);
        return row;
    }
    
    renderWarehouseListing(source, list, index) {
        var classinfo = (source.source === this.state.expandwh) ? 'etilizePageWarehouses' : 'etilizePageWarehouses hidden';

        return (<tr className={classinfo} key={'whlist' + index}>
                <td colSpan='5'>
                    <table className='warehousetable' cellPadding={0} cellSpacing={0}>
                       <tbody>{list}</tbody>
                    </table>
                </td>
            </tr>
        );
    }

    render() {
        var pendingSearches = [];
        var priceInfo = [];

        if (this.state.searching) {
            for (let i = 0; i < this.state.searchingSources.length; i++) {
                pendingSearches.push(<div className={'searching ' + this.state.searchingSources[i]}><Spinner /><div className='sourcename'>Searching {this.state.searchingSources[i]}...</div></div>);
            }
        }
        if (this.state.priceinfo.length > 0) {
            var rows = [];
            for (var i = 0; i < this.state.priceinfo.length; i++) {
                var source = this.state.priceinfo[i];
                if (source.iscatalog === true) {
                    this.renderAndAddCatalogRow(source, rows);
                }
                else {
                    rows.push(this.renderStandardRow(source, i));
                    var wList = this.renderWarehouseRows(source, i);
                    if (wList.length > 0) {
                        rows.push(this.renderWarehouseListing(source, wList, i));
                    }
                }
            }
            priceInfo = <table id='sourcestable' cellPadding={0} cellSpacing={0}><tbody>{rows}</tbody></table>;
        }
        this.priceUpdated = false;
        return (<div className='panel'>
            <div className='title' id="EtilizeProductDetailsId">Price Sources</div>
            <div className='content'>
                <div id="pricing">
                    <div className="list">{priceInfo}</div>
                    <div className="pending">{pendingSearches}</div>
                </div>
            </div>
        </div>);
    }
}

/* ################################################################################################## */
quosal.etilizeHelper = {
    getSourceList(config) {
        var sources = [];
        if (config.UseJenne) {
            sources.push('Jenne');
        }
        if (config.UseSynnex) {
            sources.push('TD SYNNEX');
        }
        if (config.UseIngram) {
            sources.push('Ingram Micro');
        }
        if (config.UseDNH) {
            sources.push('D&H');
        }
        if (config.UseAccutech) {
            sources.push('Accutech Data');
        }
        if (config.UseArbitech) {
            sources.push('Arbitech');
        }
        if (config.UseTechDataUK) {
            sources.push('Tech Data UK');
        }
        if (config.UseWestcoast) {
            sources.push('Westcoast');
        }
        if (config.UseIngramSAP) {
            sources.push('Ingram Micro SAP');
        }
        if (config.UseScansource) {
            sources.push('Scansource');
        }
        if (config.UseBluestar) {
            sources.push('BlueStar');
        }
        if (config.UseDigitek) {
            sources.push('Digitek');
        }
        if (config.UseArrow) {
            sources.push('Arrow');
        }
        if (config.UseADI) {
            sources.push('ADI');
        }
        if (config.ShowCatalogSource) {
            sources.push('Catalog');
        }
        return sources;
    },

    toSourceKey(s) {
        s = s.toLowerCase();
        if (s === 'ingram micro' || s === 'ingram micro usa') {
            s = 'ingram';
        }
        if (s === 'ingram micro impulse') {
            s = 'ingram_impulse';
        }
        if (s === 'ingram micro sap') {
            s = 'ingram_sap';
        }
        if (s === 'accutech data') {
            s = 'accutech';
        }
        if (s === 'tech data uk') {
            s = 'techdatauk';
        }
        if (s === 'td synnex') {
            s = 'synnex';
        }
        // all others are single word as displayed
        return s.replace(/\s/g, '');
    },
	
    skuTypesBySource(config, source) {
        var types = [];
        switch (source.toLowerCase()) {
            case 'jenne':
                types = [config.EtilizeJenneKey];
                break;
            case 'accutech':
            case 'accutech data':
                types = [config.EtilizeAccutechKey];
                break;
            case 'adi':
                types = [config.EtilizeADIey];
                break;
            case 'arbitech':
                types = [config.EtilizeArbitechKey];
                break;
            case 'arrow':
                types = [config.EtilizeArrowKey];
                break;
            case 'scansource':
                types = [config.EtilizeScansourceKey];
                break;
            case 'ingram':
            case 'ingram micro':
            case 'ingram micro usa':
            case 'ingram micro uk':
                types = [config.EtilizeIngramKey, config.EtilizeIngramUKKey];
                break;
            case 'ingram micro sap':
                types = [config.EtilizeIngramSapKey];
                break;
            case 'tech data europe':
            case 'tech data uk':
                types = [config.EtilizeTechDataUKKey];
                break;
            case 'synnex':
                types = [config.EtilizeSynnexKey];
                break;
            case 'd&h':
                types = [config.EtilizeDnHKey];
                break;
            default:
                types = [source];
        }
           
        return types;
    },

    productSkuBySource(config, skus, source) {
        var skuTypes = quosal.etilizeHelper.skuTypesBySource(config, source);
        if (skus != undefined) {
            return skus.filter(item => skuTypes.includes(item.SkuType)).map(item => item.Number);
        }
        return [];
    },

    priceHeaders(config) {
        var stdprice = 'etilizeresultcol etilizepricecol';
        var head = [];
        if (config.UseJenne) {          
            head.push(<td className={stdprice} key='p01'>Jenne</td>);     
        }
        if (config.UseSynnex) {         
            head.push(<td className={stdprice} key='p02'>TD SYNNEX</td>);     
        }
        if (config.UseIngram) {         
            head.push(<td className={stdprice} key='p03'>Ingram Micro</td>);     
        }
        if (config.UseDNH) {            
            head.push(<td className={stdprice} key='p04'>D&amp;H</td>);     
        }
        if (config.UseAccutech) {       
            head.push(<td className={stdprice} key='p05'>Accu-Tech</td>);     
        }
        if (config.UseArbitech) {       
            head.push(<td className={stdprice} key='p06'>Arbitech</td>);     
        }
        if (config.UseTechDataUK) {     
            head.push(<td className={stdprice} key='p07'>Tech Data UK</td>);     
        }
        if (config.UseWestcoast) {      
            head.push(<td className={stdprice} key='p08'>WestCoast</td>);     
        }
        if (config.UseIngramSAP) {      
            head.push(<td className={stdprice} key='p09'>Ingram Micro SAP</td>);     
        }
        if (config.UseScansource) {     
            head.push(<td className={stdprice} key='p10'>Scansource</td>); 
        }
        if (config.UseBluestar) {       
            head.push(<td className={stdprice} key='p11'>BlueStar</td>);     
        }
        if (config.UseDigitek) { 
            head.push(<td className={stdprice} key='p12'>Arlington</td>); 
        }
        if (config.UseArrow) { 
            head.push(<td className={stdprice} key='p13'>Arrow</td>); 
        }
        if (config.UseADI) {
            head.push(<td className={stdprice} key='p14'>ADI</td>)
        }
        if (config.ShowCatalogSource) { 
            head.push(<td className={stdprice} key='p15'>Catalog</td>);     
        }
        return head;
    },

    skuCode(str) {
        if (str.ciEquals('ingram') || str.ciEquals('ingram micro') || str.ciEquals('Ingram Micro Impulse')) {
            return 'Ingram Micro USA';
        }
        if (str.ciEquals('d&h') || str.ciEquals('dnh')){
            return 'D&H Distributing Co., Inc';
        }
        if (str.ciEquals('Tech Data UK')){
            return 'Tech Data UK';
        }
        return str;
    }
};

