import { Box, IconButton } from "@mui/material";
import { CommonBreadCrumbs } from "../../common/guidedSelling/components/commonBreadCrumbs.jsx";
import { CommonButton } from "../../common/guidedSelling/styledComponents/commonButton.js";
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SalesTrackForm from "./salesTrackForm.jsx";
import { SalestrackFormLinkToFormStyled } from "./styledComponents/salesTrackStyled.js";

export class EditSalesTrack extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDirty: false,
        };
        this.formRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        this.setState({ ...this.state, isDirty: false });
    }
    
    render() {
        const { onCancel, onDelete, salesTracksItem, createSalesTrack, linkToForm } = this.props;
        const breadCrumbsData = {
            actualBreadCrumbName: "Sales Track Details",
            breadCrumbs: [
                {
                    name: 'Sales Tracks',
                    navigate: onCancel,
                }
            ],
        }

        return (
            <Box className="createsalestrack-container">                
                <CommonBreadCrumbs {...breadCrumbsData} />
                <Box className='createsalestrack-header'>
                    <h2 className="salesTrack-title">Sales Tracks Details</h2>
                    <Buttons 
                        onCancel={onCancel} 
                        onSave={() => this.formRef.current.requestSubmit()} 
                        onDelete={() => onDelete(salesTracksItem)}
                        linkToForm={linkToForm}
                        disabled={!this.state.isDirty} 
                    />
                </Box>
                <SalesTrackForm
                    ref={this.formRef}
                    salesTracksItem={salesTracksItem}
                    createSalesTrack={createSalesTrack}
                    onSubmit={this.onSubmit}
                    onChange={({ isDirty }) => {if (this.state.isDirty !== isDirty) { this.setState({ ...this.state, isDirty }) }}} />
            </Box>
        )
    }
}

class LinkToForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const handleLink = (e) => {
            e.preventDefault();
            this.props.linkToForm();
        }
        return (
            <SalestrackFormLinkToFormStyled>
                <div className='linktoform'>
                        <Box className="salestrack-card-header">Link</Box>
                        <a className="linktoform-link" href='#' onClick={handleLink}>
                            <span>Access to the form</span>
                            <img src="img/svgs/sell/Arrow_Forward.svg" alt="arrow-forward" />
                        </a>
                </div>
            </SalestrackFormLinkToFormStyled>
        );
    }
}

class Buttons extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {onCancel, onSave, onDelete, linkToForm, disabled} = this.props;
        const handleLink = (e) => {
            e.preventDefault();
            linkToForm();
        }

        return (
            <Box className="salestrack-buttons-bar">                
                <IconButton aria-label="delete" color="error" size="small" id='edit-salestrack-delete-button' onClick={onDelete}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
                <CommonButton id='edit-salestrack-access-to-form-button' variant="ghost" onClick={handleLink}>Access To The Form</CommonButton>
                <CommonButton id='edit-salestrack-cancel-button' variant="outlined" onClick={onCancel}>Cancel</CommonButton>
                <CommonButton id='edit-salestrack-save-button' variant="contained" disabled={disabled} onClick={onSave}>Save</CommonButton>
            </Box>
        )
    }
}