import { Box, FormControl, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Dialog } from "js/jsx/src/classes/dialog.jsx";
import { CommonInputTextStyled } from '../../common/guidedSelling/styledComponents/commonStyled.js';
import { 
    SalestrackCardStyled, SalestrackFormCardImageStyled, SalestrackFormCardPreviewImageStyled, 
    SalestrackFormCharacterCounterStyled, SalestrackFormFilePreviewStyled, SalestrackFormMainLabelStyled, 
    SalestrackFormRadioStyled } from './styledComponents/salesTrackStyled.js';
import { api } from '../../common/guidedSelling/api/api.js';
import { Dropdown, StyledOption } from '../../common/guidedSelling/components';

class SalesTrackForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			salesTrack: {
				id: "",
				name: "",
				state: true,
				description: "",
				notes: "",
				image: "",
				quoteTemplateId: ""
			},
			salesTracksItem: {},
			errors: {},
			dimensions: {
				height: "",
				width: ""
			},
			pictureLink: "",
			previewImage: "",
			selectedImageName: "",
			dragActive: false,
			quoteTemplates: [],
			isLoading: false,
			quoteTemplate: null
		};

        this.validFileText = `Max file size is ${app.settings.global.MaxMBSizeForImageUpload}MB. Supported file types are .jpg, and .png`;

        //Bind this
        this.setStateValue = this.setStateValue.bind(this);
        this.getTextCount = this.getTextCount.bind(this);
        this.formStateHandler = this.formStateHandler.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.validateFile = this.validateFile.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
        this.onImgLoad = this.onImgLoad.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.onError = this.onError.bind(this);
        this.initState = this.initState.bind(this);
        this.getQuoteTemplates = this.getQuoteTemplates.bind(this);
        this.handleSelectTemplateChange = this.handleSelectTemplateChange.bind(this);
    }

    initState(salesTrack) {
        if (!salesTrack) { return; }

        const { pictureLink } = salesTrack;

        this.setState({
            ...this.state,
            salesTracksItem: salesTrack, 
            salesTrack: { ...salesTrack, picture: pictureLink },
            previewImage: pictureLink,
            pictureLink,
        });
    }

    componentDidMount() {
		this.initState(this.props.salesTracksItem);
	    this.getQuoteTemplates();
    }

    componentDidUpdate() {
        const getCompareObject = ({ name, description, state, notes, quoteTemplateId }) => ({
			name,
			description,
			state,
			notes,
			quoteTemplateId
		});
        const isItemDirty = JSON.stringify(getCompareObject(this.state.salesTracksItem)) !== JSON.stringify(getCompareObject(this.state.salesTrack));
        const isImageDirty = this.state.pictureLink !== this.state.previewImage;
        const { onChange } = this.props;

        onChange && onChange({isDirty: isItemDirty || isImageDirty});
    }

    setStateValue = function (state) {
        const salesTrack = { ...this.state.salesTrack, state }
        this.setState({ ...this.state, salesTrack });
    }

    formStateHandler = (e) => {
        const { name, value } = e.target;
        this.setState({
            salesTrack: {
                ...this.state.salesTrack,
                [name]: value
            }
        });
    }

    getTextCount = function (text) {
        return text ? text.length : 0;
    }

    handleRemoveFile = function () {
        this.setState({ 
            ...this.state, 
            salesTrack: { ...this.state.salesTrack, picture: '' }, 
            previewImage: '', 
            selectedImageName: '' 
        });
    }

    onError = function (title, message) {
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }

    validateFile = function (file) {
        if (!!file) {
            const allowedExtensions = /(\.jpg|\.png)$/i;

            if (!allowedExtensions.exec(file.name) || (file.size / 1048576) > app.settings.global.MaxMBSizeForImageUpload) {
                Dialog.open({
                    title: "Image not valid",
                    message: this.validFileText,
                    links: [Dialog.links.ok]
                });
            } else {
                var formData = new FormData();
                formData.append('file', file);

                $.ajax('uploadtemporaryimage.quosalweb' + location.search + '&noheader=yes', {
                    type: 'post',
                    data: formData,
                    dataType: 'json',
                    processData: false,
                    contentType: 'multipart/form-data',
                    mimeType: 'multipart/form-data',
                    success: function (data) {
                        if (data.tempImage) {
                            this.setState({ 
                                ...this.state, 
                                salesTrack: { ...this.state.salesTrack, picture: data.tempImage }, 
                                previewImage: data.tempImage, 
                                selectedImageName: file.name 
                            });
                        } else if (data.error) {
                            this.onError("Error uploading image", data.error);
                        }
                    }.bind(this)
                });
            }
        }
    }

    saveImage = function (id) {
        const { onSubmit, onCreateForm, updateSalesTracks } = this.props;
        const { previewImage, salesTrack } = this.state;
        const { pictureLink, picture } = salesTrack;

        if (previewImage === pictureLink || !picture) {
            onCreateForm && onCreateForm();
            onSubmit && onSubmit();
            this.initState({ ...salesTrack, pictureLink: previewImage });
            updateSalesTracks && updateSalesTracks();
            return;
        }

        const imgProps = {
            "x1": 0,
            "x2": this.state.dimensions.width,
            "y1": 0,
            "y2": this.state.dimensions.height,
            "w": this.state.dimensions.width,
            "h": this.state.dimensions.height,
            "scale": 1,
            "wOriginal": this.state.dimensions.width,
            "hOriginal": this.state.dimensions.height,
            "imageId": "SalesTrackPicture",
            "newlyUploadedImage": this.state.previewImage,
            "imageUrlFromInternet": null,
            "salesTrackId": id
        }
        
        var imageSaveApi = quosal.api.image.saveImage(imgProps);
        imageSaveApi.finished = function (msg) {
            if (msg) {
                onCreateForm && onCreateForm();
                onSubmit && onSubmit(salesTrack);
                this.initState({ ...salesTrack, pictureLink: previewImage });
                updateSalesTracks && updateSalesTracks();
            }
            if (msg.error) {
                this.onError("Error saving image", msg.error);
            }
        }.bind(this);

        imageSaveApi.call();

    }

    onImgLoad = function ({ target: img }) {
        this.setState({
            dimensions: {
                height: img.offsetHeight,
                width: img.offsetWidth
            }
        });
    }

    handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            this.setState({ ...this.state, dragActive: true });
        } else if (e.type === "dragleave") {
            this.setState({ ...this.state, dragActive: false });
        }
    };

    handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ ...this.state, dragActive: false });
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            this.validateFile(e.dataTransfer.files[0])
        }
    };

    handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            this.validateFile(e.target.files[0])
        }
    };

    validateFields = function () {
        let errors = {};
        const { name, description } = this.state.salesTrack;

        if (!name) {
            errors = { ...errors, name: ["Name is required"] };
        }

        this.setState({ ...this.state, errors });
        return Object.keys(errors).length === 0;
    }

    getQuoteTemplates =  function () {
        this.setState({
			isLoading: true
		});
		api.getAllQuoteTemplates((msg) => {
			const quoteTemplates =
				msg?.quoteTemplateList?.length > 0
					? msg.quoteTemplateList.map((rawTemplate) => {
							const parsedTemplate = JSON.parse(rawTemplate);
							return {
								templateName: parsedTemplate.name,
								templateId: parsedTemplate.idQuoteMain
							};
					  })
					: [];
			const salesTrack = this.props.salesTracksItem;
			const quoteTemplate =
				quoteTemplates.length > 0 && salesTrack?.quoteTemplateId
					? quoteTemplates.find(
							(template) => template.templateId === salesTrack.quoteTemplateId
					  )
					: null;
			this.setState({
				isLoading: false,
				quoteTemplates,
				quoteTemplate
			});
		});
    }

    handleSelectTemplateChange = function (selectedTemplate) {
        this.setState({
			quoteTemplate: selectedTemplate,
			salesTrack: {
				...this.state.salesTrack,
				quoteTemplateId: selectedTemplate ? selectedTemplate.templateId : ""
			}
		});
    }

    render() {
        const { salesTrack, quoteTemplates, isLoading, quoteTemplate } = this.state;
        const { name, state, description, notes } = salesTrack;
        const { forwardedRef, createSalesTrack } = this.props;

        const handleSubmit = (event) => {
            event.preventDefault();
            if (!this.validateFields()) {
                return;
            }

            createSalesTrack && createSalesTrack(salesTrack, this.saveImage);
        };

        return (
			<Box>
				<form id="salestrack-form" ref={forwardedRef} onSubmit={handleSubmit}>
					<Box className="salestrack-form-panel">
						<Box
							className="salestrack-form-container"
							style={{ flex: 10, height: "fit-content" }}
						>
							<SalestrackCardStyled sx={{ flex: 1, width: "unset" }}>
								<SalestrackFormMainLabelStyled>{`${
									!salesTrack.id ? "Add " : ""
								}Details`}</SalestrackFormMainLabelStyled>
								<Grid container>
									<Grid item xs={12} mt="5px">
										<CommonInputTextStyled
											label="Sales Track"
											placeholder="Add name"
											name="name"
											value={name}
											onChange={this.formStateHandler}
											error={this.state.errors.name}
											helperText={this.state.errors.name}
											inputProps={{ maxLength: 50 }}
										/>
									</Grid>
									<Grid item xs={12} mt="20px">
										<FormControl>
											<FormLabel className="salestrack-form-status-label">
												Status
											</FormLabel>
											<RadioGroup
												row
												className="salestrack-form-status-options-label"
											>
												<SalestrackFormRadioStyled
													control={
														<Radio
															id="salestrack-active-radio-button"
															name="state"
															checked={state}
															onChange={() =>
																this.setStateValue(true)
															}
														/>
													}
													label="Active"
												/>
												<SalestrackFormRadioStyled
													control={
														<Radio
															id="salestrack-inactive-radio-button"
															name="state"
															checked={!state}
															onChange={() =>
																this.setStateValue(false)
															}
														/>
													}
													label="Inactive"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12} mt="20px" sx={{ position: "relative" }}>
										<SalestrackFormCharacterCounterStyled>
											{this.getTextCount(description)}/600
										</SalestrackFormCharacterCounterStyled>
										<CommonInputTextStyled
											label="Description"
											placeholder="Add description"
											name="description"
											value={description}
											onChange={this.formStateHandler}
											inputProps={{ maxLength: 600 }}
											multiline
											minRows={4}
										/>
									</Grid>
									<Grid item xs={12} mt="25px" sx={{ position: "relative" }}>
										<SalestrackFormCharacterCounterStyled>
											{this.getTextCount(notes)}/200
										</SalestrackFormCharacterCounterStyled>
										<CommonInputTextStyled
											label="Add note"
											placeholder="Add note"
											name="notes"
											value={notes}
											onChange={this.formStateHandler}
											inputProps={{ maxLength: 200 }}
											multiline
											minRows={2}
										/>
									</Grid>
								</Grid>
							</SalestrackCardStyled>
							<SalestrackCardStyled
								sx={{ flex: 1, width: "unset", marginTop: "20px" }}
							>
								<SalestrackFormMainLabelStyled>
									Add to Quote Template
								</SalestrackFormMainLabelStyled>
								<Grid container sx={{ marginTop: "8px" }}>
									<Dropdown
										name="quoteTemplate"
										label="Select Template"
										placeholder="Choose template"
										size="large"
										sx={{ width: "100%" }}
										skeleton={isLoading}
										options={quoteTemplates}
										value={quoteTemplate}
										onChange={this.handleSelectTemplateChange}
										getOptionLabel={(option) => option.templateName}
										renderOption={(props, option) => (
											<StyledOption {...props} key={option.templateId}>
												{option.templateName}
											</StyledOption>
										)}
									/>
								</Grid>
							</SalestrackCardStyled>
						</Box>
						<Box
							className="salestrack-form-container"
							style={{ flex: 0.2, height: "fit-content" }}
						>
							<SalestrackFormCardPreviewImageStyled>
								<img
									onLoad={this.onImgLoad}
									src={this.state.previewImage || "images/empty.png"}
									alt="Salestrack image preview"
									className="salestrack-preview-image"
								/>
							</SalestrackFormCardPreviewImageStyled>
							<SalestrackFormCardImageStyled>
								<Box className="salestrack-card-header">
									{!!this.props.onEditForm ? "Edit Image" : "Add Image"}
								</Box>
								<label htmlFor="image">{this.validFileText}</label>
								<Box
									className="salestrack-file-upload"
									onDragEnter={this.handleDrag}
								>
									{!!this.state.previewImage ? (
										<SalestrackFormFilePreviewStyled
											onClick={this.handleRemoveFile}
										>
											<p className="salestrack-file-label">
												{!!this.state.selectedImageName
													? this.state.selectedImageName
													: "Current image"}
											</p>
											<img
												src="img/svgs/v1.0/Action_Close.svg"
												alt="menu"
												className="salestrackitem-menuicon"
												onClick={() => {}}
											/>
										</SalestrackFormFilePreviewStyled>
									) : (
										<>
											<input
												type="file"
												className="input-file-upload"
												id="input-file-upload"
												multiple={false}
												onChange={this.handleChange}
											/>
											<label
												id="label-file-upload"
												htmlFor="input-file-upload"
												className={
													this.state.dragActive ? "drag-active" : ""
												}
											>
												<Box>
													<p>
														Drag and drop files here or click to upload
													</p>
												</Box>
											</label>
											{this.state.dragActive && (
												<Box
													id="drag-file-element"
													className="drag-file-element"
													onDragEnter={this.handleDrag}
													onDragLeave={this.handleDrag}
													onDragOver={this.handleDrag}
													onDrop={this.handleDrop}
												></Box>
											)}
										</>
									)}
								</Box>
							</SalestrackFormCardImageStyled>
							{!!this.props.onEditForm && <>{this.props.onEditForm()}</>}
						</Box>
					</Box>
				</form>
			</Box>
		);
    }
}

export default React.forwardRef(function(props, ref) {
    return <SalesTrackForm {...props} forwardedRef={ref} />
})
