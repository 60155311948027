import { COMMON_GRID_PADDING, DIVIDER_STYLE, SECTION_NAME_CHARACTER_LIMIT } from "../../constants/createFormConstants";
import { Grid, Tooltip } from '@mui/material'
import { CommonInputTextStyled, DeletButtonStyled } from "../../../../common/guidedSelling/styledComponents/commonStyled";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { QuestionLayout } from "./questionLayout";
import { QuestionButtonStyled, SectionGridStyled } from "../../styledComponents/createFormStyled";
import AddIcon from '@mui/icons-material/Add';

export class SectionLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        const { sectionIndex, section: { name, questions }, formActions, onDelete, sections, isSendable, isEdited, theme } = this.props;

        return <SectionGridStyled container pb={COMMON_GRID_PADDING}>
            <Grid container item xs={12} direction="row" justifyContent="flex-end" sx={{ padding: '0', height: 'fit-content', }}>
                <QuestionButtonStyled id={`createform-add-question-button`} disabled={!isEdited} endIcon={<AddIcon />} onClick={() => formActions['add-question'](sectionIndex)} sx={{ color: theme.palette.button_primary.main }}>Add Question</QuestionButtonStyled>
                <QuestionButtonStyled id={`createform-delete-section-button`} disabled={isEdited ? !(sections.length > 1) : !isEdited} endIcon={<DeleteOutlineOutlinedIcon />} onClick={() => onDelete('Section', () => formActions['remove-section'](sectionIndex))} sx={{ color: theme.palette.button_danger.secondary }}>Delete Section</QuestionButtonStyled>
            </Grid>
            <Grid item xs={12} p={COMMON_GRID_PADDING} sx={{ borderBottom: DIVIDER_STYLE }}>
                <CommonInputTextStyled id={`section-name-input-section-${sectionIndex}`} inputProps={{ maxLength: SECTION_NAME_CHARACTER_LIMIT }} name='section-name' label="Section Name" placeholder='Section Name' value={name} onChange={(event) => formActions[event.target.name](event.target.value, sectionIndex)} />
            </Grid>
            {
                questions.map((question, questionIndex) => <QuestionLayout theme={theme} isSendable={isSendable} questions={questions} onDelete={onDelete} key={'form-section-' + sectionIndex + '-question-' + questionIndex} question={question} questionIndex={questionIndex} sectionIndex={sectionIndex} formActions={formActions} />)
            }
        </SectionGridStyled>
    }
}