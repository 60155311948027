import React, { memo } from "react";

import { Add, DragIndicator, ExpandLess, ExpandMore, Tune } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TaskAltOutlined from "@mui/icons-material/TaskAltOutlined";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import {
	Box,
	Checkbox,
	CircularProgress,
	Collapse,
	FormControlLabel,
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	Tooltip,
	styled
} from "@mui/material";
import { useAppGlobalContext } from "../context/globalContext";
import useDraggableExpandableContainer from "./draggableExpandableContainer";
import useStyles from "./styles";

const StyledContent = styled(Box)(({ theme }) => ({
	marginTop: 0,
	padding: theme.spacing(2),
	backgroundColor: theme.palette.background.default,
	borderRadius: theme.shape.borderRadius,
	textOverflow: "ellipsis",
	overflow: "hidden"
}));

function DraggableExpandable({
	id,
	tabItem,
	onRemove,
	onMove,
	open,
	duplicated,
	updateOpenTab,
	setDuplicated,
	setChange,
	setData,
	content,
	expanded,
	openCustomizeGrid,
	setLoading
}) {

	const {
		contentGrid,
		disabledAddNewSection,
		gridRows,
		anchorEl,
		sheetAnchorEl,
		renameTab,
		tabName,
		setTabName,
		isSpreadsheetRefreshing,
		isOptional,
		isPrinted,
		isTotalsIncluded,
		isTabSelected,
		orderPorterRequired,
		isStandardUser,
		isStandardUserPlus,
		showShortMenu,
		listClasses,
		textInput,
		drop,
		isActive,
		isDragging,
		drag,
		handleClick,
		handleMenuClick,
		handleSelectedTabIds,
		handleMenuClose,
		handleSheetMenuClick,
		handleSheetMenuClose,
		duplicateTab,
		duplicateTabAsRadioOption,
		optionGroupLabel,
		tabGroupLabel,
		handleCheckBoxClick,
		handleRenameTab,
		handleKeyUp,
		handleOnBlur,
		removeZeroQtyItems,
		handleEditTab,
		darkMode,
		updateSpreadsheet,
		handleUpdateAllCosts,
		handleSelectNewTab,
		scrollToSelectableTab,
		isTabDroppedIntoEditor
	} = useDraggableExpandableContainer(
		id,
		tabItem,
		onMove,
		open,
		duplicated,
		updateOpenTab,
		setDuplicated,
		setChange,
		setData,
		setLoading
	);

	const { isDisabledQuoteActions, selectedTabs, isQuoteTemplate} = useAppGlobalContext();

	const isMenuItemDisabled = disabledAddNewSection || isDisabledQuoteActions



	const {
		listItemTextStyle,
		dragIndicatorStyle,
		textFieldStyle,
		menuCheckboxStyle,
		elementsColor
	} = useStyles();

	const listItemTextMarkup = disabledAddNewSection ? (
		<ListItemText
			sx={{ color: "GrayText" }}
			primary={tabName}
			primaryTypographyProps={{
				...listItemTextStyle
			}}
		/>
	) : (
		<ListItemText
			sx={{
				fontStyle: isOptional ? "italic" : "normal",
				cursor: isTabDroppedIntoEditor() ? "pointer" : "auto",
				color: darkMode ? "white" : undefined,
			}}
			onClick={(e) => scrollToSelectableTab(e, tabItem?.IdQuoteTabs)}
			primaryTypographyProps={{
				...listItemTextStyle
			}}
			primary={
				<span>
					{tabName} {tabGroupLabel} {optionGroupLabel}
				</span>
			}
		/>
	);

	const dragAndDropcheckboxStyle =
		tabItem?.TabName === "Term Options"
			? { display: "none" }
			: {
					"&.Mui-checked": {
						color: "#2E3F80"
					},
					padding: "0px"
			  };

	
	const handleFewTabsDragContext = (node) => {
		const selectedTabIds = selectedTabs.map((tab) => tab.tabId);

		if (selectedTabIds !== null && selectedTabIds.length > 1) {
			return drag(drop(node));
		}

		return null;
	};


	return (
		<Box>
			<ListItem
				className={listClasses}
				ref={(node) => drag(drop(node))}
				style={{
					opacity: isDragging || isActive ? 0.5 : 1,
					textOverflow: "ellipsis",
					overflow: "hidden",
					marginBottom:  open === id ? 0 : undefined,
					borderRadius: open === id ? "10px 10px 0 0" : undefined,
				}}
			>
				<Box display="flex" alignItems="center" width="100%">
					{!isQuoteTemplate && isTabDroppedIntoEditor() ?
						<TaskAltOutlined
						sx={{
							color: "#4CAF50",
							marginRight: "0px",
							marginLeft: "-3px"
						}}
					/> :	
					(isTabDroppedIntoEditor() && isTabSelected) ? (
						<TaskAltOutlined
							onClick={() => handleSelectedTabIds(isTabSelected)}
							sx={{
								color: "#4CAF50",
								marginRight: "0px",
								marginLeft: "-3px"
							}}
						/>
					) : (
						<FormControlLabel
							control={
								<Checkbox
									checked={isTabSelected}
									onChange={() => handleSelectedTabIds(isTabSelected)}
									sx={{ ...dragAndDropcheckboxStyle }}
									disabled={disabledAddNewSection}
								/>
							}
							sx={{
								marginRight: "0px",
								marginLeft: "-3px"
							}}
						/>
					)}
					<IconButton
						size="small"
						ref={(node) => handleFewTabsDragContext(node)}
						edge="start"
						onClick={handleClick}
						disabled={disabledAddNewSection}
						style={{ ...dragIndicatorStyle, opacity: isDragging || isActive ? 0.5 : 1 }}
					>
						<DragIndicator />
					</IconButton>
					{(duplicated || renameTab) && open === id ? (
						<TextField
							draggable
							onDragStart={(event) => event.preventDefault()}
							inputRef={textInput}
							onBlur={handleOnBlur}
							label="Name"
							onKeyUp={handleKeyUp}
							variant="standard"
							value={tabName}
							onChange={(e) => setTabName(e.target.value)}
							InputLabelProps={{ style: elementsColor }}
							sx={{ ...textFieldStyle }}
						/>
					) : (
						listItemTextMarkup
					)}
					<div className="tabActions">
						<ListItemSecondaryAction sx={{ display: "flex" }}>
							{tabItem?.HasSpreadsheet ? (
								<div className="spreadsheetMenu">
									<IconButton
										size="small"
										edge="end"
										disabled={disabledAddNewSection}
										onClick={handleSheetMenuClick}
									>
										<WindowOutlinedIcon />
									</IconButton>
									<Menu
										id="action-menu"
										anchorEl={sheetAnchorEl}
										keepMounted
										open={Boolean(sheetAnchorEl)}
										onClose={handleSheetMenuClose}
										disableScrollLock
										anchorOrigin={{
											vertical: "bottom",
											horizontal: !expanded ? "right" : "left"
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: !expanded ? "left" : "right"
										}}
									>
										<MenuItem onClick={() => contentGrid.launchSpreadsheet(id)}>
											Launch Spreadsheet
										</MenuItem>
										{!isSpreadsheetRefreshing ? (
											<MenuItem onClick={updateSpreadsheet}>Refresh</MenuItem>
										) : (
											<MenuItem>
												<div style={{ marginRight: "5px" }}>Refreshing</div>
												<CircularProgress size={20} />
											</MenuItem>
										)}
									</Menu>
								</div>
							) : null}
							<Tooltip title="Tab Actions">
								<span>
									<IconButton
										aria-controls="action-menu"
										aria-haspopup="true"
										size="small"
										edge="end"
										onClick={handleMenuClick}
									>
										<Tune />
									</IconButton>
								</span>
							</Tooltip>
							{showShortMenu ? (
								<Menu
									id="action-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
									disableScrollLock
									anchorOrigin={{
										vertical: "bottom",
										horizontal: !expanded ? "right" : "left"
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: !expanded ? "left" : "right"
									}}
								>
									<MenuItem onClick={handleEditTab}>Tab Details</MenuItem>
									<MenuItem disabled={isMenuItemDisabled} onClick={handleMenuClose}>
										Show Line Item Quick Filter
									</MenuItem>
									<MenuItem disabled={isMenuItemDisabled} onClick={duplicateTab}>Duplicate Tab</MenuItem>
									<MenuItem disabled={isMenuItemDisabled} onClick={duplicateTabAsRadioOption}>
										Duplicate Tab as Radio Option
									</MenuItem>
								</Menu>
							) : (
								<Menu
									id="action-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
									disableScrollLock
									anchorOrigin={{
										vertical: "bottom",
										horizontal: !expanded ? "right" : "left"
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: !expanded ? "left" : "right"
									}}
								>
									<MenuItem disabled={isMenuItemDisabled} onClick={handleRenameTab}>Rename Tab</MenuItem>
									{isStandardUser || isStandardUserPlus ? null : (
										<MenuItem
											disabled={isMenuItemDisabled}
											onClick={() => {
												handleMenuClose();
												openCustomizeGrid(tabItem?.IdQuoteTabs);
											}}
										>
											Edit Grid Layout
										</MenuItem>
									)}
									<MenuItem onClick={handleEditTab}>Edit Tab</MenuItem>
									<MenuItem
										disabled={isMenuItemDisabled}
										onClick={() => {
											removeZeroQtyItems(tabItem?.IdQuoteTabs);
										}}
									>
										Remove 0 Quantity Items
									</MenuItem>
									<MenuItem
										disabled={!gridRows.length || isMenuItemDisabled}
										onClick={() => handleUpdateAllCosts(tabItem?.IdQuoteTabs)}
									>
										Update All Costs
									</MenuItem>
									<MenuItem disabled={isMenuItemDisabled}>
										<FormControlLabel
											control={
												<Checkbox
													checked={isOptional}
													onChange={() =>
														handleCheckBoxClick(
															"IsOptional",
															isOptional
														)
													}
													sx={{ ...menuCheckboxStyle }}
												/>
											}
											label="Is Deselected Options"
										/>
									</MenuItem>
									<MenuItem disabled={isMenuItemDisabled}>
										<FormControlLabel
											control={
												<Checkbox
													checked={isPrinted}
													onChange={() =>
														handleCheckBoxClick("IsPrinted", isPrinted)
													}
													sx={{ ...menuCheckboxStyle }}
												/>
											}
											label="Is Printed"
										/>
									</MenuItem>
									<MenuItem disabled={isMenuItemDisabled}>
										<FormControlLabel
											control={
												<Checkbox
													checked={isTotalsIncluded}
													onChange={() =>
														handleCheckBoxClick(
															"IsTotalsIncluded",
															isTotalsIncluded
														)
													}
													sx={{ ...menuCheckboxStyle }}
												/>
											}
											label="Is Totals Included"
										/>
									</MenuItem>
									<MenuItem disabled={isMenuItemDisabled}>
										<FormControlLabel
											control={
												<Checkbox
													checked={orderPorterRequired}
													onChange={() =>
														handleCheckBoxClick(
															"OrderPorterRequired",
															orderPorterRequired
														)
													}
													sx={{ ...menuCheckboxStyle }}
												/>
											}
											label="Option Locked"
										/>
									</MenuItem>
									{quosal.settings.getValue("canSpreadsheet") ? (
										<MenuItem onClick={() => contentGrid.launchSpreadsheet(id)}>
											Tab Spreadsheet
										</MenuItem>
									) : null}
									<MenuItem disabled={isMenuItemDisabled} onClick={duplicateTab}>Duplicate Tab</MenuItem>
									<MenuItem disabled={isMenuItemDisabled} onClick={duplicateTabAsRadioOption}>
										Duplicate Tab as Radio Option
									</MenuItem>
								</Menu>
							)}
							<IconButton
								size="small"
								edge="end"
								onClick={() => onRemove(true, id)}
								disabled={isMenuItemDisabled}
							>
								<DeleteOutlineOutlinedIcon />
							</IconButton>
							{disabledAddNewSection ? (
								<IconButton
									size="small"
									edge="end"
									color="primary"
									onClick={handleSelectNewTab}
									disabled={isDisabledQuoteActions}
									sx={{ ...elementsColor }}
								>
									<Add />
								</IconButton>
							) : (
								<IconButton size="small" edge="end" onClick={handleClick}>
									{open === id ? <ExpandLess /> : <ExpandMore />}
								</IconButton>
							)}
						</ListItemSecondaryAction>
					</div>
				</Box>
			</ListItem>
			<Collapse in={open === id} timeout="auto" unmountOnExit>
				<StyledContent>{content}</StyledContent>
			</Collapse>
		</Box>
	);
}

export default memo(DraggableExpandable);
