import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Box } from "@mui/material";

export class PriceModifierHelper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            helperDialogOpen: false,
            priceModifier: this.props.priceModifier
        }
        this.togglePricingHelper = this.togglePricingHelper.bind(this);
        this.savePriceModifier = this.savePriceModifier.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }

    handleOutsideClick(e) {
        var src = $(e.target);

        if (this.refs && this.refs.priceModRef && this.refs.priceModRef.contains(e.target) || src.hasClass('close')) {
            return;
        }
        else {
            // The dialog needs to be closed when clicked outside of it
            this.togglePricingHelper();
        }
    }

    savePriceModifier(updatedPriceModifier) {
        if (this.props.priceModifier == updatedPriceModifier) {
            return;
        }

        var fields = {};
        fields[this.props.field.FieldName] = updatedPriceModifier;
        var tableName, fieldName, fieldValue
        var fieldChangeUpdates = [];

        if (this.props.priceModifierLevel == 'Quote') {
            tableName = 'QuoteMain';
            fieldName = 'IdQuoteMain'
            fieldValue = this.props.idQuoteMain
        }
        else {
            tableName = 'QuoteItems';
            fieldName = 'IdQuoteItems'
            fieldValue = this.props.idQuoteItems
        }

        fieldChangeUpdates.push({
            fields: fields,
            queries: [{
                table: tableName,
                where: [{
                    field: fieldName,
                    operator: 'Equals',
                    value: fieldValue
                }]
            }]
        });

        var updateApi = quosal.api.data.update(fieldChangeUpdates, this.props.idQuoteMain);
        updateApi.setFlag('fieldChanged');
        updateApi.finished = function (msg, callbacks) {
            quosal.sell.quote.updateFromApiResponse(msg);
            this.setState({ priceModifier: this.props.priceModifier });
            this.props.setChanges ? this.props.setChanges() : null
        }.bind(this);
        updateApi.call();
    }
    
    togglePricingHelper(e) {
        if (this.state.helperDialogOpen == false) {
            this.setState({
                helperDialogOpen: true
            });
        document.addEventListener('click', this.handleOutsideClick);
        }
        else {
            this.setState({
                helperDialogOpen: false
            });
            document.removeEventListener('click', this.handleOutsideClick);
        }

        if (this.props.handleModalClick) {
            this.props.handleModalClick(e)
            this.props.setModalData(
                {
                    priceModifier: this.props.priceModifier,
                    itemPrice: this.props.itemPrice,
                    itemCost: this.props.itemCost,
                    tab: this.props.priceModifierLevel && this.props.priceModifierLevel == 'Quote' 
                                ? null : quosal.util.getTabByItemId(app.currentQuote, this.props.idQuoteItems),
                    itemSuggestedPrice: this.props.itemSuggestedPrice,
                    fieldName: this.props.field.FieldName,
                    priceModifierDialogSave: this.savePriceModifier,
                    togglePricingHelper: this.togglePricingHelper,
                    advancedRoundingEnabled: this.props.advancedRoundingEnabled,
                    itemBasePrice: this.props.itemBasePrice,
                    priceModifierLevel: this.props.priceModifierLevel,
                    quotePriceModifier: this.props.quotePriceModifier,
                    cellStyle: this.props.cellStyle,
                    setChanges: this.props.setChange,
                    isDisabled: this.props.isDisabled || this.props?.field?.ReadOnly,
                }
            )
        }
    }

    render() {
        const isFeatureEnabled = quosal.settings.getValue('UseEditQuoteCKEditor')

        return <div ref='priceModRef' style={{ display: 'inline' }} >
            {isFeatureEnabled
                ? <CalculateOutlinedIcon style={{ cursor: 'pointer', display: 'inline-block', verticalAlign: 'bottom', marginLeft: '5px' }}
                        onClick={this.togglePricingHelper}/>
                : <CwWidgets.CwButton icon="img/svgs/v1.0/Action_Calculate.svg" className="PriceModCalculatorIcon"
                        onClick={this.togglePricingHelper} />
            }

            { this.state.helperDialogOpen && !this.props.handleModalClick &&
                <PricingHelperDialog
                                priceModifier={this.props.priceModifier}
                                itemPrice={this.props.itemPrice}
                                itemCost={this.props.itemCost}
                                tab={this.props.priceModifierLevel && this.props.priceModifierLevel == 'Quote'
                                        ? null 
                                        : quosal.util.getTabByItemId(app.currentQuote, this.props.idQuoteItems)}
                                itemSuggestedPrice={this.props.itemSuggestedPrice}
                                fieldName={this.props.field.FieldName}
                                priceModifierDialogSave={this.savePriceModifier}
                                togglePricingHelper={this.togglePricingHelper}
                                advancedRoundingEnabled={this.props.advancedRoundingEnabled}
                                itemBasePrice={this.props.itemBasePrice}
                                priceModifierLevel={this.props.priceModifierLevel}
                                quotePriceModifier={this.props.quotePriceModifier}
                                cellStyle={this.props.cellStyle}
                                isDisabled={this.props.isDisabled || this.props?.field?.ReadOnly}
                    />
            }
        </div>
    }
}
export class PricingHelperDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading: true,
            BaseValueLabel: "Selling Price",
            BaseValue: this.props.itemPrice,
            CalculatedValue: this.props.itemPrice,
            PriceControlType: 0,
            PriceControlValueLabel: '',
            PriceControlValue: 0,
            RoundingMethod: 'Bankers',
            RoundingPrecisionValue: 2,
            IsCalculating: false,
            requireCalculate: this.props.priceModifierLevel && this.props.priceModifierLevel === 'Quote' ? false : true
        }
        this.changeRoundingMethod = this.changeRoundingMethod.bind(this);
        this.changeRoundingPrecision = this.changeRoundingPrecision.bind(this);
        this.changePriceControlType = this.changePriceControlType.bind(this);
        this.changePriceControlValue = this.changePriceControlValue.bind(this);
        this.recalculateSellingPrice = this.recalculateSellingPrice.bind(this);
        this.saveAndClosePriceModifier = this.saveAndClosePriceModifier.bind(this);
        this.updateBaseValueAndLabels = this.updateBaseValueAndLabels.bind(this);
        this.adjustPanelPosition = this.adjustPanelPosition.bind(this);
        this.parsePriceModifierText = this.parsePriceModifierText.bind(this);
    }
    componentDidMount() {
        this.parsePriceModifierText();
        this.adjustPanelPosition();
    }
    parsePriceModifierText() {
        if (this.props.priceModifierLevel && this.props.priceModifierLevel === 'Quote'
                && (this.props.priceModifier === undefined || this.props.priceModifier === '')) {
                        this.updateBaseValueAndLabels(this.state.PriceControlType, false);
                        return;
        }

        if (this.props.priceModifier == "TABDEFAULTS") {
            var priceControl = quosal.priceModifier.getPriceValues(this.props.tab, this.props.fieldName);

            this.setState({
                PriceControlType: priceControl.priceControlType,
                PriceControlValue: priceControl.discountValue,
                RoundingMethod: priceControl.roundingMethod,
                RoundingPrecisionValue: priceControl.roundingPrecision,
                CalculatedValue: this.props.itemPrice,
            }, function () {
                this.updateBaseValueAndLabels(this.state.PriceControlType, true)
            });
        } else {
            var priceModifier = this.props.priceModifier;
            if (this.props.priceModifier === 'QUOTEDEFAULTS') {
                priceModifier = this.props.quotePriceModifier;
            }
            var parseApi = quosal.api.priceModifierHelper.ParsePriceModifierText(priceModifier);
            parseApi.finished = function (msg) {
                var paramObj = JSON.parse(msg.data.modifierParams);
                this.setState({
                    PriceControlType: paramObj.ModifierControlType,
                    PriceControlValue: paramObj.PriceModifierControlValue,
                    RoundingMethod: paramObj.RoundingMethod,
                    RoundingPrecisionValue: paramObj.RoundingPrecision,
                    CalculatedValue: this.props.itemPrice,
                }, function () {
                    this.updateBaseValueAndLabels(this.state.PriceControlType, this.state.requireRecalculate)
                });
            }.bind(this);

            parseApi.call();
        }
    }
    adjustPanelPosition() {
        var scrollX = this.props.tab?.IdQuoteTabs ?
             $(`#${this.props.tab.IdQuoteTabs}`).find(".datagridbody") :
             $("#datagridbody");
        var scrollY = this.props.tab?.IdQuoteTabs ?
            $(`#${this.props.tab.IdQuoteTabs}`).find(".sidescroll-subcontainer") :
            $(".sidescroll-subcontainer");
        var style = {};
        if (scrollX && scrollX.scrollTop()) {
            style.top = $(this.refs.priceHelperPanel).position().top - scrollX.scrollTop()
        }
        if (scrollY && scrollY.scrollLeft()) {
            style.left = $(this.refs.priceHelperPanel).position().left - scrollY.scrollLeft()
        };
        this.setState({
            style: style
        });
    }

    updateBaseValueAndLabels(newPriceControlType, recalculationRequired) {
        var newBaseValueLabel, newBaseValue, newPriceControlValueLabel;
        if (newPriceControlType == 0) {
            //Normal
            newBaseValueLabel = "Selling Price",
            newBaseValue = this.props.itemSuggestedPrice,
            newPriceControlValueLabel = ""
        }
        else if (newPriceControlType == 1) {
            //TargetMargin 
            newBaseValueLabel = "Cost",
            newBaseValue = this.props.itemCost,
            newPriceControlValueLabel = "Target Margin %"
        }
        else if (newPriceControlType == 2) {
            //TargetMarkup
            newBaseValueLabel = "Cost",
            newBaseValue = this.props.itemCost,
            newPriceControlValueLabel = "Target Markup %"
        }
        else if (newPriceControlType == 3) {
            //BasePriceDiscount
            newBaseValueLabel = "Base Price",
            newBaseValue = this.props.itemBasePrice,
            newPriceControlValueLabel = "Discount %"
        }
        else if (newPriceControlType == 4) {
            //ListPriceDiscount
            newBaseValueLabel = "Suggested Price",
            newBaseValue = this.props.itemSuggestedPrice,
            newPriceControlValueLabel = "Discount %"
        }
        this.setState({
            BaseValue: newBaseValue,
            BaseValueLabel: newBaseValueLabel,
            PriceControlValueLabel: newPriceControlValueLabel,
            PriceControlType: newPriceControlType,
            Loading: false
        }, function () {
            if (recalculationRequired)
                this.recalculateSellingPrice()
        });
    }
    changeRoundingMethod(e) {
        var newValue = e.target.value;
        this.setState({
            RoundingMethod: newValue
        }, function () {
            this.recalculateSellingPrice()
        });
    }

    changeRoundingPrecision(e) {
        var newValue = e.target.value;
        this.setState({
            RoundingPrecisionValue: newValue
        }, function () {
            this.recalculateSellingPrice()
        });
    }

    changePriceControlType(e) {
        var newPriceControlType = e.target.value;
        this.updateBaseValueAndLabels(newPriceControlType, true);
    }

    changePriceControlValue(e) {
        var newValue = e.target.value;
        this.setState({
            PriceControlValue: newValue
        });
    }

    recalculateSellingPrice() {
        if (!this.state.requireCalculate) {
            return;
        }
        var priceModifierParam = {
            ModifierControlType: this.state.PriceControlType,
            PriceModifierControlValue: this.state.PriceControlValue ? this.state.PriceControlValue : 0,
            RoundingMethod: this.state.RoundingMethod,
            RoundingPrecision: this.state.RoundingPrecisionValue
        };
        this.setState({ IsCalculating: true });
        var calculatePrice = quosal.api.priceModifierHelper.CalculateSellingPrice(this.props.itemCost, this.props.itemSuggestedPrice,
            this.props.itemBasePrice, this.props.advancedRoundingEnabled, priceModifierParam);
        calculatePrice.finished = function (msg) {
            if (msg.validationErrors) {
                /* #10537340 If price modifier is invalid, revert to original values obtained from props */
                this.setState(
                    { IsCalculating: false },
                    function () {
                        this.parsePriceModifierText();
                    });
            }
            else {
                this.setState({
                    CalculatedValue: msg.sellingPrice,
                    IsCalculating: false
                });
            }
        }.bind(this);
        calculatePrice.call();
    }

    saveAndClosePriceModifier() {
        var priceModifierParam = {
            ModifierControlType: this.state.PriceControlType,
            PriceModifierControlValue: this.state.PriceControlValue ? this.state.PriceControlValue : 0,
            RoundingMethod: this.state.RoundingMethod,
            RoundingPrecision: this.state.RoundingPrecisionValue
        };
        var convertApi = quosal.api.priceModifierHelper.ConvertToPriceModifierText(priceModifierParam);
        var calculatedPriceMod = ''
        convertApi.finished = function (msg) {
            calculatedPriceMod = msg.priceModifierText;
            this.props.priceModifierDialogSave(calculatedPriceMod);
            this.props.onClose ? this.props.onClose() : this.props.togglePricingHelper()
            this.props.setChanges ? this.props.setChanges() : null
        }.bind(this);
        convertApi.call();
    }
    render() {
        var priceControlTypes = ['Normal', 'Gross Margin', 'Markup', 'Base Price Discount', 'List Price Discount'];
        var priceControlOptions = [];

        var roundingOptions = ['Bankers', 'Standard', 'RoundUp'];
        var roundingMethodOptions = [];

        var precisionOptions = [];
        var roundingFieldsHoverText = "Bankers (default) - Numbers which are equidistant from the two nearest integers are rounded to the nearest even integer" +
            "\nStandard - Increase price value by 1 if the next digit is 5 or more" +
            "\nRoundUp - Increase price value by 1 if there is any value above 0 to the right";
        for (var i = 4; i >= -9; i--) {
            precisionOptions.push(<option key={'precisionOption' + i} value={i}>{i}</option>);
        }
        for (var i = 0; i < roundingOptions.length; i++) {
            roundingMethodOptions.push(<option key={'option' + i} value={roundingOptions[i]}>{roundingOptions[i]}</option>);
        }
        for (var i = 0; i < priceControlTypes.length; i++) {
            priceControlOptions.push(<option key={'option' + i} value={i}>{priceControlTypes[i]}</option>);
        }
        var style = this.state.style || {};
        // css and jsx based on a feature flag   ---   start
        const isFeatureEnabled = quosal.settings.getValue('UseEditQuoteCKEditor')

        const newStyles = {
            pricingFieldsStyle: { margin: '10px', marginTop: '12px', marginBottom: '16px', display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', },
            originalCalculatedFields: { boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '222px', height: '87px', padding: '16px', border: '1px solid #9E9E9E', borderRadius: '4px', },
            pricingFieldLabelClass: '',
            pricingFieldLabelStyle: { fontWeight: 400, fontSize: '14px', },
            pricingFieldValueClass: '',
            pricingFieldValueStyle: { fontWeight: 700, fontSize: '24px', 
                                        paddingTop: this.props?.cellStyle?.paddingTop
                                            ? this.props?.isGrid
                                                ? '15px'
                                                : this.props?.cellStyle?.paddingTop
                                            : '15px'
                                    },
            formfieldWrapperStyle: { paddingBottom: '8px', },
            formfieldStyle: { paddingRight: '0px', backgroundPosition: '95%', },
            formfieldLabelStyle: { color: '#5C5C5C !important', fontWeight: 400, fontSize: '12ps', lineHeight: '16px', },
        }

        const oldStyle = {
            pricingFieldsStyle: {},
            originalCalculatedFields: {},
            pricingFieldLabelClass: 'pricingFieldLabel',
            pricingFieldLabelStyle: {},
            pricingFieldValueClass: 'pricingFieldValue',
            pricingFieldValueStyle: {},
            formfieldWrapperStyle: {},
            formfieldStyle: {},
            formfieldLabelStyle: {},
        }

        const currentStyle = isFeatureEnabled ? newStyles : oldStyle

        const formSelectFieldWrapperStyle = isFeatureEnabled
            ? { width: '296px', height: '48px', backgroundPosition: '95%', borderBottom: 'solid 0px #c6c6c6', ...inputSelectStyle}
            : { width: '150px' };
        const formSelectFieldDecimalWrapperStyle = isFeatureEnabled
            ? { width: '150px', height: '48px', backgroundPosition: '90%', borderBottom: 'solid 0px #c6c6c6' }
            : { width: '90px' };
        const inputSelectStyle = isFeatureEnabled
            ? { border: '1px solid #888888', borderRadius: '4px', height: '48px', lineHeight: '48px', padding: '0 15px', backgroundSize: '18px 18px !important', }
            : {};
        const inputStyle = isFeatureEnabled
            ? { ...inputSelectStyle, width: '150px', height: '48px', padding: '0 15px',boxSizing: 'border-box', }
            : { width: '90px' };
        const formselectfield = isFeatureEnabled
            ? { ...inputSelectStyle, width: '100%', boxShadow: 'none' }
            : {};
        const buttonWrapperStyle = isFeatureEnabled
            ? { ...currentStyle.pricingFieldsStyle, textAlign: 'right !important', borderTop: '1px solid #E0E0E0', paddingTop: '23px', margin: '0 10px', justifyContent: 'flex-end', }
            : { textAlign: 'center' };
        const panelWrapperStyle = isFeatureEnabled
                                    ? { minWidth: '486px', 
                                        height: this.state.requireCalculate 
                                                    ? this.props?.isGrid 
                                                        ? '368px'
                                                        : '371px' 
                                                    : '268px',
                                        ...style }
                                    : { ...style };
        const btnStyle = isFeatureEnabled
            ? { height: '48px', borderRadius: '4px', marginLeft: '14px', }
            : {};
        const saveBtnStyle = isFeatureEnabled
            ? { ...btnStyle,  color: '#EAEAEA', backgroundColor: '#2E3F80', border: '1px solid #2E3F80', width: '137px', }
            : {};
        const closeBtnStyle = isFeatureEnabled
            ? { ...btnStyle,  color: '#22326E', backgroundColor: '#FFF', border: '1px solid #22326E', width: '70px', }
            : {};
        const oldCloseBtn = isFeatureEnabled
            ? null
            : <div className="close hidePriceHelper" title="Hide Pricing Helper" onClick={this.props.togglePricingHelper}></div>;
        const newCloseBtn = isFeatureEnabled
            ? <Button className="btn btn-close" onClick={this.props.onClose ? this.props.onClose : this.props.togglePricingHelper} disabled={this.state.IsCalculating} style={closeBtnStyle}>Close</Button>
            : null;
        const panelGridStyle = { ...panelWrapperStyle, padding: '15px 15px 20px 15px' };
        // css and jsx based on feature flag   ---   end

        const panelContent = <div>
            { oldCloseBtn }
            {
              this.state.requireCalculate
                ? <div className="pricingFields" style={currentStyle.pricingFieldsStyle}>
                      <div className="originalFields" style={currentStyle.originalCalculatedFields}>
                          <label className={currentStyle.pricingFieldLabelClass} style={currentStyle.pricingFieldLabelStyle}>{this.state.BaseValueLabel}</label>
                          <label className={currentStyle.pricingFieldValueClass} style={currentStyle.pricingFieldValueStyle}>
                              {app.currentQuote.formatCurrency(this.state.BaseValue)}</label>
                      </div>
                      <div className="calculatedFields" style={currentStyle.originalCalculatedFields}>
                          <label className={currentStyle.pricingFieldLabelClass} style={currentStyle.pricingFieldLabelStyle}>Selling Price</label>
                          <label className={currentStyle.pricingFieldValueClass} style={currentStyle.pricingFieldValueStyle}>
                              {app.currentQuote.formatCurrency(this.state.CalculatedValue)}
                          </label>
                      </div>
                  </div>
                : null
            }        

            <div className="pricingFields" style={currentStyle.pricingFieldsStyle}>
                <div style={{ display: 'inline-block', verticalAlign: 'text-top' }}>
                    <div className="formfieldlabel" style={currentStyle.formfieldWrapperStyle}>
                        <label htmlFor="PriceControlField" className="formlabel" style={currentStyle.formfieldLabelStyle}>Price Control</label>
                    </div>
                    <div className="formselectfieldwrapper" style={formSelectFieldWrapperStyle}>
                        <select disabled={this.props.isDisabled} className="formselectfield" name="PriceControlField" id="PriceControlField"
                                title="Price Control Field" value={this.state.PriceControlType}
                                onChange={this.changePriceControlType} style={formselectfield}>
                            {priceControlOptions}
                        </select>
                    </div>
                </div>
                {this.state.PriceControlType != 0
                    ? <div style={{ display: 'inline-block', marginLeft: '15px', verticalAlign: 'text-top' }}>
                        <div className="formfieldlabel" style={currentStyle.formfieldWrapperStyle}>
                            <label htmlFor="PriceControlFieldValue" className="formlabel" style={currentStyle.formfieldLabelStyle}>
                                {this.state.PriceControlValueLabel}
                            </label>
                        </div>
                        <div className="formfield" style={currentStyle.formfieldStyle}>
                            <input  type="number" name="PriceControlFieldValue" id="PriceControlFieldValue"
                                    disabled={this.props.isDisabled}
                                    title="Price Control Field Value" value={this.state.PriceControlValue}
                                    onChange={this.changePriceControlValue} onBlur={this.recalculateSellingPrice}
                                    style={inputStyle} />
                        </div>
                      </div>
                    : null}
            </div>

            <div className="pricingFields" style={currentStyle.pricingFieldsStyle}>
                <div style={{ display: 'inline-block' }}>
                    <div className="formfieldlabel" style={currentStyle.formfieldWrapperStyle}>
                        <label htmlFor="PriceRoundingField" className="formlabel" style={currentStyle.formfieldLabelStyle}>Rounding Method</label>
                    </div>
                    <div className="formselectfieldwrapper" style={formSelectFieldWrapperStyle}>
                        <select disabled={this.props.isDisabled} className="formselectfield" name="PriceRoundingField" id="PriceRoundingField"
                                title="Price Rounding Field" value={this.state.RoundingMethod}
                                onChange={this.changeRoundingMethod} style={formselectfield}>
                            {roundingMethodOptions}
                        </select>
                    </div>
                </div>

                <div style={{ display: 'inline-block', marginLeft: '15px' }}>
                    <div className="formfieldlabel" style={currentStyle.formfieldWrapperStyle}>
                        <label htmlFor="PriceRoundingPrecisionField" className="formlabel" style={currentStyle.formfieldLabelStyle}>Decimal Precision</label>
                    </div>
                    <div className="formselectfieldwrapper" style={formSelectFieldDecimalWrapperStyle}>
                        <select disabled={this.props.isDisabled} className="formselectfield" name="PriceRoundingPrecisionField" id="PriceRoundingPrecisionField"
                                title="Price Rounding Precision Field" value={this.state.RoundingPrecisionValue}
                                onChange={this.changeRoundingPrecision} style={formselectfield}>
                            {precisionOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div className="pricingFields" style={buttonWrapperStyle}>
                {newCloseBtn}
                {!this.props.isDisabled && <Button className="btn btn-save" id="closeCalculator" onClick={this.saveAndClosePriceModifier} disabled={this.state.IsCalculating}
                        style={saveBtnStyle}>Save and Close</Button>}
            </div>
        </div>

        if (this.props?.isGrid) {
            return  <Box ref="priceHelperPanel" style={panelGridStyle}>
                        {this.state.Loading ? <Spinner /> : panelContent}
                    </Box>
        } else {
            return  <div ref="priceHelperPanel" className="panel pricingHelper" style={panelWrapperStyle}>
                        {this.state.Loading ? <Spinner /> : panelContent}
                    </div>
        }
    }
}