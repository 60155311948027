import React, { memo } from "react";
import { IconButton, Tooltip, Divider } from "@mui/material";
import { getRecurringOptions } from "../WidgetHelper.jsx";
import { useAppGlobalContext } from "../context/globalContext";

function ToolbarWidget({ id, title, icon, isDividerNext, handleInitialState }) {
	const {
		ckeditor,
		contentGrid: {
			props: { quote }
		}
	} = useAppGlobalContext();
	const divider = <Divider sx={{ backgroundColor: "grey.500", width: "80%" }} />;

	const handleWidgetClick = (e, id, title) => {
		e.stopPropagation();
		switch (title) {
			case "Quote Information":
				ckeditor.execute("shiftEnter");
				ckeditor.execute("insertQuoteSummary", id);
				break;

			case "Quote Summary/Expenses":
				ckeditor.execute("input", { text: "Quote Summary" });
				ckeditor.execute("insertNotRecurringQuoteSummary", id);
				break;

			case "Optional Summary":
				ckeditor.execute("input", { text: "Optional Summary" });
				ckeditor.execute("insertQuoteOptionalSummary", id);
				break;

			case "Recurring Summary":
				const options = getRecurringOptions();

				if (options && options.length) {
					for (const option of options) {
						ckeditor.execute("input", { text: `${option} Recurring Option` });
						ckeditor.execute(
							"insertRecurringQuoteSummary",
							`recurring ${option}`,
							option
						);
					}
				} else {
					ckeditor.execute("shiftEnter");
					ckeditor.execute("insertRecurringQuoteSummary", id, "");
				}
				break;

			case "Sales Signature":
				ckeditor.execute("shiftEnter");
				ckeditor.execute("insertSalesSignature", id);
				break;

			case "Customer Signature":
				ckeditor.execute("shiftEnter");
				ckeditor.execute("insertCustomerSignature", id);
				break;

			case "DocuSign Signature":
				ckeditor.execute("shiftEnter");
				ckeditor.execute("insertDocuSignature", id);
				break;

			default:
				ckeditor.execute("insertWidget", id);
				break;
		}

		ckeditor.editing.view.focus();
		handleInitialState(false);
	};

	return (
		<>
			<Tooltip title={title} placement="left" id={id}>
				{title === "Widgets Toolbar" ? (
					<IconButton
						sx={{
							"&:hover": { cursor: "default" },
							minWidth: "45px",
							borderRadius: 0,
							pointerEvents: "none"
						}}
						disabled={quote.IsTemplate}
					>
						{icon}
					</IconButton>
				) : (
					<IconButton
						sx={{ minWidth: "45px", borderRadius: 0 }}
						onClick={(e) => handleWidgetClick(e, id, title)}
					>
						{icon}
					</IconButton>
				)}
			</Tooltip>
			{isDividerNext && divider}
		</>
	);
}

export default memo(ToolbarWidget);
