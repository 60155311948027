import { useState, useEffect, useContext } from "react";
import { Grid, MenuItem, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelIcon from "@mui/icons-material/Close";
import { GridRowModes, DataGrid,useGridApiContext, GridActionsCellItem,GridRowEditStopReasons,GridEditInputCell } from "@mui/x-data-grid";

import { ConfirmationModal } from "../../../../common/guidedSelling/components";
import FormContext from "../../contexts/configureFormContext";
import { EditSelectStyled } from "../../styledComponents/mapTableStyled";
import { EDIT_DYNAMIC_QUES_CONDITION, REMOVE_DYNAMIC_QUES_CONDITION } from "../../actions/formActions";

export const DynamicQuestionConditionsTable = ({
	dynamicQuestionLists,
	isActive,
	currentSectionIndex,
	currentQuestionIndex,
	currentQuestion
}) => {
	const [stateData, setStateData] = useState({
		rows: [],
		rowModesModel: {},
		isValid: true,
		invalidRowId: "",
		lastRowModified: {},
		isEditing: false,
		answerIndex: ""
	});
	const commomSelectStyles = {
		height: "90%",
		width: "90%",
		"& .MuiSelect-select": {
			display: "flex",
			alignItems: "center"
		}
	};
	const formContext = useContext(FormContext);
	const theme = useTheme();
	const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
	const [currentCondition, setCurrentCondition] = useState(null);
	const deleteConditionConfirmationText = (
		<>
			Do you want to delete this Dynamic Condition? <br />
			This action cannot be undone.
		</>
	);
	const { formActions, formState: { sections } } = formContext;
	const questions = sections.flatMap((section) => section.questions);
	const formatedDQuestionList = dynamicQuestionLists.map(
		({ answer, dynamicQuestionId, operation, questionId }) => {
			const questionText = questions.find((question) => question.questionId === questionId)?.question;
			return {
				answer,
				dynamicQuestionId,
				operation,
				thenDisplay: { questionId, question: questionText }
			};
		}
	);

	useEffect(() => {
		setStateData({ ...stateData, rows: formatedDQuestionList });
	}, [JSON.stringify(formatedDQuestionList)]);

	const { rows, rowModesModel, lastRowModified, invalidRowId, isValid, isEditing } = stateData;

	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const handleEditClick = (id) => () => {
		isEditing === false &&
			setStateData({
				...stateData,
				rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.Edit } },
				isEditing: true
			});
	};

	const handleSaveClick = (id) => () => {
		setStateData({
			...stateData,
			rowModesModel: { ...rowModesModel, [id]: { mode: GridRowModes.View } },
			isEditing: false
		});
	};

	const handleCancelClick = (id) => () => {
		if (Object.keys(lastRowModified).length > 0) {
			const newRowsArray = rows.map((row) =>
				row.id === invalidRowId ? { ...lastRowModified } : row
			);
			setStateData({
				...stateData,
				rows: newRowsArray,
				rowModesModel: {
					...rowModesModel,
					[id]: { mode: GridRowModes.View, ignoreModifications: true }
				},
				invalidRowId: "",
				isValid: true,
				lastRowModified: {},
				isEditing: false
			});
		} else {
			setStateData({
				...stateData,
				rowModesModel: {
					...rowModesModel,
					[id]: { mode: GridRowModes.View, ignoreModifications: true }
				},
				invalidRowId: "",
				isValid: true,
				lastRowModified: {},
				isEditing: false
			});
		}

		const editedRow = rows.find((row) => row.dynamicQuestionId === id);
		if (editedRow?.isNew) {
			setStateData({ ...stateData, rows: rows.filter((row) => row.id !== id) });
		}
	};

	const processRowUpdate = (updatedRow) => {
		setStateData({
			...stateData,
			rows: rows.map((row) => {
				if (row.dynamicQuestionId === updatedRow.dynamicQuestionId) {
					formActions[EDIT_DYNAMIC_QUES_CONDITION]({
						sectionIndex: currentSectionIndex,
						questionIndex: currentQuestionIndex,
						updatedRow
					});
					return updatedRow;
				}
				return row;
			})
		});
		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setStateData({ ...stateData, rowModesModel: newRowModesModel });
	};

	function EditInputValueCell(params) {
		const { id, value, field } = params;
		const apiRef = useGridApiContext();
		const handleChange = async (event) => {
			await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
			apiRef.current.stopCellEditMode({ id, field });
		};

		return (
			<EditSelectStyled
				value={value}
				onChange={handleChange}
				size="small"
				sx={{ ...commomSelectStyles }}
				MenuProps={{ disableScrollLock: true }} 
			>
				{currentQuestion?.answers?.map((answer) => (
					<MenuItem
						key={`option-${answer?.answerId}-${answer?.sortOrder}`}
						value={answer.answer}
					>
						{answer.answer}
					</MenuItem>
				))}
			</EditSelectStyled>
		);
	}

	const EditThenDisplayValueCell = (params) => {
		const { id, value, field } = params;
		const apiRef = useGridApiContext();
		const handleChange = async (event) => {
			await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
			apiRef.current.stopCellEditMode({ id, field });
		};
		return (
			<EditSelectStyled
				value={value}
				onChange={handleChange}
				size="small"
				sx={{ ...commomSelectStyles }}
				renderValue={(value) => value.question}
				MenuProps={{ disableScrollLock: true }}
			>
				{inputQuestionOptions?.map((question, index) => (
					<MenuItem
						key={`option-${question?.value?.questionId}-${index}`}
						value={{
							questionId: question.value.questionId,
							question: question.value.question
						}}
					>
						{question?.value?.question}
					</MenuItem>
				))}
			</EditSelectStyled>
		);
	};

	const inputQuestionOptions = sections
		.flatMap((section) => section.questions)
		.filter((question) => question.sortOrder !== currentQuestion.sortOrder && question.isActive)
		.map((question) => ({
			label: `${question.sortOrder}. ${question.question}`,
			value: {
				questionId: question.questionId,
				question: question.question,
				sortOrder: question.sortOrder
			}
		}));

	const renderSelectEditOperation = (params) => {
		const { id, value, field } = params;
		const apiRef = useGridApiContext();
		const handleChange = async (event) => {
			await apiRef.current.setEditCellValue({ id, field, value: event.target.value });
			apiRef.current.stopCellEditMode({ id, field });
		};
		return (
			<EditSelectStyled
				value={value}
				onChange={handleChange}
				size="small"
				sx={{ ...commomSelectStyles }}
				MenuProps={{ disableScrollLock: true }} 
			>
				<MenuItem key={`option-${field}-${value}-${id}`} value={"equals"}>
					Equals
				</MenuItem>
			</EditSelectStyled>
		);
	};

	const renderEditInputValue = (params) => {
		const newParams = {
			...params,
			error: !isValid,
			invalidrowid: invalidRowId
		};
		return <EditInputValueCell {...newParams}/>;
	};

	const renderEditThenDisplayValue = (params) => {
		const newParams = {
			...params,
			error: !isValid,
			invalidrowid: invalidRowId
		};
		return <EditThenDisplayValueCell {...newParams}/>;
	};

	const onConditionDelete = (currentRow) => {
		setCurrentCondition(currentRow);
		setIsOpenDeleteModal(true);
	};

	const closeDeleteConditionModal = () => {
		setIsOpenDeleteModal(false);
	};

	const handleConditionDelete = () => {
		formActions[REMOVE_DYNAMIC_QUES_CONDITION]({
			sectionIndex: currentSectionIndex,
			questionIndex: currentQuestionIndex,
			dynamicQuestionId: currentCondition.dynamicQuestionId
		});
		closeDeleteConditionModal();
	};

	const columns = [
		{
			field: "operation",
			headerName: "Operation",
			minWidth: 300,
			maxWidth: 450,
			renderEditCell: renderSelectEditOperation,
			editable: true,
			valueFormatter:(params) => {
				return params.value.charAt(0).toUpperCase() + params.value.slice(1);
			}
		},
		{
			field: "answer",
			headerName: "Input Value",
			flex: 1,
			align: "left",
			headerAlign: "left",
			editable: true,
			renderEditCell: renderEditInputValue
		},
		{
			field: "thenDisplay",
			headerName: "Then Display",
			flex: 1,
			align: "left",
			headerAlign: "left",
			editable: true,
			renderEditCell: renderEditThenDisplayValue,
			valueGetter: (params) => ({
				questionId: params.row.thenDisplay.questionId,
				question: params.row.thenDisplay.question
			}),
			valueFormatter:(params) => {
				return params.value.question;
			}
		},
		{
			field: "actions",
			type: "actions",
			headerName: "Actions",
			width: 100,
			cellClassName: "actions",
			align: "right",
			getActions: ({ id, row }) => {
				const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={<SaveOutlinedIcon />}
							label="Save"
							disabled={!isActive}
							sx={{
								color: "button_primary.main",
								minWidth: "32px",
								minHeight: "32px"
							}}
							onClick={handleSaveClick(id)}
						/>,
						<GridActionsCellItem
							icon={<CancelIcon />}
							label="Cancel"
							disabled={!isActive}
							sx={{
								color: "button_danger.main",
								minWidth: "32px",
								minHeight: "32px"
							}}
							onClick={handleCancelClick(id)}
						/>
					];
				}

				return [
					<GridActionsCellItem
						disabled={isEditing || !isActive}
						icon={<EditIcon />}
						label="Edit"
						sx={{
							color: "button_primary.main",
							minWidth: "32px",
							minHeight: "32px"
						}}
						onClick={handleEditClick(id)}
					/>,
					<GridActionsCellItem
						disabled={isEditing || !isActive}
						icon={<DeleteIcon />}
						label="Delete"
						sx={{
							color: "button_danger.main",
							minWidth: "32px",
							minHeight: "32px"
						}}
						onClick={() => onConditionDelete(row)}
					/>
				];
			}
		}
	];

	return (
		<Grid
			item
			xs={12}
			sx={{
				marginTop: "10px",
				"& .actions": {
					color: "text.secondary"
				},
				"& .textPrimary": {
					color: "text.primary"
				},
				"& .MuiDataGrid-row--editing ": {
					"& .MuiDataGrid-cell": {
						backgroundColor: theme.palette.layer.layer1 + " !important"
					}
				},
				"& .MuiDataGrid-cell--editable": {
					padding: "0",
					"& .MuiInputBase-root": {
						height: "100%",
						"& .MuiInputBase-input": {
							padding: "0 10px",
							border: "1px solid",
							borderRadius: "6px",
							color: theme.palette.border.border_strong1,
							borderColor: theme.palette.border.border_strong1,
							background: theme.palette.layer.layer2
						}
					},
					"& .MuiInputBase-input": {
						height: "100%"
					}
				},
				"& .Mui-error": {
					border: `2px solid #ec0001`,
					color: "#750f0f"
				}
			}}
		>
			<DataGrid
				rows={rows}
				columns={columns}
				editMode="row"
				getRowId={(row) => row?.dynamicQuestionId}
				rowModesModel={rowModesModel}
				onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				hideFooterPagination
				hideFooterSelectedRowCount
				hideFooter
				sx={{
					border: 0,
					background: "layer.layer1",
					"& .MuiDataGrid-columnHeaders": {
						borderTop: "none"
					},
					"& .MuiDataGrid-cell.MuiDataGrid-cell--editing ": {
						padding: "4px",
						background: theme.palette.layer.layer1
					},
					"& .MuiDataGrid-cell.MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within ":
						{
							outline: "none"
						}
				}}
			/>
			<ConfirmationModal
				isOpen={isOpenDeleteModal}
				handleClose={closeDeleteConditionModal}
				title="Delete"
				body={deleteConditionConfirmationText}
				cancelBtnText="Cancel"
				confirmBtnText="Delete"
				onCancel={closeDeleteConditionModal}
				onConfirm={handleConditionDelete}
			/>
		</Grid>
	);
};
