import React, { useState, useEffect, useCallback } from "react";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";

const defaultValue = {
	quote: {},
	contentGrid: {},
	rowActions: [],
	ckeditor: {},
	handleCKEditorSelectedTabId: () => {},
	buildNotesDialog: () => {},
	handleSetCKEditor: () => {}
};
const AppGlobalContext = React.createContext(defaultValue);
export const useAppGlobalContext = () => React.useContext(AppGlobalContext);

export function AppGlobalProvider({ value, children }) {
	//List copied from QuoteTabsCustom.cs, along with a few that already existed in the original code
	//Probably should leave out Term Options as it has its own spcial handling
	const gridFormats = ["Cover Letter", "Executive Summary", "Pre Outline", "Post Outline", "Appendix", 
		"Statement of Work", "SoW", "Scope of Work", "Quosal Cover Letter"];

	const initQuote = value?.contentGrid?.props.quote || value?.quote || app.currentQuote;
	const [quote, setQuote] = useState(initQuote);
	const [ckeditor, setCKEditor] = useState({});
	const [selectedTabs, setSelectedTabs] = useState([]);
	
	const setSelectedTabsToDroppedTabs = useCallback(() => {
		const alreadyDroppedTabs = getCKEditorTabsIds(quote?.HTMLContentForAutoSaving);
		const selectedTabs = [];
		alreadyDroppedTabs.forEach((tabId) => {
			const tab = quote.Tabs.find((tab) => tab.IdQuoteTabs === tabId);
			if(tab) {
				selectedTabs.push({tabId: tab.IdQuoteTabs, tabNumber: tab.TabNumber});
			}
		});
		setSelectedTabs(selectedTabs);
	}, [quote]);

	useEffect( ()=> setSelectedTabsToDroppedTabs(), []);
	const isLockedDownQuote =
		quote.IsLocked &&
		!quote.IsArchive &&
		!quote.IsTemplate;

	const isHistoricalQuote =
	quote.IsArchive && !quote.IsTemplate;

	const isArchivedQuote = isLockedDownQuote && quote.QuoteStatus === "Archived";

	const isDisabledQuoteActions = isHistoricalQuote || isArchivedQuote || isLockedDownQuote;

	const internalValue = {
		gridFormats,
		isDisabledQuoteActions,
		isQuoteTemplate: quote.IsTemplate,
		ckeditor,
		setCKEditor,
		selectedTabs, 
		setSelectedTabs,
		setSelectedTabsToDroppedTabs,
		setQuote
	}

	return (
		<AppGlobalContext.Provider value={{ ...value, ...internalValue }}>
			{children}
		</AppGlobalContext.Provider>
	);
}

AppGlobalProvider.Consumer = AppGlobalContext.Consumer;
