import { CardContainer } from "../common/components/CardContainer";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import { memo, useMemo } from "react";

const areEqual = (prevProps, nextProps) => {
	if (
		prevProps.allTax === nextProps.allTax &&
		prevProps.baseCurrency === nextProps.baseCurrency &&
		prevProps.quoteTotal === nextProps.quoteTotal &&
		prevProps.subtotal === nextProps.subtotal &&
		prevProps?.theme?.palette?.mode === nextProps?.theme?.palette?.mode
	) {
		return true;
	} else {
		return false;
	}
};

export const DonutChart = memo(
	({ subtotal, allTax, baseCurrency, quoteTotal, formatCurrency, theme }) => {
		const options = useMemo(
			() => [
				{
					chart: {
						type: "pie",
						backgroundColor: theme.palette.background.paper,
						width: 350,
						height: 130,
						spacingTop: -20,
						marginLeft: 0,
						style: {
							fontFamily: "'Inter' , sans-serif"
						}
					},
					title: {
						useHTML: true,
						text: `<div style="display:flex;justify-content:end;width:220px;flex-direction:row;color:${
							theme.palette.text.secondary
						}">
								<div style="display:flex;justify-content:start;margin-right:20px;font-size:16px;font-weight:600;width:fit-content">Quote Total</div>
								<div title=${formatCurrency(
									quoteTotal
								)} style="width:fit-content;font-size:14px;font-weight:400; text-align:end;"> ${
							formatCurrency(quoteTotal).length > 12
								? formatCurrency(quoteTotal).slice(0, 12) + "..."
								: formatCurrency(quoteTotal)
						} </div>
							</div>`,
						align: "center",
						x: 40,
						y: 60
					},
					credits: {
						enabled: false
					},
					plotOptions: {
						pie: {
							allowPointSelect: false,
							cursor: "pointer",
							dataLabels: {
								enabled: false
							},
							innerSize: "80%"
						}
					},
					legend: {
						layout: "vertical",
						align: "right",
						verticalAlign: "bottom",
						x: -10,
						itemMarginTop: 5,
						itemMarginBottom: 10,
						labelFormatter: function () {
							let formatted = `<div x="20" text-anchor="start" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px">
								${this?.name} 
								</div>
								<div x="28%" text-anchor="start" style="color:${theme.palette.text.secondary}"> 
								${
									formatCurrency(this?.y).length > 12
										? formatCurrency(this?.y).slice(0, 12) + "..."
										: formatCurrency(this?.y)
								}
								</div>`;
							return formatted;
						}
					},
					tooltip: {
						formatter: function () {
							return `<strong>  ${
								this?.key === "Est. Tax" ? "Estimated Tax" : this?.key
							}  </strong> <br/> <strong> ${formatCurrency(this?.y)} </strong>`;
						}
					},
					series: [
						{
							name: "",
							color: theme.palette.text.secondary,
							lineWidth: 1,
							marker: {
								enabled: false,
								symbol: "circle",
								radius: 3,
								states: {
									hover: {
										enabled: true,
										lineWidth: 1
									}
								}
							},
							data: [
								{
									name: "Subtotal",
									y: subtotal,
									color: theme.palette.icon.turnary
								},
								{
									name: "Est. Tax",
									y: allTax,
									color: theme.palette.icon.primary
								}
							],
							showInLegend: true
						}
					],
					accessibility: {
						enabled: false
					}
				},
				{
					// This section is for blank donut
					chart: {
						type: "pie",
						backgroundColor: theme.palette.background.paper,
						width: 350,
						height: 120,
						spacingTop: 10,
						style: {
							fontFamily: "'Inter' , sans-serif"
						}
					},
					title: {
						text: `<p style="font-size:16px; font-weight:700">${formatCurrency(
							0
						)}</p><br/> <p style="font-size:12px; font-weight:400">Quote Total</p>`,
						align: "center",
						verticalAlign: "middle",
						x: -92,
						y: 15,
						style: {
							fontSize: 12,
							fontWeight: 600,
							color: theme.palette.text.secondary,
							lineHeight: 22
						}
					},
					credits: {
						enabled: false
					},
					plotOptions: {
						pie: {
							allowPointSelect: false,
							enableMouseTracking: false,
							cursor: "pointer",
							dataLabels: {
								enabled: false
							}
						}
					},
					legend: {
						layout: "vertical",
						align: "right",
						verticalAlign: "middle",
						itemMarginTop: 10,
						itemMarginBottom: 10,
						labelFormatter: function () {
							let formatted = `<div x="20" style="color:${
								theme.palette.text.secondary
							};font-size:14px;font-weight:400;line-height:18px">
								${this?.name} 
								</div>
								<div x="120" style="color:${theme.palette.text.secondary}"> 
								${formatCurrency(0)}
								</div>`;
							return formatted;
						}
					},
					tooltip: {
						formatter: function () {
							return false;
						}
					},
					series: [
						{
							name: "",
							color: theme.palette.text.secondary,
							lineWidth: 1,
							marker: {
								enabled: false,
								symbol: "circle",
								radius: 3,
								states: {
									hover: {
										enabled: true,
										lineWidth: 1
									}
								}
							},
							data: [
								{
									name: "Subtotal",
									y: null,
									color: theme.palette.icon.turnary
								},
								{
									name: "Est. Tax",
									y: null,
									color: theme.palette.icon.primary
								}
							],
							borderWidth: 10,
							color: theme.palette.icon.blankChart,
							showInLegend: true
						}
					],
					accessibility: {
						enabled: false
					}
				}
			],
			[subtotal, allTax, baseCurrency, quoteTotal, theme]
		);

		return (
			<CardContainer width="356px" sx={{height: "auto"}}>
				{quoteTotal > 0 ? (
					<PieChart highcharts={Highcharts} options={options[0]} immutable={true} />
				) : (
					<PieChart highcharts={Highcharts} options={options[1]} immutable={true} />
				)}
			</CardContainer>
		);
	},
	areEqual
);
