import React, { useEffect, useState } from "react";
import { Stack, Box } from "@mui/material";
import { useQuoteContext, useUserSettingsContext } from "../context";
import { StyledLabelSm, StyledValueSm } from "../common/styledComponents";
import { QuoteExpirationDate } from "./QuoteExpirationDate";
import { parseDateFormat } from "../utils";
import dayjs from "dayjs";


export const QuoteDates = ({ isLegacyPage }) => {

	const quote = useQuoteContext()
	const userSettings = useUserSettingsContext()
	const [ExpirationDate, setExpirationDate] = useState()
	const [dateFormat, setDateFormat] = useState()

	useEffect(() => {
		setExpirationDate(quote?.ExpirationDate)
	}, [quote?.ExpirationDate])

	useEffect(() => {
		setDateFormat(parseDateFormat(userSettings?.dateFormat))
	}, [userSettings?.dateFormat])

	return (
		<Stack direction="row" spacing={1}
			sx={{
				flexGrow: 1,
				justifyContent: "end"
			}}>
			<Box sx={{ minWidth: "140px", display: "flex" }}>
				<StyledLabelSm sx={{marginRight:"0px"}}>
					Created:
				</StyledLabelSm>

				<StyledValueSm data-testid="test-created-date">
					{isLegacyPage && dateFormat ? dayjs(quote.CreateDate).format(dateFormat) : quote?.CreateDate || ""}
				</StyledValueSm>
			</Box>
			<Box sx={{ minWidth: "140px", display: "flex", marginRight: "8px" }}>
				<QuoteExpirationDate quote={{ ExpirationDate: ExpirationDate, IdQuoteMain: quote?.IdQuoteMain }} dateFormat={dateFormat} isLegacyPage = {isLegacyPage}/>
			</Box>
		</Stack>
	);
};
