const useDraggableExpandableHelper = (
	tabItem,
	setTabName,
	setChange,
	tabName,
	updateOpenTab,
	setDuplicated,
	setData,
	ckeditor
) => {
	const getOption = (option) => {
		let currentTab = null;
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				currentTab = tab;
			}
		});
		if (currentTab && tabItem?.IdQuoteTabs) {
			return currentTab[option];
		}
		return false;
	};

	const saveTabName = () => {
		const afterUpdate = function (msg) {
			if (msg.error) {
				Dialog.open({
					title: "ERROR",
					message: msg.error,
					links: [
						{
							title: "OK",
							callback: function callback() {
								Dialog.close();
							}
						}
					]
				});
			} else {
				app.currentQuote.Tabs.forEach((tab) => {
					if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
						tab.TabName = tabName;
					}
				});
				setTabName(tabName);
				const termOptionsTab = "Term Options";
				if (tabName === termOptionsTab || tabItem?.TabName === termOptionsTab) {
					setChange();
				}
				quosal.sell.quote.updateFromApiResponse(msg);
				ckeditor.execute("updateProduct", tabItem?.IdQuoteTabs);
				
			}
		};

		const updateApi = quosal.api.data.update(
			{
				fields: {
					TabName: tabName
				},
				queries: [
					{
						table: "QuoteTabs",
						where: [
							{
								field: "IdQuoteTabs",
								operator: "Equals",
								value: tabItem?.IdQuoteTabs
							}
						]
					}
				]
			},
			app.currentQuote.IdQuoteMain
		);
		updateApi.finished = function (msg) {
			afterUpdate(msg);
		};
		updateApi.call();
	};

	const createColorGroups = () => {
		quosal.util.color.pallette.default.reset();
		const optionGroupColors = {};
		const tabGroupColors = {};
		const optionGroups = [];
		const tabGroups = [];
		const currentQuoteTabs = app.currentQuote.Tabs;

		for (let tab = 0; tab < currentQuoteTabs.length; tab++) {
			const quoteTab = currentQuoteTabs[tab];

			if (
				!quosal.util.nullOrEmpty(quoteTab.TabGroup) &&
				tabGroups.indexOf(quoteTab.TabGroup) < 0
			) {
				tabGroups.push(quoteTab.TabGroup);
			}

			if (
				!quosal.util.nullOrEmpty(quoteTab.OrderPorterGroup) &&
				optionGroups.indexOf(quoteTab.OrderPorterGroup) < 0
			) {
				optionGroups.push(quoteTab.OrderPorterGroup);
			}
		}

		optionGroups.sort();
		tabGroups.sort();

		for (let i = 0; i < tabGroups.length; i++) {
			tabGroupColors[tabGroups[i]] = quosal.util.color.pallette.default.nextColor();
		}

		for (let i = 0; i < optionGroups.length; i++) {
			optionGroupColors[optionGroups[i]] = quosal.util.color.pallette.default.nextColor();
		}

		const groupColors = {
			tabGroupColors,
			optionGroupColors
		};

		return groupColors;
	};

	const getOptionGroupLabelColor = () => {
		let optionGroupLabelColor = null;
		const { optionGroupColors } = createColorGroups();
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				optionGroupLabelColor = optionGroupColors[tab.OrderPorterGroup];
			}
		});

		return optionGroupLabelColor;
	};

	const getTabGroupLabelColor = () => {
		let tabGroupLabelColor = null;
		const { tabGroupColors } = createColorGroups();
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				tabGroupLabelColor = tabGroupColors[tab.TabGroup];
			}
		});

		return tabGroupLabelColor;
	};

	const getOptionGroupLabelTitle = () => {
		let labelTitle = null;
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				labelTitle = tab.OrderPorterGroup;
			}
		});

		return labelTitle;
	};

	const getTabGroupLabelTitle = () => {
		let labelTitle = null;
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				labelTitle =
					tab.IsGroupQuantityMaster && tab.TabGroup
						? `${tab.TabGroup}(Qty master)`
						: tab.TabGroup;
			}
		});

		return labelTitle;
	};

	const handleDuplicateTab = (isRadioOption) => {
		let currentTab = null;
		app.currentQuote.Tabs.forEach((tab) => {
			if (tab.IdQuoteTabs === tabItem?.IdQuoteTabs) {
				currentTab = tab;
			}
		});

		const duplicateApi = quosal.api.quote.duplicateTab(
			currentTab.IdQuoteMain,
			currentTab.IdQuoteTabs,
			isRadioOption
		);
		duplicateApi.finished = function (msg) {
			quosal.sell.quote.updateFromApiResponse(msg);
			const newIdQuoteTabs = msg.partialResponse.newTab.IdQuoteTabs;
			setData(app.currentQuote.Tabs)
			setDuplicated(true);
			updateOpenTab(newIdQuoteTabs);
		};
		duplicateApi.call();
	};

	return {
		getOption,
		saveTabName,
		getOptionGroupLabelColor,
		getTabGroupLabelColor,
		getOptionGroupLabelTitle,
		getTabGroupLabelTitle,
		handleDuplicateTab
	};
};

export default useDraggableExpandableHelper;
