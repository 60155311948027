export const DATE_FORMAT = "MM/DD/YYYY";
export const CUST_DETAILS_NOTE = `Changes made to the below fields would only be applicable to the 'Quote to'
customer details.`;
export const CUSTOMER_FIELD_HELPER_TEXT =
	"To change the Customer go to the Customer Setup tab of your quote";
export const QUOTE_DETAILS_HEADING = "Quote Details";
export const CUSTOMER_DETAILS_HEADING = "Customer Details";
export const QUOSAL_ADMIN_USERNAME = "QuosalAdmin";
export const DETAILS_NOT_SAVED_MSG = "Details not saved successfully.";
export const PRIMARY_REP_SAVED_MSG = "Primary Rep saved successfully.";
export const INSIDE_REP_SAVED_MSG = "Inside Rep saved successfully.";
