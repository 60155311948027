global.app = {};
app.dashboard = {};
app.settings = {
    global: {},
    user: {}
};
app.history = {
    browserBackOrForward: true
};

app.productBranding = "ConnectWise CPQ";

app.authenticate = function (msg) {
    app.entity = msg.entity;
    app.deviceId = msg.deviceId;
    app.currentUser = msg.currentUser;
    app.originalUserUserId = msg.originalUserUserId;
    app.currentUser.IsQuosalAdmin = (app.currentUser.UserName === 'QuosalAdmin');
};

app.loadSettings = function (msg) {
    app.settings.global = msg.globalSettings;
    //SP 2/8/18: This is a copy of the Package Levels created in the EntitiesCustom file.  
    app.packageLevels = {
        premium: "PREMIUM",
        standard: "STANDARD",
        starter: "STARTER"  
    }

    app.getPackageLevelDispayName = function (packageLevel) {
        switch (packageLevel) {
            case app.packageLevels.premium:
                return "PREMIUM";
            case app.packageLevels.standard:
                return "STANDARD";
            case app.packageLevels.starter:
                return "BASIC";
        }
    }

    app.settings.user = msg.userSettings;
    app.featureFlags = msg.featureFlags;
    if (quosal.util.isIPad) { // FSP 9/1/16 8097981
        app.settings.user.documentPreviewDisabled = true;
    }
    $.datepicker.setDefaults({ dateFormat: app.settings.user.dateFormat });
    $.quosal.clipboard.data = app.settings.user.clipboardData;

    quosal.sell.modules.register(msg.clientModules);
    quosal.customization.fields.register(msg.fieldConfigurations);
    quosal.customization.grids.register(msg.gridConfigurations);
    quosal.customization.forms.register(msg.formConfigurations);

    if (msg.fieldEnumerations != null) {
        if (!quosal.metadata.enums) {
            quosal.metadata.enums = msg.fieldEnumerations[0];
        } else {
            for (var enumType in msg.fieldEnumerations[0]) {
                if (msg.fieldEnumerations[0].hasOwnProperty(enumType)) {
                    quosal.metadata.enums[enumType] = msg.fieldEnumerations[0][enumType];
                }
            }
        }
    }

    quosal.metadata.productSearchPrefillMappings = msg.productSearchPrefillMappings || [];
    quosal.metadata.modificationTags = msg.modificationTags || [];
    quosal.customization.teamNames = msg.teamNames;
    quosal.formFieldMetadata = msg.formFieldMetadata;

    //find current module, redirect if it is not present
    var moduleUrl = location.currentModule();
    var currentModule = quosal.sell.modules.allModules.where(function(s) { return s.Url == moduleUrl; }).firstOrNull();

    if (currentModule) {
        app.currentModule = currentModule;
    } else {
        $('#loadingMessage').text('Module Not Found, Redirecting to Login...');
        location.href = 'login.quosalweb' + location.search;
    }

    if (app.settings.user.embeddedTheme === 'ConnectWise Underline') {
        var themeCss = document.createElement('link');
        themeCss.setAttribute('href', 'skins/quosal/css/cw_embedded.css?' + app.settings.global.cacheExpireTag);
        themeCss.setAttribute('rel', 'stylesheet');
        themeCss.setAttribute('type', 'text/css');
        document.head.appendChild(themeCss);
    }
    if (app.settings.user.embeddedTheme === 'ConnectWise Business Suite') {
        var suiteCss = document.createElement('link');
        suiteCss.setAttribute('href', 'skins/quosal/css/cw_suite.css?' + app.settings.global.cacheExpireTag);
        suiteCss.setAttribute('rel', 'stylesheet');
        suiteCss.setAttribute('type', 'text/css');
        document.head.appendChild(suiteCss);
    }

    //Fix for IE being stupid
    if (app.settings.user.headerStyle) {
        $('#header').css('background-color', app.settings.user.headerStyle);
    }
};

app.init = function (args) {
    args = args || {};
    //word-merge main.htm
    const partial_react_block = document.getElementsByClassName('partial_react_block')?.[0] || null;
    quosal.ui.views.parse(partial_react_block);

    //server mode and build infopp
    var serverMode = app.settings.user.serverMode.indexOf('QA') >= 0 ? 'QA' : app.settings.user.serverMode;
    var match;
    if (serverMode == 'RELEASE')
        match = location.pathname.toLowerCase().indexOf('/quosalweb/') >= 0;
    else
        match = location.pathname.toLowerCase().indexOf(serverMode.toLowerCase()) >= 0;

    if (!match || serverMode != 'RELEASE') {
        if (!match) {
            $('#serverModeLabel').css('background-color', '#f33');
            $('#serverModeLabel').attr('title', 'Your app server mode and browser URL do not match and may result in unexpected behavior.');
        }

        $('#serverModeLabel').text(serverMode + app.settings.global.buildInfo.replace('BUILD', '').replace('(', '').replace(')', ''));
        $('#serverModeLabel').show();
    }

    //notifications
    if (app.settings.user.notifyCount > 0) {
        $('#notifycount').html(app.settings.user.notifyCount);
        $.quosal.updates.notifications();
    }

    //liquid menu
    $('#liquidmenu').click(function () {
        var menu = $('.menuContainer');
        if ($(menu).hasClass('hideliquid')) {
            $.cookie('c_liquidmenu-btn', 'visible', { expires: 14 });
            $('.menuContainer').removeClass("hideliquid");
        } else {
            $.cookie('c_liquidmenu-btn', 'hidden', { expires: 14 });
            $('.menuContainer').addClass("hideliquid");
        }
    });

    $(window).unbind('statechange');
    History.Adapter.bind(window, 'statechange', quosal.navigation.clientNavigate);
    //load current page url
    app.currentModule.load({
        query: location.search,
        callback: args.callback
    });

    quosal.util.getCallbackManager('identify').waiting = false;
};

app.callIdentifyApi = function (args) {
    args = args || {};
    var identifyCallbackManager = quosal.util.getCallbackManager('identify');
    if ('function' === typeof args.callback) {
        identifyCallbackManager.list.push(args.callback);
    }
    if (identifyCallbackManager.waiting === true) {
        if (identifyCallbackManager.currentApiCall) {
            identifyCallbackManager.currentApiCall.localAbort();
        }
    }
    identifyCallbackManager.waiting = true;
    $('#loadingMessage').text('Authenticating...');

    var identify = quosal.api.core.identify(quosal.util.queryString('accesskey'));
    identifyCallbackManager.currentApiCall = identify;

    var doc = document;
    identify.stateChanged = function (msg) {
        if (msg.action == 'SessionExpired') {
            $('#loadingMessage').text('Redirecting to Login...');
            location.href = 'login.quosalweb' + location.search + '&referralurl=' + encodeURIComponent(location.href);
        }
        else if (msg.action == 'UnauthorizedIpAddress') {
            $('#loadingMessage').text('Logging out due to access from unauthorized IP address...');
            location.href = 'logout.quosalweb' + location.search;
        }
        else if (msg.action == 'Authenticated') {
            app.authenticate(msg);
            $('#loadingMessage').text('Loading user settings...');
        }
        else if (msg.action === 'EulaStatusResponse') {
            if (msg.signed) {
                sessionStorage.EulaSigned = true;
                window.removeEventListener('message', app.EulaEventListener, false);
            }
            else {
                sessionStorage.EulaSigned = false;
                $('#loadingMessage').text('Checking EULA...');
                var iframe = doc.createElement('iframe');
                iframe.setAttribute("id", "eulaFrame");
                iframe.style.height = "100%";
                iframe.style.width = "100%";
                iframe.style.position = "absolute";
                iframe.src = msg.eulaUILink;
                doc.body.appendChild(iframe);
                window.addEventListener('message', app.EulaEventListener, false);

            }
        }
        else if (msg.action == 'LoadSettings') {
            app.loadSettings(msg);

            //Home Page footer shows BuildInfo which can be populated only after LoadSettings has executed
            const setFooterText = function () {
                var footerDiv = document.getElementById('footerText');
                if (!footerDiv)
                {
                    return;
                }
                var year = new Date().getFullYear();
                var buildInfo = "";
                if (app && app.settings && app.settings.global && app.settings.global.buildInfo)
                {
                    buildInfo = app.settings.global.buildInfo;
                }
                var footerText = "&copy;" + year + " ConnectWise Inc.&nbsp;&nbsp; All Rights Reserved.&nbsp;&nbsp;" +
                    "Technology used in " + app.productBranding + " is patent pending.&nbsp;&nbsp;&nbsp;&nbsp; " + buildInfo;
                footerDiv.innerHTML = footerText;
            };

            setFooterText();
        }
    };
    if (app.currentUser) {
        identify.finished = function (identifyCallbackManager) {
            Dialog.closeAll({skipAnimation:true});
            identifyCallbackManager.runAll();
        }.bind(null, identifyCallbackManager);
    }
    else {
        identify.finished = function (identifyCallbackManager) {
            if (app.currentUser) {
                $('#loadingScreen').hide();
                if (sessionStorage.EulaSigned === 'true')
                {
                     app.init({ callback: identifyCallbackManager.runAll });
                }
            }
        }.bind(null, identifyCallbackManager);
    }
    identify.call();
};

app.EulaEventListener = function (event) {
    if (event.origin === "https://api-cwservices-na.connectwisedev.com" ||
        event.origin === "https://api-cwservices-na.myconnectwise.net")
    {
        if (event.data && event.data.type === 'eulaSigned')
        {
            sessionStorage.EulaSigned = true;
            var saveSignedEulaOp = quosal.api.eula.saveSignedEula();
            saveSignedEulaOp.call();
            $('#eulaFrame').hide();
            saveSignedEulaOp.finished = function (msg) {               
                app.init();
            };           
        }
    }
};