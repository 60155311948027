import React, { useState, useMemo, createContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const ColorModeContext = createContext();

function Theme({ children }) {
	const [darkMode, setDarkMode] = useState($("#dark-toggle").prop("checked"));

	const colorMode = useMemo(() => {
		const $checkbox = $("#dark-toggle[type=checkbox]");
		$checkbox.on("change", function () {
			setDarkMode(this.checked);
		});

		return () => {
			$checkbox.off("change");
		};
	}, []);

	const mode = useMemo(() => (darkMode ? "dark" : "light"), [darkMode]);

	const themes = {
		light: {
			palette: {
				mode: "light",
				primary: {
					main: "#2E3F80",
					light: "#576599",
					dark: "#202c59",
					contrastText: "#fff"
				},
				secondary: {
					main: "#308CAB",
					light: "#59a3bb",
					dark: "#216277",
					contrastText: "#fff"
				},
				text: {
					primary: "#101120",
					secondary: "#5C5C5C",
					disabled: "#3f404c"
				},
				background: {
					paper: "#fff",
					default: "#fff"
				}
			},
			components: {
				MuiTypography: {
					styleOverrides: {
						root: { fontSize: 14 }
					}
				},
				MuiButton: {
					styleOverrides: {
						root: { textTransform: "none" }
					}
				}
			}
		},
		dark: {
			palette: {
				mode: "dark",
				primary: {
					main: "#0093d0",
					light: "#33a8d9",
					dark: "#006691",
					contrastText: "#e0e0e0"
				},
				secondary: {
					main: "#308CAB",
					light: "#59a3bb",
					dark: "#216277",
					contrastText: "#e0e0e0"
				},
				text: {
					primary: "#e0e0e0",
					secondary: "#e0e0e0",
					disabled: "#65666f"
				},
				background: {
					paper: "#333333",
					default: "#434343"
				}
			},
			components: {
				MuiTypography: {
					styleOverrides: {
						root: { color: "#e0e0e0", fontSize: 14 }
					}
				},
				MuiInputBase: {
					styleOverrides: {
						root: { backgroundColor: "#434343" }
					}
				},
				MuiButton: {
					styleOverrides: {
						root: { textTransform: "none" }
					}
				}
			}
		}
	};

	const theme = createTheme(themes[mode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default Theme;
