export class ParserNav extends React.Component {
    static windowHeight(){ $(window).innerHeight() * 0.80;}
    static windowWidth(){ $(window).innerWidth() * 0.75;}
    constructor(props) {
        super(props);

        this.allowEditNotes;
        this.chosenModule;
        this.eQuoteHtml;
        this.chosenParseModule;
        
        this.modules = {
            canUsePaste: quosal.settings.getValue('useDellPasteQuote'),
            canUsePdf: quosal.settings.getValue('useDellPDFQuote') || quosal.settings.getValue('UseADIParser'),
        }
        this.module = {
            CHOOSE: 'choose',
            PASTE: 'paste',
            PDF: 'pdf',
            LOADING: 'loading',
            REVIEW: 'review',
            ERROR: 'error',
            PARSE: 'parse',
            NONE: 'none'
        }
        this.chosenModule = this.getStartingModule();

        this.state = {
            currentModule: this.chosenModule
        };

        // This binding is necessary to make `this` work in the callback
        this.goBack = this.goBack.bind(this);
        this.changeState = this.changeState.bind(this);
        this.getStartingModule = this.getStartingModule.bind(this);
        this.submitPaste = this.submitPaste.bind(this);
        this.setFile = this.setFile.bind(this);
        this.submitPDF = this.submitPDF.bind(this);
        this.uploadData = this.uploadData.bind(this);
        this.updateItemNotes = this.updateItemNotes.bind(this);
        this.saveParsedQuoteItems = this.saveParsedQuoteItems.bind(this);
        this.setChosenParseModule = this.setChosenParseModule.bind(this);
        this.getModule = this.getModule.bind(this);
    }

    componentDidMount() {
        window.addEventListener("dragover",function(e) {
            e = e || event;
            e.preventDefault();
          }, false);
          window.addEventListener("drop",function(e) {
            e = e || event;
            e.preventDefault();
          }, false);
    }

    goBack() {
        var me = this;
        this.eQuoteHtml = "QuoteFile";
        this.itemsToDisplay = null;
        if (this.state.currentModule == this.module.REVIEW || this.state.currentModule == this.module.ERROR ) {
           
        }
        else if (this.state.currentModule == this.module.PARSE) {
            Dialog.close();
        } 
    }

    changeState(newModule) {
        this.setState({currentModule: newModule })
    }

    getStartingModule() {    
        if (this.props.parser == 'Dell') {    
            if (this.modules.canUsePaste && !this.modules.canUsePdf) {
                return this.module.PASTE;
            }
            else if (!this.modules.canUsePaste && this.modules.canUsePdf) {
                return this.module.PDF;
            }
            else {
                return this.module.PARSE;
            }
        }
        else if (this.props.parser == 'ADI') {
            if (this.modules.canUsePdf) {
                return this.module.PDF;
            }
        }
        else {
            return this.module.PARSE;
        }
    }

    submitPaste() {
        this.eQuoteHtml = $("#dellParserEquoteText").val();
        var me = this;
        this.setState({currentModule: this.module.LOADING});
        ParserDataService.parsePaste(app.currentQuote.IdQuoteMain, this.props.currentTab, this.eQuoteHtml, this.props.parser, function(dellItems, allowEditNotes, error) {
            if (error != ""){
                me.setState({currentModule: me.module.ERROR, errorText: error})
            } else {
                me.allowEditNotes = allowEditNotes
                me.setState({currentModule: me.module.REVIEW, itemsToDisplay: dellItems})
            }
        })
    }

    setFile(file) {
        this.file = file;
    }

    submitPDF() {
        var me = this;
        this.setState({currentModule: this.module.LOADING});
        ParserDataService.parsePDF(app.currentQuote.IdQuoteMain, this.props.currentTab, this.file[0], this.props.parser, function(parsedItems, allowEditNotes, error) {
            if (error != "") {
                me.setState({currentModule: me.module.ERROR, errorText: error})
            } else {
                me.allowEditNotes = allowEditNotes;
                me.setState({currentModule: me.module.REVIEW, itemsToDisplay: parsedItems})
            }
        })
    }

    uploadData() {
        var me = this;
        var returnFunction = function (items, allowEditNotes, error) {
            if (error != "") {
                me.setState({currentModule: me.module.ERROR, errorText: error})
            } else {
                me.allowEditNotes = allowEditNotes;
                me.setState({currentModule: me.module.REVIEW, itemsToDisplay: items})
            }
        }

        if (this.chosenParseModule == this.module.PDF) {
            if (this.file[0].size > 31457280) {
                this.setState({ currentModule: this.module.ERROR, errorText: "File size must be 30MB or smaller. Please upload another PDF file." });
            }
            else if (this.file[0].type != "application/pdf") {
                this.setState({ currentModule: this.module.ERROR, errorText: "Incorrect file type uploaded. Please upload a PDF file." })
            }
            else {
                this.setState({ currentModule: this.module.LOADING });
                ParserDataService.parsePDF(app.currentQuote.IdQuoteMain, this.props.currentTab, this.file[0], this.props.parser, returnFunction);
            }
        }
        else if (this.chosenParseModule == this.module.PASTE) {
            this.setState({currentModule: this.module.LOADING});
            this.eQuoteHtml = $("#dellParserEquoteText").val();
            ParserDataService.parsePaste(app.currentQuote.IdQuoteMain, this.props.currentTab, this.eQuoteHtml, this.props.parser, returnFunction);           
        }
    }

    updateItemNotes(itemIndex, noteIndex, isSelected) {
        if (itemIndex == null) { //Update all itemNotes for all aitems if itemIndex is null
            this.state.itemsToDisplay.forEach(function(item) {
                item.ItemNotes.forEach(function(note){
                    note.isSelected = isSelected;
                })
                item.isAllSelected = isSelected; //allows Global checkbox to toggle local item level select all
            })
        }
        else if (noteIndex == null) { //Update all itemNotes for the item at specified itemIndex
            this.state.itemsToDisplay[itemIndex].ItemNotes.forEach(function(note) {
                note.isSelected = isSelected;
            })
            this.state.itemsToDisplay[itemIndex].isAllSelected = isSelected;
        }
        else { //update specific note of specific item at provided indexes 
            this.state.itemsToDisplay[itemIndex].ItemNotes[noteIndex].isSelected = isSelected;
            var selectionBool = true;
            this.state.itemsToDisplay[itemIndex].ItemNotes.forEach(function(note) {
                if (note.isSelected == false) {
                    selectionBool = false;
                }
            })
        }
        this.forceUpdate();
    }

    saveParsedQuoteItems() {
        this.setState({currentModule: this.module.LOADING});
        ParserDataService.saveParsedQuoteItems(app.currentQuote.IdQuoteMain, this.props.currentTab, this.state.itemsToDisplay, function() {
            Dialog.close();            
        });
    }

    setChosenParseModule(newModule) {
        this.chosenParseModule = newModule;
    }

    getModule() {
        var me = this;
        switch(this.state.currentModule) {
            case this.module.CHOOSE:
                return {
                    module: <DellChooseUpload modules={this.modules} module={this.module} changeState={this.changeState}/>, 
                    backButtonText: null, 
                    nextButtonText: null,
                    onNext: function(){},
                    onBack: this.goBack
                }
            case this.module.PASTE:
                return {
                    module: <DellPasteEquote navRef={this}/>,
                    nextButtonText: "REVIEW",
                    onNext: this.submitPaste,
                    onBack: this.goBack
                }
            case this.module.PDF:
                return {
                    module: <PdfEquote navRef={this}/>,
                    nextButtonText: "REVIEW",
                    onNext: this.submitPDF,
                    onBack: null
                }
            case this.module.PARSE:
                return{
                    module: <DellParse parent={this}/>,
                    nextButtonText: "REVIEW",
                    onNext: this.uploadData,
                    onBack: null
                }
            case this.module.REVIEW:
                return {
                    module: <ReviewEquote updateItemNotes={this.updateItemNotes} allowEditNotes={this.allowEditNotes} itemsToDisplay={this.state.itemsToDisplay}/>,
                    backButtonText: "BACK", 
                    nextButtonText: "FINISH",
                    onNext: this.saveParsedQuoteItems,
                    onBack: function(){this.setState({currentModule: this.chosenModule})}.bind(this)
                }
            case this.module.LOADING:
          
           var spinnerArgs = {
               lines: 12,
               length: 12,
               width: 6,
               radius: 20
               
           };
                return {
                    module: <div style={{display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center'}}><Spinner args={spinnerArgs} title=" " /></div>,
                    backButtonText: null, 
                    nextButtonText: null,
                }
            case this.module.ERROR:
                return {
                    module: <Panel title="Error" style={{display:"flex", flex: 1, flexDirection: "column"}}><div style={{display: "flex", flex: 1, fontSize: "10pt", marginTop: "5px"}}>{this.state.errorText}</div></Panel>,
                    backButtonText: "Back", 
                    onBack: function(){this.setState({currentModule: this.chosenModule})}.bind(this), 
                    nextButtonText: null,
                }
        }
    }

    render() {
        var moduleState = this.getModule();
        var moduleStyle = { overflow: "auto", flex: 1, display: 'flex'}
        if (this.state.currentModule === this.module.REVIEW) {
            moduleStyle.border = "1px solid #cccccc" 
        }
        return (
            <div className="nodrop" style={{ display: "flex", position: 'relative', flex: 1 }}>
                <div style={moduleStyle}>
                    {moduleState.module}
                </div>
                <div className="links" style={{textAlign:"right", position: 'absolute', bottom: '-30px', right: 0 }}>
                    {moduleState.backButtonText ? 
                        <a className="link" onClick={moduleState.onBack}>{moduleState.backButtonText}</a> 
                        :
                        <div style={{display: "inline"}}/>
                    }
                    {moduleState.nextButtonText ? 
                        <a className="link" id="dellNavNextButton" onClick={moduleState.onNext}>{moduleState.nextButtonText}</a> 
                        :
                        <div style={{display: "inline"}}/>
                    }
                    {moduleState.middleButtonText ? 
                        <a className="link" onClick={moduleState.onMiddleClick}>{moduleState.middleButtonText}</a> 
                        :
                            <a className="link" onClick={Dialog.close.bind(null, null)}>CANCEL</a>
                    }
                </div>
            </div>
        );
    }
}

export class DellParse extends React.Component {
    render() {
        return (
            <div style={{ display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column' }}>
                <div style={{marginBottom: "30px", fontSize:"14px"}}>Choose one of the import methods below.</div>
                <PdfEquote parent={this} navRef={this.props.parent}/>
                <div style={{ marginTop: "10px", marginBottom:"10px", alignSelf: 'center' }}>Or</div>
                <DellPasteEquote parent={this} navRef={this.props.parent}/>
            </div>
        )
    }
}

class DellPasteEquote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // This binding is necessary to make `this` work in the callback
        this.chooseModule = this.chooseModule.bind(this);
        this.checkEquoteTextVisbility = this.checkEquoteTextVisbility.bind(this);
    }

    chooseModule() {
        this.props.navRef.setChosenParseModule(this.props.navRef.module.PASTE);
        if (this.props.parent) {
            this.props.parent.forceUpdate();
        }
        else {
            this.forceUpdate();
        }
    }

    checkEquoteTextVisbility() {
        var pasteSelected = this.props.navRef.chosenParseModule == this.props.navRef.module.PASTE;
        return pasteSelected || $("#dellParserEquoteText").val() ? 100 : 0;
    }

    componentDidMount() {
        var me = this;
        $("#dellTextDiv").mousedown(function(ev) {
            if(ev.which == 3) {
                me.chooseModule();
            }
        });
    }

    render() {
        var pasteStyle = {border: "1px solid #cccccc", flex: 1, position: 'relative', display: 'flex'};
        if (this.props.navRef.chosenParseModule == this.props.navRef.module.PASTE) {
            pasteStyle.border = "orange 1px solid";
        }
        var parserEquoteText = {resize: "none", flex: 1, border: 0};
        parserEquoteText.opacity = this.checkEquoteTextVisbility();     
        return (
            <div id="dellTextDiv" onClick={this.chooseModule} style={pasteStyle}>
                <div style={{textAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'center', position: "absolute",
                    flexDirection: 'column', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1
                }}>
                    <div className="paste-icon"></div>
                    <div style={{lineHeight: "35px", color: "#6a6a6a", fontSize: "15px"}}>
                        Copy &amp; Paste your Equote here
                    </div>
                </div>
                <textarea id="dellParserEquoteText" style={parserEquoteText}></textarea>
            </div>
        )
    }
}

class PdfEquote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        // This binding is necessary to make `this` work in the callback
        this.chooseModule = this.chooseModule.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.getFileSize = this.getFileSize.bind(this);
        this.getFileIconClass = this.getFileIconClass.bind(this);
    }

    componentDidMount() {
        var me = this;
        $('.file-drop').on('dragover', function(e) {
            me.chooseModule(me.props.navRef.module.PDF);
            return false;
        });
        $('.file-drop').on('dragleave', function(e) {
            me.chooseModule(me.props.navRef.module.NONE);
            return false;
        });
        $('.file-drop').on('drop', function(e) {
            e.preventDefault();
            var file = e.originalEvent.dataTransfer.files
            me.props.navRef.setFile(file);
            me.forceUpdate();
            return false;
        });
    }

    browseClick(){
        $("#QuoteFile").click();        
    }

    chooseModule(moduleParam) {
        this.props.navRef.setChosenParseModule(moduleParam ? moduleParam : this.props.navRef.module.PDF);
        if (this.props.parent) {
            this.props.parent.forceUpdate();
        } else {
            this.forceUpdate();
        }
    }

    onFileChange(e) {
        var file = e.currentTarget.files;
        this.props.navRef.setFile(file);
        this.forceUpdate();        
    }

    getFileIconClass() {
        var icon = "upload-icon"
        if (this.props.navRef.file && this.props.navRef.file[0]) {
            if (this.props.navRef.file[0].type == "application/pdf") {
                icon = "completed-icon";
            } else {
                icon = "error-icon";
            }
        }
        return icon;
    }

    getFileSize() {
        var fileSize = 0;
        if (this.props.navRef.file && this.props.navRef.file[0]) {
            fileSize = this.props.navRef.file[0].size;
        }

        return fileSize;
    }

    render() {
        var pdfStyle = {lineHeight: "35px", fontSize: "15px", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'};
        pdfStyle['border'] = this.state && this.state.pdfBorder ? this.state.pdfBorder : "1px dashed #cccccc" ;
        if (this.props.navRef.chosenParseModule == this.props.navRef.module.PDF) {
            pdfStyle["border"] = "orange 1px solid";
        } else {
            pdfStyle["border"] = "1px dashed #cccccc";
        }
        var uploadErrortext = "";
        var fileIconClass = this.getFileIconClass();        
        if (fileIconClass == "error-icon") {
            uploadErrortext = "Incorrect file type uploaded. Please upload a PDF file.";
        }

        var uploadSizeErrortext = "";
        var fileSize = this.getFileSize();
        if (fileSize > 31457280) {
            fileIconClass = "error-icon";
            uploadSizeErrortext = "File size must be 30MB or smaller. Please upload another PDF file.";
        }
        return (
            <div className="file-drop" onClick={this.chooseModule.bind(this, this.props.navRef.module.PDF)} style={pdfStyle}>
                <div style={{textAlign: "center"}}>
                    <div className={fileIconClass}></div>
                    <div style={{ color: "#6a6a6a" }}>{uploadErrortext}</div>
                    <div className={fileSize}></div>
                    <div style={{ color: "#6a6a6a" }}>{uploadSizeErrortext}</div>
                    <div style={{color: "#6a6a6a"}}>Drop your Equote PDF file here or
                        <a href="javascript:void(0)" onClick={this.browseClick}> Browse</a>
                    </div>
                    <div style={{color: "#6a6a6a"}}>File must be 30MB or smaller.</div>
                    {this.props.navRef.file && <div style={{color: "#6a6a6a"}}>Filename: {this.props.navRef.file[0].name}</div>}
                    <input style={{visibility :"hidden", position: 'absolute', left: '-10000px'}} type="file" onChange={this.onFileChange} id="QuoteFile" title="Quote File"/>
                </div>
            </div>
        );
    }
}

class ReviewEquote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            selectAllState: true
        }; 

        // This binding is necessary to make `this` work in the callback
        this.toggleNoteSelection = this.toggleNoteSelection.bind(this);
        this.toggleAllNoteSelection = this.toggleAllNoteSelection.bind(this);
        this.toggleAllItems = this.toggleAllItems.bind(this);
        this.displayItems = this.displayItems.bind(this);
    }
   
    toggleNoteSelection(e) {
        var selectedState = !this.props.itemsToDisplay[e.target.dataset.itemindex].ItemNotes[e.target.dataset.noteindex].isSelected;
        this.props.updateItemNotes(e.target.dataset.itemindex, e.target.dataset.noteindex, selectedState )
    }

    toggleAllNoteSelection(itemIndex, isSelectedState) {
        this.props.updateItemNotes(itemIndex, null, !isSelectedState);
    }

    toggleAllItems() {
        this.props.updateItemNotes(null, null, !this.state.selectAllState);
        this.setState({selectAllState: !this.state.selectAllState});
    }

    formatMoney(amount) {
        if (accounting != null) {
            return accounting.formatMoney(amount);
        }
        return amount;
    }

    displayItems() {
        var me = this;
        var parseItems = this.props.itemsToDisplay.map(function(item, index) {
            var itemNotes;
            if (me.props.allowEditNotes) {
                var itemIndex = index;
                itemNotes = item.ItemNotes.map(function(item, index) {
                    var selectionBool = me.props.itemsToDisplay[itemIndex].ItemNotes[index].isSelected;
                    var isChecked = selectionBool;
                    return (
                        <div style={{marginLeft: "40px"}} key={"note_"+ index}>
                            <input data-itemindex={itemIndex} data-noteindex={index} type="checkbox" checked={isChecked} onChange={me.toggleNoteSelection} style={{ verticalAlign: "middle", marginRight: "3px"}}/>
                            <span style={{marginLeft: "10px", lineHeight: "24px", fontSize: "11pt" }}>{item.note}</span>
                        </div>
                    )
                })
            }
            
            return (
                <div key={"item_" + index}>
                    <div style={{marginLeft: "-10px", background:"#f5f5f5", height:"30px", lineHeight:"30px"}}>
                    <label style={{whiteSpace: "nowrap", fontSize: "12pt", display: "inline-block", width: "20%", overflow:"hidden", paddingRight: "20px", paddingLeft:"35px"}} title={item.LongDescription}>{item.LongDescription}</label>
                    <label style={{whiteSpace: "nowrap", fontSize: "12pt", display: "inline-block", width: "15%", overflow:"hidden", paddingRight: "20px", paddingLeft:"5px"}} title={"MFP: " + item.ManufacturerPartNumber}><span style={{color:"gray"}}>MFP:</span> {item.ManufacturerPartNumber}</label>
                    <label style={{whiteSpace: "nowrap", fontSize: "12pt", display: "inline-block", width: "5%", overflow:"hidden", paddingRight: "20px", paddingLeft:"5px"}} title={"Qunatity: " + item.Quantity}><span style={{color:"gray"}}>Qty:</span> {item.Quantity}</label>
                    <label style={{whiteSpace: "nowrap", fontSize: "12pt", display: "inline-block", width: "20%",  overflow:"hidden", paddingRight: "20px", paddingLeft:"5px"}} title={"Starting Cost: " + me.formatMoney(item.StartingCost)}><span style={{color:"gray"}}>Starting Cost:</span> {me.formatMoney(item.StartingCost)}</label>
                    <label style={{whiteSpace: "nowrap", fontSize: "12pt", display: "inline-block", width: "20%",  overflow:"hidden", paddingLeft:"5px"}} title={"Cost: " + me.formatMoney(item.Cost)}><span style={{color:"gray"}}>Cost:</span> {me.formatMoney(item.Cost)}</label>
                    </div>
                    <div style={{paddingBottom: "20px"}}>
                        {me.props.allowEditNotes &&
                                <div style={{marginTop: "10px", marginLeft: "20px", fontSize: "14px"}}>
                                    <input style={{verticalAlign: "middle"}} type="checkbox" checked={item.isAllSelected}  onChange={me.toggleAllNoteSelection.bind(me, index, item.isAllSelected)}/>
                                    <span style={{marginLeft: "10px"}}>Select all sub items</span>
                                </div>
                        }
                        {itemNotes}
                    </div>
                </div>
            )               
        })

        return (
            <div  style={{display: 'flex', flex: 1, alignItems: 'stretch', flexDirection: 'column'}} >
                {this.props.allowEditNotes && 
                <div style={{marginLeft: "10px", fontSize: "14px", marginTop: "25px", marginBottom:"10px"}}>
                    <input style={{verticalAlign: "middle"}} type="checkbox" checked={this.state.selectAllState}  onChange={this.toggleAllItems}/>
                    <span style={{marginLeft: "10px"}}>Select all</span>
                </div>}
                {parseItems}
            </div>
        );
    }

    render() {
        if (this.props.itemsToDisplay != null) {
            var parseItemHtml = this.displayItems();
        }
        return (
            <div title="Review Items" style={{display:"flex", flex: 1}}>
                {parseItemHtml}
            </div>
        )
    }
}

class ParserDataService {
    static parsePaste(idQuoteMain, currentTab, pasteContent, parser, callback) {
        var submit = quosal.api.cloud.parseEquote(idQuoteMain, currentTab, pasteContent, "PASTE", parser);
        submit.finished = function(msg){
            ParserDataService.parseItemNotes(msg, callback);
        };
        submit.call();
    }

    static parsePDF(idQuoteMain, currentTab, pasteContent, parser, callback) {
       var formData = new FormData();
        formData.append("file", pasteContent);

        $.ajax('uploadParsePDF.quosalweb' + location.search + '&noheader=yes', {
            cache: false,
            type: 'post',
            data: formData,
            processData: false,
            contentType: false,
            success: (function (data) {
                var parsedData = JSON.parse(data);
                if (parsedData.error) {
                    if(parsedData.sellErrorIds){
                        parsedData.error += ". Error Id: ";
                        parsedData.error += parsedData.sellErrorIds;
                    }
                    callback(null, null, parsedData.error);
                } else {
                    var submit = quosal.api.cloud.parseEquote(idQuoteMain, currentTab, pasteContent, "PDF", parser);
                    submit.finished = function(msg){
                        ParserDataService.parseItemNotes(msg, callback);
                    };
                    submit.call()
                }
            }).bind(this)
        });
    }

    static parseItemNotes(msg, callback) {
        if (msg.error != "") {
            callback(msg.parsedItems, msg.allowEditNotes, msg.error)
            return;
        }
        msg.parsedItems.map(function (dItem, index) {
        dItem.ItemNotes = dItem.ItemNotesPlain.split(/\n/g).map(function(item, index) {
            return {note: item, isSelected: true}
            });
        dItem.isAllSelected = true;
        });

        callback(msg.parsedItems, msg.allowEditNotes, msg.error)        
    }

    static saveParsedQuoteItems(idQuoteMain, currentTab, itemsToDisplay, callback) {
        itemsToDisplay.forEach(function(item) {
            item.table = "QuoteItems";
            item.ItemNotesPlain = item.ItemNotes.reduce(function(note, noteLine){
                if(noteLine.isSelected){
                    note += noteLine.note;
                    note += "\n";
                }
                return note;
            }, "");
			//default the font and size for now as the backend will correct it based on default font and size set in Settings
			//need to check which item note is selected or not to avoid always grabbing all the item notes regardless of selection
			item.ItemNotesHtml = item.ItemNotes.reduce(function(note, noteLine) {
				if(noteLine.isSelected) {
					note += noteLine.note;
					note += "<br/>";
				}
				return note;
			}, '<div><span style="font-family:Arial;font-size:10pt;">');
			item.ItemNotesHtml += "</span></div>";
            item.ItemNotes.forEach(function(noteLine){
                noteLine.note = encodeURIComponent(noteLine.note);
            });
            item.ItemNotesPlain = encodeURIComponent(item.ItemNotesPlain);
            item.LongDescription =  encodeURIComponent(item.LongDescription)
        });     
        
        var submit = quosal.api.data.create(itemsToDisplay, idQuoteMain, true);
        
        submit.finished = function(msg){
            quosal.sell.quote.update(msg.quote);
            callback();
        };
        submit.call()
    }   
}