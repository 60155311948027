import { getCKEditorTabsIds } from "./getCKEditorTabsIds";

export const deleteTableFromEditor = (ckeditor, deleteTabId) => {
	const editorData = app.currentQuote.HTMLContentForAutoSaving;
	const droppedTabIds = getCKEditorTabsIds(editorData);
	if (droppedTabIds.includes(deleteTabId)) {
		const pattern = new RegExp(
			`<section[^>]*data-id="${deleteTabId}"[^>]*>[\\s\\S]*?<\\/section>`
		);
		const editorDataAfterDeleteTable = editorData.replace(pattern, "");
		ckeditor.setData(editorDataAfterDeleteTable);
		app.currentQuote.HTMLContentForAutoSaving = editorDataAfterDeleteTable;
	}
};
