import {Warning} from "js/jsx/src/classes/notifications/statusWarning.jsx";
import {CustomerSetupToolbar} from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";
import {Form, FormFieldInput} from "js/jsx/src/classes/forms.jsx";

export class CwOpportunity extends React.Component {
    constructor(props) {
        super(props);

        this.startLoadTime = new Date().getTime();

        this.state = {
            configuration: null,
            validSubCategories: {},
            isDirty: false,
            tabsSetToOneTimeWithOnlyRecurringItems: null
       };

        this.isEoPage = !!($("#isEoPage").length);
        this.saveCallback = this.saveCallback.bind(this);
        this.saveOpportunityApiCallback = this.saveOpportunityApiCallback.bind(this);
        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.initialize.call(this);
    }

    static newOppItemState(allItems) {
        allItems = allItems || {};
        return {
            forecastItems: allItems.forecastItems || null,
            newItems: allItems.newItems || null,
            oppItems: allItems.oppItems || null
        };
    }
    saveCallback() {
        if (this.validate()) {
            var params = {
                formValues: this.refs.opportunityDetailsPanel.getFormValues({getAllValues: true}),
                tabValues: this.refs.tabPanel.getValues(),
                forecastValues: this.refs.forecastPanel && this.refs.forecastPanel.getValues(),
                newItemValues: this.refs.newItemPanel && this.refs.newItemPanel.getValues(),
                oppItemValues: this.refs.oppItemPanel && this.refs.oppItemPanel.getValues()
            }

            Dialog.setIsWorking();
            var saveOpportunityAPI = quosal.api.crm.connectwise.saveOpportunity(app.currentQuote.IdQuoteMain, params)
            saveOpportunityAPI.finished = function (msg) {
                if(msg.saveOpportunityWarnings){
                    Dialog.setIsWorking(false);

                    var dialogLinks = [{
                        title: 'OK', callback: ()=>{Dialog.closeAll(); if( !msg.isError) {this.saveOpportunityApiCallback(msg);}}
                    }];

                    if(msg.poExistsError)
                    {
                        dialogLinks = [{
                            title: 'Yes',
                            callback: ()=>{Dialog.closeAll(); this.detachPO(msg);}
                        }, {
                            title: 'No',
                            callback:()=>{ Dialog.closeAll();}
                        }]
                    }

                    Dialog.open({
                        title: msg.poExistsError ? "Save Opportunity Error" : "Save Opportunity Warning",
                        message: msg.saveOpportunityWarnings,
                        closeRequiresButton: true,
                        links: dialogLinks
                    });
                }
                else{
                    this.saveOpportunityApiCallback(msg);
                }
                
            }.bind(this);
            saveOpportunityAPI.call();
        }
    }
    saveOpportunityApiCallback(msg){
        if (msg.quote) {
            quosal.sell.quote.update(msg.quote);
        }
        Dialog.setIsWorking(false);

        app.currentModule.unloadSubModules();

        if(this.isEoPage)
        {
            quosal.navigation.navigate(quosal.util.url('electronicorder.quosalweb', 'nav=convertopportunity'));
        }
        else
        {
            if (app.currentQuote.OrderPorterApproved) {
                quosal.navigation.navigate(quosal.util.url('editquote.quosalweb', 'nav=win'));
            } else {
                quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.home'));
            }
        }
    }
    detachPO(msg){
        var detachPoAPI = quosal.api.electronicOrders.detachPurchaseOrders(app.currentQuote.IdQuoteMain);
        detachPoAPI.call();
    }
    initialize() {
        quosal.data.searchAPIs = quosal.data.searchAPIs || {};
        var oppGroupingTypes = quosal.customization.forms.CwOpportunity.Metadata.OpportunityGroupingTypes;
        if (oppGroupingTypes[0].Value === 'None') {
            quosal.customization.forms.CwOpportunity.Metadata.OpportunityGroupingTypes = oppGroupingTypes.slice(1);
        }

        if(this.isEoPage)
        {
            app.currentQuote.ConnectwiseOpGroup = oppGroupingTypes[1].Value; // default to "Group By Line Item".          
        }

        var preparationApi = quosal.api.crm.connectwise.prepareForCwOpportunityDetail(app.currentQuote.IdQuoteMain, this.isEoPage ? app.currentQuote.ConnectwiseOpGroup : null );
        preparationApi.finished = function (msg) {
            if(msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }

            var opportunity = msg.opportunity;
            if (!opportunity) {
                quosal.log.error('Opportunity is missing.');
                return;
            }
            var opportunityMetadata = msg.opportunityMetadata;
            for (var enumType in opportunityMetadata) {
                if (opportunityMetadata.hasOwnProperty(enumType)) {
                    quosal.metadata.enums[enumType] = opportunityMetadata[enumType];
                }
            }

            var validSubCategories = msg.validSubCategories;
            var validSubcategoryHashset = {};
            if (validSubCategories) {
                for (var i in validSubCategories) {
                    if (validSubCategories.hasOwnProperty(i)) {
                        validSubcategoryHashset[validSubCategories[i]] = true;
                    }
                }
            }

            quosal.data.searchAPIs.CwMarketingCampaignSearch = function (searchCriteria) {
                return quosal.api.crm.connectwise.campaignSearch(searchCriteria.CampaignName);
            }.bind(this);


            var formConfiguration = quosal.customization.forms.bindForm(quosal.customization.forms['CwOpportunity'].Configurations.Default, opportunity);

            var fieldsByName = {};
            for (var i in formConfiguration.Fields) {
                var field = formConfiguration.Fields[i];
                if (field.FieldName) {
                    fieldsByName[field.FieldName] = field;
                }
            }

            if (!msg.canShowDropship) {
                fieldsByName['Dropship'].className = 'hide';
            }
            fieldsByName['Dropship'].Value = msg.dropship;
            this.dropship = msg.dropship;
            fieldsByName['Dropship'].onChange = function (cwOpportunityPage, input, callback) {
                if (cwOpportunityPage.state.oppItems
                    && cwOpportunityPage.state.oppItems.length
                    && cwOpportunityPage.dropship != input.checked) {
                    cwOpportunityPage.dropship = input.checked;
                    cwOpportunityPage.refs.oppItemPanel.setDropships(input.checked);

                    if('function' === typeof callback) {
                        callback();
                    }
                }

            }.bind(null, this);
            fieldsByName['AttachDocuments'].Value = msg.allowAttachment;
            fieldsByName['GroupingType'].Value = app.currentQuote.ConnectwiseOpGroup;
            fieldsByName['GroupingType'].onChange = function (cwOpportunityPage, input, callback) {
                if (Dialog.isWorking()) {
                    return;
                }
                if (app.currentQuote.ConnectwiseOpGroup != input.value) {
                    Dialog.setIsWorking();
                    var updateOppItemsApi = quosal.api.crm.connectwise.getOpportunityItems(app.currentQuote.IdQuoteMain, input.value);
                    updateOppItemsApi.finished = function (cwOpportunityPage, msg) {
                        if (msg.quote) {
                            quosal.sell.quote.update(msg.quote);
                        }
                        Dialog.setIsWorking(false);

                        var newState = CwOpportunity.newOppItemState(msg.oppItems);
                        newState.tabsSetToOneTimeWithOnlyRecurringItems = msg.tabsSetToOneTimeWithOnlyRecurringItems;

                        cwOpportunityPage.setState(newState);
                    }.bind(null, cwOpportunityPage);
                    updateOppItemsApi.call();
                }
                
                if('function' === typeof callback) {
                    callback();
                }
            }.bind(null, this);
            fieldsByName['MarketingCampaign'].onChange = function (cwOpportunityPage, input, callback) {
                cwOpportunityPage.MarketingCampaign = input.value;

                if('function' === typeof callback) {
                    callback();
                }
            }.bind(null, this);

            var newState = CwOpportunity.newOppItemState(msg.oppItems);
            newState.opportunity = opportunity;
            newState.configuration = formConfiguration;
            newState.validSubCategories = validSubcategoryHashset;
            newState.canShowDropship = msg.canShowDropship;
            newState.canShowSpecialOrder = msg.canShowSpecialOrder;
            newState.dropship = msg.dropship;
            newState.hasSubmittedEo = msg.hasSubmittedEo;
            newState.tabsSetToOneTimeWithOnlyRecurringItems = msg.tabsSetToOneTimeWithOnlyRecurringItems;
            this.setState(newState);

            if (window.appInsights) {
                var userInfo = quosal.util.getUserEmailAndRole();
                var elapsed = new Date().getTime() - this.startLoadTime;
                window.appInsights.trackPageView("Load - CW Opportunity",
                    window.location.href,
                    { EmailAddress: userInfo.email, UserRole: userInfo.role },
                    null,
                    elapsed);
            }
        }.bind(this);

        preparationApi.call();
    }
    validate() {
        var result = true;

        var expectedCloseDateFormField = this.refs.opportunityDetailsPanel.refs.panel0.refs.fields.getFieldByName('ExpectedCloseDate');
        var getErrorForExpectedCloseDateFormField = function () {
            var expectedCloseDate = this.refs.input.getValue();
            if (expectedCloseDate) {
                return false;
            } else {
                return 'Expected Close Date is required.';
            }
        }.bind(expectedCloseDateFormField);
        expectedCloseDateFormField.defineValidationFunction(getErrorForExpectedCloseDateFormField);
        result = result && expectedCloseDateFormField.validate();
        
        return result;
    }
    componentWillUnmount() {
        delete quosal.data.searchAPIs.CwMarketingCampaignSearch;
    }
    onChange() {
        this.setState({isDirty: true});
    }
    reloadOpportunityPage() {
        app.currentModule.unloadSubModule(app.currentModule.getActiveSubModule());
        app.currentModule.loadSubModule('crm.opportunity', {container: 'quoteModule'});
    }
    render() {
        var customerSetupToolbarProps = {
            saveCallback: this.saveCallback,
            cancelCallback: this.reloadOpportunityPage,
            isDirty: this.state.isDirty,
            saveButtonAlwaysActive: true,
            submoduleName: 'crm.opportunity',
            afterRemoveOpportunity: this.reloadOpportunityPage
        };
        return (
            !this.state.opportunity ? <FormPlaceholder message={'Loading Manage Opportunity...'} /> :
            <div>
                {!this.state.hasSubmittedEo ? <CustomerSetupToolbar {...customerSetupToolbarProps} /> : null}
                    <form ref="form" disabled={this.state.hasSubmittedEo}>
                    <div>
                        <Form ref="opportunityDetailsPanel" configuration={this.state.configuration} editable={false}
                              doCorrectSynonyms={true}
                              onChange={this.onChange} onSubmit={this.saveCallback}
                              customizeInput={this.customizeInput} disabled={this.state.hasSubmittedEo}/>

                            <CwOpportunityTabPanel ref="tabPanel" cwOpportunityPage={this} disabled={this.state.hasSubmittedEo} tabsSetToOneTimeWithOnlyRecurringItems={this.state.tabsSetToOneTimeWithOnlyRecurringItems} />
                        {this.state.forecastItems ? <CwOpportunityForecastPanel ref="forecastPanel" disabled={this.state.hasSubmittedEo} forecastItems={this.state.forecastItems} onChange={this.onChange} /> : null}
                        {this.state.newItems ? <CwOpportunityNewItemPanel ref="newItemPanel" disabled={this.state.hasSubmittedEo} newItems={this.state.newItems} validSubCategories={this.state.validSubCategories} onChange={this.onChange} /> : null}
                        {this.state.oppItems ? <CwOpportunityItemPanel ref="oppItemPanel" disabled={this.state.hasSubmittedEo}  oppItems={this.state.oppItems} canShowDropship={this.state.canShowDropship} canShowSpecialOrder={this.state.canShowSpecialOrder} onChange={this.onChange } /> : null}
                    </div>
                </form>
                        {!this.state.hasSubmittedEo ? <CustomerSetupToolbar {...customerSetupToolbarProps} isBottomToolbar={true} /> : null}
            </div>
        );
                        }
}

global.CwOpportunity = CwOpportunity;
class CwOpportunityTabPanel extends React.Component {
    constructor(props) {
        super(props);

        this.getValues = this.getValues.bind(this);
        this.itemsByTabId = quosal.util.getItemsByTabId(app.currentQuote);      
    }
    getValues () {
        var values = {};
        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            var row = this.refs[i];
            if (row) {
                values[row.props.tab.id] = row.getValues();
            }
        }
        return values;
    }
    render () {
        var tableRows = [];
        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            var tab = app.currentQuote.Tabs[i];
            if ((!tab.IsPrinted && !tab.IsTotalsIncluded) ||
                !(this.itemsByTabId[tab.id] && this.itemsByTabId[tab.id].length)
            ) {
                continue;
            }
            tableRows.push(
                <CwOpportunityTabRow disabled={this.props.disabled} key={i} ref={i} tab={tab} cwOpportunityPage={this.props.cwOpportunityPage}/>
            );
        }

        var tabsOneTimeWarningMessage = '';
        if (this.props.tabsSetToOneTimeWithOnlyRecurringItems && this.props.tabsSetToOneTimeWithOnlyRecurringItems.length > 1) {
            tabsOneTimeWarningMessage = this.props.tabsSetToOneTimeWithOnlyRecurringItems.join(", ") +
                "  are set as One-Time tabs but only have recurring products on them. These will not be included in the forecast. " +
                "Please change the Term/Recurring option for these products to be included in the forecast.";

        }
        if (this.props.tabsSetToOneTimeWithOnlyRecurringItems && this.props.tabsSetToOneTimeWithOnlyRecurringItems.length == 1) {
            tabsOneTimeWarningMessage = this.props.tabsSetToOneTimeWithOnlyRecurringItems.join() +
                " is set as a One-Time tab but only has recurring products on it. These will not be included in the forecast. " +
                "Please change the Term/Recurring option for these products to be included in the forecast.";
        }
        else {
            tabsOneTimeWarningMessage: '';
        }

        return (           
            <Panel title="Tab Settings" id="ManageOpportunityId" disabled={this.props.disabled}>
            <PanelContent>
                    {(tabsOneTimeWarningMessage) &&                   
                        <Warning message={tabsOneTimeWarningMessage}/>
                    }
                <table id="tabsettings" className="datagrid nosort">
                    <thead>
                        <tr>
                            <th width="400" className="title">Tab Name</th>
                            <th width="10" className="title">Manage Type</th>
                            <th width="10" className="title">Term / Recurring</th>
                            <th width="200" className="title">Tab Is Deselected Option</th>
                        </tr>
                    </thead>
                    <tbody>
    {tableRows}
                    </tbody>
                </table>
            </PanelContent>
        </Panel>
    );
    }
}
class CwOpportunityTabRow extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);
        this.onRecurringRevenueChange = this.onRecurringRevenueChange.bind(this);
        this.onCwTypeChange = this.onCwTypeChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
    }
    getValues() {
        var values = {};
        for (var i in this.refs) {
            values[i] = this.refs[i].getValue();
        }
        return values;
    }
    onTabChange() {
        if (Dialog.isWorking()) {
            return;
        }
        Dialog.setIsWorking();
        var updateOppItemsApi = quosal.api.crm.connectwise.changeTabSettingsAndGetOpportunityItems(
            app.currentQuote.IdQuoteMain, this.props.tab.IdQuoteTabs, this.refs.ConnectwiseType.getValue(), this.refs.IdRecurringRevenue.getValue());
        updateOppItemsApi.finished = function (cwOpportunityPage, msg) {
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            Dialog.setIsWorking(false);

            //this.props.onTabSettingChange(msg.tabsSetToOneTimeWithOnlyRecurringItems);
            var cwOppState = CwOpportunity.newOppItemState(msg.oppItems);
            cwOppState.tabsSetToOneTimeWithOnlyRecurringItems = msg.tabsSetToOneTimeWithOnlyRecurringItems;
            cwOpportunityPage.setState(cwOppState);
        }.bind(null, this.props.cwOpportunityPage);
        updateOppItemsApi.call();
    }
    onCwTypeChange() {
        if (this.props.tab.ConnectwiseType != this.refs.ConnectwiseType.getValue()) {
            this.onTabChange();
        }
    }
    onRecurringRevenueChange() {
        if (this.props.tab.IdRecurringRevenue != this.refs.IdRecurringRevenue.getValue()) {
            this.onTabChange();
        }
    }
    render() {
        var tab = this.props.tab;
        var tabConnectWiseTypeField = {
            FieldName: 'Tab.ConnectwiseType.' + tab.IdQuoteTabs,
            DataType: 'Enum',
            EnumType: 'ConnectWiseTabType',
            fieldId: 'Tab.ConnectwiseType.' + tab.IdQuoteTabs,
            Value: tab.ConnectwiseType
        };
        var tabRecurringRevenueField = {
            FieldName: 'Tab.RecurringRevenue.' + tab.IdQuoteTabs,
            DataType: 'Enum',
            EnumType: 'AllRecurringRevenues',
            fieldId: 'Tab.RecurringRevenue.' + tab.IdQuoteTabs,
            Value: tab.IdRecurringRevenue
        };
        return (
            <tr data-tab={tab.IdQuoteTabs} >
                <td className="content">{tab.TabName}</td>
                <td className="content">
                    <FormFieldInput disabled={this.props.disabled} ref="ConnectwiseType" field={tabConnectWiseTypeField} onChange={this.onCwTypeChange} />
                </td>
                <td className="content">
                    <FormFieldInput disabled={this.props.disabled} ref="IdRecurringRevenue" field={tabRecurringRevenueField} onChange={this.onRecurringRevenueChange} />
                </td>
                <td className="content center"><input type="checkbox" disabled defaultChecked={tab.IsOptional}/></td>
            </tr>
        );
    }
}

class CwOpportunityForecastPanel extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);
    }
    getValues() {
        var values = {};
        for (var i = 0; i < this.props.forecastItems.length; i++) {
            var item = this.props.forecastItems[i];
            var input = this.refs['input' + i];
            values[item.description] = input.checked;
        }
        return values;
    }
    render() {

        var forecastRows = [];
        var forecastItems = this.props.forecastItems;
        for (var i = 0; i < forecastItems.length; i++){
            var item = forecastItems[i];
            var row = <tr key={i} >
                <td className="content center"><input ref={'input' + i} type="checkbox" defaultChecked={item.included}
                                                      value="true" name={'Forecast.Include.' + item.description}
                    onChange={this.props.onChange} disabled={this.props.disabled} /></td>
                <td className="content center" id={item.description + " description"}>{item.description}</td>
                <td className="content center" id={item.description + " type"}>{item.type}</td>
                <td className="content center" id={item.description + " term"}>{item.term}</td>
                <td className="content center" id={item.description + " revenue"}>{item.revenue}</td>
                <td className="content center" id={item.description + " cost"}>{item.cost}</td>
                <td className="content center" id={item.description + " recurringRevenue"}>{item.recurringRevenue}</td>
                <td className="content center" id={item.description + " recurringCost"}>{item.recurringCost}</td>
            </tr>
            forecastRows.push(row);
        }

        return (
            <Panel title="Manage Forecasts" id="forecastpanel" >
                <PanelContent>
                    <table className="datagrid nosort" >
                        <thead>
                            <tr>
                                <th width="100" className="title">Include</th>
                                <th width="300" className="title">Description</th>
                                <th width="100" className="title">Type</th>
                                <th width="100" className="title">Term / Recurring</th>
                                <th width="100" className="title">One-Time Revenue</th>
                                <th width="100" className="title">One-Time Cost</th>
                                <th width="100" className="title">Recurring Revenue</th>
                                <th width="100" className="title">Recurring Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {forecastRows}
                        </tbody>
                    </table>
                </PanelContent>
            </Panel>
        );
    }
}
class CwOpportunityNewItemPanel extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);
    }
    getValues() {
        var values = {};
        for (var i = 0; i < this.props.newItems.length; i++) {
            var row = this.refs[i];
            values[row.props.item.id] = row.getValues();
        }
        return values;
    }
    render() {
        var newItemRows = [];
        var newItems = this.props.newItems;
        for (var i = 0; i < newItems.length; i++) {
            var item = newItems[i];

            newItemRows.push(<CwOpportunityNewItemRow key={i} ref={i} index={i} item={item} onChange={this.props.onChange}></CwOpportunityNewItemRow>);
        }
        return (
            <Panel title="Create New Items" id="newitemspanel">
                <PanelContent>
                    <table className="datagrid nosort">
                        <thead>
                        <tr>
                            <th width="90" className="title">Product ID</th>
                            <th width="260" className="title">Description</th>
                            <th width="80" className="title">Cost</th>
                            <th width="80" className="title">Price</th>
							<th width="80" className="title">Recurring Cost</th>
							<th width="80" className="title">Recurring Price</th>
                            <th width="75" className="title">Taxable</th>
                            <th width="75" className="title">Serialized</th>
							<th width="75" className="title">Apply Cost by Serial Number</th>
                            <th width="90" className="title">Class</th>
                            <th width="90" className="title">Type</th>
                            <th width="90" className="title">Cat / Subcat</th>
                            <th width="90" className="title">Manufacturer</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newItemRows}
                        </tbody>
                    </table>
                </PanelContent>
            </Panel>
        );
    }
}
class CwOpportunityNewItemRow extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);		
    }
    getValues() {
        var values = {};
        for (var i in this.refs) {
            var input = this.refs[i];
            if (input.getValue) {
                values[i] = input.getValue();
            } else {
                values[i] = input.checked;
            }
        }
        return values;
    }
	showApplyCostCheckbox(id) {
		this.setState({isDirty: true});
		if (document.getElementById("SerializedCheck" + id).checked) {
			document.getElementById("SerializedCostCheck" + id).disabled = false;
		}
		else {
			document.getElementById("SerializedCostCheck" + id).disabled = true;
		}
	}
    render() {
        var i = this.props.index;
        var item = this.props.item;

        var cwclassField = {
            FieldName: 'cwclass' + item.id,
            DataType: 'Enum',
            EnumType: 'ConnectWiseProductClasses',
            Value: item.cwclass
        };
        var cwtypeField = {
            FieldName: 'cwtype' + item.id,
            DataType: 'Enum',
            EnumType: 'ConnectWiseProductTypes',
            Value: item.cwtype
        };
        var cwcategoryField = {
            FieldName: 'cwcategory' + item.id,
            DataType: 'Enum',
            EnumType: 'ConnectWiseProductCategories',
            Value: item.cwcategory
        };
        var cwmanufacturerField = {
            FieldName: 'cwmanufacturer' + item.id,
            DataType: 'Enum',
            EnumType: 'ConnectWiseProductManufacturers',
            Value: item.cwmanufacturer
        };
        return (
            <tr key={i}>
                <td className="content" id={item.id + " cwMfp"}>{item.mfp}</td>
                <td className="content center" id={item.id + " cwDescription"}>{item.description}</td>
                <td className="content" id={item.id + " cwCost"}>{item.cost}</td>
                <td className="content" id={item.id + " cwPrice"}>{item.price}</td>
                <td className="content" id={item.id + " cwRecurringCost"}>{item.recurringCost}</td>
                <td className="content" id={item.id + " cwRecurringPrice"}>{item.recurringPrice}</td>
                <td className="content center">
                    <input ref="IsTaxable" disabled={this.props.disabled} id={item.id + " cwIsTaxable"} type="checkbox" name={'IsTaxable' + item.id} value="yes" defaultChecked={item.istaxable} onChange={this.props.onChange}/>
                </td>
                <td className="content center">
                    <input id={'SerializedCheck' + item.id} ref="Serialized" disabled={this.props.disabled} type="checkbox" name={'Serialized' + item.id} value="yes" defaultChecked={item.isserializable} onChange={this.showApplyCostCheckbox.bind(this, item.id)} />
                </td>
				<td className="content center">
                    <input id={'SerializedCostCheck' + item.id} ref="SerializedCost" disabled type="checkbox" name={'SerializedCost' + item.id} value="yes" defaultCheck={item.serializedCost} onChange={this.props.onChange}/>
				</td>
                <td className="content">
                    <FormFieldInput ref="cwclass" disabled={this.props.disabled} id={item.id + " cwClass"} field={cwclassField} style={{width:100}} onChange={this.props.onChange} />
                </td>
                <td className="content">
                        <FormFieldInput ref="cwtype" disabled={this.props.disabled} id={item.id + " cwType"} field={cwtypeField} style={{width:100}} onChange={this.props.onChange} />
                </td>
                <td className="content">
                        <FormFieldInput ref="cwcategory" disabled={this.props.disabled} id={item.id + " cwCategory"} field={cwcategoryField} style={{width:100}} onChange={this.props.onChange}
                                    doCorrectSynonyms={true} />
                </td>
                <td className="content">
                        <FormFieldInput ref="cwmanufacturer" disabled={this.props.disabled} id={item.id + " cwManufacturer"} field={cwmanufacturerField} style={{width:100}} onChange={this.props.onChange} />
                </td>

            </tr>
        );
    }
}
class CwOpportunityItemPanel extends React.Component {
    constructor(props) {
        super(props);
        
        var dropship = [];
        var items = this.props.oppItems;
        for (var i = 0; i < items.length; i++) {
            dropship[i] = items[i].dropship;
        }
        this.state = {
            dropship: dropship
        };

        this.getValues = this.getValues.bind(this);
        this.setDropships = this.setDropships.bind(this);
        this.onDropshipChange = this.onDropshipChange.bind(this);
    }
    getValues() {
        var values = {};
        for (var i = 0; i < this.props.oppItems.length; i++) {
            var row = this.refs[i];
            values[row.props.item.idQuoteItems] = row.getValues();
        }
        return values;
    }
    setDropships(newValue) {
        if ('boolean' === typeof newValue) {
            var dropship = [];
            var items = this.props.oppItems;
            for (var i = 0; i < items.length; i++) {
                dropship[i] = newValue;
            }
            this.setState({
                dropship: dropship
            })
            this.props.onChange();
        }
    }
    onDropshipChange(index, e) {
        this.state.dropship[index] = e.target.checked;
        this.setState({dropship: this.state.dropship});
        this.props.onChange();
    }
    render() {        
        var itemRows = [];
        var items = this.props.oppItems;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            itemRows.push(
                <CwOpportunityItemRow parent={this} key={i} ref={i} index={i} item={item} onChange={this.props.onChange} ></CwOpportunityItemRow>
            );
        }
        return (
            <Panel title="Manage Items" id="oppitemspanel">
                <PanelContent>
                    <table className="datagrid nosort">
                        <thead>
                        <tr>
                            <th width="100" className="title">Quantity</th>
                            <th width="300" className="title">Description</th>
                            <th width="100" className="title">Cost</th>
                            <th width="100" className="title">Price</th>
                            <th width="100" className="title">Recurring Cost</th>
							<th width="100" className="title">Recurring Price</th>
                            <th width="100" className="title">Billable</th>
                            <th width="100" className="title">Dropship</th>
                            {this.props.canShowSpecialOrder ? <th width="100" className="title">Special Order</th> :  null}
                        </tr>
                        </thead>
                        <tbody>
                        {itemRows}
                        </tbody>
                    </table>
                </PanelContent>
            </Panel>
        );
    }
}
class CwOpportunityItemRow extends React.Component {
    constructor(props) {
        super(props);
        this.getValues = this.getValues.bind(this);
    }
    getValues() {
        var values = {};
        for (var i in this.refs) {
            values[i] = this.refs[i].checked;
        }
        return values;
    }
    render() {
        var i = this.props.index;
        var item = this.props.item;
        var tdData = item.isBundleHeader ? null : <input ref="SpecialOrder" disabled={this.props.disabled} type="checkbox" name={'SpecialOrder_' + item.idQuoteItems} defaultChecked={item.specialOrder} onChange={this.props.onChange} />;

        return (
            <tr key={i}>
                <td className="content" id={item.idQuoteItems + " quantity"}>{item.quantity}</td>
                <td className="content center" id={item.idQuoteItems + " description"}>{item.description}</td>
                <td className="content" id={item.idQuoteItems + " cost"}>{item.cost}</td>
                <td className="content" id={item.idQuoteItems + " price"}>{item.price}</td>
                <td className="content" id={item.idQuoteItems + " recurringCost"}>{item.recurringCost}</td>
                <td className="content" id={item.idQuoteItems + " recurringPrice"}>{item.recurringPrice}</td>
                <td className="content center">
                    <input ref="Billable" disabled={this.props.disabled} type="checkbox" name={'Billable_' + item.idQuoteItems} value="yes"
                           defaultChecked={item.billable} onChange={this.props.onChange}/>
                </td>
                <td className="content center">
                    <input ref="Dropship" disabled={this.props.disabled || !this.props.parent.props.canShowDropship} type="checkbox" value="yes" name={'Dropship_' + item.idQuoteItems}
                           onChange={this.props.parent.onDropshipChange.bind(this.props.parent, i)}
                           checked={this.props.parent.state.dropship[i]}/>
                </td>
                {this.props.parent.props.canShowSpecialOrder ?
                    <td className="content center">
                        {tdData}
                    </td> : null}
            </tr>
        );
    }
}