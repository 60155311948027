import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import { CardContainer } from "../common/components/CardContainer";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { AssessmentOutlined, MoneyOutlined, EventRepeatOutlined } from "@mui/icons-material";
import { useQuoteContext, useUserSettingsContext } from "../context";
import { useMemo } from "react";
import { text_ellipsis } from "../styles";

export const QuoteStatistics = () => {
	const quote = useQuoteContext();
	const { hideProfit } = useUserSettingsContext();
	const theme = useTheme();

	const totalProfitsData = useMemo(
		() => [
			{
				title: "Total Contract Value",
				id: "TCV",
				data: `${quote.formatCurrency(quote?.TotalContractValue)}`,
				icon: (
					<PaymentsOutlinedIcon fontSize="large" htmlColor={theme.palette.icon.primary} />
				),
				isProfitInNegative: false
			},
			{
				title: "Gross Profit (%)",
				id: "TGP",
				disabled: hideProfit,
				data: `${quote.formatCurrency(quote?.GrossMarginAmount)} (${quote?.GrossMargin}%)`,
				icon: (
					<AssessmentOutlined fontSize="large" htmlColor={theme.palette.icon.primary} />
				),
				isProfitInNegative: quote?.GrossMarginAmount < 0
			},
			{
				title: "Down Payment",
				id: "TDP",
				data: `${quote.formatCurrency(
					!quote?.OrderPorterAmount ? 0 : quote?.OrderPorterAmount
				)}`,
				icon: <MoneyOutlined fontSize="large" htmlColor={theme.palette.icon.primary} />,
				isProfitInNegative: false
			},
			{
				title: "Recurring",
				id: "TRECURRING",
				data: quote.formatCurrency(quote.RecurringTotal),
				icon: (
					<EventRepeatOutlined fontSize="large" htmlColor={theme.palette.icon.primary} />
				),
				isProfitInNegative: false
			}
		],
		[
			quote?.BaseCurrency,
			quote?.GrossMarginAmount,
			quote?.RecurringTotal,
			quote?.TotalContractValue,
			quote?.OrderPorterAmount
		]
	);
	return (
		<>
			<CardContainer height={"auto"} sx={{ flex: 2 }}>
				<Stack direction="row" sx={{ flexWrap: "wrap", height: "100%", flex: "1" }}>
					{hideProfit ? <>
						<Stack direction="row" sx={{ justifyContent: "space-between", flex: "1" }}>
							<QuoteCard {...totalProfitsData[0]} />
							<QuoteCard {...totalProfitsData[2]} />
							<QuoteCard {...totalProfitsData[3]} />
						</Stack> </> : (
						<>
							<Stack direction="row" sx={{ justifyContent: "space-between", flex: "1" }}>
								<QuoteCard {...totalProfitsData[0]} />
								<QuoteCard {...totalProfitsData[1]} />
							</Stack>
							<Stack direction="row" sx={{ justifyContent: "space-between", flex: "1" }}>
								<QuoteCard {...totalProfitsData[2]} />
								<QuoteCard {...totalProfitsData[3]} />
							</Stack>
						</>
					)}
				</Stack>
			</CardContainer>
		</>
	);
};


const QuoteCard = ({ id, title, icon, data, isProfitInNegative }) => {
	const theme = useTheme();

	return <Card
		key={id}
		sx={{
			padding: "8px",
			boxShadow: "none",
			alignItems: "center",
			display: "flex",
			flex: "1",
			marginRight: "8px",
		}}
	>
		<Box sx={{ marginRight: "8px", height: "36px" }}>
			{icon}
		</Box>
		<Stack
			direction="column"
			sx={{
				color: theme.palette.text.secondary,
				overflow:"hidden"
			}}
		>
			<Typography
				sx={{
					fontWeight: "400",
					lineHeight: "16px",
					fontSize: "12px",
					height: "16px",
					letterSpacing: "0.32px",
					whiteSpace: "nowrap",
				}}
			>
				{title}
			</Typography>
			<Typography
				sx={{
					fontWeight: "600",
					lineHeight: "22px",
					height: "22px",
					fontSize: "16px",
					width: "100%",
					color: isProfitInNegative
						? theme.palette.text.error
						: theme.palette.text.secondary,
					...text_ellipsis
				}}
				title={data}
			>
				{data}
			</Typography>
		</Stack>
	</Card>
}