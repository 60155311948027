import { QuoteCustomerDetails } from "js/jsx/src/classes/quote/quoteCustomerDetails.jsx";

export default function customerdetails(){
    if (!app.currentQuote) {
        return;
    }

    var start = new Date().getTime();
    quosal.ui.react.render(
        <FormPlaceholder message="Loading Customer Details..." />
        , document.getElementById('quoteModule'));

    var renderEditForm = function () {
        quosal.ui.react.update(<QuoteCustomerDetails refs={"detailRef"} quote={app.currentQuote}
            onUpdate={() => renderEditForm()} />, document.getElementById('quoteModule'));
    }

    var detailPage = quosal.ui.react.render(
        <QuoteCustomerDetails onUpdate={renderEditForm} quote={app.currentQuote} />,
        document.getElementById('quoteModule'));

    var updateDetailsPage = function () {
        if (detailPage.componentIsMounted && detailPage.mountedCustomerDetail) {
            detailPage.mountedCustomerDetail.forceUpdate();
        }
    }

    quosal.sell.quote.onUpdate.bind(updateDetailsPage);
    $('#pageName').val('quote.customerdetails');
    quosal.util.calculateAndTrackPageLoadTimeInAppInsights(start, "Load - Customer Details");
}