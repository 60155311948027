import { COMMON_GRID_PADDING, handleValidateDate } from "../../../constants/createFormConstants";
import { Grid, FormControl } from '@mui/material'
import FormContext from "../../../contexts/configureFormContext";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, PickersPopper } from '@mui/x-date-pickers/LocalizationProvider';
import { CommonDatePickerStyled } from "../../../../../common/guidedSelling/styledComponents/commonStyled";


export class DateTypeForm extends React.Component {

    static contextType = FormContext;
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {

        const { questionIndex, sectionIndex, question: { isActive, from, to } } = this.props;
        const { formActions, isSendable } = this.context;

        const handleChange = (newValue, name) => {
            formActions['common'](newValue, name, sectionIndex, questionIndex)
        };

      
        return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                        <Grid key={'form-section-' + sectionIndex + '-question-' + questionIndex} container item xs={12} p={COMMON_GRID_PADDING} justifyContent='space-between' alignItems='flex-start'>
                            <Grid item xs={3} pr='16px'>
                                <FormControl sx={{  width: '100%',}}>
                                    <CommonDatePickerStyled
                                        label="From"
                                        value={from}
                                        onChange={(newValue) => handleChange(newValue, 'from')}
                                        slotProps={{ textField: { 
                                            error: !isSendable && !!handleValidateDate(from, from, to),
                                            helperText: !isSendable && handleValidateDate(from, from, to),
                                            fullWidth: true,
                                            id: `from-date-input-section-${sectionIndex}-question-${questionIndex}`,
                                        }, 
                                            popper: {
                                                sx: {
                                                    '.MuiDateCalendar-root': {
                                                        width: '400px'
                                                    },
                                                },
                                            }
                                        }}  
                                        orientation="landscape"
                                        />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                 <FormControl sx={{  width: '100%',}}>
                                    <CommonDatePickerStyled
                                        label="To"
                                        value={to}
                                        id=''
                                        onChange={(newValue) => handleChange(newValue, 'to')}
                                        slotProps={{ textField: { 
                                            error: !isSendable && !!handleValidateDate(to, from, to),
                                            helperText: !isSendable && handleValidateDate(to, from, to),
                                            fullWidth: true,
                                            id: `to-date-input-section-${sectionIndex}-question-${questionIndex}`,
                                        }, 
                                            popper: {
                                                sx: {
                                                    '.MuiDateCalendar-root': {
                                                        width: '400px'
                                                    },
                                                },
                                            }
                                        }}     
                                        orientation="landscape"
                                    />
                                 </FormControl>
                            </Grid>
                            <Grid item container xs={6} justifyContent='center' alignItems='center'>
                            </Grid>
                        </Grid>
            </LocalizationProvider>
    }
}