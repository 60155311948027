import React, { forwardRef, useState, useImperativeHandle, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Paper
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// eslint-disable-next-line react/display-name
const MarginModalComponent = forwardRef((props, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [marginData, setMarginData] = useState({});
	const [darkMode, setDarkMode] = useState($("#dark-toggle").prop("checked"));

	useImperativeHandle(ref, () => ({
		showModal(isShown, data) {
			setIsOpen(isShown);
			setMarginData(data);
		}
	}));

	useEffect(() => {
		const $checkbox = $("#dark-toggle[type=checkbox]");
		$checkbox.on("change", function () {
			setDarkMode(this.checked);
		});

		return () => {
			$checkbox.off("change");
		};
	}, []);

	return (
		<Dialog
			open={isOpen}
			onClose={() => setIsOpen(false)}
			aria-labelledby="margin-dialog-title"
			id="CKEditor_marginDialog"
		>
			<DialogTitle
				sx={{
					m: 0,
					p: 2,
					...(darkMode
						? {
								backgroundColor: "var(--dark-mode-blue-color) !important"
						  }
						: { backgroundColor: "#2E3F80 !important" })
				}}
				id="margin-dialog-title"
			>
				{marginData?.titleText}
				<IconButton
					aria-label="close"
					onClick={() => setIsOpen(false)}
					sx={{
						position: "absolute",
						right: 8,
						top: 12,
						minWidth: 30,
						color: "#FFFFFF"
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				dividers
				sx={{
					...(darkMode
						? { backgroundColor: "#393939 !important" }
						: { backgroundColor: "#FFF !important" })
				}}
			>
				<TableContainer
					component={Paper}
					id="tableContainer"
					sx={{
						...(darkMode
							? { backgroundColor: "#393939 !important" }
							: { backgroundColor: "#FFF !important" })
					}}
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell align="left">Total Price</TableCell>
								<TableCell align="left">Total Cost</TableCell>
								<TableCell align="left">Gross Profit</TableCell>
								<TableCell align="left">Gross Margin %</TableCell>
								<TableCell align="left">Markup %</TableCell>
								<TableCell align="left">Total Suggested</TableCell>
								<TableCell align="left">Discount %</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell align="left">{marginData.props?.price}</TableCell>
								<TableCell align="left">{marginData.props?.cost}</TableCell>
								<TableCell align="left">{marginData.props?.profitAmount}</TableCell>
								<TableCell align="left">
									{marginData.props?.marginPercent}
								</TableCell>
								<TableCell align="left">
									{marginData.props?.markupPercent}
								</TableCell>
								<TableCell align="left">
									{marginData.props?.suggestedPrice}
								</TableCell>
								<TableCell align="left">
									{marginData.props?.discountPercent}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions
				sx={{
					...(darkMode
						? { backgroundColor: "#393939 !important" }
						: { backgroundColor: "#FFF !important" })
				}}
			>
				<Button
					variant="contained"
					onClick={() => setIsOpen(false)}
					id="okButtonId"
					autoFocus
					sx={{
						...(darkMode
							? {
									backgroundColor: "var(--dark-mode-blue-color) !important",
									color: "#FFF !important"
							  }
							: { backgroundColor: "#2E3F80 !important" })
					}}
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
});

export default MarginModalComponent;
