import { Form } from "js/jsx/src/classes/forms.jsx";

export class QuoteEditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            isDirty: false
        }; 
        // This binding is necessary to make `this` work in the callback
        this.fieldChanged = this.fieldChanged.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.onReset = this.onReset.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.validateField = this.validateField.bind(this);
        this.customizeEnum = this.customizeEnum.bind(this);
        this.checkForCrmOperations = this.checkForCrmOperations.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.onWindowUnloading = this.onWindowUnloading.bind(this);
    }

    fieldChanged(form, panel, input, callback) {
        if (typeof callback === 'function') {
            this.setState({isDirty: true}, callback);
        } else {
            this.setState({isDirty: true});
        }
    }

    saveForm(e) {
        if (quosal.htmlEditorCallback) {
            quosal.htmlEditorCallback();
            delete quosal.htmlEditorCallback;
        }

        var finishedEvent = quosal.events.create();

        var saveForm = function (finishedEvent) {
            this.setState({ isSaving: true });

            var updatedValues = this.refs.form.getFormValues(true);

            for (var field in updatedValues) {
                if (typeof updatedValues[field] === 'object' && updatedValues[field].id) {
                    updatedValues[field] = updatedValues[field].id;
                }
            }

            var updateApi = quosal.api.data.update({
                fields: updatedValues,
                queries: [{
                    table: 'QuoteMain',
                    where: [{
                        field: 'IdQuoteMain',
                        operator: 'Equals',
                        value: this.props.quote.IdQuoteMain
                    }]
                }]
            }, this.props.quote.IdQuoteMain);

            updateApi.finished = function (finishedEvent, msg) {
                if (msg.opportunityLost) {
                    Dialog.open({
                        message: msg.opportunityLost,
                        links: [Dialog.links.ok]
                    });
                }

                quosal.sell.quote.updateFromApiResponse(msg);
                if (msg.partialResponse && msg.partialResponse.partialQuote && msg.partialResponse.partialQuote.WonLostDate === null) {
                    //WonLostDate has been updated to null, reset the UI
                    $('#QuoteEdit_WonLostDate').datepicker('setDate', '');
                }

                let newQuoteName = (
                    (msg.quote && msg.quote.QuoteName) ||
                    (msg.partialResponse && msg.partialResponse.partialQuote && msg.partialResponse.partialQuote.QuoteName)
                );
                if (newQuoteName) {
                    document.title = newQuoteName
                }

                if (this.componentIsMounted) {
                    this.setState({ isDirty: false, isSaving: false });

                    if (this.refs.form) {
                        this.refs.form.reset();
                    }
                }

                finishedEvent.call();
            }.bind(this, finishedEvent);

            updateApi.call();
        }.bind(this, finishedEvent);

        if (this.refs.form.prepareThenSave) {
            this.refs.form.prepareThenSave(saveForm);
        } else {
            saveForm();
        }

        return finishedEvent;
    }

    customizeQuickSearchResults(results) {
        results.removeAll(s=>s.UserName.ciEquals('System') || s.UserName.ciEquals('QuosalAdmin'));
    }
    onReset() {
        delete quosal.htmlEditorCallback;
        this.setState({isDirty: false});
    }

    cancelChanges() {
        delete quosal.htmlEditorCallback;
        this.refs.form.reset();
    }

    customizeInput(formField, style) {
        const readOnlyFields = ['IsStandardModification', 'BaseCurrency', 'CustomerSignatureCapture', 'OrderPorterPreviewPicture']
        if(readOnlyFields.includes(formField.props.field.FieldName)) {
            formField.state.disabled = true;             
        }
    }

    customizeFields(fields) {}
    
    validateField(field, action) {
        if (field.props.field.FieldName == 'QuoteName') {
            var requiresNameChange = this.props.quote.RequiresNameChange && quosal.settings.getValue('ForceNameChangeFromTemplate');

            if(requiresNameChange) {
                return {
                    level: 'error',
                    message: 'After copying from a template, Quote Name must be changed.',
                    allowSave: true
                };
            }
        }
    }

    customizeEnum(field, values) {
        if(this.props.quote.QuoteStatus == 'Deleted') {
            values.push({ Label: 'Deleted', Value: 'Deleted' });
        }
        return values;
    }

    checkForCrmOperations() {
        var opportunityId = quosal.util.queryString('opportunityid');

        if (!String.isNullOrEmpty(opportunityId)) {
            //attach opportunity
            //oppusername does nothing?
            Dialog.open({
                message: <FormPlaceholder message="Attaching Opportunity..." />
            });

            var attachOppApi = quosal.api.crm.attachOpportunity(app.currentQuote.IdQuoteMain, opportunityId);
            attachOppApi.error = function(msg) {
                if(msg.error && msg.error.indexOf('This opportunity is already attached.') == 0) {
                    Dialog.closeAll({
                        skipAnimation: true,
                        callback: function() {
                            Dialog.open({
                                message: msg.error,
                                links: [Dialog.links.ok]
                            });
                        }.bind(this)
                    });

                } else {
                    throw msg.error;
                }
            };
            attachOppApi.finished = function(msg) {
                quosal.sell.quote.update(msg.quote);

                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function(msg) {
                        var crm = quosal.settings.getValue('customerProvider');

                        if('Connectwise'.ciEquals(crm)) {
                            if(msg.opportunity.hasItems) {
                                //show item import selection
                                var getItems = quosal.api.crm.getOpportunityItems(opportunityId);


                            }
                        }
                    }.bind(this, msg)
                });
            }.bind(this);
            attachOppApi.call();
        }
    }

    onNavigation(navState) {
        Form.onNavigation.call(this.refs.form, navState);
    }

    onWindowUnloading(e) {
        Form.onWindowUnloading.call(this, e);
    }

    componentDidMount() {
        this.componentIsMounted = true;
        Form.hookUpNavigationActions.call(this);

        this.checkForCrmOperations();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        Form.unhookNavigationActions.call(this);
    }

    render() {
        if (this.state.loadingMessage) {
            return (
                <FormPlaceholder message={this.state.loadingMessage}/>
            );
        }

        var isEditable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly;// || app.currentUser.IsAdministrator;

        var topToolbar = {position: 'top'};
        topToolbar.control =
            <QuoteEditToolbar key="editToolbar" quote={this.props.quote} form={this} isEditable={isEditable}/>;

        var bottomToolbar = {position: 'bottom'};
        bottomToolbar.control =
            <QuoteEditToolbar key="editToolbar" quote={this.props.quote} form={this} isEditable={isEditable}/>;

        var infoMessage = <QuoteEditInfo enableAdvancedRounding = {this.props.quote.EnableAdvancedRounding}/>;
        return (
            <Form ref="form" disabled={this.state.isSaving || !isEditable} onSubmit={this.saveForm}
                  noNavigationActions={true} productEditForm={this} customizeInput={this.customizeInput}
                  quote={this.props.quote} customizeEnum={this.customizeEnum}
                  onChange={this.fieldChanged} onReset={this.onReset} configuration={this.props.configuration}
                  formControls={[topToolbar, bottomToolbar]} validateField={this.validateField}
                  fieldCustomizer={this.customizeFields} customizeQuickSearchResults={this.customizeQuickSearchResults} 
                  infoMessage = {infoMessage}/>
        );
    }
}

class QuoteEditInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            infoBlockOpen : true
        };
        this.closeInfoBlock = this.closeInfoBlock.bind(this);
    }

   closeInfoBlock(){
        this.setState(
        {
            infoBlockOpen: false
        });  
    }

   render() {
        if (!this.props.enableAdvancedRounding && this.state.infoBlockOpen) {
            return (<div className = "quoteEditInfoBlock">
                    <div style = {{verticalAlign: "bottom", display: "inline-block"}}>
                        <CwWidgets.CwImage src = "img/svgs/v1.0/Application_Information.svg" isInlineSVG = "true" style= {{width : "18px", height: "18px", fill: '#0a92fa'}}/>
                    </div>
                    <p className = "quoteEditInfoMessage">
                        This quote is not using 2018.8 rounding. We have recently updated our rounding methods. 
                        <a href = "https://docs.connectwise.com/ConnectWise_Sell/Sell_Release_Notes" target="_blank"> Learn More</a>        
                    </p>
                    <div className="quoteEditCloseInfoBlock" title="Hide Info" onClick={this.closeInfoBlock}></div>
                </div>);
        }
        else {
            return null;
        }
    }
}

export class QuoteEditToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        // This binding is necessary to make `this` work in the callback
        this.saveForm = this.saveForm.bind(this);
        this.checkOrderPorterApproval = this.checkOrderPorterApproval.bind(this);
        this.redownloadOrderPorterApproval = this.redownloadOrderPorterApproval.bind(this);
        this.checkDocuSignApproval = this.checkDocuSignApproval.bind(this);
        this.downloadOrderPorter = this.downloadOrderPorter.bind(this);
        this.deleteQuote = this.deleteQuote.bind(this);
        this.copyQuote = this.copyQuote.bind(this);
        this.newVersion = this.newVersion.bind(this);
        this.expireCache = this.expireCache.bind(this);
        this.importQuote = this.importQuote.bind(this);
        this.repairQuote = this.repairQuote.bind(this);
        this.approveQuote = this.approveQuote.bind(this);
        this.checkManagerApproval = this.checkManagerApproval.bind(this);
        this.unarchiveQuote = this.unarchiveQuote.bind(this);
        this.canManualWinQuote = this.canManualWinQuote.bind(this);
        this.winQuote = this.winQuote.bind(this);
    }

    static importQuote() {
        var stateObj = {};

        Dialog.open({
            title: 'Import a Quote from File',
            message: <ImportQuoteDialog stateObj={stateObj} />,
            links: [{
                title: 'Import',
                callback: (function (stateObj) {
                    var file = stateObj.dialog.refs.input.files[0];

                    var fd = new FormData();
                    var url = quosal.util.url("generateimportasynch.quosalweb", "noheader=yes");
                    fd.append("filelocation", file, file.name);

                    Dialog.setIsWorking();
                    $.post({
                        url: url,
                        type: "POST",
                        data: fd,
                        processData: false,
                        contentType: false
                    }, function (data) {
                        try {
                            var msg = JSON.parse(data);
                            if (msg.error) {
                                Dialog.closeAll({ skipAnimation: true });
                                let errorMessage = "Import failed!";
                                if (msg.error.includes("20 MB") || msg.error.includes(".quosalsell")) {
                                    errorMessage += "\n" + msg.error;
                                }
                                alert(errorMessage);
                            }
                            else {
                                var loadQuoteHomeSubmodule = (function (msg) {
                                    Dialog.closeAll({ skipAnimation: true });
                                    quosal.sell.quote.update(msg.quote);
                                    app.currentModule.loadSubModule('quote.home', { container: 'quoteModule', query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain }) });
                                }).bind(this, msg);

                                if (app.currentModule.Type !== 'QuoteDashboard') {
                                    app.currentModule.unload();
                                    app.currentModule = quosal.sell.modules.allModules.where(function (s) {
                                        return s.Type == 'QuoteDashboard';
                                    }).firstOrNull();
                                    app.currentModule.load({
                                        callback: loadQuoteHomeSubmodule,
                                        query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain })
                                    });
                                } else {
                                    loadQuoteHomeSubmodule();
                                }
                            }   
                        } catch (err) {
                            Dialog.closeAll({ skipAnimation: true });
                            alert("Import failed!");
                        }
                    });
                }).bind(this, stateObj)
            }, Dialog.links.cancel]
        });
    }

    static copyQuote(idQuoteMain, quoteStatus) {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Copying quote..." />
        });

        var copyApi = quosal.api.quote.copyQuote(idQuoteMain || this.props.quote.IdQuoteMain, quoteStatus || this?.props?.quote?.QuoteStatus);
        copyApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);

            var loadQuoteHomeSubmodule = function (msg) {
                app.currentModule.loadSubModule('quote.home', {
                    container: 'quoteModule',
                    query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain })
                });
            }.bind(this, msg);

            if (app.currentModule.Type !== 'QuoteDashboard') {
                app.currentModule.unload();
                app.currentModule = quosal.sell.modules.allModules.where(function (s) {
                    return s.Type == 'QuoteDashboard';
                }).firstOrNull();
                app.currentModule.load({ callback: loadQuoteHomeSubmodule });
            } else {
                loadQuoteHomeSubmodule();
                Dialog.closeAll();
            }
        };
        copyApi.call();
    }

    saveForm() {
        if (quosal.htmlEditorCallback) {
            quosal.htmlEditorCallback();
            delete quosal.htmlEditorCallback;
        }

        this.props.form.refs.form.submit();
    }

    checkDocuSignApproval() {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Checking for DocuSign approval..." />
        });

        var docusignApi = quosal.api.quote.checkDocuSignApproval(this.props.quote.IdQuoteMain, this.props.quote.Version, this.props.quote.DocusignEnvelopeId, this.props.quote.QuoteReadableId);
        docusignApi.finished = function (msg) {
            if (msg.isApproved) {
                quosal.sell.quote.update(msg.quote);

                app.currentModule.loadSubModule('quote.home', { container: 'quoteModule', query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain }) });

                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: 'Your quote has been approved and you can now process it in WIN! section in the quote menu.',
                            links: [{
                                title: 'OK',
                                callback: function() {
                                    Dialog.closeAll();
                                    $('#refreshReview').remove();
                                }
                            }]
                        });
                    }.bind(this)
                });
            } else {
                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: 'This quote has not been approved by your customer yet. You can pick this option again when the quote is approved.',
                            links: [{
                                title: 'OK',
                                callback: Dialog.closeAll
                            }]
                        });
                    }.bind(this)
                });
            }
        };
        docusignApi.call();
    }

    checkOrderPorterApproval() {
        this.props.form.refs.form.promptForAction(this.downloadOrderPorter.bind(this, false));
    }

    redownloadOrderPorterApproval() {
        var doRedownloadOrderPorterApproval = function () {
            Dialog.open({
                title: 'Confirm Redownload',
                message: 'Are you sure you want to redownload this Order Porter?',
                links: [{
                    title: 'Yes',
                    callback: function () {
                        this.downloadOrderPorter(true);
                    }.bind(this)
                }, {
                    title: 'No',
                    callback: Dialog.closeAll
                }]
            });
        }.bind(this);

        this.props.form.refs.form.promptForAction(doRedownloadOrderPorterApproval);
    }

    downloadOrderPorter(forceDownload) {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Checking for Order Porter approval..." />
        });

        var opApi = quosal.api.quote.checkOrderPorterApproval(this.props.quote.IdQuoteMain, forceDownload, this.props.quote.QuoteReadableId, this.props.quote.Version, this.props.quote.OrderPorterPasscode);
        opApi.finished = function (msg) {
            if (msg.isApproved) {
                quosal.sell.quote.update(msg.quote);

                app.currentModule.loadSubModule('quote.home', { container: 'quoteModule', query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain }) });

                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: 'Your quote has been approved and you can now process it in WIN! section in the quote menu.',
                            links: [{
                                title: 'OK',
                                callback: Dialog.closeAll
                            }]
                        });
                    }.bind(this)
                });
            } else {
                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: 'This quote has not been approved by your customer yet. You can pick this option again when the quote is approved.',
                            links: [{
                                title: 'OK',
                                callback: Dialog.closeAll
                            }]
                        });
                    }.bind(this)
                });
            }
        };
        opApi.call();
    }

    deleteQuote() {
        var doDelete = ()=> quosal.setQuoteStatus.deleted(this.props.quote.IdQuoteMain, true);

        if (this.props.form.refs.form.state.isDirty) {
            this.props.form.refs.form.promptForAction({
                confirm: doDelete,
                saveLinkTitle: 'Save and Delete',
                discardLinkTitle: 'Discard and Delete'
            });
        } else {
            Dialog.open({
                title: 'Confirm Delete',
                message: 'Are you sure you want to delete this quote?',
                links: [{
                    title: 'Yes, Delete',
                    callback: function (doDelete) {
                        Dialog.close({
                            callback: function (doDelete) {
                                doDelete();
                            }.bind(this, doDelete)
                        });
                    }.bind(this, doDelete)
                }, {
                    title: 'No, Cancel',
                    callback: Dialog.close
                }]
            });
        }
    }

    copyQuote() {
        this.props.form.refs.form.promptForAction(QuoteEditToolbar.copyQuote.bind(this));
    }

    newVersion() {
        var doNewVersion = function () {
            var createVersion = function (versionComment) {
                Dialog.open({
                    closeRequiresButton: true,
                    message: <FormPlaceholder message="Creating new version..." />
                });

                var versionApi = quosal.api.quote.newVersion(this.props.quote.IdQuoteMain, versionComment);
                versionApi.finished = function (msg) {
                    quosal.sell.quote.update(msg.quote);
                    app.currentModule.loadSubModule('quote.home', {
                        container: 'quoteModule',
                        query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain })
                    });

                    Dialog.closeAll();
                };
                versionApi.call();
            }.bind(this);

            var commentRequired = quosal.settings.getValue('RequireVersionCommenting');
            var commentOptional = quosal.settings.getValue('AllowVersionCommenting');

            if (commentRequired || commentOptional) {
                var stateObj = {};

                Dialog.open({
                    title: 'Version Comment',
                    message: <VersionCommentDialog stateObj={stateObj} commentRequired={commentRequired} />,
                    links: [{
                        title: 'OK',
                        callback: function (stateObj, createVersion, commentRequired) {
                            if (commentRequired && String.isNullOrEmpty(stateObj.dialog.refs.comment.value)) {
                                Dialog.open({
                                    message: 'A version comment is required to create a new version of this quote.',
                                    links: [Dialog.links.ok]
                                });
                                return true;
                            } else {
                                createVersion(stateObj.dialog.refs.comment.value);
                            }
                        }.bind(this, stateObj, createVersion, commentRequired)
                    }, Dialog.links.cancel]
                });
            } else {
                createVersion();
            }
        };

        this.props.form.refs.form.promptForAction(doNewVersion.bind(this));
    }

    expireCache() {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Expiring cache..." />
        });

        var cacheApi = quosal.api.quote.expireCache(this.props.quote.IdQuoteMain);
        cacheApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);

            Dialog.close();
        };
        cacheApi.call();
    }

    importQuote() {
        this.props.form.refs.form.promptForAction(QuoteEditToolbar.importQuote.bind(this));
    }

    repairQuote() {
        let idQuoteMain = this.props.quote.IdQuoteMain;
        function doRepair() {
            Dialog.close({ skipAnimation: true });
            Dialog.open({
                closeRequiresButton: true,
                message: <FormPlaceholder message="Analyzing quote..." />,
            });
            var repairQuoteApi = quosal.api.quote.repairQuote(idQuoteMain);
            repairQuoteApi.finished = function (msg) {
                quosal.sell.quote.update(msg.quote);
                Dialog.close({ skipAnimation: true });
                Dialog.open({
                    closeRequiresButton: false,
                    message: <div dangerouslySetInnerHTML={{ __html: msg.message }}></div>,
                    links: [Dialog.links.close]
                });
            };
            repairQuoteApi.call();
        }

        Dialog.open({
            message: "Do you want to check for orphaned items on this quote?",
            links: [
                {
                    title: "Yes",
                    callback: doRepair
                },
                Dialog.links.cancel
            ]
        });
    }

    approveQuote() {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Approving Quote..." />
        });

        var approveApi = quosal.api.quote.approveQuote(this.props.quote.IdQuoteMain);
        approveApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);

            Dialog.close({
                skipAnimation: true,
                callback: function () {
                    Dialog.open({
                        message: "The quote has been approved",
                        links: [Dialog.links.ok]
                    });
                }.bind(this)
            });
        };
        approveApi.call();
    }

    canApproveQuote() {
        return app.currentUser.IsApprover &&
            app.currentQuote.InsideRep.id != app.currentUser.id &&
            app.currentQuote.Owner.id != app.currentUser.id && 
            app.settings.global.ApprovalMethod != 'Multilevel';
    }

    checkManagerApproval() {
        var doCheckManagerApproval = function () {
            Dialog.open({
                closeRequiresButton: true,
                message: <FormPlaceholder message="Checking for Manager Approval..." />
            });

            var approvalApi = quosal.api.quote.checkManagerApproval(this.props.quote.IdQuoteMain, this.props.quote.QuoteReadableId, this.props.quote.Version);
            approvalApi.finished = function (msg) {
                var previousQuoteId = this.props.quote.IdQuoteMain;
                quosal.sell.quote.update(msg.quote);

                if (msg.quote.IdQuoteMain != previousQuoteId) {
                    app.currentModule.loadSubModule('quote.home', {
                        container: 'quoteModule',
                        query: quosal.util.updateQueryString({ idquotemain: msg.quote.IdQuoteMain })
                    });
                }

                if (!msg.approvalMessage)
                    msg.approvalMessage = 'An error occurred while checking for manager approval, please try again.';

                Dialog.closeAll({
                    skipAnimation: true,
                    callback: function () {
                        Dialog.open({
                            message: msg.approvalMessage,
                            links: [{
                                title: 'OK',
                                callback: Dialog.closeAll
                            }]
                        });
                    }.bind(this)
                });
            }.bind(this);
            approvalApi.call();
        }.bind(this);

        this.props.form.refs.form.promptForAction(doCheckManagerApproval);
    }

    moreSelectionChanged(selectedItem) {
        if (selectedItem.callback) {
            selectedItem.callback();
        } else if (selectedItem.url) {
            var state = { rand: quosal.util.generateGuid() };

            if (selectedItem.target) {
                state.target = selectedItem.target;
            }

            if (selectedItem.bypassClientNav) {
                state.bypassClientNav = selectedItem.bypassClientNav;
            }

            quosal.navigation.navigate(selectedItem.url, state);
        }
    }

    navigateTo(url) {
        var state = { rand: quosal.util.generateGuid() };
        quosal.navigation.navigate(url, state);
    }

    unarchiveQuote() {
        Dialog.open({
            closeRequiresButton: true,
            message: <FormPlaceholder message="Unarchiving quote..." />
        });

        var cacheApi = quosal.api.quote.unarchiveQuote(this.props.quote.IdQuoteMain);
        cacheApi.finished = function (msg) {
            quosal.sell.quote.update(msg.quote);

            Dialog.close();
        };
        cacheApi.call();
    }

    canManualWinQuote () {
        return (
            ((!this.props.quote.IsLocked && !this.props.quote.IsArchive && app.settings.global.ApprovalMethod != 'Multilevel') //legacy approval
                ||
                (app.settings.global.ApprovalMethod == 'Multilevel' && //mla approval
                    (
                        (app.currentUser.IsAdministrator && this.props.quote.ApprovalStatus != 'Request Approval') ||
                        (this.props.quote.IsLocked && this.props.quote.ApprovalStatus != 'Request Approval') || //quote has been approved and is locked down
                        (app.settings.global.AllowMLAManualWinBeforeApproval && this.props.quote.ApprovalStatus != 'Request Approval') || //quote has not been sent for approval and the allow manual win setting is checked (regardless of triggered rules or not)
                        !app.quoteHasTriggeredApprovalRules && this.props.quote.ApprovalStatus != 'Request Approval' //the quote has no triggered rules and the quote is not out for approval
                    )
                ))
            &&
            !app.currentUser.IsReadOnly && app.currentUser.IsManualWinner); //readonly and non manual winner users can never manually win
    }

    winQuote() {
        var state = { rand: quosal.util.generateGuid() };
        state.bypassClientNav = true;
        function doWinQuote() {
            Dialog.close({ skipAnimation: false });
            quosal.navigation.navigate(quosal.util.url('winquote.quosalweb'), state);
        };
        if (this.props.quote.QuoteStatus === 'Won')
        {
            Dialog.open({
                title: 'Win! Quote',
                message: 'This quote is already in a Won state. Choosing to manually Win! Quote again will create and win new version of this quote. Do you wish to continue?',
                links: [
                    {
                        title: "Yes",
                        callback: doWinQuote
                    },
                    Dialog.links.cancel
                ]
            });
        } else
        {
            quosal.navigation.navigate(quosal.util.url('winquote.quosalweb'), state);
        }
    }

    render() {
        var quoteActions = [];
        var isConnectwise = String.ciEquals(quosal.settings.getValue('customerProvider'), 'Connectwise');
        if (this.canManualWinQuote()) {
            quoteActions.push({
                id: 1,
                name: 'Win! Quote',
                callback: this.winQuote,
            });
        }
        if (isConnectwise) {
            quoteActions.push({
                id: 2,
                name: 'Agreements',
                url: quosal.util.url('cwagreement.quosalweb')
            });
            quoteActions.push({
                id: 3,
                name: 'Configurations',
                url: quosal.util.url('cwconfiguration.quosalweb')
            });
            quoteActions.push({
                id: 4,
                name: 'View Tickets',
                url: quosal.util.url('cwticket.quosalweb')
            });
        }
        quoteActions.push({
            id: 5,
            name: 'Audit Trail',
            url: quosal.util.url('quoteaudit.quosalweb')
        });
        if (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) {
            quoteActions.push({
                id: 6,
                name: 'Edit Publish Settings',
                url: quosal.util.url('editquote.quosalweb', 'nav=publishsettings', 'subnav=publishsettings')
            });
        }
        if (app.currentUser.IsAdministrator && quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium)) {
            quoteActions.push({
                id: 7,
                name: 'Edit Terms',
                url: quosal.util.url('editquote.quosalweb', 'nav=terms', 'subnav=terms')
            });
        }
        if (app.currentUser.IsAdministrator) {
            quoteActions.push({
                id: 8,
                name: 'Process Success Tracks',
                url: quosal.util.url('adminsuccessprofile.quosalweb', 'processquote=' + this.props.quote.IdQuoteMain, 'quotereturnurl=' + encodeURIComponent(location.href))
            });
        }
        if (this.canApproveQuote()) {
            quoteActions.push({
                id: 9,
                name: 'Approve Quote',
                callback: this.approveQuote
            });
        }
        if (app.settings.global.ApprovalMethod != 'Multilevel' && this.props.quote.ApprovalStatus == 'Request Approval') {
            quoteActions.push({
                id: 10, 
                name: 'Check for Manager Approval',
                callback: this.checkManagerApproval
            });
        }
        if (this.props.quote.ElectronicOrderEnabledForUser && this.props.quote.HasSubmittedElectronicOrder) {
            quoteActions.push({
                id: 11,
                name: 'Check Electronic Order Shipping Status',
                url: quosal.util.url('shippingsearch.quosalweb')
            });
        }
        if (app.currentUser.IsAdministrator && !app.currentUser.IsReadOnly) {
            quoteActions.push({
                id: 12,
                name: 'Export Quote',
                url: quosal.util.url('generateexport.quosalweb'),
                target: '_blank'
            });
            quoteActions.push({
                id: 13,
                name: 'Import Quote',
                callback: this.importQuote
            });
        }
        if (app.currentUser.IsQuosalAdmin) {
            //check for orphaned items
            quoteActions.push({
                id: 14,
                name: 'Repair Quote',
                callback: this.repairQuote
            });
        }
        if (app.currentUser.IsAdministrator) {
            quoteActions.push({
                id: 15,
                name: 'Expire Cache',
                callback: this.expireCache
            });
        };
        if (quosal.settings.getValue('enableGuidedSelling', false)) {
            quoteActions.push({
                id: 16,
                name: 'Submitted Sales Track Forms',
                url: function () {
                    app.currentModule.loadSubModule('product.salestracksforms', {
                       container: 'quoteModule',
                    });
                }
            });
        }

        var isQuoteArchived = this.props.quote.QuoteStatus == 'Archived';
        var isMLAApproved = app.settings.global.ApprovalMethod && app.settings.global.ApprovalMethod == 'Multilevel' && this.props.quote.ApprovalStatus && this.props.quote.ApprovalStatus == 'Approved';        
        var orderPorterDocuSignButton = null;
        if (!isQuoteArchived) {
            if ((!this.props.quote.IsLocked || isMLAApproved) && !app.currentUser.IsReadOnly) {
                if (this.props.quote.PublishMethod == 'DOCUSIGN' && this.props.quote.DocusignEnvelopeId) {
                    orderPorterDocuSignButton = <CwWidgets.CwButton toolTip="Check DocuSign for Approval" onClick={this.checkDocuSignApproval} icon="img/svgs/sell/Action_Download.svg" />
                }
                else if (this.props.quote.IsOrderPorterUploaded) {
                    if (this.props.quote.OrderPorterApproved) {
                        orderPorterDocuSignButton = <CwWidgets.CwButton toolTip="Redownload Order Porter Approval" onClick={this.redownloadOrderPorterApproval} icon="img/svgs/sell/Action_Download.svg" />;
                    }
                    else {
                        orderPorterDocuSignButton = <CwWidgets.CwButton toolTip="Check Order Porter Approval" onClick={this.checkOrderPorterApproval} icon="img/svgs/sell/Action_Download.svg" />;
                    }
                }                
            }
        }

        var moreButton = <div style={{ display: 'inline-block' }} data-cy='moreButton' ><CwWidgets.CwMenuButton value="MORE" items={quoteActions} onDropDownItemClick={this.moreSelectionChanged} /></div>
        var canCopyQuote = (app.currentUser.IsContentMaintainer || app.currentUser.IsAdministrator || app.currentUser.IsStandardPlus || !quosal.settings.getValue('DisableCopyQuote')) && !app.currentUser.IsReadOnly;
        let canCreateNewVersion = !app.currentUser.IsReadOnly && !(app.currentQuote.IsLocked && app.currentQuote.DocusignEnvelopeId);
        let canSaveAsTemplate = (app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer) && !app.currentUser.IsReadOnly;
        return (
            <Panel>
                <PanelToolbar style={{ display: 'block', fontSize: '12px' }}>
                    {canCreateNewVersion && !isQuoteArchived ? <div style={{display: 'inline-block'}} data-cy="versionButton"><CwWidgets.CwButton toolTip="New Version" onClick={this.newVersion} icon="img/svgs/v1.0/Action_AddNew.svg" className="QuoteSetupClass" /></div> : null}
                    {!isQuoteArchived ? <CwWidgets.CwButton toolTip="Quote Comments" onClick={this.navigateTo.bind(this, quosal.util.url('quotecomments.quosalweb'))} icon="img/svgs/v1.0/Action_Comment.svg" /> : null}
                    {canCopyQuote ? <CwWidgets.CwButton toolTip="Copy Quote" onClick={this.copyQuote} icon="img/svgs/v1.0/Action_Copy.svg" /> : null}
                    {this.props.isEditable && this.props.quote.QuoteStatus != 'Deleted' && !isQuoteArchived ? <CwWidgets.CwButton toolTip="Delete Quote" onClick={this.deleteQuote} icon="img/svgs/v1.0/Action_Delete.svg" /> : null}
                    {orderPorterDocuSignButton}
                    {!isQuoteArchived && (this.props.quote.Version != 1 || this.props.quote.IsArchive) ? <CwWidgets.CwButton toolTip="View All Versions" value="VERSIONS" onClick={this.navigateTo.bind(this, quosal.util.url('versionlist.quosalweb'))} /> : null}
                    {canSaveAsTemplate && !isQuoteArchived ? <CwWidgets.CwButton toolTip="Create New Template from this Quote" value="SAVE AS TEMPLATE" onClick={this.navigateTo.bind(this, quosal.util.url('copy_to_new_template.quosalweb'))} /> : null}
                    {isQuoteArchived && this.props.quote.IsArchive == false ? <CwWidgets.CwButton toolTip="Unarchive Quote" value="UNARCHIVE" onClick={this.unarchiveQuote} /> : null}
                    {!isQuoteArchived ? moreButton : null}
                    {this.props.isEditable ? <SaveButton style={{ float: 'right', marginRight: 10 }} disabled={!this.props.form.state.isDirty || this.props.form.state.isSaving} isSaving={this.props.form.state.isSaving} onClick={this.saveForm} /> : null}
                    {this.props.isEditable ? <Button type="cancel" style={{ float: 'right', marginRight: 10 }} disabled={!this.props.form.state.isDirty} onClick={this.props.form.cancelChanges} >Cancel</Button> : null}
                </PanelToolbar>
            </Panel>
        );
    }
}
global.QuoteEditToolbar = QuoteEditToolbar;

class VersionCommentDialog extends React.Component {
    render() {
        this.props.stateObj.dialog = this;

        return (
            <div>
                <div id="versionCommentForm" style={{display: 'block'}}>
                    <div>Enter a comment for the quote version you are creating{this.props.commentRequired ? ' (required).' : '.'}</div><br />
                    <textarea ref="comment" className="disable_exempt" type="text" name="versioncomment" id="versioncomment" cols="47" rows="8"></textarea>
                </div>
            </div>
        );
    }
}

class ImportQuoteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.uploadImage = this.uploadImage.bind(this);
    }

    uploadImage(){
        const file = document.getElementById('filelocation').files;
        if (file && file[0]) {
            if (file[0].size && file[0].size / 1048576 > 20) {
                alert("The file needs to be less than 20 MB.");
                document.querySelector('#filelocation').value = '';
            }
            if (file[0].name && !file[0].name.includes('.quosalsell') && !file[0].name.includes('.zip')) {
                alert("The file must be .quosalsell or .zip.");
                document.querySelector('#filelocation').value = '';
            }
        }        
    }

    render() {
        this.props.stateObj.dialog = this;

        return (
            <div>
                <form method="post" encType="multipart/form-data" action={quosal.util.url('generateimport.quosalweb', 'addfile=yes', 'noheader=yes')}>
                    <div id="fileuploadlabel"><i>You must choose a .zip or .quosalsell file with size 20 MB or less.</i></div><br />
                    <div><input type="file" ref="input" id="filelocation" name="filelocation" onChange={this.uploadImage}/></div>
                </form>
            </div>
        );
    }
}