import {QuoteSearch} from "js/jsx/src/classes/quote/quoteSearch.jsx";

quosal.sell = {};
quosal.sell.data = {};
quosal.sell.quote = {};
quosal.sell.product = {};
quosal.sell.modules = { allModules: [] };
quosal.sell.customization = {};
quosal.sell.scripts = {};


//=============================//
//* Quosal.Sell.Data       *//
//=============================//
quosal.sell.data.formatValue = function(value, field) {
    if(field.DataType === 'Double' && value !== null && typeof value === 'number') {
        if(field.IsCurrency && app.currentQuote) {
            value = app.currentQuote.formatCurrency(value);
        } else {
            value = accounting.toFixedAuto(value);

            if(field.IsPercentage) {
                value = value + '%';
            }
        }
    }

    return value;
}

quosal.sell.data.getQuoteSearchNoteFields = function() {
    try {
        var gridHeaders = quosal.customization.grids["QuoteSearch"].Configurations[quosal.settings.getValue('QuoteSearch_GridConfiguration') || 'Default'].Columns;
        var mappedGridHeaders = gridHeaders.map(function(item){
            return item.FieldName;
        });
        if(mappedGridHeaders.indexOf("QuoteStatusIndicators") > -1){
            mappedGridHeaders.push("QuoteStatus");
            mappedGridHeaders.push("IsLocked");
            mappedGridHeaders.push("IdCRMOpportunity");
            mappedGridHeaders.push("IsTemplate"); 
            mappedGridHeaders.push("AccountName");
            mappedGridHeaders.push("QuoteName");
        }
        return mappedGridHeaders;
    } catch(e){ 
        return null;
    }
    
}

quosal.sell.data.preloadQuoteSearch = function() {
    try{
        if (sessionStorage.QuoteSearchResult != null || quosal.sell.data.failedPreLoadQuoteSearch || quosal.sell.data.isPreloadingQuoteSearch == true) {
            return;
        }

        var cookieStringValue = quosal.util.cookie(QuoteSearch.cookieId);
        var cookie = cookieStringValue && JSON.parse(cookieStringValue);
        if (cookie) {
            var pageNumber = cookie.pageNumber;
            var pageSize = QuoteSearch.pageSize;
            var searchCriteria = cookie.searchCriteria;
            var enumFields = cookie.enumFields;
            var listFields = quosal.sell.data.getQuoteSearchNoteFields();
            var searchApi = quosal.api.quote.search(searchCriteria, pageSize, pageNumber, enumFields, listFields);
            searchApi.finished = function(msg) {
                try {
                    quosal.sell.data.isPreloadingQuoteSearch = false;
                    quosal.sell.data.isPreloadingQuoteSearchOpId = null;
                    sessionStorage.QuotePreLoadSessionId = app.currentUser.IdUsers;
                    sessionStorage.QuoteSearchResult = JSON.stringify(msg);
                    if(quosal.sell.data.quoteSearchFinishedCallback){
                        quosal.sell.data.quoteSearchFinishedCallback(msg);
                    }
                } 
                catch(e) {
                    quosal.sell.data.failedPreLoadQuoteSearch = true;
                }
            }.bind(this);
            searchApi.call();
            quosal.sell.data.isPreloadingQuoteSearch = true;
            quosal.sell.data.isPreloadingQuoteSearchOpId = searchApi.opId
        }
    }catch(e){}
}

//=============================//
//* Quosal.Sell.Modules       *//
//=============================//
quosal.sell.modules.onBeforeLoad = quosal.events.create();
quosal.sell.modules.onAfterLoad = quosal.events.create();
quosal.sell.modules.reloaded = quosal.events.create();
quosal.sell.modules.find = function(typeOrUrl) {
    return quosal.sell.modules.allModules.where(function(s) { return s.Url === typeOrUrl || s.Type === typeOrUrl; }).firstOrNull();
};
quosal.sell.modules.register = function(modules) {
    for (var i = 0; i < modules.length; i++) {
        var module = modules[i];
        var existingModule = quosal.sell.modules.find(module.Url);

        if(existingModule) {
            existingModule.merge(module);
            continue;
        }

        module.state = {};
        module.subModules = [];
        module.container = null;
        module.parentModule = null;
        module.onBeforeUnload = quosal.events.create();
        module.onAfterUnload = quosal.events.create();
        
        module.isLoaded = function() {
            return !!this.parentModule;
        };
        module.isActive = function() {
            return this.subModules.length === 0;
        };
        module.setState = function(newState) {
            if(!newState) return;

            for(var i in newState) {
                this.state[i] = newState[i];
            }
        };
        module.replaceState = function(newState) {
            if(!newState) newState = {};
            this.state = newState;
        };
        module.reset = function() {
            this.state = {};
        };
        module.merge = function(newModule) {
            for(var i in newModule) {
                this[i] = newModule[i];
            }
        };
        module.getDefaultContainer = function() {
            var quoteModules = ['quote.content', 'product.edit', 'tab.edit', 'quote.review', 'quote.dashboard', 'quote.customer', 'quote.approval'];

            if(quoteModules.indexOf(this.Url) >= 0)
                return 'quoteModule';
            else
                return 'currentDocument';
        };
        module.getUrl = function(args) {
            var url = quosal.util.parseUri(quosal.util.url(this.Url));
            var query = args.query || '';
            var sep = '&';

            var tokens = query.replace('?', '').split('&');

            for(var i = 0; i < tokens.length; i++) {
                if(String.isNullOrEmpty(tokens[i]) || tokens[i].indexOf('=') < 0)
                    continue;

                var parts = tokens[i].split('=');
                var key = parts[0].toLowerCase();

                if (args.queryKeys && args.queryKeys !== null && args.queryKeys.indexOf(key) < 0)
                    continue;

                url.queryKey[key] = parts[1];
            }

            if(!url.queryKey['submodules']) {
                sep = '';
                var submodules = '';

                var extractSubModuleUrls = function (parent) {
                    for (var i = 0; i < parent.subModules.length; i++) {
                        if (parent.subModules[i] !== parent) {
                            submodules += sep + parent.subModules[i].Url;
                            sep = ',';

                            extractSubModuleUrls(parent.subModules[i]);
                        }
                    }
                };

                extractSubModuleUrls(this);

                if (submodules) {
                    url.queryKey['submodules'] = submodules;
                }
            }

            return url.toRelativeUrl();
        };
        module.load = function(args) {
            if(!args) args = {};
            args.module = this;

            if(!args.bypassBeforeLoadEvent) {
                args.cancelNavigation = function() {
                    this.cancel = true;
                }.bind(args);

                quosal.sell.modules.onBeforeLoad.call(args);

                if (args.cancel === true) {
                    args.resumeNavigation = function (args) {
                        args.bypassBeforeLoadEvent = true;
                        this.load(args);
                    }.bind(this, typeOrUrl, args);

                    return null;
                }
            }

            if(app.currentModule)
                app.currentModule.unload();

            app.currentModule = this;

            // FSP 11/15/2016 This is scary... functions being passed in are probably not expecting to be re-bound. Remove this once we're more confident that nothing was relying on it.
            // if(args.callback)
            //     args.callback = args.callback.bind(this, this);

            var containerId = args.container || this.getDefaultContainer();
            this.container = document.getElementById(containerId);

            if(!args.suppressNavigation) {
                //Check history state to determine if it should be a full navigate?
                if (args.forceNavigation || location.currentModule() !== this.Url) {
                    quosal.navigation.navigate(this.getUrl(args), {
                        target: containerId,
                        callback: args.callback,
                        overrideUrl: null
                    }, this.Name);
                } else {
                    //callback may not be triggered here
                    app.history.browserBackOrForward = false;
                    History.replaceState({
                        target: containerId,
                        callback: args.callback,
                        rand: quosal.util.generateGuid(),
                        overrideUrl: null
                    });
                }
            } else if(args.callback) {
                args.callback();
            }

            quosal.sell.modules.onAfterLoad.call(this);

            return this;
        };
        module.unload = function() {
            this.onBeforeUnload.call(this);

            this.unloadSubModules();

            quosal.ui.react.unmount(this.container);
            this.container = null;
            this.parentModule = null;

            if(app.currentModule === this)
                app.currentModule = null;

            this.onAfterUnload.call();
        };
        module.loadSubModule = function(typeOrUrl, args) {
            if(String.isNullOrEmpty(typeOrUrl)) throw 'Must specify a sub-module "url" or "type" argument.';
            var subModule = quosal.sell.modules.find(typeOrUrl);
            if(!subModule) throw 'Invalid sub-module specified: ' + typeOrUrl;

            if(!args) args = {};
            args.module = this;
            args.subModule = subModule;
            if(!args.keepItemId){
                var clearModule = quosal.sell.modules.find("quote.content");
                if(clearModule){
                    clearModule.selectedItem = null;
                }
            }

            if(!args.bypassBeforeLoadEvent) {
                args.cancelNavigation = function() {
                    this.cancel = true;
                }.bind(args);

                quosal.sell.modules.onBeforeLoad.call(args);

                if (args.cancel === true) {
                    args.resumeNavigation = function (typeOrUrl, args) {
                        args.bypassBeforeLoadEvent = true;
                        this.loadSubModule(typeOrUrl, args);
                    }.bind(this, typeOrUrl, args);

                    return null;
                }
            }

            var subModulesToUnload = args.unloadSubModules ? app.currentModule.subModules : [];
            if(subModule.parentModule && subModule.parentModule.subModules.indexOf(subModule) >= 0 && subModulesToUnload.indexOf(subModule) < 0) {
                subModulesToUnload.push(subModule);
            }
            for (var i = 0; i < subModulesToUnload.length; i++) {
                subModulesToUnload[i].parentModule.unloadSubModule(subModulesToUnload[i]);
            }

            if(app.currentModule.subModules.length > 0)
                subModule.parentModule = app.currentModule.subModules[app.currentModule.subModules.length - 1];
            else
                subModule.parentModule = app.currentModule;

            subModule.parentModule.subModules.push(subModule);

            if(args.callback)
                args.callback = args.callback.bind(this, subModule);

            var containerId = args.container || this.getDefaultContainer();
            subModule.container = document.getElementById(containerId);

            if(!args.suppressNavigation) {
                var url = app.currentModule.getUrl(args);
                quosal.navigation.navigate(url, {target: containerId, callback: args.callback, overrideUrl: subModule.Url, suppressHistory: args.suppressHistory}, subModule.Name);
            } else if(args.callback) {
                args.callback();
            }

            quosal.sell.modules.onAfterLoad.call(subModule);

            return subModule;
        };
        module.unloadSubModules = function() {
            while(this.subModules.length > 0) {
                this.unloadSubModule(this.subModules[0]);
            }
        };
        module.unloadSubModule = function(subModule) {
            var parentModule = subModule.parentModule;

            subModule.unload();

            if(parentModule.subModules.indexOf(subModule) >= 0) {
                parentModule.subModules.splice(parentModule.subModules.indexOf(subModule), 1);
            }
        };
        module.getActiveSubModule = function() {
            var findActive = function(parent) {
                for(var i = 0; i < parent.subModules.length; i++) {
                    if(parent.subModules[i].isActive())
                        return parent.subModules[i];
                    else {
                        var result = findActive(parent.subModules[i]);
                        if(result)
                            return result;
                    }
                }

                return null;
            };
 
            return findActive(this);
        };

        quosal.sell.modules.allModules.push(module);
    }
};
quosal.sell.modules.unregister = function(modules) {
    //TODO: should probably unload before unregistering?
    quosal.sell.modules.allModules.removeAll(modules);
};
quosal.sell.modules.reload = function () {
    //Recreates the module hierarchy based on the current URL. Called when browser navigation is used, which bypasses module navigation.
    var currentModule = location.currentModule();
    var subModules = location.subModules();
    var changedModules = false;
    if(app.currentModule.Url !== currentModule) {
        app.currentModule.unload();
        var newModule = quosal.sell.modules.find(currentModule);
        var params = { suppressNavigation: newModule.SuppressNavigationOnReload };
        var idQuoteMain = quosal.util.queryString('idquotemain');

        if(idQuoteMain){
            params.query = 'idquotemain=' + idQuoteMain;
        }

        newModule.load(params);
    }

    var parentModule = app.currentModule;

    var checkForSubModule = function(parent, sub) {
        var existingSub = parent.subModules.firstOrNull(function(s) { return s.Url === sub; });
       
        if (!existingSub) {
			parent.unloadSubModules();
			const containsSubModule = Boolean(quosal.sell.modules.find(sub));
			if (containsSubModule)
				existingSub = parent.loadSubModule(sub, { suppressNavigation: true });
		}

        return existingSub;
    };
    if(!app.currentModule.SuppressNavigationOnReload && changedModules){
        return;
    }
    for(var i = 0; i < subModules.length; i++) {
        parentModule = checkForSubModule(parentModule, subModules[i]);
    }

    if(parentModule?.subModules?.length > 0)
        parentModule.unloadSubModules();

    quosal.sell.modules.reloaded.call();
};

quosal.sell.modules.checkIfLocked = function(nameOfModule, quote) {
    const nav = quosal.sell.modules.find(nameOfModule)
    
    if(!nav) return false;

    if (nav.Module == "quote.publish" && app.settings.global.ApprovalMethod == 'Multilevel') {
        if (quote.ApprovalStatus == "Approved" && nav.IsLocked) {
            return false;
        }
        else if (!nav.IsLocked && !app.currentUser.IsAdministrator) {
            if((app.quoteHasTriggeredApprovalRules && quote.ApprovalStatus != "Approved") || quote.ApprovalStatus == "Request Approval") {
                return true;
            }
            else if(quosal && quosal.publish && quosal.publish.publishCheat){
                return true;
            }
        } 
    } 
    return nav.IsLocked
    
}

//=============================//
//* Quosal.Sell.Quote         *//
//=============================//
quosal.sell.quote.onUpdate = quosal.events.create();

quosal.sell.quote.isUpdating = false;
quosal.sell.quote.objectsUpdated = [];
quosal.sell.quote.objectUpdating = function(objectId) {
    quosal.sell.quote.objectsUpdated.push(objectId);
};
quosal.sell.quote.updatePartial = function(partialResponse, updateId){
    quosal.util.mergeObject(app.currentQuote, partialResponse.partialQuote);
    if(partialResponse.items){
        if (Array.isArray(partialResponse.items)) {
            partialResponse.items.forEach(function (updatedItem) {
                var foundItem = app.currentQuote.Items.find(function (item) {
                    return item.IdQuoteItems == updatedItem.IdQuoteItems
                })
                quosal.util.mergeObject(foundItem, updatedItem);
                delete foundItem.isUnsaved;
            });
        } else {
            app.currentQuote.Items.forEach(function (foundItem) {
                var updatedItem = partialResponse.items[foundItem.id];
                if (updatedItem) {
                    quosal.util.mergeObject(foundItem, updatedItem);
                }
                delete foundItem.isUnsaved;
            });
        }

        if (partialResponse.deletedIds) {
            for (var i = app.currentQuote.Items.length - 1; i >= 0; i--) {
                if (partialResponse.deletedIds[app.currentQuote.Items[i].id]) {
                    app.currentQuote.Items.splice(i, 1);
                }
            }
        }
    }
    if (partialResponse.tabs) {
        if (Array.isArray(partialResponse.tabs)) {
            partialResponse.tabs.forEach(function (updatedTab) {
                var foundTab = app.currentQuote.Tabs.find(function (tab) {
                    return tab.IdQuoteTabs == updatedTab.IdQuoteTabs
                })
                quosal.util.mergeObject(foundTab, updatedTab);
                delete foundTab.isUnsaved;
            });
        } else {
            app.currentQuote.Tabs.forEach(function (foundTab) {
                var updatedTab = partialResponse.tabs[foundTab.id];
                if (updatedTab) {
                    quosal.util.mergeObject(foundTab, updatedTab);
                    delete foundTab.isUnsaved;
                }
            });
        }

        if (partialResponse.deletedIds) {
            for (var i = app.currentQuote.Tabs.length - 1; i >= 0; i--) {
                if (partialResponse.deletedIds[app.currentQuote.Tabs[i].id]) {
                    app.currentQuote.Tabs.splice(i, 1);
                }
            }
        }
    }
    if(partialResponse.newItems) {
        app.currentQuote.Items.push(...partialResponse.newItems);
    }
    if(partialResponse.newTab) {
        app.currentQuote.Tabs.push(partialResponse.newTab);
    }
    quosal.sell.quote.update(app.currentQuote, updateId);
}
quosal.sell.quote.update = function (quote, updateId) {
    quosal.sell.quote.isUpdating = true;
    quosal.sell.quote.objectsUpdated = [];

    try {
        if (quote && !quote.Navigation) {
            quote.Navigation = app.currentQuote.Navigation;
        }
        if (!quote.Documents || quote.Documents.PdfDocument === "" && app.currentQuote && app.currentQuote.Documents) {
            quote.Documents = app.currentQuote.Documents
        }
        app.currentQuote = quosal.sell.quote.initQuote(quote);
        quosal.sell.quote.onUpdate.call(quote, updateId);
    } catch (ex) {
        quosal.log.error(ex);
    }

    quosal.sell.quote.isUpdating = false;
    quosal.sell.quote.objectsUpdated = null;

    return quote;
};
quosal.sell.quote.updateFromApiResponse = function (msg) {
    if (msg.quote) {
        quosal.sell.quote.update(msg.quote);
    } else if (msg.partialResponse) {
        quosal.sell.quote.updatePartial(msg.partialResponse);
    }
}
quosal.sell.quote.initQuote = function (quote) {
    quote.formatCurrency = function (number) {
        return accounting.formatMoney(number, {
            symbol: this.currencyInfo.CurrencySymbol,
            precision: quosal.data.getDecimalPrecision(number),
            thousand: this.currencyInfo.CurrencyGroupSeparator,
            decimal: this.currencyInfo.CurrencyDecimal,
            format: this.currencyInfo.CurrencyFormat
        });
    };
    quote.parseCurrency = function (number) {
        return accounting.unformat(
            number, 
            this.currencyInfo.CurrencyDecimal
        );
    };
    quosal.sell.modules.register(quote.Navigation);
    if (quote.EoNavigation) {
        quosal.sell.modules.register(quote.EoNavigation);
    }

    if (quosal.util.shouldDocumentPreviewBeOpen(quote)) {
        quosal.sell.quote.showDocumentPreviewer(true);
    }

    return quote;
};
quosal.sell.quote.unload = function(quote) {
    if(!quote) quote = app.currentQuote;
    app.currentQuote = null;
};

quosal.sell.quote.showDocumentPreviewer = function(enabled) {
    var right = 0;
    var width = 'calc(100% - 92px)';
    var visible = !app.settings.user.documentPreviewDisabled;

    if(!enabled) {
        right = -87;
        width = '';
        visible = false;
    }

    if(visible)
        $('#documentPreview').show();

    $('#documentPreview').animate({
        right: right
    }, {
        complete: function() {
            $('#quoteContainer').css('width', width);

            if(!visible)
                $('#documentPreview').hide();
        }
    });
}

//=============================//
//* Quosal.Sell.Product       *//
//=============================//
quosal.sell.product.sources = null;
quosal.sell.product.sourceGroups = null;
quosal.sell.product.regeneratePdf = false;

quosal.sell.product.generatePdf = function(idQuoteMain, opId){
    var doCall = true;
    quosal.net.activeAjaxCalls.some(function(call){
        if(call.message.api == "Quote.Publish" && call.message.opId != opId){
            doCall = false;
            quosal.sell.product.regeneratePdf = true;
            return true;
        } else {
            return false;
        }
    });

    if(doCall){
        var publishApi = quosal.api.quote.publish({idQuoteMain: idQuoteMain, isPreview: true});
        quosal.sell.product.regeneratePdf = false;
        publishApi.call();
    }
}
quosal.sell.product.loadPriceSourcing = function(force, callback) {
    if (!quosal.sell.product.sourceGroups || quosal.sell.product.sourceGroups.length === 0 || force) {
        if (!quosal.api) return;
        quosal.sell.product.sourceGroups = [];
        var sourcingApi = quosal.api.pricing.getPriceSourcing();
        sourcingApi.finished = function (msg) {
            quosal.sell.product.sources = msg.sources;

            if(msg.sourceGroups) {
                for (var i = 0; i < msg.sourceGroups.length; i++) {
                    var groupSources = msg.sourceGroups[i].Sources.split(',');
                    msg.sourceGroups[i].Sources = [];

                    for (var gs = 0; gs < groupSources.length; gs++) {
                        var rts = msg.sources.where(function (s) {
                            return s.FormattedSourceName === groupSources[gs] }
                        ).firstOrNull();
                        if(rts)
                            msg.sourceGroups[i].Sources.push(rts);
                    }
                }

                quosal.sell.product.sourceGroups = msg.sourceGroups;
            } else {
                quosal.sell.product.sourceGroups = [];
            }

            if (callback)
                callback();
        };
        sourcingApi.call();
    } else {
        if (callback)
            callback();
    }
};


quosal.sell.scripts.cache = {};
quosal.sell.scripts.getScripts = function(scriptType) {
    return quosal.sell.scripts.cache[scriptType];
};
quosal.sell.scripts.load = function(scriptType, callback) {
    if(quosal.sell.scripts.cache[scriptType]) {
        callback(quosal.sell.scripts.cache[scriptType]);
    } else {
        var getScriptsApi = quosal.api.script.getScripts(scriptType);
        getScriptsApi.finished = function (msg) {
            quosal.sell.scripts.cache[msg.scriptType] = msg.scripts;

            callback(quosal.sell.scripts.cache[scriptType]);
        };
        getScriptsApi.call();
    }
};
quosal.sell.scripts.runScript = function(script, callback) {
    var runApi = quosal.api.script.runScript(script.IdQuosalSellScript);
    runApi.finished = function(msg) {
        callback(msg);
    };
    runApi.call();
};

quosal.sell.product.getCatalogs = function (idQuoteMain, sources) {
    if (!quosal.api) return;
    var catalogsApi = quosal.api.call('Product.GetCategories', {
        idQuoteMain: idQuoteMain,
        sources: sources
    });
    catalogsApi.finished = function (msg) {
        sessionStorage.setItem("catalogs", JSON.stringify(msg.catalogs));          
    }
    catalogsApi.call();    
};