import {FormField, FormFieldInput} from "js/jsx/src/classes/forms.jsx";

export class TermOptionsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {                       
        }; 

        // This binding is necessary to make `this` work in the callback
        this.getDirtyTermOptionDetails = this.getDirtyTermOptionDetails.bind(this);
        this.updateRadios = this.updateRadios.bind(this);
        this.update = this.update.bind(this);
        this.reset = this.reset.bind(this);
    }
    
    static getSetOfVisibleTabNames() {
            var tabs = app.currentQuote.Tabs;
            var tabNames = {};
            for (var i = 0; i < tabs.length; i++) {
                if(tabs[i].IsHidden && !(app.currentUser.IsAdministrator || app.currentUser.IsContentMaintainer)) {
                    continue;
                }
                tabNames[tabs[i].TabName.toLowerCase().trim()] = true;
            }
            return tabNames;
    }
    getDirtyTermOptionDetails() {
        var dirtyTermOptionDetails = [];
        for(var refKey in this.refs) {
            if(refKey.indexOf('termOptionDetail') === 0) {
                var termOptionDetail = this.refs[refKey];
                if (termOptionDetail.state.isDirty) {
                    dirtyTermOptionDetails.push(termOptionDetail);
                }
            }
        }
        return dirtyTermOptionDetails;
    }
    updateRadios(financingGroup, index) {
        if (this.isUpdatingRadios) {
            return;
        }

        // FSP 8/7/17 9255471: index not null represents the radio with that index being toggled; update all details except the indexed one to false.
        //                     index being null represents a change that isn't a radio toggle but requires that radios be checked for validity; update first true one to true and force the rest to false.
        var detailsNeedingRadioUpdate = [];
        var valuesToSet = [];
        var foundTheSelectedDetail = false;
        for(var refKey in this.refs) {
            if(refKey.indexOf('termOptionDetail') === 0) {
                if (index != null && refKey == 'termOptionDetail' + index) {
                    continue;
                }
                var termOptionDetail = this.refs[refKey];
                if(termOptionDetail.financingGroup() == financingGroup) {
                    var valueToSet = false;
                    if (!foundTheSelectedDetail && index == null) {
                        valueToSet = !!termOptionDetail.refs.booleanField_IsSelected.refs.input.getValue();
                        foundTheSelectedDetail = valueToSet;
                    }
                    detailsNeedingRadioUpdate.push(termOptionDetail);
                    valuesToSet.push(valueToSet);
                }
            }
        }

        if (detailsNeedingRadioUpdate.length > 0) {
            this.isUpdatingRadios = true;
            var didRadioUpdateCountdown = detailsNeedingRadioUpdate.length;
            var that = this;
            var markDidRadioUpdate = function () {
                didRadioUpdateCountdown--;
                if (didRadioUpdateCountdown == 0) {
                    that.isUpdatingRadios = false;
                    that.forceUpdate();
                }
            }
            for (var i = 0; i < detailsNeedingRadioUpdate.length; i++) {
                detailsNeedingRadioUpdate[i].updateRadio(valuesToSet[i], markDidRadioUpdate);
            }
        }
    }
    update() {
        var updates = [];
        var dirtyTermOptionDetails = this.getDirtyTermOptionDetails();
        for (var i = 0; i < dirtyTermOptionDetails.length; i++) {
            var detail = dirtyTermOptionDetails[i];
            var update = detail.getUpdateObject();
            if (update) {
                updates.push(update);
            }
        }
        if (this.refs.OrderPorterPaymentMode && this.refs.OrderPorterPaymentMode.state.isDirty) {
            var update = {
                fields: {OrderPorterPaymentMode: this.refs.OrderPorterPaymentMode.state.newValue},
                queries: [{
                    table: 'QuoteMain',
                    where: [{
                        field: 'IdQuoteMain',
                        operator: 'Equals',
                        value: app.currentQuote.IdQuoteMain
                    }]
                }]
            };
            updates.push(update);
        }

        if (updates.length === 0) {
            return;
        }

        var updateApi = quosal.api.data.update(updates, app.currentQuote.IdQuoteMain);
        updateApi.finished = function (msg) {
            quosal.sell.quote.updateFromApiResponse(msg);    
            this.setState({ isSaving: false });      
        }.bind(this);
        this.setState({isSaving: true});
        updateApi.call();
    }
    reset() {
        if(this.refs.OrderPorterPaymentMode){
            this.refs.OrderPorterPaymentMode.reset();
        }
        var dirtyTermOptionDetails = this.getDirtyTermOptionDetails();
        for (var i = 0; i < dirtyTermOptionDetails.length; i++) {
            dirtyTermOptionDetails[i].reset();
        }
    }
    render() {

        var orderPorterPaymentModeList = quosal.settings.getValue('paymentProcessors');
        var isEditable = this.props.editable;
        var termOptionDetails = [];
        var quote = app.currentQuote;
        var terms = quote.Terms;
        if (terms && terms.length) {
            var visibleTabNames = null;
            for (var i = 0; i < terms.length; i++) {
                if (terms[i].OnlyShowWithTabFilter) {
                    visibleTabNames = visibleTabNames || TermOptionsTab.getSetOfVisibleTabNames();
                    var tabNamesThatAllowThisTermToShow = terms[i].OnlyShowWithTabFilter.split(/[,|\r\n]/).map(x => x.toLowerCase().trim()).where(x => x);
                    if (!tabNamesThatAllowThisTermToShow.some(x => visibleTabNames[x])) {
                        continue;
                    }
                }
                termOptionDetails.push(<TermOptionDetail ref={'termOptionDetail' + i} parent={this} key={i} keyProp={i} term={terms[i]} disabledSettings={this.state.isSaving || !isEditable} disabled={this.state.isSaving || !isEditable || !quosal.validation.isPackageLevelAuthorized(app.packageLevels.premium)} />);
            }
        }

        var orderPorterPaymentModeOptions = [];
        for (var i = 0; i < orderPorterPaymentModeList.length; i++) {
            orderPorterPaymentModeOptions.push(<option key={i} value={orderPorterPaymentModeList[i]} >{orderPorterPaymentModeList[i]}</option>);
        }
        var onChange = function () {};
        var metadata = {};
        metadata.OrderPorterPaymentMode = orderPorterPaymentModeList.map(function (currentValue) { return {Value: currentValue, Label: currentValue}; });

        return (
            <div id="termOptionsTab" >
                <link type="text/css" href="css/termOptionsTab.css" rel="stylesheet" />
                <div className="panel toolbar top" style={{width:'calc(100% - 10px)', padding:'5px', margin:'0px', border:'0px', height:'28px'}}>
                    <div className="right">
                        {isEditable && <Button className="cancel" onClick={this.reset} disabled={this.state.isSaving} >Cancel</Button>}
                        {isEditable && <SaveButton onClick={this.update} isSaving={this.state.isSaving} ></SaveButton>}
                    </div>
                </div>
                <br />
               <div>
                    <br />
                    <table cellPadding="0" cellSpacing="0" style={{width: '100%', backgroundColor: '#DDDDDD'}} >
                        <tbody><tr>
                            <td className="orderPorterPaymentMode" width="100" ><b>Order Porter Payment Mode:</b></td>
                            <td width="400" >
                                <FormFieldInput field={{DataType: 'Enum', EnumType: 'OrderPorterPaymentMode', FieldName: 'OrderPorterPaymentMode', Value: quote.OrderPorterPaymentMode}}
                                                ref="OrderPorterPaymentMode" onChange={onChange} highlightWhenDirty={true} name="OrderPorterPaymentMode"
                                                metadata={metadata} disabled={this.state.isSaving || !isEditable} />
                            </td>
                        </tr></tbody>
                    </table>
                    <br />
                </div>
                <br />
                {termOptionDetails}
                <br />
                <div className="panel toolbar top" style={{width:'calc(100% - 10px)', padding:'5px', margin:'0px', border:'0px', height:'28px'}}>
                    <div className="right">
                        {isEditable && <Button className="cancel" onClick={this.reset} disabled={this.state.isSaving} >Cancel</Button>}
                        {isEditable && <SaveButton onClick={this.update} isSaving={this.state.isSaving} ></SaveButton>}
                    </div>
                </div>
            </div>
        );
    }
}

class TermOptionDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            isDirty: false,
            financingGroup: this.props.term.FinancingGroup
        }; 

        // This binding is necessary to make `this` work in the callback
        this.updateRadio = this.updateRadio.bind(this);
        this.financingGroup = this.financingGroup.bind(this);
        this.getDirtyInputs = this.getDirtyInputs.bind(this);
        this.getUpdateObject = this.getUpdateObject.bind(this);
        this.handleFinancingGroupChangeAfterTimeout = this.handleFinancingGroupChangeAfterTimeout.bind(this);
        this.reset = this.reset.bind(this);

        this.timeoutID = null;
    }   
    updateRadio(value, callback) {
        this.refs.booleanField_IsSelected.refs.input.setValue(value, {callback: callback});
    }
    financingGroup() {
        return this.refs.field_FinancingGroup.getValue();
    }
    getDirtyInputs() {
        var dirtyInputs = [];
        for(var refKey in this.refs) {
            if(refKey.indexOf('field') === 0) {
                var fieldInput = this.refs[refKey];
                if (fieldInput.state.isDirty) {
                    dirtyInputs.push(fieldInput);
                }
            } else if(refKey.indexOf('boolean') === 0) {
                var fieldInput = this.refs[refKey];
                if (fieldInput.state.isDirty) {
                    dirtyInputs.push(fieldInput.refs.input);
                }
            }
        }
        return dirtyInputs;
    }
    getUpdateObject() {
        var dirtyInputs = this.getDirtyInputs();
        if (dirtyInputs.length === 0) {
            return null;
        }

        var fieldUpdates = {};
        for (var i = 0; i < dirtyInputs.length; i++) {
            var fieldInput = dirtyInputs[i];
            fieldUpdates[fieldInput.props.field.FieldName] = fieldInput.state.newValue;
        }

        var update = {
            fields: fieldUpdates,
            queries: [{
                table: 'QuoteFinancing',
                where: [{
                    field: 'IdQuoteFinancing',
                    operator: 'Equals',
                    value: this.props.term.IdQuoteFinancing
                }]
            }]
        };
        return update;
    }
    handleFinancingGroupChangeAfterTimeout(target) {
        var newValue = $(target).val();
        this.setState(
            {
                isDirty: true,
                financingGroup: newValue,
            },
            function () {
                window.clearTimeout(this.timeoutID);
                this.timeoutID = window.setTimeout(function () {
                    this.props.parent.updateRadios(newValue);
                    this.timeoutID = null;
                }.bind(this), 300);
            }.bind(this)
        );
    }
    reset() {
        var dirtyInputs = this.getDirtyInputs();
        for (var i = 0; i < dirtyInputs.length; i++) {
            dirtyInputs[i].reset();
        }
        this.setState({isDirty: false});
    }
    render() {
        var quote = app.currentQuote;
        var term = this.props.term;
        var thisTermOptionDetail = this;
        var onChange = function () {
            thisTermOptionDetail.setState({isDirty: true});
        };
        var onChangeForRadio = function (value, callback) {
            thisTermOptionDetail.props.parent.updateRadios(this.financingGroup(), this.props.keyProp);
            onChange();
            if ('function' === typeof callback) {
                callback();
            }
        }.bind(this);
        var formFieldInputFromField = function (field, style, disabled) {
            var disabledProp = this.props.disabled || disabled;
            var additionalProps = {};
            if (style) { additionalProps['style'] = style};
            var id = 'Term.' + field.FieldName + '.' + term.IdQuoteFinancing;
            var onChangeForThisInput = onChange;
            if(field.FieldName === 'FinancingGroup') {
                onChangeForThisInput = this.handleFinancingGroupChangeAfterTimeout;
            }
            if(field.FieldName == "IsSelected" && !this.props.disabledSettings){
                disabledProp = false
            }
            if(field.FieldName == "IsPrinted" && !this.props.disabledSettings){
                disabledProp = false
            }
            if (field.DataType === 'Boolean') {
                var isRadio = (field.RadioGroup !== undefined);
                var onChangeForBoolean = isRadio ? onChangeForRadio : onChange;
                if (isRadio && quosal.settings.getValue('AllowUnselectTermRadios')) {
                    delete field.RadioGroup;
                }
                return <FormField ref={'booleanField_' + field.FieldName} field={field} fieldId={id} onChange={onChangeForBoolean} disabled={disabledProp} ></FormField>
            }
            return <FormFieldInput ref={'field_' + field.FieldName} field={field} parentId={this.props.keyProp} fieldId={id}
                                   highlightWhenDirty={true} onChange={onChangeForThisInput}
                                   disabled={disabledProp} {...additionalProps} />
        }.bind(this);
        var financingGroupInput = formFieldInputFromField({Value: term.FinancingGroup, FieldName: 'FinancingGroup', DataType: 'String'}, {width:'150px'});
        var termNameInput = formFieldInputFromField({Value: term.Name, FieldName: 'Name', DataType: 'String'}, {width:'700px'});
        var isSelectedRadio = formFieldInputFromField({Value: term.IsSelected, FieldName: 'IsSelected', LabelName: 'Is Selected', DataType: 'Boolean', RadioGroup: this.state.financingGroup});
        var periodsInput = formFieldInputFromField({Value: term.Periods, FieldName: 'Periods', DataType: 'Double'});
        var recurringPeriodsInput = formFieldInputFromField({Value: term.RecurringPeriods, FieldName: 'RecurringPeriods', DataType: 'Double'});
        var isPrintedCheckbox = formFieldInputFromField({Value: term.IsPrinted, FieldName: 'IsPrinted', LabelName: 'Is Printed', DataType: 'Boolean'});
        var interestRateInput = formFieldInputFromField({Value: term.InterestRate, FieldName: 'InterestRate', DataType: 'Double'});
        var intervalToStartInput = formFieldInputFromField({Value: term.IntervalToStart, FieldName: 'IntervalToStart', DataType: 'Int32'});
        var downPaymentInput = formFieldInputFromField({Value: term.DownPayment, FieldName: 'DownPayment', DataType: 'Double'});
        var spreadFeeCheckbox = formFieldInputFromField({Value: term.SpreadOneTime, FieldName: 'SpreadOneTime', LabelName: 'Spread Fee', DataType: 'Boolean'}, null, term.IsLease);
        var isAutomaticallyBillableCheckbox = formFieldInputFromField({Value: term.IsAutomaticallyBillable, FieldName: 'IsAutomaticallyBillable', LabelName: 'Setup Subscription', DataType: 'Boolean'});

        return (
            <div>
                <br />

                <table className="termOptionsPrepareContent" cellPadding="0" cellSpacing="0" style={{width: '100%', backgroundColor: '#DDDDDD'}} >
                    <tbody>
                        <tr>
                            <td>
                                {financingGroupInput}
                                <span> : </span>
                                {termNameInput}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellPadding="0" cellSpacing="0" className="termOptionTabDetailTable"  style={{width: '100%', backgroundColor: '#FFFFFF'}}>
                    <tbody>
                        <tr>
                            <td width="140">
                                {isSelectedRadio}
                            </td>

                            <td width="110">Finance Periods:</td>
                            <td width="80">{periodsInput}</td>

                            <td width="110">Recurring Periods:</td>
                            <td width="80">{recurringPeriodsInput}</td>

                            <td width="110">Principal:</td>
                            <td width="80">{quote.formatCurrency(term.Principal)}</td>

                            <td width="110">Per Period:</td>
                            <td width="80">{quote.formatCurrency(term.RecurringTotalAmount)}</td>
                        </tr>
                        <tr>
                            <td width="140">
                                {isPrintedCheckbox}
                            </td>

                            <td width="110">Annual Interest Rate:</td>
                            <td width="80">{interestRateInput}</td>

                            <td width="110">Start Delay:</td>
                            <td width="80">{intervalToStartInput}</td>


                            <td width="110">Period Payment:</td>
                            <td width="80">{quote.formatCurrency(term.PeriodPaymentAmount)}</td>

                            <td width="110">Total Recurring:</td>
                            <td width="80">{quote.formatCurrency(term.RecurringTotalAggregated)}</td>
                        </tr>
                        <tr>
                            <td width="140"></td>

                            <td width="110">Down Payment:</td>
                            <td width="80">{downPaymentInput}</td>

                            <td width="190" colSpan={2}>
                                {spreadFeeCheckbox}
                            </td>


                            <td width="110">Total Payment:</td>
                            <td width="80">{quote.formatCurrency(term.TotalAmount)}</td>

                            <td width="190" colSpan={2}>
                                {isAutomaticallyBillableCheckbox}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}