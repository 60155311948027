import { useState, useEffect } from "react";
import { useAppGlobalContext } from "../../context/globalContext";

const useNewRecordContainer = (props) => {
	const { currentTab, updateGridRows, tabId, ids, onPopoverClose } = props;
	const { contentGrid: grid, ckeditor } = useAppGlobalContext();

	const [textFieldValue, setTextFieldValue] = useState("");
	const [dropdownValue, setDropdownValue] = useState("");
	const [numberValue, setNumberValue] = useState(0);
	const [currencyValue, setCurrencyValue] = useState(app.currentQuote.formatCurrency(0));
	const [loadingCheckProduct, setLoadingCheckProduct] = useState(false);
	const [loadingAddProduct, setLoadingAddProduct] = useState(false);
	const [productExists, setProductExists] = useState(false);
	const [productError, setProductError] = useState(false);
	const [productChecked, setProductChecked] = useState(false);
	const showGearIcon = app.currentUser.IsAdministrator;

	// darkMode state start
	const [dialogClasses, setDialogClasses] = useState("");
	const [darkMode, setDarkMode] = useState($("#dark-toggle").prop("checked"));
	// darkMode state end

	// helpers start
	const getGroupOptions = () => {
		const groups = quosal.util.clone(quosal.sell.product.sourceGroups);
		if (groups && groups.length) {
			groups.sort((a, b) => {
				const textA = a.GroupName ? $.trim(a.GroupName.toUpperCase()) : "";
				const textB = b.GroupName ? $.trim(b.GroupName.toUpperCase()) : "";
				return textA < textB ? -1 : textA > textB ? 1 : 0;
			});
		}

		return groups;
	};

	const regexManipulation = (data) => {
		let price = String(data).trim();

		if (!price || !price.match(/[0-9]/)) {
			return 0;
		}

		const endsWithDot = price.endsWith(".");

		if ((price.match(/\./g) || []).length > 1) {
			const lastDotIndex = price.lastIndexOf(".");
			price =
				price.substring(0, lastDotIndex).replace(/\./g, "") + price.substring(lastDotIndex);
		} else {
			price = price.replace(/[^0-9.]/g, "");
		}

		let [integerPart, decimalPart] = price.split(".");

		integerPart = integerPart.replace(/^0+(?!\.|$)/, "");

		if (decimalPart && decimalPart.length > 2) {
			decimalPart = decimalPart.substr(0, 2);
		}

		decimalPart = decimalPart === "00" || !decimalPart ? "" : `.${decimalPart}`;

		price = integerPart + decimalPart;

		return endsWithDot && !decimalPart
			? `${price}.`
			: price.includes(".")
			? price
			: parseFloat(price);
	};
	// helpers end

	const resetDialog = () => {
		setTextFieldValue("");
		setDropdownValue("");
		setNumberValue(0);
		setCurrencyValue(app.currentQuote.formatCurrency(0));
		setLoadingCheckProduct(false);
		setLoadingAddProduct(false);
		setProductExists(false);
		setProductError(false);
		setProductChecked(false);
	};

	const handleClose = () => {
		resetDialog();
		onPopoverClose();
	};

	const handleCheck = () => {
		if (!textFieldValue) {
			setProductError(true);
			return;
		}

		const checkProductApi = quosal.api.product.checkProduct(
			textFieldValue,
			app.currentQuote.IdQuoteMain,
			currentTab.IdQuoteTabs,
			dropdownValue
		);

		checkProductApi.finished = function (msg) {
			setProductExists(msg.exists);
			setProductChecked(true);

			if (msg.exists) {
				setCurrencyValue(app.currentQuote.formatCurrency(msg.item.QuoteItemPrice));
			}

			setNumberValue(1);
			setLoadingCheckProduct(false);
		};

		setLoadingCheckProduct(true);
		checkProductApi.call();
	};

	const handleTextFieldChange = (event) => {
		setTextFieldValue(event.target.value);

		if (productChecked) {
			setNumberValue(0);
			setCurrencyValue(app.currentQuote.formatCurrency(0));
			setProductExists(false);
			setProductChecked(false);
		}
	};

	const handleDropdownChange = (event) => {
		setDropdownValue(event.target.value);
	};

	const handleNumberChange = (event) => {
		if (event.target.value !== "") {
			const val = event.target.value.replace(/[^0-9]/g, "");
			setNumberValue(Number(val));
		}
	};

	const onQuantityBlur = () => {
		let value = numberValue;
		const max = 100000000;
		if (value > max) {
			value = max;
		}

		setNumberValue(Number(value));
	};

	const handleCurrencyChange = (event) => {
		if (event.target.value !== null && event.target.value !== undefined) {
			const value = regexManipulation(event.target.value);

			setCurrencyValue(value);
		}
	};

	const onPriceFocus = () => {
		if (currencyValue) {
			const value = regexManipulation(currencyValue);

			setCurrencyValue(value);
		}
	};

	const onPriceBlur = () => {
		const value = currencyValue.toString().replace(/,/g, ".");
		const val = app.currentQuote.formatCurrency(value);

		setCurrencyValue(val);
	};

	const handleUpdateProduct = (msg) => {
		const fieldChangeUpdates = [];
		const price = regexManipulation(currencyValue);

		if ((productChecked || price !== 0) && price !== msg.newItem.QuoteItemPrice) {
			fieldChangeUpdates.push({
				fields: { QuoteItemPrice: price },
				queries: [
					{
						table: "QuoteItems",
						where: [
							{
								field: "IdQuoteItems",
								operator: "Equals",
								value: msg.newItem.IdQuoteItems
							}
						]
					}
				]
			});
		}

		if (numberValue > 0 && numberValue !== 1) {
			fieldChangeUpdates.push({
				fields: { Quantity: numberValue || 1 },
				queries: [
					{
						table: "QuoteItems",
						where: [
							{
								field: "IdQuoteItems",
								operator: "Equals",
								value: msg.newItem.IdQuoteItems
							}
						]
					}
				]
			});
		}

		if (fieldChangeUpdates.length > 0) {
			const updateApi = quosal.api.data.update(
				fieldChangeUpdates,
				app.currentQuote.IdQuoteMain
			);

			updateApi.finished = function (msg) {
				if (msg.partialResponse) {
					quosal.sell.quote.updatePartial(msg.partialResponse);
				}

				const forceUpdateApi = quosal.api.quote.forceUpdate(app.currentQuote.IdQuoteMain);
				forceUpdateApi.finished = function (msg) {
					quosal.sell.quote.updateFromApiResponse(msg);
					updateGridRows();
					setLoadingAddProduct(false);
					handleClose();
					if (ids !== null && ids.includes(tabId)) {
						ckeditor.execute("updateProduct", tabId);
					}
				};
				forceUpdateApi.call();
			};

			updateApi.call();
		} else {
			quosal.sell.quote.update(msg.quote);
			updateGridRows();
			setLoadingAddProduct(false);
			handleClose();
		}
	};

	const handleNewProduct = (insertBefore) => {
		const quickAdd = quosal.api.product.quickItemEntry(
			textFieldValue,
			app.currentQuote.IdQuoteMain,
			currentTab.IdQuoteTabs,
			dropdownValue,
			insertBefore,
			false
		);

		quickAdd.finished = function (msg) {
			handleUpdateProduct(msg);
			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor.execute("updateProduct", tabId);
				}
			}, 1000);
		};

		quickAdd.call();
	};

	const handleExistingProduct = (insertBefore) => {
		const quickAdd = quosal.api.product.quickItemEntry(
			textFieldValue,
			app.currentQuote.IdQuoteMain,
			currentTab.IdQuoteTabs,
			dropdownValue,
			insertBefore,
			false
		);

		quickAdd.finished = function (msg) {
			handleUpdateProduct(msg);
			setTimeout(() => {
				if (ids !== null && ids.includes(tabId)) {
					ckeditor.execute("updateProduct", tabId);
				}
			}, 1000);
		};

		quickAdd.call();
	};

	const handleSubmit = () => {
		if (!textFieldValue) {
			setProductError(true);
			return;
		}

		const insertBefore = grid.state.insertBefore ? grid.state.insertBefore : "";

		setLoadingAddProduct(true);

		if (productExists) {
			handleExistingProduct(insertBefore);
		} else {
			handleNewProduct(insertBefore);
		}
	};

	// helpers effect start
	useEffect(() => {
		const groups = getGroupOptions();
		for (let i = 0; i < groups.length; i++) {
			if (groups[i].IsPrimary) {
				if (dropdownValue === "") {
					setDropdownValue(groups[i].IdProductSourceGroup);
				}
				break;
			}
		}
	}, [dropdownValue]);
	// helpers effect end

	// darkMode effect start
	useEffect(() => {
		const $checkbox = $("#dark-toggle[type=checkbox]");
		$checkbox.on("change", function () {
			setDarkMode(this.checked);
		});

		return () => {
			$checkbox.off("change");
		};
	}, []);

	useEffect(() => {
		setDialogClasses(darkMode ? "darkMode" : "");
	}, [darkMode]);
	// darkMode effect end

	return {
		handleClose,
		handleTextFieldChange,
		handleDropdownChange,
		handleNumberChange,
		onQuantityBlur,
		handleCurrencyChange,
		onPriceFocus,
		onPriceBlur,
		handleCheck,
		handleSubmit,
		getGroupOptions,
		dialogClasses,
		productError,
		textFieldValue,
		dropdownValue,
		showGearIcon,
		numberValue,
		currencyValue,
		loadingCheckProduct,
		loadingAddProduct
	};
};

export default useNewRecordContainer;
