import { DateTimeInput } from "js/jsx/src/classes/forms/specialInputs.jsx";
import { Panel, PanelContent } from "js/jsx/src/classes/panels.jsx";
import moment from 'moment';

export class ArrowQuoteImportPage extends React.Component {
    constructor(props) {
        super(props);
        var todayDate = new Date();
        var twoWeeksAgoDate = new Date();
        twoWeeksAgoDate.setDate(twoWeeksAgoDate.getDate() - 14);
        this.state = {
            step: 'search',
            singleResultQuoteNumber: null,
            quoteId: '',
            fromDate: twoWeeksAgoDate.toCompanyFormat(),
            toDate: todayDate.toCompanyFormat()
            
        };
        this.transitionToResults = this.transitionToResults.bind(this);
        this.goToSearchStart = this.goToSearchStart.bind(this);
        this.transitionToSingleQuoteResult = this.transitionToSingleQuoteResult.bind(this);
        this.importQuote = this.importQuote.bind(this);
        this.noQuoteFoundDialog = this.noQuoteFoundDialog.bind(this);
    }
    transitionToResults () {
        this.setState({
            step: 'results',
            singleResultQuoteNumber: null
        });
    }
    goToSearchStart () {
        this.setState({
            step: 'search'
        });
    }
    transitionToSingleQuoteResult() {
        this.setState({
            step: 'results',
            singleResultQuoteNumber: this.state.quoteId
        });
    }
    importQuote(params) {
        params = params || {};
        var quoteNumber = params.quoteNumber;
        var idQuoteTabs = params.idQuoteTabs;
        var selectedItems = params.selectedItems;
        var useArrowExpirationDate = params.useArrowExpirationDate;
        var arrowExpirationDate = params.arrowExpirationDate;

        Dialog.setIsWorking();
        const apiCall = quosal.api.arrowQuote.importQuote(quoteNumber, this.props.idQuoteMain, idQuoteTabs, selectedItems, useArrowExpirationDate, arrowExpirationDate);
        apiCall.finished = function(idQuoteMain, idQuoteTabs, msg){
            if (msg.quote) {
                quosal.sell.quote.update(msg.quote);
            }
            Dialog.setIsWorking(false);
            if (msg.success === true) {
                var url = quosal.util.url('quote.dashboard', 'idquotemain=' + idQuoteMain, 'idquotetabs=' + idQuoteTabs, 'submodules=quote.content');
                quosal.navigation.navigate(url);
            } else if (msg.errorMessage) {
                Dialog.closeAll({skipAnimation: true});
                Dialog.open({
                    title: 'Error',
                    message: msg.errorMessage,
                    links: [Dialog.links.close]
                });
            }
        }.bind(this, this.props.idQuoteMain, idQuoteTabs);
        apiCall.call();
    }
    noQuoteFoundDialog (message) {
        Dialog.open({
            title: 'No Quote Found',
            message: <div>{message || 'We could not find a quote based on the search criteria given.'}</div>,
            links: [{
                title: 'Back to Search',
                callback: function () {
                    this.goToSearchStart();
                    Dialog.close();
                }.bind(this)
            }]
        });
    }
    render () {
        if (this.state.step == 'search') {
            return <ArrowQuoteSearch
                        fromDate={this.state.fromDate} toDate={this.state.toDate} quoteId={this.state.quoteId}
                        setInputValue={(name, value) => {this.setState({[name]: value})}}
                        transitionToResults={this.transitionToResults}
                        searchButtonHandler={this.transitionToSingleQuoteResult}
                    {...this.props} />;
        } else if (this.state.step == 'results') {
            return <ArrowQuoteSearchResults fromDate={this.state.fromDate} toDate={this.state.toDate}
                        goToSearchStart={this.goToSearchStart}
                        importQuoteHandler={this.importQuote}
                        singleResultQuoteNumber={this.state.singleResultQuoteNumber}
                        noQuoteFoundHandler={this.noQuoteFoundDialog}
                        arrowQuoteSearchCancelButton
                    {...this.props} />;

        }
    }
}
global.ArrowQuoteImportPage = ArrowQuoteImportPage;

export class ArrowQuoteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDateIsValid: true,
            toDateIsValid: true
        };
        this.onQuoteIdChange = this.onQuoteIdChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.showDateFormatWarning = this.showDateFormatWarning.bind(this);
    }
    onQuoteIdChange (e) {
        this.props.setInputValue("quoteId", e.target.value);
    }
    handleDateChange (name, value) {
        if (moment(value, ["MM/DD/YYYY", "M/D/YYYY", "MM/DD/YY",
            "MM-DD-YYYY", "M-D-YYYY", "MM-DD-YY",
            "MM.DD.YYYY", "M.D.YYYY", "MM.DD.YY"], true).isValid()) 
        {
            this.setState({ [`${name}IsValid`]: true });
        } else { 
            this.setState({ [`${name}IsValid`]: false });
        }
        this.props.setInputValue(name, value);
    }
    showDateFormatWarning (name) {
        const isValid = this.state[`${name}IsValid`];
        if (!isValid)
        {
            return <p id="ArrowQuoteImportDateInputWarning">Please enter a valid date.</p>;
        } else
        {
            return null;
        }
    }
    render () {
        const dateSubmitDisabled = !(this.props.fromDate && this.props.toDate) || (Date.parse(this.props.fromDate) > Date.parse(this.props.toDate)) || (!this.state.fromDateIsValid || !this.state.toDateIsValid);
        const quoteIdSubmitDisabled = !(this.props.quoteId);
        return (<div>
            <Panel>
                <PanelContent>
                    <Button type="cancel" id="arrowQuoteSearchCancelButton" style={{float:'right'}}
                            onClick={() => quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.content'))}>
                        Cancel
                    </Button>
                </PanelContent>
            </Panel>
            <Panel id="arrowQuoteSearchByDatePanel" title="Search Your Arrow Quotes by Creation Date">
                <PanelContent>
                    <div className="formcolumn ArrowQuoteImportDateInputFormColumns">
                        <div className="formfieldlabel"><label htmlFor="arrowQuoteSearchStartDateInput" >Start Date</label></div>
                        <div className="formfield" >
                            <DateTimeInput 
                                ref="input"
                                id="arrowQuoteSearchStartDateInput"
                                name="arrowQuoteSearchStartDateInput" 
                                inputElementProps={{value:this.props.fromDate}}
                                setValue={(value) => this.handleDateChange("fromDate", value)} />
                            {this.showDateFormatWarning("fromDate")}
                        </div>
                    </div>
                    <div className="formcolumn ArrowQuoteImportDateInputFormColumns">
                        <div className="formfieldlabel"><label htmlFor="arrowQuoteSearchEndDateInput" >End Date</label></div>
                        <div className="formfield" >
                            <DateTimeInput 
                                ref="input" 
                                id="arrowQuoteSearchEndDateInput"
                                name="arrowQuoteSearchEndDateInput"
                                inputElementProps={{value:this.props.toDate}}
                                setValue={(value) => this.handleDateChange("toDate", value)} />
                            {this.showDateFormatWarning("toDate")}
                        </div>
                    </div>
                    <div className="formcolumn" style={{float: 'right', width: 90}}>
                        <div className="formfieldlabel"></div>
                        <div className="formfield">
                            <Button type="save" id="arrowQuoteSearchByDateSubmitButton"
                                    onClick={this.props.transitionToResults}                              
                                    disabled={dateSubmitDisabled}>
                                Next Step
                            </Button>
                        </div>
                    </div>
                </PanelContent>
            </Panel>
            <Panel id="arrowQuoteSearchByQuoteIdPanel" title="Import an Arrow Quote by Quote ID">
                <PanelContent>
                    <div className="formcolumn">
                        <div className="formfieldlabel"><label htmlFor="arrowQuoteSearchQuoteIdInput" >Arrow Quote ID</label></div>
                        <div className="formfield" >
                            <input type="text" id="arrowQuoteSearchQuoteIdInput"
                                   placeholder="Enter Quote ID"
                                   value={this.props.quoteId} onChange={this.onQuoteIdChange} />
                        </div>
                    </div>
                    <div className="formcolumn" style={{float: 'right', width: 90}}>
                        <div className="formfieldlabel"></div>
                        <div className="formfield">
                            <Button type="save" id="arrowQuoteSearchByQuoteIdSubmitButton"
                                    onClick={this.props.searchButtonHandler}
                                    disabled={quoteIdSubmitDisabled}>
                                Search
                            </Button>
                        </div>
                    </div>
                </PanelContent>
            </Panel>
        </div>);
    }
}
global.ArrowQuoteSearch = ArrowQuoteSearch;

export class ArrowQuoteSearchResults extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            results: null,
            previews: null,
            previewsHidden: null,
            previewIsLoading: null,
            selectedTab: this.props.defaultDestinationTab
        };
        this.doSearch = this.doSearch.bind(this);
        this.isSingleQuoteMode = this.isSingleQuoteMode.bind(this);
        this.updateStateOnTabSelectionChange = this.updateStateOnTabSelectionChange.bind(this);
        this.getPreview = this.getPreview.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.displayPreviewBeforeAttach = this.displayPreviewBeforeAttach.bind(this);
    }
    isSingleQuoteMode() {
        return !!this.props.singleResultQuoteNumber;
    }
    componentDidMount () {
        if (this.isSingleQuoteMode()) {
            
            var msg = {};
            this.setState({
                results: msg.searchResults || [{
                    endUserCompanyName: '',
                    quoteDate: '',
                    quoteNumber: this.props.singleResultQuoteNumber,
                    quoteTotal: ''
                }],
                previews: [],
                previewsHidden: [],
                previewIsLoading: []
            }, this.displayPreviewBeforeAttach.bind(this, 0));
        } else {
            this.doSearch({fromDate: this.props.fromDate, toDate: this.props.toDate});
        }
    }
    componentDidUpdate (prevProps) {
        if (
            !this.isSingleQuoteMode() &&
            (prevProps.fromDate !== this.props.fromDate ||
            prevProps.toDate !== this.props.toDate)
        ) {
            this.setState({
                results: null,
                previews: null,
                previewsHidden: null
            }, this.doSearch({fromDate: this.props.fromDate, toDate: this.props.toDate}));
        }
    }
    updateStateOnTabSelectionChange (e) {
        this.setState({
            selectedTab: e.target.value
        })
    }
    createTabRoutingDropdown () {
        var tabOptions = [];
        for (var i = 0; i < app.currentQuote.Tabs.length; i++) {
            tabOptions.push(<option key={app.currentQuote.Tabs[i].IdQuoteTabs}
                                    value={app.currentQuote.Tabs[i].IdQuoteTabs}>{app.currentQuote.Tabs[i].TabName}</option>)
        }
        return (
            <div className="formcolumn">
                <div className="formfieldwrapper">
                    <label htmlFor="tabSelection" className="formlabel">Tab Routing Options</label>
                    <div className="formselectfieldwrapper">
                        <select id="tabSelection" key="tabSelection"
                                onChange={this.updateStateOnTabSelectionChange}
                                value={this.state.selectedTab}
                                className="formselectfield">{tabOptions}</select>
                    </div>
                </div>
            </div>
        )
    }
    doSearch (args) {
        const apiCall = quosal.api.arrowQuote.searchQuotes(args.fromDate, args.toDate);
        apiCall.finished = function (msg) {

            if (this.props.fromDate === args.fromDate && this.props.toDate === args.toDate) {
                this.setState({
                    results: msg.searchResults || [],
                    previews: [],
                    previewsHidden: [],
                    previewIsLoading: []
                });
            }
        }.bind(this);
        apiCall.call();
    }
    getPreview(params) {
        var index = params.index;
        var quoteNumber = params.quoteNumber;
        var callback = params.callback;
        var suppressPreviewButtonSpinner = params.suppressPreviewButtonSpinner;

        if (!suppressPreviewButtonSpinner) {
            this.setState((prevState) => {
                var previewIsLoading = prevState.previewIsLoading.slice();
                previewIsLoading[index] = true;
                return {
                    previewIsLoading: previewIsLoading
                }
            });
        }
        var afterSetState = function (callback) {
            if ('function' === typeof callback) {
                callback();
            }
        }.bind(null, callback);
        const apiCall = quosal.api.arrowQuote.previewQuote(quoteNumber);
        apiCall.finished = function (index, msg) {
            var quoteDetails;
            if (msg.success == true) {
                quoteDetails = msg.quoteDetails && JSON.parse(msg.quoteDetails)
                this.state.results[index].quoteDate = this.state.results[index].quoteDate || quoteDetails.QuoteDate;
                this.state.results[index].endUserCompanyName = this.state.results[index].endUserCompanyName || quoteDetails.EndUserCompanyName;
                this.state.results[index].quoteTotal = this.state.results[index].quoteTotal || quoteDetails.QuoteTotal;
            }
            this.setState((prevState) => {
                var previewIsLoading1 = prevState.previewIsLoading.slice();
                previewIsLoading1[index] = false;
                var previews = this.state.previews.slice();
                if (msg.success == true) {
                    var productList = msg.productPreviewList && JSON.parse(msg.productPreviewList)
                    previews[index] = {
                        success: true,
                        productPreviewList: productList, 
                        quoteDetails: quoteDetails
                    };
                }
                else{
                    previews[index] = {
                        success: false,
                        errorMessage: msg.errorMessage
                    };
                }
                return {
                    previews: previews,
                    previewIsLoading : previewIsLoading1
                }
            }, afterSetState);
        }.bind(this, index);
        apiCall.call();       
    }
    displayPreviewBeforeAttach(index) {
       
        var openConfirmQuoteImportDialog = function (index) {
            Dialog.setIsWorking(false);
            var previewData = this.state.previews[index];
            if(!previewData.success){
                if(previewData.errorMessage === 'arrowQuoteNotFound' ){
                        this.props.noQuoteFoundHandler();
                }
                else{
                    Dialog.open({
                        title : 'Error',
                        message: 'Server returned an error: '+ previewData.errorMessage,
                        links: [Dialog.links.close]
                    });
                }
            } else {
                var suggestedItems = previewData.productPreviewList;
                var quoteDetails = previewData.quoteDetails;

                Dialog.open({
                    title: 'Confirm Quote Details',
                    width: '90%',
                    message: <ArrowQuoteConfirmImportMessage quoteDetails={quoteDetails}
                                                              suggestedItems={suggestedItems} />,
                    links: [
                        Dialog.links.cancel,
                        {
                            title: 'Continue',
                            callback: this.checkExpirationDateAndCallImportHandler.bind(this, index, quoteDetails, suggestedItems)
                        }
                    ]
                });
            }
        }.bind(this, index);
        if (this.state.previews[index] && this.state.previews[index].success){
            openConfirmQuoteImportDialog();
        } else {
            Dialog.setIsWorking();
            this.getPreview({
                index: index,
                quoteNumber: this.state.results[index].quoteNumber,
                callback: openConfirmQuoteImportDialog,
                suppressPreviewButtonSpinner: true
            });
        }
    }
    checkExpirationDateAndCallImportHandler(index, quoteDetails, suggestedItems) {
        var importQuoteHandlerArgs = {
            quoteNumber: this.state.results[index].quoteNumber,
            idQuoteTabs: this.state.selectedTab,
            selectedItems: suggestedItems
        };

        if (quoteDetails.QuoteExpirationDate &&
            (quoteDetails.QuoteExpirationDate !== app.currentQuote.ExpirationDate)) {

            Dialog.open({
                title: 'Update Expiration Date?',
                width: '90%',
                closeRequiresButton: true,
                message: (
                    'The current quote\'s expiration date (' +
                    app.currentQuote.ExpirationDate +
                    ') is not the same as the expiration date of the importing arrow quote (' +
                    quoteDetails.QuoteExpirationDate +
                    '). Would you like to update the current quote\'s expiration date to ' +
                    quoteDetails.QuoteExpirationDate +
                    ' so that it matches the arrow quote?'
                ),
                links: [{
                    title: 'No, Keep Existing Date',
                    callback: function (importQuoteHandlerArgs) {
                        this.props.importQuoteHandler(importQuoteHandlerArgs);
                     
                    }.bind(this, importQuoteHandlerArgs)
                }, {
                    title: 'Yes, Update',
                    callback: function (importQuoteHandlerArgs) {
                        importQuoteHandlerArgs.arrowExpirationDate = quoteDetails.QuoteExpirationDate;
                        importQuoteHandlerArgs.useArrowExpirationDate = true;
                        this.props.importQuoteHandler(importQuoteHandlerArgs);
                    }.bind(this, importQuoteHandlerArgs)
                }]
            });
        } else {
            this.props.importQuoteHandler(importQuoteHandlerArgs);
        }
    }
    togglePreview(index) {
        var previewsHidden = this.state.previewsHidden.slice();
        var isHiding = previewsHidden[index];
        previewsHidden[index] = !isHiding;
        this.setState({
            previewsHidden : previewsHidden
        });
    }

    render() {     
        let content = <PanelContent><div data-cy="arrowQuoteSearchSearchingMessage"><Spinner /> Searching for quotes...</div></PanelContent>;
        if (this.state.results) {
            if (this.state.results.length > 0) {
                let resultRows = [];
                let i;
                for(i = 0; i < this.state.results.length; i++) {
                    var hasPreview = (this.state.previews && this.state.previews[i]);
                    var isPreviewHidden = (this.state.previewsHidden && this.state.previewsHidden[i]);
                    var previewHandler = hasPreview ?
                        this.togglePreview.bind(this, i) :
                        this.getPreview.bind(this, { index: i, quoteNumber: this.state.results[i].quoteNumber } );
                    var attachHandler = this.displayPreviewBeforeAttach.bind(this, i);
                    resultRows.push(<ArrowQuoteSearchResultsRow quote={this.state.results[i]}
                                                                 key={i}
                                                                 hasPreview={hasPreview}
                                                                 isPreviewHidden={isPreviewHidden}
                                                                 previewHandler={previewHandler}
                                                                 attachHandler={attachHandler}
                                                                 isSingleQuoteMode={this.isSingleQuoteMode()}
                                                                 previewLoading= {this.state.previewIsLoading[i]} />);
                    if (hasPreview){
                        resultRows.push(<ArrowQuoteSearchResultPreviewTable key={'preview_' + i}
                                                                             isPreviewHidden={isPreviewHidden}
                                                                             data={this.state.previews[i]}
                                                                             previewIndex = {'preview_' + i} />);
                    }
                }
                content = (
                    <PanelContent>
                        {this.createTabRoutingDropdown()}
                        <table id="arrowQuoteSearchResultsGrid" className='datagrid nosort'>
                            <thead>
                                <tr>
                                    <th className="title">Quote Number</th>
                                    <th className="title">Quote Total</th>
                                    <th className="title">Quote Date</th>
                                    <th className="title">End User Company Name</th>
                                    { !this.isSingleQuoteMode() &&
                                        <th className="nosort"></th>
                                    }
                                    <th className="nosort"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultRows}
                            </tbody>
                        </table>
                    </PanelContent>
                );
            }
            else {
                content = <PanelContent>No records were found for the provided date range.</PanelContent>;
            }
        }
        return (
		      <div>
                <Panel>
                    <PanelContent>
                        <Button type="cancel" id="arrowQuoteSearchCancelButton" style={{float:'right'}}
                                onClick={() => quosal.navigation.navigate(quosal.util.url('quote.dashboard', 'submodules=quote.content'))}>
                            Cancel
                        </Button>
                    </PanelContent>
                </Panel>
				<Panel id="arrowQuoteSearchResults" title="Preview Arrow Quote List">
					{content}
					<div className="footer">
						<div className="left">
							<button onClick={this.props.goToSearchStart} id="previousstep" className="btn" style={{width:120}}>
								<div className="icon previous"></div>Previous Step
							</button>
						</div>
					</div>
				</Panel>
		</div>);
    }
}

global.ArrowQuoteSearchResults = ArrowQuoteSearchResults;

export class ArrowQuoteConfirmImportMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.renderSuggestedItemRows = this.renderSuggestedItemRows.bind(this);
    }
   
    renderSuggestedItemRows(){
        var rows = this.props.suggestedItems;
        var renderedRows = [];
        for (var i = 0; i < rows.length; i++) {
            var suggestedItem = rows[i];
            renderedRows.push(<tr key={i}>
                <td className="content">{suggestedItem.ManufacturerPartNumber}</td>
                <td className="content">{suggestedItem.ItemDescription}</td>
                <td className="content">{suggestedItem.MSRP}</td>
                <td className="content">{suggestedItem.UnitCost}</td>
                <td className="content">{suggestedItem.Quantity}</td>
                <td className="content">{suggestedItem.ExtendedPrice}</td>
            </tr>);
        }
        return renderedRows;
    }
    render() {
        var quoteDetails = this.props.quoteDetails;
        var hasSuggestedItems = this.props.suggestedItems && this.props.suggestedItems.length;

        return (
            <div>
                <p style={{ fontSize: '14px' }}>
                    <b>Quote Number:</b> {quoteDetails.QuoteNumber}<br />
                    <b>Quote Name:</b> {quoteDetails.QuoteName}<br />
                    <b>Quote Expiration Date :</b> {quoteDetails.QuoteExpirationDate}<br />
                </p>
                { !hasSuggestedItems ? '' :
                    <div>
                        <b>Products Suggested by arrow:</b><br />
                        <table className="datagrid nosort td-quote-preview-table">
                            <thead>
                                <tr>
                                    <th>Manufacturer Part Number</th>
                                    <th>Item Description</th>
                                    <th>MSRP</th>
                                    <th>Unit Cost</th>
                                    <th>Quantity</th>
                                    <th>Extended Price</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.renderSuggestedItemRows()}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        );
    }
}

export class ArrowQuoteSearchResultsRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return <tr>
            <td className="content left">{this.props.quote.quoteNumber}</td>
            <td className="content left">{this.props.quote.quoteTotal}</td>
            <td className="content right">{this.props.quote.quoteDate}</td>
            <td className="content right">{this.props.quote.endUserCompanyName}</td>
            { !this.props.isSingleQuoteMode &&
                <td className="content center">
                    <Button type="preview" onClick={this.props.previewHandler} disabled={this.props.previewLoading}>
                        {this.props.previewLoading ? <Spinner/> :
                            !this.props.hasPreview ? 'Preview' :
                                this.props.isPreviewHidden ? 'Show' :
                                    'Hide'}
                    </Button>
                </td>
            }
            <td className="content center">
                <Button type="attach" onClick={this.props.attachHandler} disabled={this.props.previewLoading}>Attach</Button>
            </td>
        </tr>
    }
}

export class ArrowQuoteSearchResultPreviewTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderRows = this.renderRows.bind(this);
    }
    renderRows() {
        var rows = this.props.data.productPreviewList;
        var renderedRows = [];
        for (var iRow = 0; iRow < rows.length; iRow++) {
            var product = rows[iRow];
                renderedRows.push(<ArrowQuoteSearchResultsPreviewRow productPreview = {product} key= {this.props.previewIndex + '_row_' + iRow}/>)
        }
        return renderedRows;
    }
    render () {      
        var content;
        if(this.props.data.success==true)
        {
            if(this.props.data.productPreviewList && this.props.data.productPreviewList.length > 0) {
                content = <table className="datagrid nosort td-quote-preview-table">
                        <thead>
                        <tr>
                            <th>Manufacturer Part Number</th>
                            <th>Item Description</th>
                            <th>MSRP</th>
                            <th>Unit Cost</th>
                            <th>Quantity</th>
                            <th>Extended Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderRows()}
                        </tbody>
                    </table>
            }
            else{
                content = "Quote Product Records not found";
            }
        }
        else{
            content = this.props.data.errorMessage ? this.props.data.errorMessage : "arrow API returned an Error Response";           
        }
        
        var style = {
            display: this.props.isPreviewHidden ? 'none' : 'table-row'
        };         
        return <tr style={style} id={this.props.id}>
                    <td colSpan={6} className="content center">
                    {content}
                </td>
        </tr>
    }
}

export class ArrowQuoteSearchResultsPreviewRow extends React.PureComponent{

    constructor(props) {
        super(props);   
        this.state = {};    
    }
    render(){      
            return <tr>
                <td className="content left">{this.props.productPreview.ManufacturerPartNumber}</td>
                <td className="content left">{this.props.productPreview.ItemDescription}</td>
                <td className="content right">{this.props.productPreview.MSRP}</td>
                <td className="content right">{this.props.productPreview.UnitCost}</td>
                <td className="content right">{this.props.productPreview.Quantity}</td>
                <td className="content right">{this.props.productPreview.ExtendedPrice}</td>
                </tr>
    }
}