import React from "react";
import { QuoteHeader } from "./QuoteHeader";
import { QuoteHeaderTheme } from "./styles";
import { QuoteNavigationTabs } from "./QuoteNavigationTabs";
import { QuoteProvider, UserProvider, UserSettingsProvider } from "./context";
import { useQuoteHeader } from "./customHooks";
import { isEoPage } from "./utils";

export const QuoteHeaderContainer = (props) => {
	const { currentQuote, currentUser, userSettings } = useQuoteHeader(props);
	const isElectronicOrderPage = isEoPage()
	return (
		<QuoteHeaderTheme>
			<QuoteProvider quote={currentQuote}>
				<UserProvider user={currentUser}>
					<UserSettingsProvider userSettings={userSettings}>
						<QuoteHeader hidePdfViewButton={!!props.hidePdfViewButton || isElectronicOrderPage} hideOpportunityDetails={isElectronicOrderPage} isLegacyPage ={props?.isLegacyPage}/>
					</UserSettingsProvider>
				</UserProvider>
				<QuoteNavigationTabs />
			</QuoteProvider>
		</QuoteHeaderTheme>
	);
};
