export class QuoteNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.modulesReloaded = this.modulesReloaded.bind(this); 
    }
    componentDidMount() {
        quosal.navigation.parse(this.refs.navigation);
        quosal.sell.modules.reloaded.bind(this.modulesReloaded);
    }
    componentWillUnmount() {
        quosal.sell.modules.reloaded.unbind(this.modulesReloaded);
    }
    modulesReloaded() {
        this.forceUpdate();
    }
    render() {
        var steps = [];
        var isEoNav = this.props.isEoNav;
        var isDarkMode = document.body.classList.contains('darkMode');
        var prevStep = null;

        for(var i = 0; i < this.props.steps.length; i++) {
            var step = this.props.steps[i];
            var nextStep = i < this.props.steps.length - 1 ? this.props.steps[i + 1] : null;
            var activeSubModule = null;

            var isActive = step.isLoaded();
            var subModuleActive = !step.isActive();

            if(isActive && subModuleActive) {
                activeSubModule = step.getActiveSubModule();
            }

            var fontColor = isDarkMode ? '#3AAACF' : '#0093d0';
            var bgColor = isDarkMode ? '#333333' : '#ffffff';
            var endColor = isDarkMode ? '#333333' : '#ffffff';
            var lockedColor = isDarkMode ? '#434343' : '#8b8b8b';
            var activeStepColor = isDarkMode ? (isEoNav ? '#74ad01' : "#FCB663") : (isEoNav ? '#80c000' : '#f78f1e');

            if(step.IsLocked) {
                bgColor = lockedColor;
                fontColor = isDarkMode ? '#9C9C9C' : '#cccccc';
            } else if(isActive && !subModuleActive && step.Module != "quote.customer") {
                bgColor = activeStepColor;
                fontColor = isDarkMode ? '#333333' : '#ffffff';
            }

            var nextStepActive = nextStep && nextStep.isLoaded();
            var nextStepSubModuleActive = nextStepActive && !nextStep.isActive();

            if(!nextStep || nextStep.IsLocked) {
                endColor = lockedColor;
            } else if(nextStepActive && !nextStepSubModuleActive) {
                endColor = activeStepColor;
            }

            steps.push(<QuoteNavigationStep key={step.Name} step={step} bgColor={bgColor} endColor={endColor} fontColor={fontColor} isActive={isActive && !subModuleActive} subModuleName={activeSubModule ? activeSubModule.Name : null} />);

            prevStep = step;
        }

        return (
            <div ref="navigation" id="quotenav" style={{backgroundColor: isDarkMode ? '#434343' : '#8b8b8b'}}>
                <div style={{display:'inline-block'}}>
                    {steps}
                </div>
            </div>
        );
    }
}

global.QuoteNavigation = QuoteNavigation;

class QuoteNavigationStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };       
        this.navigateToStep = this.navigateToStep.bind(this); 
    }
    navigateToStep() {
        if(!this.props.step.IsLocked) {
            if(this.props.step.Url.indexOf('.quosalweb') >= 0) {
                quosal.navigation.navigate(this.props.step.Url);
            } else {
                app.currentModule.loadSubModule(this.props.step.Url, {
                    container: 'quoteModule',
                    unloadSubModules: true
                });
            }
        }
    }
    render() {
        return (
            <div data-cy={this.props.step.IsLocked ? "locked" : "unlocked"} style={{display:'inline-block'}}>
                <div className="quotenav_item" style={{backgroundColor: this.props.bgColor}}>
                    <div className="quotenav_name">
                        <a style={{verticalAlign:'middle'}} className="link" onClick={this.navigateToStep}>
                            <font style={{fontSize:'16px', fontWeight: this.props.isActive ? 'bold' : 'normal', color:this.props.fontColor}}>&nbsp;{this.props.step.Name}</font>
                        </a>
                    </div>
                    {this.props.subModuleName ?
                    <div className="subnav">
                        <div className="icon sub-nav"></div>
                        <div className="subnavitem current">{this.props.subModuleName}</div>
                    </div> : null}
                </div>
                <div className="quotenav_cap" style={{position: 'relative', backgroundColor: this.props.endColor}}>
                    <svg width="20" height="40">
                        <g>
                            <path id="svg_6" d="m19.89329,19.89329l-19.89329,19.89328l-0.00001,-39.78657l19.89329,19.89329z" stroke="#CCC" strokeWidth="1" fill={this.props.bgColor}/>
                            <line fill={this.props.bgColor} stroke={this.props.bgColor} strokeWidth="2" strokeLinejoin="null" x1="0" y1="1" x2="0" y2="39" id="svg_3"/>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}
