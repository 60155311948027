import {ProductPriceHistoryPanel} from "js/jsx/src/classes/product/productPriceHistory.jsx";
import {CloudSourcePanel} from "js/jsx/src/classes/quote/cloudSourcing.jsx";
import {Form, FormFieldInput} from "js/jsx/src/classes/forms.jsx";
import {ActionsMenu} from "js/jsx/src/classes/menus.jsx";

export class ProductEditForm extends React.Component {
    constructor(props) {
        super(props);

        var hideSources = String.isNullOrEmpty(this.props.product.ManufacturerPartNumber) ||
            this.props.product.ManufacturerPartNumber.toUpperCase() === 'NEW' ||
            quosal.util.cookie('product_edit_sources_hidden') === 'true';

        var userCanModifyProtectedPrice= quosal.util.userCanModifyProtectedPrice();
        var userCanModifyProtectedItem= quosal.util.userCanModifyProtectedItem();
        var userCanModifyProtectedTab= quosal.util.userCanModifyProtectedTab();
        var modTagMode = this.props.product.EnforceItemModTags && (quosal.util.userUseModificationTagToUpdate() || quosal.util.quoteReadOnlyMode());

        this.state = {
            isDirty: false, 
            additionalThumbnails: null, 
            hideSources: hideSources,
            userCanModifyProtectedPrice: userCanModifyProtectedPrice,
            userCanModifyProtectedItem: userCanModifyProtectedItem,
            userCanModifyProtectedTab: userCanModifyProtectedTab,
            modTagMode: modTagMode,
            invalidFields: []
        };

        this.fieldChanged = this.fieldChanged.bind(this);
        this.saveAndCloseForm = this.saveAndCloseForm.bind(this);
        this.saveForm = this.saveForm.bind(this);
        this.onReset = this.onReset.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.createProduct = this.createProduct.bind(this);
        this.createComment = this.createComment.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.metadataLoaded = this.metadataLoaded.bind(this);
        this.etilizeLookup = this.etilizeLookup.bind(this);
        this.ciscoLookup = this.ciscoLookup.bind(this);
        this.getPreviousItem = this.getPreviousItem.bind(this);
        this.getNextItem = this.getNextItem.bind(this);
        this.previousProduct = this.previousProduct.bind(this);
        this.nextProduct = this.nextProduct.bind(this);
        this.toggleSources = this.toggleSources.bind(this);
        this.getMfp = this.getMfp.bind(this);
        this.customizeInput = this.customizeInput.bind(this);
        this.onNavigation = this.onNavigation.bind(this);
        this.onWindowUnloading = this.onWindowUnloading.bind(this);
        this.hasExternalQuote = this.hasExternalQuote.bind(this);
    }

    fieldChanged(form, panel, input, callback) {
        this.setState({isDirty: true});

        if(callback)
            callback();
    }
    saveAndCloseForm(e) {
        this.saveForm(e, true);
    }
    saveForm(e, closeAfterSave) {
        if (quosal.htmlEditorCallback) {
            quosal.htmlEditorCallback();
            delete quosal.htmlEditorCallback;
        }

        var saveForm = function () {
            this.setState({isSaving:true});

            var updatedValues = this.refs.form.getFormValues(true);
            var invalidFields = [];

            for(var fieldName in updatedValues) {
                if(fieldName == 'QuoteItemPrice' ||
                    fieldName == 'SuggestedPrice' ||
                    fieldName == 'RecurringPrice' ||
                    fieldName == 'RecurringSuggestedPrice') {
                    var newValue = parseFloat(updatedValues[fieldName]);
                    if(newValue > quosal.data.MAX_INTEGER) {
                        var fieldDisplayName = quosal.customization.fields.getFieldDisplayName('BusinessObject', 'QuoteItems', fieldName);
                        invalidFields.push(fieldDisplayName);
                        delete updatedValues[fieldName];
                    }
                }
            }

            if(invalidFields.length > 0) {
                Dialog.open({
                    title: 'Value Too Large',
                    message: 'The following field' + (invalidFields.length > 1 ? 's exceed' : ' exceeds') + ' the maximum allowed value of ' + quosal.data.MAX_INTEGER + ' and will not be saved: ' + invalidFields.join(', '),
                    links: [Dialog.links.ok]
                });
            }

            var updateApi = quosal.api.data.update({
                fields: updatedValues,
                queries: [{
                    table: 'QuoteItems',
                    where: [{
                        field: 'IdQuoteItems',
                        operator: 'Equals',
                        value: this.props.product.IdQuoteItems
                    }]
                }]
            }, app.currentQuote.IdQuoteMain);
            updateApi.finished = function (msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
                if(app.currentQuote.ShouldGeneratePdfAfterSave) {
                    quosal.sell.product.generatePdf(app.currentQuote.IdQuoteMain, msg.opId);
                }

                if (quosal.settings.getValue('UseEditQuoteCKEditor')) {
                    sessionStorage.setItem('addProductTabId', this.props.product.IdQuoteTabs);
                }
                

                if (closeAfterSave === true) {
                    app.currentModule.unloadSubModules();
                    app.currentModule.loadSubModule('quote.content', {
                        container: 'quoteModule'
                    });
                } else if (this.componentIsMounted) {
                    this.setState({isDirty: false, isSaving: false});

                    if (this.refs.form)
                        this.refs.form.reset();
                }
            }.bind(this);
            updateApi.call();
        }.bind(this);

        if (this.refs.form.prepareThenSave) {
            this.refs.form.prepareThenSave(saveForm);
        } else {
            saveForm();
        }
    }
    onReset() {
        delete quosal.htmlEditorCallback;
        this.setState({isDirty: false});
    }
    cancelChanges() {
        this.refs.form.reset();
    }
    createProduct(e, lineType) {
        var quantity = lineType == "Comment" ? 0 : 1;

        if (quosal.settings.getValue('UseEditQuoteCKEditor')) {
            sessionStorage.setItem('cpq_open_tab', this.props.product.IdQuoteTabs);
        }

        var createApi = quosal.api.data.create({
            table: 'QuoteItems',
            IdQuoteMain: app.currentQuote.IdQuoteMain,
            IdQuoteTabs: this.props.product.IdQuoteTabs,
            ManufacturerPartNumber: (lineType == null ? 'NEW' : ''),
            LineType: lineType,
            Quantity: quantity,
            SortOrder: app.currentQuote.Items.where(s=>s.IdQuoteTabs == this.props.product.IdQuoteTabs).length
        }, app.currentQuote.IdQuoteMain);
        createApi.finished = function(msg) {
            if(msg.quote)
                quosal.sell.quote.update(msg.quote);

            if(msg.results && msg.results.length > 0) {
                var newProduct = msg.results[0];

                app.currentModule.loadSubModule('product.edit', {
                    container: 'quoteModule',
                    query: 'itemid=' + newProduct.IdQuoteItems
                });
            }
        }.bind(this);

        var navState = {
            cancelNavigation: function() { this.canceled = true; },
            resumeNavigation: function(createApi, lineType) {
                createApi.call();
                this.setState({ loadingMessage: 'Creating New ' + (lineType == 'Comment' ? 'Comment' : 'Item') + '...' });
            }.bind(this, createApi, lineType)
        };

        var onNavigation = this.refs.form.onNavigation || this.refs.form.props.onNavigation;
        if (typeof onNavigation === 'function') {
            onNavigation(navState);
        }

        if(!navState.canceled) {
            navState.resumeNavigation();
        }
    }
    createComment(e) {
        this.createProduct(e, 'Comment');
    }
    deleteProduct() {
        var doDelete = function() {
            Dialog.open({
                closeRequiresButton: true,
                skipAnimation: true,
                message: <FormPlaceholder message="Deleting Product..." />
            });

            var deleteApi = quosal.api.data.delete({
                table: 'QuoteItems',
                where: [{
                    field: 'IdQuoteItems',
                    operator: 'Equals',
                    value: this.props.product.IdQuoteItems
                }]
            }, app.currentQuote.IdQuoteMain);
            deleteApi.finished = function(msg) {
                Dialog.closeAll({ skipAnimation: true });
                var nextItem = this.getNextItem(true);
                this.loadItem(nextItem, true);
                quosal.sell.quote.updateFromApiResponse(msg);
            }.bind(this);
            deleteApi.call();
        }.bind(this);

        Dialog.open({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this product?',
            links: [{
                title: 'Yes, Delete',
                callback: function(doDelete) {
                    Dialog.close({
                        skipAnimation: true,
                        callback: function(doDelete) {
                            doDelete();
                        }.bind(this, doDelete)
                    });
                }.bind(this, doDelete)
            }, {
                title: 'No, Cancel',
                callback: Dialog.close
            }]
        });
    }
    metadataLoaded(metadata) {
        if(this.state.additionalThumbnails == null && metadata.images) {
            this.setState({additionalThumbnails: metadata.images});
        }
    }
    hasExternalQuote() {
        return this.props.product.ExternalQuoteNumber && this.props.product.ExternalQuoteNumber.length > 0;
    }
    etilizeLookup() {
        if(this.state.etilizeUpdating)
            return;

        var mfp = this.props.product.ManufacturerPartNumber;
        var formVals = this.refs.form.getFormValues();

        if(formVals['ManufacturerPartNumber'])
            mfp = formVals['ManufacturerPartNumber'];

        if(String.isNullOrEmpty(mfp) || mfp.toUpperCase() === 'NEW')
            return;

        var vendorPartNumber = this.props.product.VendorPartNumber;
        if (formVals['VendorPartNumber']) vendorPartNumber = formVals['VendorPartNumber'];

        this.setState({etilizeUpdating: true});

        var updateApi = quosal.api.product.updateFromEtilize(app.currentQuote.IdQuoteMain, this.props.product.IdQuoteItems, mfp, vendorPartNumber);
        updateApi.finished = function(msg) {
            this.etilizeApiCall = null;

            if(!this.componentIsMounted)
                return;

            if(msg.images) {
                this.metadataLoaded(msg);
            }

            var formFields = this.refs.form  ? this.refs.form.getFields() : null;
            var updateIfBlankFields = ['LongDescription', 'ShortDescription', 'ProductClass'];
            var imageFields = ['Thumbnail', 'Picture', 'Z_customPicture1', 'Z_customPicture2', 'Z_customPicture3', 'Z_customPicture4'];

            var updateIfBlank = function(formField, value) {
                if(String.isNullOrEmpty(formField.getValue()) && !String.isNullOrEmpty(value)) {
                    formField.setValue(value);
                }
            }.bind(this);

            var updateImage = function(formField, value) {
                var currentValue = formField.getValue();
                if ((String.isNullOrEmpty(currentValue) || currentValue === 'images/empty.png')
                    && !(String.isNullOrEmpty(value) || value === 'images/empty.png')) {
                    if (value.indexOf('http') < 0) {
                        //The api does not know the server prefix i.e. http://servername/QuosalWeb so the client must provide it here
                        //This only affects where the API downloads the image from initially, afterwards it is stored in the database.
                        value = location.fullPath() + value;
                    }

                    if(formFields)
                    {
                        formFields[i].setValue(value);
                    }

                }
            };

            if(formFields){
                for(var i = 0; i < formFields.length; i++) {
                    if(updateIfBlankFields.indexOf(formFields[i].props.field.FieldName) >= 0) {
                        updateIfBlank(formFields[i], msg.item[formFields[i].props.field.FieldName]);
                    } else if(formFields[i].props.field.FieldName === 'SuggestedPrice') {
                        if(formFields[i].getValue() == 0 && msg.item[formFields[i].props.field.FieldName] != 0) {
                            formFields[i].setValue(msg.item[formFields[i].props.field.FieldName]);
                        }
                    } else if(formFields[i].props.field.FieldName === 'ItemNotesHtml') {
                        if(String.isNullOrEmpty(msg.item[formFields[i].props.field.FieldName]))
                            continue;

                        if (this.state.modTagMode)
                            continue;

                        var rtfEditor = formFields[i].refs.input.refs.editor;
                        var existingContent = rtfEditor.getText();

                        if(existingContent.length == 0 || (existingContent.length == 1 && existingContent.charCodeAt(0) == 160)) { //indicates empty rtf
                            rtfEditor.setHtml(msg.item[formFields[i].props.field.FieldName]);
                        }
                    } else if(imageFields.indexOf(formFields[i].props.field.FieldName) >= 0) {
                        updateImage(formFields[i], msg.item[formFields[i].props.field.FieldName]);
                    }
                }
            }

            this.setState({etilizeUpdating: false});
        }.bind(this);
        this.etilizeApiCall = updateApi;
        updateApi.call();
    }

    ciscoLookup()
    {
        var ccwCatalogLookup = {
            mfp: 'C1A1TN9300XF-3Y',
            idQuoteMain: app.currentQuote.IdQuoteMain,
            idQuoteItem : this.props.product.IdQuoteItems
        };
        var ciscoCatalogApi = quosal.api.product.getCiscoProductInformation(ccwCatalogLookup);
        ciscoCatalogApi.finished = function (msg)
        {
            if (msg.redirectUrl)
            {
                window.location.href = msg.redirectUrl;
            } 
        }.bind(this);
        ciscoCatalogApi.call();
    }
    getPreviousItem() {
        var tabItems = app.currentQuote.Items.where(s=>s.IdQuoteTabs == this.props.product.IdQuoteTabs);
        var index = tabItems.findIndex(s=>s.IdQuoteItems == this.props.product.IdQuoteItems);
        var needOne = true;
        var previousItem = null;
        while (needOne) {
            index--;

            if(index < 0)
                index = tabItems.length - 1;

            previousItem = tabItems[index];
            if (previousItem.IdQuoteItems == this.props.product.IdQuoteItems) {
                needOne = false;
            }
            else if (quosal.util.userIsAdminOrMaintainer() || !previousItem.IsHiddenItem) {
                needOne = false;
            }
        }

        if(previousItem == null || previousItem.IdQuoteItems == this.props.product.IdQuoteItems)
            return null;
        else
            return previousItem;
    }

    getNextItem(afterItemDeletion) {
        var tabItems = app.currentQuote.Items.where(s=>s.IdQuoteTabs == this.props.product.IdQuoteTabs);
        var index = tabItems.findIndex(s => s.IdQuoteItems == this.props.product.IdQuoteItems);

        // Deleting a package header will delete all the package items. getNextItem should skip package items. 
        if (afterItemDeletion && this.props.product.IsPackageHeader) {
            var packageItemsCount = tabItems.where(s => s.IsPackageItem && s.ParentQuoteItem == this.props.product.IdQuoteItems).length;
            index += packageItemsCount;
        }
        var needOne = true;
        var nextItem = null;
        while (needOne) {
            index++;
            
            if(index >= tabItems.length)
                index = 0;
    
            nextItem = tabItems[index];
            if (nextItem.IdQuoteItems == this.props.product.IdQuoteItems) {
                needOne = false;
            }
            else if (quosal.util.userIsAdminOrMaintainer() || !nextItem.IsHiddenItem) {
                needOne = false;
            }
        }

        if(nextItem == null || nextItem.IdQuoteItems == this.props.product.IdQuoteItems)
            return null;
        else
            return nextItem;
    }
    loadItem(item, skipNavPrompt) {
        if(item) {
            app.currentModule.loadSubModule('product.edit', {
                bypassBeforeLoadEvent: skipNavPrompt,
                container: 'quoteModule',
                query: 'itemid=' + item.IdQuoteItems + '&idquotetabs=' + quosal.util.queryString('idquotetabs')
            });
        } else {
            app.currentModule.unloadSubModules();
            app.currentModule.loadSubModule('quote.content', {
                bypassBeforeLoadEvent: skipNavPrompt,
                container: 'quoteModule'
            });
        }
    }
    previousProduct() {
        //TODO: switching across items while a price lookup is happening does not abort it
        this.loadItem(this.getPreviousItem());
    }
    nextProduct() {
        //TODO: switching across items while a price lookup is happening does not abort it
        this.loadItem(this.getNextItem());
    }
    toggleSources(hide, animate, updateSources, mfp) {
        if(hide === null)
            hide = !this.state.hideSources;

        var target = $(ReactDOM.findDOMNode(this.refs.cloudSources));

        if(hide) {
            if(this.refs.cloudSources)
                this.refs.cloudSources.abortPricingLookup();

            quosal.util.cookie('product_edit_sources_hidden', 'true');
        } else {
            quosal.util.cookie('product_edit_sources_hidden', 'false');
        }

        var finished = function() {
            this.setState({hideSources: hide}, function() {
                if(updateSources && this.refs.cloudSources) {
                    this.refs.cloudSources.beginPricingLookup(mfp);
                }
            }.bind(this));
        }.bind(this);

        if(animate && hide)
            target.slideUp(finished);
        else if(animate && !hide)
            target.slideDown(finished);
        else {
            if(hide)
                target.hide();
            else if(!hide)
                target.show();

            finished();
        }
    }
    getMfp() {
        var mfpField = this.refs.form.getFields().firstOrNull(f=>{ return f.props.field.FieldName == 'ManufacturerPartNumber' });

        if(mfpField) {
            return mfpField.getValue();
        } else {
            return this.props.product.ManufacturerPartNumber;
        }
    }
    customizeInput(formField, style) {
        if(app.currentQuote.QuoteStatus === 'Won' && formField.props.field.FieldName !== 'Cost' && formField.props.field.FieldName !== 'RecurringCost') {
            formField.state.disabled = true;
        }

        if(formField.props.field.FieldName === 'OverridePrice' || formField.props.field.FieldName === 'QuoteItemPrice') {
            if(this.props.product.PriceModifier === 'PRICEOVERRIDE') {
                style.fontStyle = 'italic';
                style.fontWeight = 'bold';
            }
        }
        if(formField.props.field.FieldName === 'RecurringAmount') {
            if(this.props.product.RecurringPrice !== undefined) {
                formField.props.field.Value = this.props.product.RecurringPrice;
            }
            if(this.props.product.RecurringPriceModifier === 'PRICEOVERRIDE') {
                style.fontStyle = 'italic';
                style.fontWeight = 'bold';
            }
        }
        if(formField.props.field.FieldName === 'OverridePriceModifier') {
            if(formField.props.field.Value === "") {
                formField.props.field.Value = this.props.product.PriceModifier;
            }
        }
        if(formField.props.field.FieldName === 'RecurringPriceModifier') {
            if(formField.props.field.Value === "") {
                formField.props.field.Value = this.props.product.RecurringCalculatedPriceModifier;
            }
        }
        if(formField.props.field.FieldName === 'RampPeriods') {
            formField.state.disabled = !this.props.product.IsRampable;
        }
        if(formField.props.field.FieldName === 'DefermentPeriods') {
            formField.state.disabled = !this.props.product.IsDeferrable;
        }
        if (formField.props.field.FieldName === 'ManufacturerPartNumber'
                && (this.props.quoteTab && this.props.quoteTab.HasLimitOnNewItems) && !quosal.util.userIsAdminOrMaintainer() && !app.currentUser.IsStandardPlus) {
            formField.state.disabled = true;        
        } 
        if (formField.props.field.FieldName === 'ManufacturerPartNumber'
                && this.hasExternalQuote()) {
            formField.state.noLookup = true;        
        }      
        if (this.props.product.IsProtectedPrice && !this.state.userCanModifyProtectedPrice && quosal.util.isProtectedPriceItem(formField.props.field.FieldName))
        {
            formField.state.disabled = true;
        }

        if((formField.props.field.FieldName === 'ItemNotesHtml' || formField.props.field.FieldName == 'ItemNotes') && this.state.modTagMode) {
                formField.props.field.Value = this.props.product.ItemNotesHtml;               
            }
        
        if(formField.props.field.FieldName === 'IsModTagModified' ) {
            formField.state.disabled = true;
        }
        if(formField.props.field.FieldName == "IsProtectedItem" && !this.state.userCanModifyProtectedItem){
            formField.state.disabled = true;
        }
        if(formField.props.field.FieldName == "IsProtectedPrice" && !this.state.userCanModifyProtectedPrice){
            formField.state.disabled = true;
        }
        if (formField.props.field.FieldName === 'IsPhaseItem' && (!this.props.product.IsPackageHeader || this.props.product.InvoiceGroupingRecid)) {
			formField.props.field.Value = false;
			formField.state.disabled = true;
        }
        if (this.props.product.IsPackageHeader && this.props.product.InvoiceGroupingRecid) {
            if (formField.props.field.FieldName === 'Quantity' || formField.props.field.FieldName === 'PackageQty'|| formField.props.field.FieldName === 'ManufacturerPartNumber'
                || formField.props.field.FieldName === 'PriceModifier' || formField.props.field.FieldName === 'RecurringPriceModifier'
                || formField.props.field.FieldName === 'RecurringCalculatedPriceModifier' || formField.props.field.FieldName === 'OverridePriceModifier'
                || formField.props.field.FieldName === 'CostModifier' || formField.props.field.FieldName === 'RecurringCostModifier'
                || (formField.props.field.DataType === 'Double' && typeof formField.props.field.Value === 'number')) {
                formField.state.disabled = true;
            }
        }
        if (app.settings.global.UseAsioTaxService) {

            if (formField.props.field.FieldName === 'TaxCode' ||
                formField.props.field.FieldName === 'TaxRate' ||
                formField.props.field.FieldName === 'Tax') {

                formField.state.disabled = true;
            }
        }
    }
    customizeFields(fields) {
        fields.removeAll(function(f) {
            return f.FieldName == 'ItemNotes' || f.FieldName == 'OverridePrice' || f.FieldName == 'RecurringPrice'
        });
    }
    onNavigation(navState) {
        if (this.refs.form) {
            Form.onNavigation.call(this.refs.form, navState);
        }
    }
    onWindowUnloading(e) {
        Form.onWindowUnloading.call(this, e);
    }
    componentDidMount() {
        this.componentIsMounted = true;
        Form.hookUpNavigationActions.call(this);

        if(!this.state.hideSources && this.refs.cloudSources) {
            this.toggleSources(false, false, false);
        }

        var tab = app.currentQuote.Tabs.firstOrNull(s=>s.IdQuoteTabs == quosal.util.queryString('idquotetabs'));
        var isEditable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly && ((tab !== null && !tab.IsProtectedTab) || this.state.userCanModifyProtectedTab);
        if (this.props.product.IsProtectedItem && !this.state.userCanModifyProtectedItem){
            isEditable = false;
        }

        if(isEditable && tab && !tab.DisableAutoEtilizeSourcing && quosal.settings.getValue('useEtilize')) {
            this.etilizeLookup();
        }
    }
    componentWillUnmount() {
        this.componentIsMounted = false;
        Form.unhookNavigationActions.call(this);

        if(this.refs.cloudSources)
            this.refs.cloudSources.abortPricingLookup();

        if(this.etilizeApiCall){
            try {
                this.etilizeApiCall.abort();
            } catch(ex) {
                quosal.log.warn(ex);
            }
        }
    }
    render() {
        const isStandardUser = !app.currentUser.IsContentMaintainer && !app.currentUser.IsAdministrator && !app.currentUser.IsStandardPlus;
        const isLockStandardUsersOutOfItemEdit = quosal.settings.getValue('LockStandardUsersOutOfItemEdit');
        const isStandardUserAndLockItemEdit = isStandardUser && isLockStandardUsersOutOfItemEdit;

        if (!quosal.settings.getValue('UseEditQuoteCKEditor') ) {
            if (!(app.currentUser.IsContentMaintainer
                  || app.currentUser.IsAdministrator 
                  || app.currentUser.IsStandardPlus 
                  || !(quosal.settings.getValue('LockStandardUsersOutOfItemEdit'))
                )) {
                    return <div className="prose">Standard users may not access Item Edit.</div>
            }
        }

        if(this.state.loadingMessage) {
            return (
                <FormPlaceholder message={this.state.loadingMessage} />
            );
        }

        var isEditable = !app.currentQuote.IsLocked && !app.currentUser.IsReadOnly;
        if (this.props.quoteTab.IsProtectedTab && !this.state.userCanModifyProtectedTab) {
            isEditable = false;
        }
        if (this.props.product.IsProtectedItem && !this.state.userCanModifyProtectedItem){
            isEditable = false;
        }
        if (isStandardUserAndLockItemEdit){
            isEditable = false;
        }
        
        var disallowNew = false;
        if (this.props.quoteTab !== null){
            disallowNew =  this.props.quoteTab.HasLimitOnNewItems && !quosal.util.userIsAdminOrMaintainer() && !app.currentUser.IsStandardPlus;
        }
        var topToolbar = { position: 'top' };
        var toolProps = {
                key:'editToolbar',
                product: this.props.product,
                form: this,
                isEditable: isEditable,
                disallowNew: disallowNew,
                hasInvalidFields: this.state.invalidFields.length > 0
            };
        if (this.props.product.LineType == 'Comment') {
            toolProps.noEtilize = true;
        }
        topToolbar.control = <ProductEditToolbar {...toolProps} />;

        var bottomToolbar = { position: 'bottom' };
        bottomToolbar.control = <ProductEditToolbar {...toolProps}/>;

        if(this.props.product.LineType == 'Comment') {
            var notesField = {
                FieldName: 'ItemNotesHtml',
                DataType: 'String',
                WidgetType: 'rtfeditor',
                WidgetParameters: '{"urlParameters": ["itemid"]}',
                IsEditable: isEditable
            };

            var rtfChanged = function(params) {
                params = params || {};
                if(this.refs.form.state.isDirty)
                    this.fieldChanged(null, null, null, params.callback);
            }.bind(this);

            var onNavigation = function (navState) { Form.onNavigation.call(this.refs.form, navState); }.bind(this);

            var componentDidMount = function (formFieldInput) {
                formFieldInput.prepareThenSave = function (saveCallback) {
                    if (typeof this.beforeSave === 'function') {
                        this.beforeSave(saveCallback);
                    } else {
                        saveCallback();
                    }
                }.bind(formFieldInput);
            };

            return (
                <div>
                    {topToolbar.control}
                    <Panel title="Comments" allowFullscreen={true}>
                        <PanelContent>
                            <FormFieldInput ref="form" field={notesField} item={this.props.product} dirtyStateChanged={rtfChanged} componentDidMount={componentDidMount}
                                            onNavigation={onNavigation} onReset={this.onReset} onSubmit={this.saveForm} />
                        </PanelContent>
                    </Panel>
                </div>
            );
        } else {
            var priceClickCallback = isEditable ? function (price) {
                this.refs.form.getFields().firstOrNull(field => field.props.field.FieldName === 'QuoteItemPrice').setValue(price);
            }.bind(this) : null;

            return (
                <div>
                    <div className="grid col4x1" style={{float:'left'}}>
                        {
                            isEditable ?
                                <CloudSourcePanel ref="cloudSources" hidden={this.state.hideSources} getMfp={this.getMfp} form={this} 
                                        item={this.props.product} type="cloudsourcing" sourceUpdated={this.props.onUpdate} locked={this.hasExternalQuote()}
                                        onMetadata={this.metadataLoaded} onClose={this.toggleSources.bind(this, true, true)} />
                                : null
                        }

                        {this.state.additionalThumbnails ? <ProductImagesPanel images={this.state.additionalThumbnails} item={this.props.product} /> : ''}
                        <ProductTotalsPanel form={this} product={this.props.product}/>
                        <ProductPriceHistoryPanel form={this} product={this.props.product} priceClickCallback={priceClickCallback} hoverTitlePrice='Update price with historical value' 
                                                                    hoverTitleQuote='View quote in separate tab'/>
                    </div>
                    <Form ref="form" disabled={this.state.isSaving || !isEditable} onSubmit={this.saveForm} noNavigationActions={true} productEditForm={this} customizeInput={this.customizeInput} product={this.props.product}
                          onChange={this.fieldChanged} onReset={this.onReset} configuration={this.props.configuration} formControls={[topToolbar, bottomToolbar]} additionalThumbnails={this.state.additionalThumbnails}
                          fieldCustomizer={this.customizeFields} />
                </div>
            );
        }
    }
}


class ProductEditToolbar extends React.Component {
    render() {
         return (
            <Panel>
                <PanelToolbar style={{display:'block'}}>
                    <CwWidgets.CwButton toolTip="Previous Product" onClick={this.props.form.previousProduct} icon="img/svgs//v1.0/Action_Back.svg" />
                    <CwWidgets.CwButton toolTip="Next Product" onClick={this.props.form.nextProduct} icon="img/svgs/v1.0/Action_Forward.svg" />
                    <CwWidgets.CwButton toolTip="Create New Product" onClick={this.props.form.createProduct.bind(this.props.form, null, null)} 
                                            icon="img/svgs/v1.0/Action_AddNew.svg" disabled={this.props.disallowNew || !this.props.isEditable}/>
                    <CwWidgets.CwButton toolTip="Create New Comment Line" onClick={this.props.form.createComment} icon="img/svgs/v1.0/Action_Comment.svg" 
                                            disabled={!this.props.isEditable} />
                    <CwWidgets.CwButton toolTip="Delete This Product" onClick={this.props.form.deleteProduct} icon="img/svgs/v1.0/Action_Delete.svg" 
                                            disabled={!this.props.isEditable} />
                    {this.props.noEtilize || !quosal.settings.getValue('useEtilize') ? 
                        null : 
                        <CwWidgets.CwButton toolTip="Update Content from Etilize" value="ETILIZE" onClick={this.props.form.etilizeLookup} 
                                disabled={!this.props.isEditable || this.props.disableEtilize} /> }
                    {this.props.noEtilize || !this.props.isEditable || this.props.disableEtilize || !quosal.settings.getValue('useEtilize') 
                                || !this.props.form.state.etilizeUpdating ? 
                        null : 
                         <Spinner />}

                     {app.settings.global.enableCCWCatalog.toLowerCase()==='true' ?
                         <CwWidgets.CwButton tooltip="Update Content from Cisco" value="CISCO" onClick={this.props.form.ciscoLookup} />
                         : null}

                     <SaveButton text="Save and Close" style={{ float: 'right' }} disabled={!this.props.form.state.isDirty || this.props.form.state.isSaving || this.props.hasInvalidFields} 
                                    isSaving={this.props.form.state.isSaving} onClick={this.props.form.saveAndCloseForm} visible={this.props.isEditable} />
                    <SaveButton style={{float:'right', marginRight:10}} disabled={!this.props.form.state.isDirty || this.props.form.state.isSaving || this.props.hasInvalidFields} 
                                    isSaving={this.props.form.state.isSaving} onClick={this.props.form.saveForm} visible={this.props.isEditable} />
                    <Button type="cancel" style={{float:'right', marginRight:10}} disabled={!this.props.form.state.isDirty} 
                                    onClick={this.props.form.cancelChanges} visible={this.props.isEditable}>Cancel</Button>
                </PanelToolbar>
            </Panel>
        );
    }
}

class ProductImagesPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focusImage: null, 
            updatingImages: [],
        };

        this.imageSelected = this.imageSelected.bind(this);
        this.setImage = this.setImage.bind(this);
        this.onStartHover = this.onStartHover.bind(this);
        this.onEndHover = this.onEndHover.bind(this);
    }
    componentDidMount(){
        this.componentIsMounted = true;
    }
    componentWillUnmount(){
        this.componentIsMounted = false;
    }
    imageSelected(e) {
        this.setImage('Thumbnail', this.state.focusImage);
    }
    setImage(type, src) {
        this.state.updatingImages.push(src);
        this.forceUpdate();

        var fields = {};
        fields[type] = src;

        var updateApi = quosal.api.data.update({
            fields: fields,
            queries: [{
                table: 'QuoteItems',
                where: [{
                    field: 'IdQuoteItems',
                    operator: 'Equals',
                    value: this.props.item.IdQuoteItems
                }]
            }]
        }, this.props.item.IdQuoteMain);
        updateApi.finished = function(msg) {
            quosal.sell.quote.updateFromApiResponse(msg);

            this.state.updatingImages.removeAll(s=>s == src);
            this.forceUpdate();
        }.bind(this);
        updateApi.call();
    }
    onStartHover(e) {
        if(this.componentIsMounted) {
            var src = e.currentTarget.children[0].src;
            this.setState({focusImage: src});
        }
    }
    onEndHover(e) {
        if(this.componentIsMounted)
            this.setState({focusImage: null});
    }
    render() {
        var imageUrls = this.props.images;

        if (!imageUrls && this.props.imageEval) {
            //this was to support this as a form widget, may not be necessary now
            imageUrls = Function('return ' + this.props.imageEval)();
        }

        if (imageUrls == null || imageUrls.length == 0) {
            return <div />;
        }

        var images = [];
        for (var i = 0; i < imageUrls.length; i++) {
            var hoverIcon = null;

            if (this.state.updatingImages.indexOf(imageUrls[i]) >= 0) {
                hoverIcon = <Spinner style={{position:'absolute', float:'right', marginLeft:-20}} />;
            } else if (this.state.focusImage == imageUrls[i]) {
                var actions = [{
                    title: 'Assign to Thumbnail',
                    callback: function() { this.setImage('Thumbnail', this.state.focusImage); }.bind(this)
                }, {
                    title: 'Assign to Picture',
                    callback: function() { this.setImage('Picture', this.state.focusImage); }.bind(this)
                }, {
                    title: 'Assign to Custom Picture 1',
                    callback: function() { this.setImage('Z_customPicture1', this.state.focusImage); }.bind(this)
                }, {
                    title: 'Assign to Custom Picture 2',
                    callback: function() { this.setImage('Z_customPicture2', this.state.focusImage); }.bind(this)
                }, {
                    title: 'Assign to Custom Picture 3',
                    callback: function() { this.setImage('Z_customPicture3', this.state.focusImage); }.bind(this)
                }, {
                    title: 'Assign to Custom Picture 4',
                    callback: function() { this.setImage('Z_customPicture4', this.state.focusImage); }.bind(this)
                }];

                hoverIcon = <ActionsMenu name="massupdate" actions={actions} style={{position:'absolute', float:'right', marginLeft:-30}} iconHasBackground={true} />;
            }

            images.push(
                <div key={'thumb' + i} style={{display:'inline-block'}} onMouseEnter={this.onStartHover} onMouseLeave={this.onEndHover} title="Click to assign this image to Thumbnail.">
                    <img className="productimage" src={imageUrls[i]} onClick={this.imageSelected} />
                    {hoverIcon}
                </div>
            );
        }

        return (
            <Panel>
                <PanelTitle>Additional Images</PanelTitle>
                <PanelContent>
                    <div id="thumbnails">
                        {images}
                    </div>
                </PanelContent>
            </Panel>
        );
    }
}

class ProductTotalsPanel extends React.Component {
    render() {
        var fontStyle = {fontSize:'16px'};
        var priceFontStyle = {fontSize:'16px', textAlign:"right"};

        return (
            <Panel>
                <PanelTitle>Product Totals</PanelTitle>
                <PanelContent>
                    <table cellPadding="0" cellSpacing="0" width="195">
                        <tbody>
                            <tr>
                                <td className="content" style={fontStyle}>Extended:</td>
                                <td className="rightcontent" style={priceFontStyle}>{app.currentQuote.formatCurrency(this.props.product.ExtendedPrice)}</td>
                            </tr>
                            <tr>
                                <td className="content" style={fontStyle}>Recurring:</td>
                                <td className="rightcontent" style={priceFontStyle}>{app.currentQuote.formatCurrency(this.props.product.RecurringTotal)}</td>
                            </tr>
                        </tbody>
                    </table>
                </PanelContent>
            </Panel>
        );
    }
}

class ProductEditMFPField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.updateSources = this.updateSources.bind(this);
        this.getValue = this.getValue.bind(this);
    }
    updateSources() {
        this.props.formField.props.form.props.productEditForm.toggleSources(false, true, true, this.props.currentValue);
    }
    getValue() {
        return this.refs.input.value;
    }
    render() {
        var hasExternal = this.props.formField.state.noLookup === true;
        var disableInput = this.props.formField.isDisabled();
        var cloudDisabled = disableInput || hasExternal || String.isNullOrEmpty(this.props.currentValue) || this.props.currentValue.toUpperCase() === 'NEW';

        return (
            <div>
                <input ref="input" type="text" id={this.props.fieldId} style={this.props.style} disabled={disableInput} key={'ProductEditPartNumberField'} name={this.props.field.FieldName} value={this.props.currentValue} onChange={this.props.handleChange} onKeyPress={this.props.keyPressed} />
                <div id="lookupprices2" className={'icon field cloud' + (cloudDisabled ? ' disabled' : '')} title="Refresh Price Sources" style={{marginLeft: -22}} onClick={cloudDisabled ? null : this.updateSources}></div>
            </div>
        );
    }
}
global.ProductEditMFPField = ProductEditMFPField;