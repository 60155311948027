import React, { memo, useMemo } from "react";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Checkbox, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import EditorToToolbarWrapper from "../EditorToToolbarWrapper";
import useEditorWrapperHelper from "../EditorWrapper/editorWrapperHelpers";
import { ResizerComponent } from "../Resizer/ResizerComponent";
import TabsWrapperComponent from "../TabsWrapper";
import { AppGlobalProvider, useAppGlobalContext } from "../context/globalContext";
import { PreviewContentProvider } from "../context/previewContentContext";
import { getCKEditorTabsIds } from "../helpers/getCKEditorTabsIds";
import { useCPQEditorContainer } from "./CPQEditorContainer";

function CreatePreviewContent({contentGrid}) {
	const { ckeditor, selectedTabs, setSelectedTabs, isQuoteTemplate } = useAppGlobalContext();
	const {
		insertItemsByTabs
	} = useEditorWrapperHelper();

	const hasUniqueSelectedTabs = useMemo(() => {
		const droppedIds = getCKEditorTabsIds(ckeditor?.id && ckeditor.getData());
		return selectedTabs.every((tab) => !droppedIds.includes(tab.tabId)) || selectedTabs.length !== droppedIds.length;
		
	}, [selectedTabs]);

	const selectAllTabsStyle = {
		fontSize: "1rem",
		textTransform: "uppercase",
		cursor: hasUniqueSelectedTabs ? "pointer" : "default",
		fontWeight: 500,
		opacity: hasUniqueSelectedTabs ? undefined : 0.38,
	};

	const toggleSelectAll = () => {
		if(selectedTabs.length === app.currentQuote.Tabs.length) {
			setSelectedTabs([])
		} else {
			setSelectedTabs(app.currentQuote.Tabs.map((tab)=> {return {tabId: tab.IdQuoteTabs, tabNumber: tab.TabNumber }}))
		}
	}

	const createContent = () => {
		const selectedTabIds = selectedTabs.map((tab) => tab.tabId);
		const addableTabs = app.currentQuote.Tabs.filter((tab) => selectedTabIds.includes(tab.IdQuoteTabs));
		ckeditor.setData("");
		const addableTabId = addableTabs.map((tab) => tab.IdQuoteTabs);
		const data = insertItemsByTabs(addableTabs, addableTabId);

		if (data !== "") {
			app.currentQuote.HTMLContentForAutoSaving = data;
			contentGrid.forceUpdate();
		}

		ckeditor.editing.view.focus();
	};

	const openDialog = function () {
		const editor = <div>This action will remove all data from the Preview Content and paste in all selected tabs. This action cannot be undone. </div>;
		Dialog.open({
			title: "Confirm Create Preview Content",
			onClose: Dialog.close,
			links: [{ title: "Cancel", callback: Dialog.close}, { title: "Create", callback: ()=> {createContent(); Dialog.close();}}],
			message: editor
		});
	}
	
	//Only show the create preview content checkbox and text if editing a quote template
	if(!isQuoteTemplate) {
		return null;
	}

	return <div style={{display:"flex", alignItems:"center", marginTop: "5px", marginBottom: "-10px", marginLeft: "4px"}}>
		<Checkbox
			checked={selectedTabs.length === app.currentQuote.Tabs.length}
			onClick={toggleSelectAll}
		/>
	<Typography style={selectAllTabsStyle} onClick={hasUniqueSelectedTabs ? (app.currentQuote.HTMLContentForAutoSaving ? openDialog : createContent) : undefined}>
		Create Preview Content
	</Typography>
	</div>
}

// eslint-disable-next-line import/prefer-default-export
function CPQEditorComponent({
	contentGrid,
	rowActions,
	handleCKEditorSelectedTabId,
	buildNotesDialog
}) {
	const {
		leftContentExpanded,
		rightContentExpanded,
		leftContentWidth,
		rightContentWidth,
		panelClasses,
		LEFT_HEADER,
		RIGHT_HEADER,
		isResizingAllowed,
		expandContent,
		handleContentResize,
	} = useCPQEditorContainer(contentGrid);
	const previewContentIsVisible = useMemo( ()=> {
		return quosal.util.isNewEditorEnabled() && quosal.util.isPreviewContentOpened()
	}, [app.settings.user.DocumentPreviewVisible])

	return (
		<div className="quoteContentWrapper">
			<DndProvider backend={HTML5Backend}>
				<AppGlobalProvider
					value={{
						contentGrid,
						rowActions,
						handleCKEditorSelectedTabId,
						buildNotesDialog
					}}
				>
					<PreviewContentProvider
					value={{
						currentQuote: app.currentQuote,
					}}
				>
					<Grid container spacing={2}>
						<Grid
							id="leftContent"
							item
							sx={{
								width: !previewContentIsVisible
									? "100%"
									: leftContentExpanded
										? "100%"
										: rightContentExpanded
											? 0
											: `${leftContentWidth}%`,
								display: !previewContentIsVisible ? "block" : rightContentExpanded ? "none" : "block"
							}}
						>
							<div className={panelClasses}>
								<h2 style={{marginRight:"auto"}}>{LEFT_HEADER}</h2>
								<div
									className={leftContentExpanded ? "collapseIcon" : "expandIcon"}
									onClick={() => expandContent("left")}
								/>
							</div>
							<CreatePreviewContent contentGrid={contentGrid} />
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									overflow: "auto"
								}}
							>
								<TabsWrapperComponent expanded={leftContentExpanded} />
							</Box>
						</Grid>
						{previewContentIsVisible &&
							<Grid
								item
								sx={{
									display:
										leftContentExpanded || rightContentExpanded || !isResizingAllowed ? "none" : "block"
								}}
							>
								<div className="panelDivider" style={{ position: "relative" }}>
									<ResizerComponent onResize={handleContentResize} />
									<DragIndicatorIcon />
								</div>
							</Grid>
						}
						{previewContentIsVisible &&
							<Grid
								id="rightContent"
								item
								sx={{
									width: rightContentExpanded
										? "100%"
										: leftContentExpanded
											? 0
											: `${rightContentWidth}%`,
									paddingLeft: "20px !important",
									display: leftContentExpanded ? "none" : "flex"
								}}
							>
								<Grid
									container
									justifyContent="center"
									sx={{ display: "block", float: "right", alignItems: "flex-start" }}
								>
									<EditorToToolbarWrapper
										panelClasses={panelClasses}
										RIGHT_HEADER={RIGHT_HEADER}
										rightContentExpanded={rightContentExpanded}
										expandContent={expandContent}
									/>
								</Grid>
							</Grid>
						}
					</Grid>
					</PreviewContentProvider>
				</AppGlobalProvider>
			</DndProvider>
		</div>
	);
}

export default memo(CPQEditorComponent);
