import { ThemeProvider } from '@mui/material/styles';
import { CreateSalesTrack } from "./createSalesTrack.jsx";
import { EditSalesTrack } from "./editSalesTrack.jsx";
import { Dialog } from "js/jsx/src/classes/dialog.jsx";
import { ProductCard } from "../../common/guidedSelling/product.jsx";
import { api } from "../../common/guidedSelling/api/api.js";
import { ConfigureForm } from './configureForm.jsx';
import { Button, Grid } from '@mui/material';
import { CardGridContainerStyled, SalesTrackTopBarStyled, SimpleSalesTrackTitleStyled } from '../../common/guidedSelling/styledComponents/commonStyled.js';
import AddIcon from '@mui/icons-material/Add';
import { getDesignTokens } from '../../common/guidedSelling/theme/adminGuidedSellingTheme.js';
import { createTheme } from '@mui/material/styles';
import { FormProvider } from './contexts/configureFormContext.js';
import { SalesTracksSearch } from '../../common/guidedSelling/components/salestracksSearch.jsx';
import { ENUM_KEY_RADIO, ENUM_KEY_SELECT } from './constants/createFormConstants.js';

export class SalesTracksPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: $('#dark-toggle').prop('checked'),
            isLoading: true,
            pageAction: 'salesTracks',
            salesTracks: [],
            salesTrackItem: {},
            editingItem: {},
            editingFormItem: {},
            productIndex: "",
        };
        //Bind this
        this.onAdd = this.onAdd.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onCreateForm = this.onCreateForm.bind(this);
        this.onError = this.onError.bind(this);
        this.createSalesTrack = this.createSalesTrack.bind(this);
        this.getAllSalesTracks = this.getAllSalesTracks.bind(this);
        this.linkToForm = this.linkToForm.bind(this);
        this.updateSalesTracks = this.updateSalesTracks.bind(this);
        this.changeThemeMode = this.changeThemeMode.bind(this);
        this.updateSelectedAndEditingItem = this.updateSelectedAndEditingItem.bind(this);
    }

    componentDidMount() {
        var me = this;
        if (window.location.search.includes('justLoggedIn=yes')) {
            $.credentialChecks.start();
        }
        document.body.style.backgroundColor = "#F3F3F3"
        this.componentIsMounted = true;
        this.changeThemeMode();
        this.getAllSalesTracks('');
    }

    changeThemeMode() {
        const $checkbox = $('#dark-toggle[type=checkbox]');
        const handleSaveThemeMode = (isChecked) => {
            this.setState({ ...this.state, mode: isChecked });
        }
        $checkbox.on('change', function (e) {
            handleSaveThemeMode(e.currentTarget.checked);
        });
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        const $checkbox = $('#dark-toggle[type=checkbox]');
        $checkbox.off('change');
    }

    onAdd = function() {
        this.setState({ ...this.state, pageAction: 'addSalesTrack' });
    }

    onEdit = function (editingItem) {
        this.setState({ ...this.state, pageAction: 'editSalesTrack', editingItem });
    }

    onDelete = function ({ id, name }) {
        Dialog.open({
            title: 'Delete?',
            closeButton: true,
            width: "400px",
            message: `Are you sure you want to Delete the sales track ${name}? On deleting it, all the information for the sales track will be permanently removed.`,
            links: [Dialog.links.cancel, { 
                title: 'Delete', 
                id:"salestrack-btn-delete", 
                callback: () => {
                    this.handleDelete(id);
                    Dialog.closeAll();
                } 
            }]
        });
    }
    
    handleDelete = function (id) {
        api.deleteSalesTrack(id, () => {
            api.getAllSalesTracks('', (msg) => {
                this.setState({
                    ...this.state,
                    salesTracks: msg.salesTracks,
                    pageAction: 'salesTracks',
                });
            });
        }, (msg) => {
            this.onError('Error', msg.error);
        });
    }

    onCancel = function() {
        this.setState({ ...this.state, pageAction: 'salesTracks' });
    }

    onCreateForm = function() {
        this.setState({ ...this.state, pageAction: 'createForm' });
    }

    onError = function (title, message) {
        Dialog.open({
            title: title,
            message: message,
            links: [Dialog.links.ok]
        });
    }

    getAllSalesTracks(search) {
        api.getAllSalesTracks(search, (msg) => {
            this.setState({
                ...this.state,
                salesTracks: msg.salesTracks
            });
        });
    }

    updateSalesTracks() {
        api.getAllSalesTracks("", (msg) => {
            const { salesTrackItem } = this.state;
            const { salesTracks } = msg;
            function isNewSalesTrack(salesTrack) {
                return salesTrack.id === salesTrackItem.IdSalesTracks;
            }

            const newSalesTrackItem = salesTracks.find(isNewSalesTrack);

            this.setState({
                ...this.state,
                salesTracks: salesTracks,
                salesTrackItem: newSalesTrackItem,
                editingItem: newSalesTrackItem,
             });
        }, (msg) => {
            this.onError(msg.error);
        });
    }

    updateSelectedAndEditingItem = function (salesTrack) {
        this.setState({
            ...this.state,
            salesTrackItem: salesTrack,
            editingItem: {
                ...this.state.editingItem,
                id:salesTrack.id,
                pictureLink: salesTrack.PictureLink,
                modifyDate: salesTrack.ModifyDate,
                description: salesTrack.SalesTracksDescription,
                name: salesTrack.SalesTracksName,
                notes: salesTrack.SalesTracksNotes,
                state: salesTrack.State,
                isDeleted: salesTrack.IsDeleted,
                quoteTemplateId: salesTrack.IdQuoteMain,
            },
        });
    }


    createSalesTrack = function (salesTrack, saveImageFunction) {
        api.createSalesTrack(salesTrack, (msg) => {
            const newSalesTrackSelected = msg.saletrack;
            saveImageFunction(newSalesTrackSelected.id);
            this.updateSelectedAndEditingItem(newSalesTrackSelected)

        }, (msg) => {
            this.onError('Error', msg.error);
        });
    }


    linkToForm = function () {
        const { id } = this.state.editingItem;
        
        api.getSalesTrackForm(
			id,
			(msg) => {
				if (msg.sections && msg.sections.length > 0) {
					this.setState({
						...this.state,
						pageAction: "editForm",
						editingFormItem: msg.sections
					});
				} else {
					this.setState({ ...this.state, pageAction: "editForm", editingFormItem: [] });
				}
			},
			(msg) => {
				this.onError("Error", msg.error);
			}
		);
    }

    render() {   
        const NEW_THEME = createTheme(getDesignTokens(this.state.mode ? 'dark' : 'light'));

        const pagesHandler = {
            'salesTracks': () => <>
                <GuidedSellingHeader theme={NEW_THEME} key="salesTrackContainer-header" onAdd={this.onAdd} />
                <SalesTrackContainer theme={NEW_THEME} key="salesTrackContainer" onEdit={this.onEdit} getAllSalesTracks={this.getAllSalesTracks} salesTracks={this.state.salesTracks} onDelete={this.onDelete}/>
            </>,
            'editSalesTrack': () => <EditSalesTrack theme={NEW_THEME} salesTracksItem={this.state.editingItem} onCancel={this.onCancel} onDelete={this.onDelete} linkToForm={this.linkToForm} createSalesTrack={this.createSalesTrack} />,
            'addSalesTrack': () => <CreateSalesTrack theme={NEW_THEME} onCancel={this.onCancel} onCreateForm={this.onCreateForm} createSalesTrack={this.createSalesTrack} updateSalesTracks={this.updateSalesTracks} />,
            'createForm': () => <FormProvider>
                <ConfigureForm theme={NEW_THEME} onCancel={this.onCancel} updateSalesTracks={this.getAllSalesTracks} onBack={this.onCancel} salesTrackItem={this.state.salesTrackItem} onEdit={this.onEdit} />
            </FormProvider>,
            'editForm': () => <FormProvider>
                <ConfigureForm theme={NEW_THEME} onCancel={this.onCancel} updateSalesTracks={this.getAllSalesTracks} onBack={this.onCancel} salesTrackItem={this.state.editingItem} editingFormItem={this.state.editingFormItem} onEdit={this.onEdit} />
            </FormProvider>,

        }

        return (
            <ThemeProvider theme={NEW_THEME}>
                {pagesHandler[this.state.pageAction]()}
            </ThemeProvider>
        )
    }
}


class GuidedSellingHeader extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {

        const { palette: { text } } = this.props.theme;

        return (
            <>
                <SalesTrackTopBarStyled>
                    <SimpleSalesTrackTitleStyled variant="h2" className="salesTrack-title">Sales Tracks</SimpleSalesTrackTitleStyled>
                    <Button id={`salestracks-add-salestrack-button`} color="button_primary" sx={{ color: text.inverse, textTransform: 'none' }} variant="contained" endIcon={<AddIcon />} onClick={this.props.onAdd}>Add</Button>
                </SalesTrackTopBarStyled>
            </>
        );
    }
}

class SalesTrackContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            results: [],
        };

        api.getAllSalesTracks("", (msg) => {
            this.setState({ ...this.state, results: msg.salesTracks });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.salesTracks.length !== this.props.salesTracks.length) {
            this.setState({...this.state, results: this.props.salesTracks});
        }
    }

    render() {
        const { results } = this.state;
        const onFetchResults = (results) => this.setState({ ...this.state, results });
        const handleOpenMenu = (index) => {
            this.setState({
                ...this.state,
                productIndex: index === this.state.productIndex ? "" : index,
            });
        }

        return (
            <CardGridContainerStyled direction="column" container >
                <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                    <SalesTracksSearch onFetchResults={onFetchResults} />
                </Grid>
                <Grid item xs={12} container rowSpacing={2.5} direction="row">
                    {results.map((product, index) => 
                        <ProductCard 
                            key={"guidedSellingProductCard-item-" + index} 
                            product={product} 
                            kebab={true}
                            menuOpen={this.state.productIndex === product.id}
                            onEdit={this.props.onEdit}
                            onDelete={this.props.onDelete} 
                            toggleMenu={handleOpenMenu} />)
                    }
                </Grid>
            </CardGridContainerStyled>
        );
    }
}