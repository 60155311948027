import { Box, Stack } from "@mui/material";
import { CardContainer } from "../../common/components/CardContainer";
import { CustomerDetailItem } from "./CustomerDetailItem";
import { useQuoteContext } from "../../context";

export const CustomerDetails = ({isCollapsed}) => {
	const { MainCust } = useQuoteContext();

	return (
		<CardContainer sx={{display: "flex", flex:"1", height: "auto", minWidth:"230px"}} styleOutlineOverrides={{display: "flex", flex:"1"}}>
			<Stack
				direction="column"
				sx={{justifyContent: "center", padding: "1px", gap: "4px", width:"100%" }}
			>
				<Stack
					direction="row"
					sx={{ height: "49px", alignItems: "center", padding: "1px" }}
				>
					<Box sx={{ marginRight: "5px", height: "38px", gap: "2px", minWidth: "100px", flex:1, overflow: "hidden" }}>
						<CustomerDetailItem title="Customer" value={MainCust.AccountName} />
					</Box>
					<Box sx={{height: "38px", gap: "2px", minWidth: "100px", flex:1, overflow: "hidden" }}>
						<CustomerDetailItem title="Email" value={MainCust.EmailAddress} />
					</Box>
				</Stack>
				{(!isCollapsed && (
					<Stack direction="row" sx={{ height: "49px", padding: "1px" }}>
						<Box sx={{ flex:1, overflow: "hidden", height: "38px", gap: "2px", minWidth: "100px" }}>
							<CustomerDetailItem
								title="Contact"
								value={`${MainCust.FirstName}  ${MainCust.LastName}`}
							/>
						</Box>
						<Box sx={{ height: "38px", gap: "2px", minWidth: "100px", flex:1, overflow: "hidden" }}>
							<CustomerDetailItem title="Phone" value={MainCust.DayPhone} />
						</Box>
					</Stack>
				)) ||
					null}
			</Stack>
		</CardContainer>
	);
};
