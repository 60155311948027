import {CustomerSetupToolbar} from "js/jsx/src/classes/quote/quoteCustomerSetupToolbar.jsx";
import {SimpleTab} from "js/jsx/src/classes/tab/simpleTab.jsx";
import {EditableImage} from "js/jsx/src/classes/imageEditor.jsx";

export class QuoteCustomerDetails extends React.Component {
    constructor(props) {
        super(props);

        //SP: fix for IE not having object.assign
        quosal.util.objectAssign();
        this.customerSaveObj = {};
        this.mountedCustomerDetail = null;

        this.tabList = [
            {name:"All Account Roles", usageType: "All Customers", component:<CustomerDetailInfo key="CustomerDetailInfo_0" parent={this}  usageType="All Customers"/>},
            {name:"Quote To", usageType: "Quote To", component:<CustomerDetailInfo key="CustomerDetailInfo_1" parent={this}  usageType="Quote To"/>},
            {name: "Bill To", usageType: "Bill To", component:<CustomerDetailInfo key="CustomerDetailInfo_2" parent={this} usageType="Bill To"/>},
            {name: "Send To", usageType: "Send To", component:<CustomerDetailInfo key="CustomerDetailInfo_3" parent={this} usageType="Send To"/>},
            {name: "Ship To", usageType: "Ship To", component:<CustomerDetailInfo key="CustomerDetailInfo_4" parent={this} usageType="Ship To"/>}
            ]
        this.usageTypeArray = ["Quote To", "Bill To", "Send To", "Ship To", "All Customers"];

        this.accountFields = ["AccountName", "AccountNumber", "Website", "Description", "PriceLevelName", "PaymentTerms"];
        this.siteAddressFields = ["Address1", "Address2", "City", "State", "PostalCode", "Country"]
        this.contactFields = ["Title", "FirstName", "LastName", "JobTitle", "EmailAddress", "DayPhone", "MobilePhone"]
        this.editableCustomerFields = [
            {id: "AccountName", name: "Account Name", isRequiredField: true}, 
            {id: "AccountNumber", name: "Account Number", isRequiredField: false},
            {id: "Website", name: "Website", isRequiredField: false},
            {id: "Description", name: "Account Description", isRequiredField: false},
            {id: "PriceLevelName", name: "Price Level", isRequiredField: false},
            {id: "PaymentTerms", name: "Payment Terms", isRequiredField: false},
            {id: "Address1", name: "Address 1", isRequiredField: false},
            {id: "Address2", name: "Address 2", isRequiredField: false},
            {id: "City", name: "City", isRequiredField: false},
            {id: "State", name: "State", isRequiredField: false},
            {id: "PostalCode", name: "Postal Code", isRequiredField: false},
            {id: "Country", name: "Country", isRequiredField: false},
            {id: "Title", name: "Title", column:1, isRequiredField: false},
            {id: "FirstName", name: "First Name", column: 1, isRequiredField: true},
            {id: "LastName", name: "Last Name", column: 1, isRequiredField: true},
            {id: "JobTitle", name: "Job Title", column: 1, isRequiredField: false},
            {id: "EmailAddress", name: "Email", column: 2, isRequiredField: true},
            {id: "DayPhone", name: "Day Phone", column: 2, isRequiredField: false},
            {id: "MobilePhone", name: "Mobile Phone", column: 2, isRequiredField: false}
        ]

        this.state = {     
            hasTriedToSave : false,
            isDirty: false, 
            showCopyIcons: true
        }; 

        // This binding is necessary to make `this` work in the callback
        this.cancelButonClick = this.cancelButonClick.bind(this); 
        this.copySiteAddressToAllTabs = this.copySiteAddressToAllTabs.bind(this);
        this.copyAllFieldsToAllTabs = this.copyAllFieldsToAllTabs.bind(this);
        this.copyFieldsToAllTabs = this.copyFieldsToAllTabs.bind(this);
        this.getPreviousCustomerInfo = this.getPreviousCustomerInfo.bind(this);
        this.getAlteredCustomerInfo = this.getAlteredCustomerInfo.bind(this);
        this.hasTriedToSave = this.hasTriedToSave.bind(this);
        this.setPreviousUsageType = this.setPreviousUsageType.bind(this);
        this.showIcons = this.showIcons.bind(this);
        this.validate = this.validate.bind(this);
        this.saveCustomerDetails = this.saveCustomerDetails.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
    }
    componentDidMount(){
        this.componentIsMounted = true;		
        if(app.currentQuote.StatusFlags.HasCustomer == false && quosal.util.areCustomersPresent()){
            this.validate();
            this.setState({hasTriedToSave: true});
        }
    }
     componentWillUnmount(){
        this.componentIsMounted = false;
    }
    cancelButonClick(){
        this.customerSaveObj = {};
        this.reloadModule();
    }
    copySiteAddressToAllTabs(){
        this.copyFieldsToAllTabs(this.siteAddressFields);
    }
    copyAllFieldsToAllTabs(){
        var fields = this.editableCustomerFields.map(function(fieldObj){
            return fieldObj.id
        });
        this.copyFieldsToAllTabs(fields);
    }
    copyFieldsToAllTabs(fieldArray){
        var me = this;
        this.setState({isDirty: true});
        var tempPrevUsageType = me.previousUsageType == "All Customers" ? "Primary" : me.previousUsageType;
        var allQuoteCustomerInfo = app.currentQuote.Customers.reduce(function(foundValue, cust){
            return foundValue || (cust.UsageType == tempPrevUsageType && cust);
        }, null);
        var quoteCustomerInfo = fieldArray.reduce(function(map, field){
            map[field] = allQuoteCustomerInfo[field]
            return map;
        }, {})
        var currentCustomerToCopy = Object.assign({}, quoteCustomerInfo)
        if(me.customerSaveObj[me.previousUsageType] && me.customerSaveObj[me.previousUsageType]["newValues"])
        {
           currentCustomerToCopy = Object.assign(currentCustomerToCopy, me.customerSaveObj[me.previousUsageType]["newValues"])
        }
        this.usageTypeArray.forEach(function (usageType) {
            var allCurrentUsageTypeCustomerInfo = app.currentQuote.Customers.reduce(function (foundValue, cust) {
                var tempUsageType = usageType == "All Customers" ? "Primary" : usageType;
                return foundValue || (cust.UsageType == tempUsageType && cust);
            }, null);
            var currentUsageTypeCustomerInfo = fieldArray.reduce(function (map, field) {
                map[field] = allCurrentUsageTypeCustomerInfo[field];
                return map;
            }, {});
            if (!me.customerSaveObj[usageType]) {
                me.customerSaveObj[usageType] = { UsageType: usageType, IdQuoteCustomers: allCurrentUsageTypeCustomerInfo.IdQuoteCustomers, newValues: {} };
            }
            fieldArray.forEach(function (field) {
                me.customerSaveObj[usageType]["newValues"][field] = currentCustomerToCopy[field];
            });
            if(quosal.settings.getValue("customerProvider").toLowerCase() == "connectwise"){
                me.customerSaveObj[usageType].newValues['CustomerSourceId'] = '';
                me.customerSaveObj[usageType].newValues['LocationId'] = '';
            }
        });

       
        if(this.state.hasTriedToSave){
            this.validate();
        }
    }
    getPreviousCustomerInfo(){
        var me = this;
        var customerObj = Object.assign({}, app.currentQuote.Customers.reduce(function(foundValue, cust){
            return foundValue || (cust.UsageType == me.previousUsageType && cust);
        }, null))
        if(this.customerSaveObj && this.customerSaveObj[this.previousUsageType])
        {
            Object.assign(customerObj, this.customerSaveObj[this.previousUsageType].newValues)
        }
        return customerObj;
    }
    getAlteredCustomerInfo(usageType){
        var customerObj = {}
        if(this.customerSaveObj && this.customerSaveObj[usageType]){
            customerObj = this.customerSaveObj[usageType].newValues
        }
        return customerObj;

    }
    getCustomerInfo(usageType){
        var customer = app.currentQuote.Customers.filter(function(cust){
            return cust.UsageType == usageType;
        })
        if(customer){
            return customer[0];
        }
    }
    hasTriedToSave(){
        return this.state.hasTriedToSave;
    }
    setPreviousUsageType(usageType){
        this.previousUsageType = usageType;
    }
    showIcons(showIconsBool){
        this.setState({showCopyIcons: showIconsBool});
    }
    validate(validateOnlyThisUsageType){
        var error = false;
        var requiredFields = Object.keys(this.editableCustomerFields).reduce(function(previous, key){
            if(this.editableCustomerFields[key].isRequiredField == true){
                previous.push(this.editableCustomerFields[key].id);
            }
            return previous;
        }.bind(this), [])
        var usageTypesToEvaluate = this.usageTypeArray;
        if(validateOnlyThisUsageType){
            usageTypesToEvaluate = [validateOnlyThisUsageType];
        }
        usageTypesToEvaluate.forEach(function(usageType){
            var quoteCustomer = app.currentQuote.Customers.reduce(function(foundValue, cust){
                var tempUsageType = usageType
                if(usageType == "All Customers"){
                    tempUsageType = "Primary";
                }
                return foundValue || (cust.UsageType == tempUsageType && cust);
            }, null)
            var tab = this.tabList.reduce(function(foundValue, tab){
                if(tab.usageType == usageType){
                    return tab;
                } else{
                    return foundValue;
                }
            }, null)
            var copy = Object.assign({}, quoteCustomer)
            if(this.customerSaveObj && this.customerSaveObj[usageType]){
                copy = Object.assign(copy, this.customerSaveObj[usageType]["newValues"] )
            }
            var tempError = false;
           
            requiredFields.forEach(function(field){
                var foundError = function(){
                    error = true;
                    tempError = true;
                    if(this.mountedCustomerDetail && (usageType == this.mountedCustomerDetail.props.usageType)){
                        $.quosal.validation.validateField($('#'+field), 'error', 'This field is required');
                    }
                }.bind(this);
                $.quosal.validation.clearField($('#'+field));

                $.quosal.validation.clearField($('#'+"error_"+tab.id));
                if(field == "LastName" && !app.settings.user.RequireLastNameForBillAndShipTo && (usageType == "Bill To" || usageType == "Ship To" || usageType == "All Customers")){
                    return;
                }
                if(field == "EmailAddress" && !quosal.util.isValidEmail(copy[field]) ){
                    foundError();
                }
                else if (copy[field] == '') {
                    foundError();
                }
            }.bind(this))
            if(tempError){
                $.quosal.validation.validateField($('#'+"error_"+tab.id), 'error', usageType +' is missing one or more required fields');
            }
        }.bind(this));
        return error;
    }
    saveCustomerDetails(){
        var me = this;
        for(var key in this.customerSaveObj){
            Object.keys(this.customerSaveObj[key].newValues).forEach(function(subkey){
                me.customerSaveObj[key].newValues[subkey] = me.customerSaveObj[key].newValues[subkey].trim();
            })
        }
        
        if(this.validate()){
            this.setState({hasTriedToSave: true},
            ()=> this.mountedCustomerDetail.forceUpdate())
            return;
        }
        this.setState({isSaving:true});
        var afterUpdate = (function (msg) {
            var openQuoteApi = quosal.api.quote.openQuote(quosal.util.queryString('idquotemain'));
            openQuoteApi.finished = function (msg) {
                quosal.sell.quote.updateFromApiResponse(msg);
                if(this.componentIsMounted){
                    this.setState({ isDirty: false, isSaving: false });
                    this.customerSaveObj = {};
                    this.props.onUpdate();
                    this.mountedCustomerDetail.forceUpdate();
                    Dialog.setIsWorking(false);
                    app.currentModule.loadSubModule('quote.home', {
                        container: 'quoteModule',
                        unloadSubModules: true
                    });
                }
            }.bind(this);
            openQuoteApi.call();
        }).bind(this);

        var updates = [];
        for(var key in this.customerSaveObj) {
            var updateValue = {
                fields: this.customerSaveObj[key].newValues,
                queries: [{
                    table: 'QuoteCustomers',
                    where: [{
                        field: 'IdQuoteCustomers',
                        operator: 'Equals',
                        value: this.customerSaveObj[key].IdQuoteCustomers
                    }]
                }]
            };
            updates.push(updateValue);
        }

        Dialog.setIsWorking();        
        var updateApi = quosal.api.data.update(updates, this.props.quote.IdQuoteMain);
        updateApi.finished = function (msg) {            
            afterUpdate(msg);
        }
        updateApi.call();           
        
        return true;
    }
    reloadModule(){
        app.currentModule.loadSubModule('quote.customerdetails', {
            container: 'quoteModule',
            bypassBeforeLoadEvent: true
        });
    }
    updateCustomer(usageType, fieldName, fieldValue, idQuoteCustomers, updateState = true ){
        if(!this.customerSaveObj[usageType]){
            this.customerSaveObj[usageType] = {UsageType: usageType, IdQuoteCustomers: idQuoteCustomers, newValues:{}}
        }
        this.customerSaveObj[usageType].newValues[fieldName] = fieldValue;

        if(quosal.settings.getValue("customerProvider").toLowerCase() == "connectwise"){
            this.customerSaveObj[usageType].newValues['CustomerSourceId'] = '';
            this.customerSaveObj[usageType].newValues['LocationId'] = '';
        }
        if(this.state.hasTriedToSave){
            this.validate(usageType);
        }
        this.previousUsageType = usageType;
        if(updateState){
            this.setState({isDirty: true});
        }
    }
    render() {
        var isLockedDown = app.currentQuote.IsLocked || app.currentUser.IsReadOnly;
		
        return ( 
            <div>
                <CustomerSetupToolbar submoduleName="quote.customerdetails" resetState={this.resetState} onUpdate={this.reloadModule} isDirty={this.state.isDirty} isSaving={this.state.isSaving} cancelCallback={this.cancelButonClick} saveCallback={this.saveCustomerDetails} quote={this.props.quote}/>
                <Panel>
                    <PanelTitle>
                        <span id="CustomerDetailsId" style={{float:'left'}}>Customer Details</span>
                        {this.state.showCopyIcons  &&
                        <div className="toolbar" style={{float:'right'}}>
                        {!isLockedDown && <button style={{float:'right', marginRight:10}}  onClick={this.copyAllFieldsToAllTabs} target="_blank" title="Copy All Fields To All Tabs" >Copy All</button>}
                        {!isLockedDown && <button style={{float:'right', marginRight:10}}  onClick={this.copySiteAddressToAllTabs} target="_blank" title="Copy Site Address To All Tabs" >Copy Address</button>}
                        </div>
                        }
                    </PanelTitle>
                    <SimpleTab tabList={this.tabList}/>
                </Panel>
            </div>
        )
    }
}
class CustomerDetailInfo extends React.Component {
    constructor(props) {
        super(props);

        var me = this;
        this.updateCustomerInformation();

        this.state = {                       
        }; 

        // This binding is necessary to make `this` work in the callback
        this.inputChanged = this.inputChanged.bind(this);
        this.getInputFields = this.getInputFields.bind(this);
        this.getInputStyle = this.getInputStyle.bind(this);
        this.getAllCustomerIcon = this.getAllCustomerIcon.bind(this);
        this.compareCustomer = this.compareCustomer.bind(this);
        this.isFormEmpty = this.isFormEmpty.bind(this);
        this.updateCustomerIfEmptyForm = this.updateCustomerIfEmptyForm.bind(this);
        this.updateCustomerInformation = this.updateCustomerInformation.bind(this);
    }

    inputChanged(fieldName){
        var value = $("#"+fieldName).val();
        this.props.parent.updateCustomer(this.props.usageType, fieldName, value, this.customer.IdQuoteCustomers)
        this.customer[fieldName] = value;
        if(this.props.usageType == "All Customers"){
            this.props.parent.copyFieldsToAllTabs([fieldName]);
        }
        this.forceUpdate();
    }
    componentDidMount(){
        this.props.parent.mountedCustomerDetail = this;
        if(this.props.parent.hasTriedToSave()){
            this.props.parent.validate(this.props.usageType);
        }
        this.props.parent.setPreviousUsageType(this.props.usageType);
        this.props.parent.showIcons(this.props.usageType != "All Customers")
    }
    UNSAFE_componentWillUpdate(){
        if(this.props.parent.hasTriedToSave()){
            this.props.parent.validate(this.props.usageType);
        }
        this.updateCustomerInformation();
    }
    getInputFields(fieldArray, column, usageType){
        var me = this;
        return fieldArray.map(function(field, index){
            var fieldObj = me.props.parent.editableCustomerFields.reduce(function(foundValue, subfield){
                if(field == subfield.id){
                    return subfield
                }
                else{
                    return foundValue
                }
            }, null);
            if((column && fieldObj.column == column) || !column){
                var checkIfDisabled = function(){
                    if (app.currentQuote.IsLocked || app.currentUser.IsReadOnly){
                        return true;
                    }
                    else if(fieldObj.id == "AccountName"){
                       if(quosal.util.getCrmShortName() == 'sf'){
                            return !quosal.settings.getValue('salesForceEnableAccountNameEditing') && app.currentQuote.StatusFlags.HasCustomer ? true : false;
                       } else {
                           return app.currentQuote.StatusFlags.HasCustomer ? true : false;
                       }
                    }
                }
                var requiredMarker = true;
                if((fieldObj.id == "LastName" && !app.settings.user.RequireLastNameForBillAndShipTo && (usageType == "Bill To" || usageType == "Ship To" || usageType == "All Customers"))){
                    requiredMarker = false;
                }
                return(
                    <div key={"inputgroup_"+index}>
                        {!fieldObj.isRequiredField && <label className="formlabel">{fieldObj.name}</label>}
                        {fieldObj.isRequiredField && requiredMarker && <label data-cy="reqiredFieldMarker" className="formlabel" title="this is a required field"><span style={{color:"red", fontSize:"11px"}}>*</span>{fieldObj.name}</label>}
                        {fieldObj.isRequiredField && !requiredMarker && <label className="formlabel"><span style={{fontSize:"11px"}}> </span>{fieldObj.name}</label>}
                        <div className="formField">
                            <input id={fieldObj.id} disabled={checkIfDisabled()} onChange={me.inputChanged.bind(me, fieldObj.id)} value={me.customer[fieldObj.id]} style={me.getInputStyle(fieldObj.id)}/>
                            { me.getAllCustomerIcon(fieldObj, index)}   
                        </div>
                    </div>
                )
            } 
        })
    }
    getInputStyle(fieldName){
        var changedFields = this.props.parent.getAlteredCustomerInfo(this.props.usageType)
        var inputStyle = {display: "inline-block", marginBottom: "5px", width:"200px", height: "18px"};
        if (fieldName in changedFields){
            inputStyle.backgroundColor = '#FFFFCC';
        }
        return inputStyle
    }
    getAllCustomerIcon(fieldObj, index){
        var me = this;
        if(this.customerDifferentFields){
            return this.customerDifferentFields.reduce(function(array, field){
                if(field == fieldObj.id && array.length==0){
                    array.push(field)
                }
                return array;
            },[]).map(function(field, idx){
                if(field == fieldObj.id){
                    return (
                        <div className="statusWarning" title={fieldObj.name + " has different values on multiple tabs\nChanging " + fieldObj.name + " here will overwrite all other values for " + fieldObj.name} key={"errorIcon_"+me.props.usageType+idx} style={{width: "22px", height: "22px", marginLeft:"4px", verticalAlign:"middle",
                            display:"inline-block", backgroundImage:'url(img/svgs/v1.0/Status_Warning.svg)'}}>
                        </div>
                    )
                }
            })
        }
    }
    compareCustomer(tempCustomer){
        var me = this;
        this.props.parent.editableCustomerFields.forEach(function(fieldObj){
            if(tempCustomer[fieldObj.id] != me.customer[fieldObj.id]){
                me.customerDifferentFields.push(fieldObj.id);
            }
        })
    }
    isFormEmpty(){
        var allEmpty = true;
        var me = this;
        this.props.parent.editableCustomerFields.some(function(fieldObj){
            if($.trim(me.customer[fieldObj.id]) !== ''){
                allEmpty = false;
                return true
            }
        })
        return allEmpty;
    }
    updateCustomerIfEmptyForm(){
        if(this.isFormEmpty()){
            var me = this;
            var previousCustomer = this.props.parent.getPreviousCustomerInfo();
            Object.keys(previousCustomer).forEach(function(fieldName){
                var value = previousCustomer[fieldName];
                me.props.parent.updateCustomer(me.props.usageType, fieldName, value, me.IdQuoteCustomers, false)
                me.customer[fieldName] = value;
            })
        }
    }
    updateCustomerInformation(){
        var me = this;
        if(this.props.usageType == "All Customers"){
            this.customer = Object.assign({}, app.currentQuote.Customers.reduce(function(foundValue, cust){
                return foundValue || (cust.UsageType === "Primary" && cust);
            }, null));
            this.customer = Object.assign(this.customer, this.props.parent.getAlteredCustomerInfo(this.props.usageType));
            this.customerDifferentFields = [];
            this.props.parent.usageTypeArray.forEach(function(usageType){
                if(usageType == "All Customers"){
                    return
                }
                var tempCustomer = Object.assign({}, app.currentQuote.Customers.reduce(function(foundValue, cust){
                    if(cust.UsageType == usageType){
                        return cust;
                    }
                    else{
                        return foundValue;
                    }
                }));
                tempCustomer = Object.assign(tempCustomer, me.props.parent.getAlteredCustomerInfo(usageType));
                me.compareCustomer(tempCustomer);
            });
        }
        else{
            this.customer = Object.assign({}, app.currentQuote.Customers.reduce(function(foundValue, cust){
                if(cust.UsageType == me.props.usageType){
                    return cust;
                }
                else{
                    return foundValue;
                }
            }, null))
            this.customer = Object.assign(this.customer, this.props.parent.getAlteredCustomerInfo(this.props.usageType));
            this.IdQuoteCustomers = this.customer.IdQuoteCustomers;
        }
    }
   
    render(){
        return(
            <Panel>
                <div style={{display: "inline-block", padding: "0 10px", width: "260px", marginBottom:"30px"}}>
                    <div className="title" style={{fontWeight: "normal"}}>
                        Account
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "10px"}}>
                    {this.getInputFields(this.props.parent.accountFields, null, this.props.usageType)}
                        {this.props.usageType == "Quote To" && 
                        <div>
                            <label style={this.getInputStyle()} className="formlabel">Account Picture</label>
                            <div className="imgcol headercol">
                                <EditableImage src={this.customer.Picture} disabled={app.currentQuote.IsLocked || app.currentUser.IsReadOnly} imageId={'QuoteToPicture'} norescale={true} IdQuoteMain={app.currentQuote.IdQuoteMain} sizeInPixels="64px"/>
                            </div>
                        </div>}
                    </div>
                </div>
                <div style={{display: "inline-block", padding: "0 10px", width: "260px", verticalAlign:"top", marginBottom:"30px"}}>
                    <div className="title" style={{fontWeight: "normal"}}>
                        Site Address
                    </div>
                    <div style={{marginLeft: "20px", marginTop: "10px"}}>
                    {this.getInputFields(this.props.parent.siteAddressFields, null, this.props.usageType)}
                    </div>
                </div>
                <div style={{display: "inline-block", padding: "0 10px", width: "550px", verticalAlign: "top", marginBottom:"30px"}}>
                    <div className="title" style={{fontWeight: "normal"}}>
                        Contact
                    </div>
                    <div style={{marginLeft: "20px", width:"550px", marginTop: "10px"}}>
                        <div style={{display: "inline-block",  paddingRight: "5px", width:"260px"}}>
                        {this.getInputFields(this.props.parent.contactFields, 1, this.props.usageType)}
                        </div>
                        <div style={{display: "inline-block", width:"260px", paddingLeft: "20px", marginBottom: "10px", verticalAlign: "top"}}>
                        {this.getInputFields(this.props.parent.contactFields, 2, this.props.usageType)}
                        </div>
                    </div>
                </div>
            </Panel>
        )
    }
}