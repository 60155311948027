import { Box } from "@mui/material";
import { CommonBreadCrumbs } from "../../common/guidedSelling/components/commonBreadCrumbs.jsx";
import { CommonButton } from "../../common/guidedSelling/styledComponents/commonButton.js";
import SalesTrackForm from "./salesTrackForm.jsx";

export class CreateSalesTrack extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    render() {
        const onSave = () => this.formRef.current.requestSubmit();
        const breadCrumbsData = {
            actualBreadCrumbName: "Add New Sales Track",
            breadCrumbs: [
                {
                    name: 'Sales Tracks',
                    navigate: this.props.onCancel,
                }
            ],
        }
        
        return (
            <Box className="createsalestrack-container">
                <CommonBreadCrumbs {...breadCrumbsData} />
                <Box className='createsalestrack-header'>
                    <h2 className="salesTrack-title">Add New Sales Track</h2>
                    <Buttons onCancel={this.props.onCancel} onCreateForm={this.props.onCreateForm} onSave={onSave}/>
                </Box>
                <SalesTrackForm ref={this.formRef} createSalesTrack={this.props.createSalesTrack} updateSalesTracks={this.props.updateSalesTracks} onCreateForm={this.props.onCreateForm} />
            </Box>
        )
    }
}

class Buttons extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Box className="salestrack-buttons-bar">
                <CommonButton id='new-salestrack-cancel-button' variant="outlined" onClick={this.props.onCancel} >Cancel</CommonButton>
                <CommonButton id='new-salestrack-save-button' variant="contained" onClick={this.props.onSave} >Save</CommonButton>
            </Box>
        )
    }
}


